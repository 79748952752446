/** @format */

import { COMPONENT_RADIOBUTTON } from "../constants/typeConstants";
import common from "./common";
/**
 * factory function to produce Radio Button Component Object
 * @param {String} name
 * @param {Array} children array
 */
export default function(name = "Radio_Button", children = []) {
    let obj = {
        name: name,
        type: COMPONENT_RADIOBUTTON,
        children: children,
    };
    obj = Object.assign({}, obj, common);
    delete obj.properties;
    obj.properties = {
        properties: {},
    };
    obj.properties.styles = {
        width: "40px",
        height: "40px",
    };
    obj.properties.customClassName = "checkbox_default";
    // obj.label = label;
    return obj;
    // return Object.assign(obj, common);
}