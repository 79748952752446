/** @format */

import React, { Component } from "react";
import { BrowserRouter } from "react-router-dom";
import { DefaultLayout } from "./components/Site/containers/layouts";
import { DndProvider } from "react-dnd";
import Backend from "react-dnd-html5-backend";
import ReactTooltip from "react-tooltip";
import { ToastContainer } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";
import "./assets/alphadnd-styling.css";

// Mohkam's COde pasted by osama

class App extends Component {
  render() {
    return (
      <div>
        <ReactTooltip />
        <DndProvider backend={Backend}>
          <BrowserRouter>
            <DefaultLayout />
          </BrowserRouter>{" "}
        </DndProvider>{" "}
        <ToastContainer />
      </div>
    );
  }
}
export default App;
