import { toast } from "react-toastify";

export function apiCallError(error) {
  if (error.response) {
    toast.error(JSON.stringify(error.response.data));
    ////(error.response.data);
    ////(error.response.status);
    ////(error.response.headers);
  } else if (error.request) {
    // The request was made but no response was received
    toast.error("The request was made but no response was received");
    //(error.request);
  } else {
    // Something happened in setting up the request that triggered an Error
    toast.error(error.message);
  }
  ////("error");
  ////(error.ERROR);
  //toast.error(JSON.stringify(error));
}
