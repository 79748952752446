import React from "react";
import FontAwesomeIconsList from "../../utils/FontAwesomeIconsList";
import { addCustomClassName } from "../../../../store/actions/action-functions/addCustomClassName";

const IconProperties = ({ component }) => {
  return (
    <div
      style={{
        width: "350px",
        height: "400",
        display: "grid",
        gridTemplateColumns: "auto auto auto auto auto auto auto ",
        overflow: "auto",
        useSelect: "none",
        gridColumnGap: "10px",
        gridRowGap: "20px"
      }}
    >
      {FontAwesomeIconsList.map((icon, index) => (
        <i
          style={{ cursor: "pointer" }}
          onClick={() => addCustomClassName(component.id, icon)}
          key={index}
          className={icon}
        ></i>
      ))}
    </div>
  );
};

export default IconProperties;
