import React, { Component } from "react";
import Dimension from "./dimension";
import "./showFewProperties.css";
import { duplicateComponent } from "../../../store/actions/action-functions/duplicateComponent";
import { connect } from "react-redux";
import {
  // changeComponentAttribute,
  cutComponent,
  deleteComponent,
  changeStyle
} from "../../../store/actions/designerActions";
import {
  moveComponentUp,
  moveComponentDown
} from "../../../store/actions/action-functions/moveComponent";
import ColorProperties from "./ColorProperties";
class ShowFewProperties extends Component {
  state = {};
  render() {
    const { component } = this.props;
    const { selectedPropertiesScreenID } = this.props.designer;
    const disable = this.isHorizontal(component.type);
    return (
      <div
        className="showFew"
        style={{
          display: "grid",
          gridTemplateColumns: "auto",
          boxSizing: "border-box",
          textAlign: "center"
        }}
      >
        {/* <div>
          <i
            title="Rename"
            onClick={() => alert("yet to implement")}
            className="fas fa-edit  rightSideIcons "
            aria-hidden="true"
          ></i>
        </div> */}
        <div>
          <span className="rightSideIcons">
            {" "}
            <ColorProperties name="backgroundColor" component={component} />
          </span>
        </div>
        <div>
          <i
            title="Duplicate"
            onClick={() => duplicateComponent(selectedPropertiesScreenID)}
            className="fas fa-clone  rightSideIcons"
            aria-hidden="true"
          ></i>
        </div>
        {/* <div>
          <i
            title="Cut"
            onClick={() => cutComponent(selectedPropertiesScreenID)}
            className="fas fa-cut  rightSideIcons "
            aria-hidden="true"
          ></i>
        </div> */}
        {/* <div>
          <i
            title="Copy"
            onClick={() => alert("yet to implement")}
            className="fas fa-copy  rightSideIcons"
            aria-hidden="true"
          ></i>
        </div> */}
        {/* <div>
          <i
            title="Paste"
            onClick={() => alert("yet to implement")}
            className="fa fa-paste  rightSideIcons "
            aria-hidden="true"
          ></i>
        </div> */}
        <div>
          <i
            title="Delete"
            onClick={() => deleteComponent(selectedPropertiesScreenID)}
            className="fa fa-trash  rightSideIcons"
            aria-hidden="true"
          ></i>
        </div>
        <div>
          <i
            title="Move Up"
            onClick={() => moveComponentUp(selectedPropertiesScreenID)}
            className="fa fa-arrow-up  rightSideIcons "
            aria-hidden="true"
          ></i>
        </div>
        <div>
          <i
            title="Move Down"
            onClick={() => moveComponentDown(selectedPropertiesScreenID)}
            className="fa fa-arrow-down  rightSideIcons"
            aria-hidden="true"
          ></i>
        </div>
        <React.Fragment>
          <div>
            <i
              title="Align Left"
              className="fas fa-align-left  rightSideIcons "
              onClick={() => this.handlingContentAlign("left")}
            />
          </div>
          <div>
            <i
              title="Align Center"
              className="fas fa-align-center  rightSideIcons"
              onClick={() => this.handleAlignCenter("center")}
            />
          </div>
          <div>
            <i
              title="Align Right"
              className="fas fa-align-right  rightSideIcons "
              name="right"
              onClick={() => this.handlingContentAlign("right")}
            />
          </div>
        </React.Fragment>
      </div>
    );
  }
  isHorizontal(type) {
    return type == "LinearLayout_Horizontal";
  }
  handlingContentAlign = e => {
    changeStyle(this.props.component.id, "textAlign", "");
    changeStyle(this.props.component.id, "float", e);
  };
  handleAlignCenter = e => {
    changeStyle(this.props.component.id, "float", "");
    changeStyle(this.props.component.id, "textAlign", e);
  };
}
const mapPropsToState = state => {
  return state;
};
export default connect(mapPropsToState)(ShowFewProperties);
