import React, { Component } from "react";
import "./Designertoolbar.css";

class DesignerToolbar extends Component {
  render() {
    const { openNavigation, toggleNavigation } = this.props;
    const designerToolBarStyles = {};
    return (
      <div style={designerToolBarStyles}>
        <i
          onClick={toggleNavigation}
          className={openNavigation ? "fas fa-times " : "fas fa-bars"}
          style={{ cursor: "pointer", fontSize: "20px" }}
        />
        {/* <button onClick={toggleNavigation}>
          {openNavigation ? (
            <i className="fas fa-times" />
          ) : (
            <i class="far fa-bars" />
          )}
        </button> */}
      </div>
    );
  }
}

export default DesignerToolbar;
