/** @format */

import React from "react";
import DesignerContextMenuTrigger from "./../../DesignerNavigation/ContextMenu/DesignerContextMenuTrigger";
import componentSelecter from "./ComponentChooser/SelectComponent";
import { useSelector } from "react-redux";
import "./../activitydesigner.css";

/**
 * isdragging hover coming from layouts
 */

const ComponentViewerSelector = ({ component, isDragging, hover, disableDrag }) => {
  const id = component.id;
  const { components } = useSelector((state) => state.designer);
  //("ComponentViewerSelector Disable Drag", disableDrag);
  return disableDrag ? (
    selectComponentViewer()
  ) : (
      // <DesignerContextMenuTrigger id={id} components={components}>
      // {
      selectComponentViewer()
      // }
    // </DesignerContextMenuTrigger>
  );

  function selectComponentViewer() {
    if (!id) return "";
    return componentSelecter(id, component.type, hover, isDragging);
  }
};

export default ComponentViewerSelector;
