/** @format */

import React, { useEffect, useRef } from "react";
import ComponentViewerSelector from "./ComponentViewerSelector";
import { DropTarget, DragSource } from "react-dnd";
// import { styleChanged } from "./../../Properties/styleActions";
import {
  dropSpecs,
  dropCollect,
  dragSpec,
  dragCollect,
} from "./utils/dndForHorizentalLayouts";
import "./selectedComponentCustomBorders.css";
import {
  addPropertiesObjectToComponent,
  addStylesObjectToComponent,
  changePropertiesComponent,
} from "../../../../store/actions/designerActions";
import flow from "lodash/flow";
import MaterialPopOver from "./Popover/MaterialPopOver";
import LayoutProperties from "./LayoutProperties/LayoutProperties";
import { getLayoutStyleObject } from "../../Properties/utils/layoutStyleObject";
import { getDimensionValue } from "../../Properties/utils/changeToHtmlUnits";
import { toast } from "react-toastify";
import { Resizable } from "re-resizable";
import { changeStyleBulk } from "../../../../store/actions/action-functions/changeStyleBulk";
import { useState } from "react";
import { basicProps } from "./shared/BasicProps";
import BorderLayout from "./LayoutBorder";

const ComponentViewerVertical = ({
  id,
  connectDropTarget,
  connectDragSource,
  hover,
}) => {
  const [state, setState] = useState({
    oW: null,
    oH: null,
    editable: false,
    anchorEl: null,
  });
  const parentRef = useRef();
  const basicObject = basicProps();
  useEffect(() => {
    // let oW = Math.round(parentRef.current.clientWidth);
    // let oH = Math.round(parentRef.current.clientHeight);
    // setState({ ...state, oW, oH });
  }, []);
  const handleClose = (e) => {
    setState({ ...state, anchorEl: null });
  };
  const hanldeDoubleClick = (e) => {
    e.stopPropagation();
    setState({ ...state, anchorEl: e.currentTarget });
  };

  const { selectedPropertiesScreenID } = basicObject;
  const showBorders = id === selectedPropertiesScreenID || hover || basicObject.dragInProcess;
  // const padding = hover ? "1px" : "0";
  const component = basicObject.components[id];
  let children = [];
  const screens = basicObject.components[2].children;
  const defaultLayout = screens.find((screen) => screen == component.parent_id);
  if (component.children) {
    children = component.children;
  }

  const i = component.id;
  if (!component.properties) {
    addPropertiesObjectToComponent(i);
    return "";
  }
  if (!component.properties.styles) {
    addStylesObjectToComponent(i);
    return "";
  }
  let styles = {
    ...component.properties.styles,
  };

  if (id === selectedPropertiesScreenID) {
    styles = { ...styles };
  }

  let arrangingStyles = getLayoutStyleObject(
    id,
    basicObject.components,
    hover,
    selectedPropertiesScreenID
  );
  return (
    <div
      className="designer-vertial-layout"
      ref={(ins) => {
        connectDragSource(ins);
        connectDropTarget(ins);
      }}
      onClick={(e) => {
        e.stopPropagation();
        if (basicObject.disableSelection) {
          toast.warn("Layouts Cannot Be Data Binded");
        }
        if (!basicObject.disableDrag && !basicObject.disableSelection)
          changePropertiesComponent(id);
      }}
      onDoubleClick={(e) => {
        e.stopPropagation();
      }}
      style={component.properties.styles}
    >
      {showBorders && <BorderLayout hover={hover} />}
      {children.map((child, index) => (
        <ComponentViewerSelector
          component={basicObject.components[child]}
          id={child}
          forwardedRef={parentRef}
        />
      ))}
    </div>
  );
};
export default flow(
  DropTarget("modules", dropSpecs, (connect, monitor) =>
    dropCollect(connect, monitor)
  ),
  DragSource("modules", dragSpec, (connect, monitor) =>
    dragCollect(connect, monitor)
  )
)(ComponentViewerVertical);
