import GenericService from "./../../services/GenericService";
import baseFactory from "../../store/factories/baseFactory";
import newAppComponents from "./../../store/data/newAppComponents";

class ProjectsService extends GenericService {
  constructor() {
    super();
  }
  getPage = (page) =>
    new Promise((resolve, reject) => {
      this.get("projects/pagination/" + page)
        .then((data) => {
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  create = (data) =>
    new Promise((resolve, reject) => {
      var self = this;
      this.post("apps", {
        name: data.appName,
        appJSON: baseFactory(data.appName),
        components: newAppComponents,
      }).then((app) => {
        self
          .post("projects", { ...data, appId: app._id })
          .then((project) => {
            resolve(project);
          })
          .catch((err) => {
                            //(err);
                          });
      });
    });
  update = (id, data) => this.put("projects/" + id, data);
  deleteProject = (id) => this.delete("projects/" + id);
  getSingleProject = (id) => this.get("projects/" + id);
}

const projectsService = new ProjectsService();
export default projectsService;
