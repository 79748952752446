/** @format */

import React from "react";

import { Route, Switch, Redirect } from "react-router-dom";

import DesignerJSON from "../../AppDesigner/DesignerJSON/DesignerJSON";
import SchemaDesigner from "./../../SchemaDesigner/SchemaDesigner";
import AppList from "../ClientArea/AppList";
import AppDesignerLoader from "../../AppDesigner/AppDesignerLoader";
import BLLBodyWidget from "../../BLL/widgets/BLLBodyWidget";
import DesignerAssets from "../../AppDesigner/SideBar/DesignerAssets";

import Designer from "../../AppDesigner/Designer";

const ProtectedRoutes = () => {
  return (
    <Switch>
      <Route path="/designer/:id" exact component={AppDesignerLoader} />
      <Route path="/assets/:id" exact component={DesignerAssets} />
      <Route path="/json" exact component={DesignerJSON} />
      <Route path="/schema-designer" exact component={SchemaDesigner} />
      <Route path="/client-area/:current_page?" exact component={AppList} />
      <Route path="/designer" exact component={Designer} />
      <Route path="/bll/:id" exact children={<BLLBodyWidget />} />
      {/* <Route path="/bllvariables" exact children={<BLLVariables />} /> */}
      <Redirect path="*" to="/client-area" />
    </Switch>
  );
};

export default ProtectedRoutes;
