import {
    HEIGHLIGHT,
    HIGHLIGHT_KEY
} from "../../constants/action-types";


import produce from "immer";



export function heightLightComponent(id) {
    window.store.dispatch({
        type: HEIGHLIGHT,
        payLoad: {
            id
        }
    })
}
export function highLightKey(pair) {
    window.store.dispatch({
        type: HIGHLIGHT_KEY,
        payLoad: {
            pair
        }
    })
}
export function highLightKeyReducer(state, action) {
    const pair = action.payLoad.pair;
    return produce(state, draftState => {
        draftState.highLightKey = pair;
    })
}
export function heightLightComponentReducer(state, action) {
    const {
        id
    } = action.payLoad;
    return produce(state, draftState => {
        draftState.heightLightComponent = id
    })
}