/** @format */

import React, { Component } from "react";
import { ContextMenuTrigger } from "react-contextmenu";
import { COMPONENT_CONTEXT_MENU } from "./ContextMenuTypes";
import { withRouter } from "react-router-dom";

class DesignerContextMenuTrigger extends Component {
  state = {};
  render() {
    const {
      match: { params },
      history,
      id,
      components
    } = this.props;
    return (
      <ContextMenuTrigger
        id={COMPONENT_CONTEXT_MENU}
        holdToDisplay={-1}
        functions={components[1].functions}
        componentID={id}
        components={components}
        appId={params.id}
        history={history}
        collect={(props = this.props) => props}
        renderTag={"span"}
        className="context-menu-styles.react-contextmenu-wrapper"
      >
        {" "}
        {this.props.children}{" "}
      </ContextMenuTrigger>
    );
  }
}

export default withRouter(DesignerContextMenuTrigger);
