import {
    BINDED_IDS,
    REMOVE_BINDED_IDS
} from "../../constants/action-types";

import produce from "immer"
import {
    toast
} from "react-toastify";

export function manageBindedId(id) {
    window.store.dispatch({
        type: BINDED_IDS,
        payLoad: {
            id
        }
    })
}

export function removeBindedId(id) {
    window.store.dispatch({
        type: REMOVE_BINDED_IDS,
        payLoad: {
            id
        }
    })
}

export function removeBindedIdsReducer(state, action) {
    return produce(state, draftState => {
        const alreadyBinded = draftState.components[draftState.selectedPropertiesScreenID].fieldsForDatabase;
        let convertingToArray = alreadyBinded.split(",");
        const index = convertingToArray.indexOf(action.payLoad.id + "");
        if (index > -1) {
            convertingToArray.splice(index, 1);
            toast.info(convertingToArray[0]);
        }
        draftState.idsBinded = convertingToArray;
        draftState.components[draftState.selectedPropertiesScreenID].fieldsForDatabase = convertingToArray.join();
        toast.info("ID Removed From Binding:" + action.payLoad.id);
    })
}
export function managedBindedIdReducer(state, action) {
    return produce(state, draftState => {
        draftState.components[draftState.selectedPropertiesScreenID].isScopeDB = true;
        draftState.idsBinded.push(action.payLoad.id)
        draftState.components[draftState.selectedPropertiesScreenID].fieldsForDatabase = draftState.idsBinded.join();
        toast.info("ID BINDED NOW:" + action.payLoad.id);
    })
}