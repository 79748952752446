/** @format */

import React, { Component } from "react";
import { connect } from "react-redux";
import CanvasToolBar from "./CanvasToolBar";
import screenSizes, {
  screenOrientation,
} from "../../../store/constants/screen-info";
import { changePropertiesComponent } from "../../../store/actions/designerActions";
import "./../../AppDesigner/ComponentDesigner/activitydesigner.css";
import ActivityDesigner from "../ComponentDesigner/ScreenDesigner";
import left from "./left-arrow.png";
import right from "./right-arrow.png";
import wifi from "./wifi.png";
import signal from "./signal.png";
import battery from "./battery.png";
class Canvas extends Component {
  state = {
    screen: "600x960",
    orientation: screenOrientation.Vertical,
    defaultScale: 0.77,
    defaultScaleH: 0.8,
    borderColor: "#3472cd",
  };
  getTime() {
    let date = new Date();
    return date.getHours() + ":" + date.getMinutes();
  }
  handleIncrementDefaultScale = () => {
    let { defaultScale } = this.state;
    defaultScale += 0.05;
    if (defaultScale > 1.5) {
      return;
    }
    this.setState({ defaultScale });
  };
  handleDecrementDefaultScale = () => {
    let { defaultScale } = this.state;
    if (defaultScale < 0.1) return;
    defaultScale -= 0.05;
    if (defaultScale < 0.6) {
      // toast.error("Minimum Limit Reached");
      return;
    }
    this.setState({ defaultScale });
  };
  render() {
    const screenSizeObject = screenSizes.find(
      (s) => s.value === this.state.screen
    );
    let { width, height } = screenSizeObject;
    if (this.state.orientation === screenOrientation.Horizontal) {
      width = screenSizeObject.height;
      height = screenSizeObject.width;
    }

    const {
      components,
      selectedPropertiesScreenID,
      storyMode,
      transitionMode
    } = this.props.designer;
    const component = components[selectedPropertiesScreenID];
    const { defaultScale } = this.state;
    const { responseJSON } = component;
    window.responseJSON = responseJSON;
    const selectedScreenID = this.props.designer.selectedScreenID;
    const previousButton = this.disableButton(selectedScreenID, "previous");
    const nextButton = this.disableButton(selectedScreenID, "next");
    window.askedWidth = width;
    window.askedHeight = height;
    window.askedScale = defaultScale;
    const myDefaultWidth = 400 * defaultScale;
    const myDefaultHeight = 800 * this.state.defaultScaleH;
    const mobileResolutionWidth = myDefaultWidth / width - 0.009;
    const mobileResolutionHeight = myDefaultHeight / height - 0.095;
    const transformAdjustmentWidth = mobileResolutionWidth;
    window.transformAdjustment = transformAdjustmentWidth;
    return (
      <div className="canvas-toolbar">
        {/* <CanvasToolBar
          screen={this.state.screen}
          orientation={this.state.orientation}
          onScreenChange={this.handleScreenChange}
          defaultScale={defaultScale}
          components={components}
          onOrientationChange={this.handleOrientationChange}
          handleIncrementDefaultScale={this.handleIncrementDefaultScale}
          handleDecrementDefaultScale={this.handleDecrementDefaultScale}
        /> */}
        {/* <div className="canvas-design"> */}


        {!transitionMode && <div
          className="left-arrow"
          disabled={true}
          style={{
            pointerEvents: this.disableButton(selectedScreenID, "previous")
              ? "none"
              : "all",
          }}
          onClick={() => this.handlePreviousActivity(selectedScreenID)}
        >
          <img src={left} alt="" className="arrow-keys" />
        </div>}
        <div
          className="tablet app-designer-add-no-scroll noScrollBar"
          style={{
            position: "relative",
            transitionDuration: "0.5s",
            overflow: "hidden",
            marginTop: "10px",
            border: 0,
          }}
        >
          {/* <div className="tablet-topbar-styling">
            <img
              src={battery}
              alt=""
              srcset=""
              style={{ marginRight: "5px" }}
            />
            <img src={wifi} alt="" srcset="" />
            <img src={signal} alt="" srcset="" />
          </div> */}
          <div
            className="noScrollBar"
            style={{
              width: "400px",
              height: "700px",
            }}
          >
            <ActivityDesigner />
          </div>
        </div>
        {!transitionMode && <div
          className="right-arrow"
          style={{
            pointerEvents: this.disableButton(selectedScreenID, "next")
              ? "none"
              : "all",
          }}
        >
          <img
            src={right}
            alt=""
            className="arrow-keys"
            onClick={() => this.handleNextActivity()}
          />
        </div>}
      </div>
    );
  }

  disableButton = (selectedScreenID, button) => {
    const { components } = this.props.designer;
    let children = components[2].children;
    switch (button) {
      case "previous":
        return children.indexOf(selectedScreenID) <= 0;
      case "next":
        return children.indexOf(selectedScreenID) === children.length - 1;
      default:
        return false;
    }
  };

  handleNextActivity = () => {
    const { components, selectedScreenID } = this.props.designer;
    let children = components[2].children;
    if (children.indexOf(selectedScreenID) == children.length - 1) return;
    changePropertiesComponent(children[children.indexOf(selectedScreenID) + 1]);
  };
  handlePreviousActivity = () => {
    const { components, selectedScreenID } = this.props.designer;
    let children = components[2].children;
    if (children.length < 2) return;
    if (children.indexOf(selectedScreenID) <= 0) return;
    changePropertiesComponent(children[children.indexOf(selectedScreenID) - 1]);
  };
  handleScreenChange = (e) => {
    this.setState({
      screen: e.target.value,
    });
  };
  handleOrientationChange = () => {
    this.setState({
      orientation:
        this.state.orientation === screenOrientation.Vertical
          ? screenOrientation.Horizontal
          : screenOrientation.Vertical,
    });
  };
}

const mapStateToProps = (state) => {
  return state;
};
export default connect(mapStateToProps)(Canvas);
