import {
    CHANGE_SELECTED_NODE_PROPERTIES
} from "../../constants/action-types";
import {
    COMPONENT_ACTIVITY
} from "./../../constants/typeConstants";
import {
    changeCurrentSelectedActivity
} from "./changeCurrentSelectedActivity";
import {
    changeCurrentSelectedDialog
} from "./selectedDialogeId";

/**
 * change the properties component e.g. the component whose properties are being shown
 * @param {*} index of the component
 */
export function changePropertiesComponent(id) {
                                                const currentState = window.store.getState()
                                                  .designer;
                                                // //("Current sTATE");
                                                // //(currentState)
                                                // //(currentState)
                                                let activityIndex = id;
                                                const dialogeContainerId =
                                                  currentState.components[2]
                                                    .dialog;
                                                let selectedId = activityIndex;
                                                let myFlag = false;
                                                let p = null;
                                                // alert(currentState.components[selectedId].parent_id)
                                                while (selectedId !== 2) {
                                                  if (
                                                    currentState.components[
                                                      selectedId
                                                    ].parent_id ==
                                                    dialogeContainerId
                                                  ) {
                                                    myFlag = true;
                                                    p =
                                                      currentState.components[
                                                        selectedId
                                                      ];

                                                    break;
                                                  }
                                                  selectedId =
                                                    currentState.components[
                                                      selectedId
                                                    ].parent_id;
                                                }
                                                if (myFlag) {
                                                  changeCurrentSelectedActivity(
                                                    null
                                                  );
                                                  changeCurrentSelectedDialog(
                                                    p
                                                  );
                                                  // return
                                                }
                                                window.store.dispatch({
                                                  type: CHANGE_SELECTED_NODE_PROPERTIES,
                                                  payLoad: {
                                                    id,
                                                  },
                                                });
                                              }

export function changePropertiesComponentReducer(state, action) {
    let id = action.payLoad.id;
    const activity = getActivityComponent(id, state.components);
    if (!activity) return {
        ...state,
        selectedPropertiesScreenID: id
    };
    if (activity.id === state.selectedScreenID)
        return {
            ...state,
            selectedPropertiesScreenID: id
        };
    else
        return {
            ...state,
            selectedPropertiesScreenID: id,
            selectedScreenID: activity.id
        };
}

function getActivityComponent(id, components) {
    const component = components[id];
    if (!component) return null;
    if (component.type === COMPONENT_ACTIVITY) return component;
    else return getActivityComponent(component.parent_id, components);
}