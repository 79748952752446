export function isLayout(type) {
    switch (type) {
        case "LinearLayout_Horizontal":
            return true;
        case "LinearLayout_Vertical":
            return true;
        case "ListView":
            return true;
        default:
            return false;
    }
}