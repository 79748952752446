import React from "react";
import ComponentViewerSelector from "../ComponentViewer/ComponentViewerSelector";
import { getDimensionValue } from "../../Properties/utils/changeToHtmlUnits";
const Dialoge = ({ component, components, toolbarHeight }) => {
  //("Before Disaster");
  //(component);
  const { width: w, height: h } = component.properties.styles;
  const styles = {
    height: "100%",
    width: "100%"
    // backgroundColor: "white"
  };
  return (
    <div style={{ ...styles }} className="designer-toolbar">
      <ComponentViewerSelector component={component} components={components} />
    </div>
  );
};

export default Dialoge;
