/** @format */

import { isActivity } from "../../ComponentDesigner/ComponentViewer/utils/labelAllowed";
import { toast } from "react-toastify";
import { duplicateComponent } from "../../../../store/actions/action-functions/duplicateComponent";
import {
    cutComponent,
    deleteComponent,
    changeStyle,
} from "../../../../store/actions/designerActions";
import { revertCutStatus } from "../../../../store/actions/action-functions/cutComponent";
import { startStopBindings } from "../../../../store/actions/action-functions/startStopBindingProcess";
import { findComponentByType } from "../../../../store/constants/component_list";
import { cssEditor } from "../../../../store/actions/action-functions/showCssEditor";
import { storyMode } from "../../../../store/actions/action-functions/enableStoryMode";

export const shortKeyManager = (e, id, components, isCut) => {
                                                               const ifActivity = isActivity(
                                                                 components[id]
                                                                   .type
                                                               );
                                                               const ifBaseLayout = isActivity(
                                                                 components[
                                                                   components[
                                                                     id
                                                                   ].parent_id
                                                                 ].type
                                                               );
                                                               // //(components);
                                                               // //("Login Key")
                                                               // //(e.which);
                                                               if (e.ctrlKey) {
                                                                 if (
                                                                   e.which == 69
                                                                 ) {
                                                                   e.stopImmediatePropagation();
                                                                   e.preventDefault();
                                                                   //("Component Id", components[id]);
                                                                   cssEditor();
                                                                   return;
                                                                 }
                                                               }
                                                               // Above code should be removed
                                                               const ifThatComponentExists =
                                                                 components[id];

                                                               if (
                                                                 !ifActivity &&
                                                                 ifThatComponentExists &&
                                                                 Number(id) &&
                                                                 e.altKey
                                                               ) {
                                                                 const re = window.store.getState()
                                                                   .designer
                                                                   .selectedPropertiesScreenID;
                                                                 switch (
                                                                   e.which
                                                                 ) {
                                                                   case 69:
                                                                     e.stopImmediatePropagation();
                                                                     e.preventDefault();
                                                                     changeStyle(
                                                                       re,
                                                                       "backgroundColor",
                                                                       ""
                                                                     );
                                                                     break;
                                                                   case 72:
                                                                     //story mode enableing alt+s

                                                                     e.stopImmediatePropagation();
                                                                     e.preventDefault();
                                                                     storyMode(
                                                                       !window.store.getState()
                                                                         .designer
                                                                         .storyMode
                                                                     );
                                                                     break;
                                                                   case 81:
                                                                     // const re = window.store.getState().designer.selectedPropertiesScreenID;
                                                                     e.preventDefault();
                                                                     e.stopImmediatePropagation();
                                                                     changeStyle(
                                                                       re,
                                                                       "color",
                                                                       ""
                                                                     );
                                                                     break;

                                                                   case 66: //b key   alt+b
                                                                     const type =
                                                                       components[
                                                                         id
                                                                       ].type;
                                                                     if (
                                                                       findComponentByType(
                                                                         type
                                                                       )
                                                                         .isLayout
                                                                     ) {
                                                                       toast.warn(
                                                                         "Choose Another Component Curretly Layout is Selected"
                                                                       );
                                                                       return;
                                                                     }
                                                                     if (
                                                                       components[
                                                                         id
                                                                       ]
                                                                         .actionType !==
                                                                       ""
                                                                     ) {
                                                                       toast.info(
                                                                         "Starting Bindings With Respect To :" +
                                                                           id
                                                                       );
                                                                       startStopBindings(
                                                                         true
                                                                       );
                                                                     } else {
                                                                       toast.error(
                                                                         "Kindly Choose Action type First"
                                                                       );
                                                                     }
                                                                     break;
                                                                   default:
                                                                     break;
                                                                 }
                                                               }
                                                               if (
                                                                 !ifActivity &&
                                                                 ifThatComponentExists &&
                                                                 Number(id) &&
                                                                 e.ctrlKey
                                                               ) {
                                                                 const re = window.store.getState()
                                                                   .designer
                                                                   .selectedPropertiesScreenID;
                                                                 // e.preventDefault();
                                                                 switch (
                                                                   e.which
                                                                 ) {
                                                                   case 67: //Copying Will be done
                                                                     // toast.warn("Hellow", id);
                                                                     // //();
                                                                     if (
                                                                       !ifBaseLayout
                                                                     ) {
                                                                       e.stopImmediatePropagation();
                                                                       revertCutStatus();
                                                                       window.navigator.clipboard.writeText(
                                                                         id
                                                                       );
                                                                       toast.info(
                                                                         "Component Id:" +
                                                                           id +
                                                                           " Type:" +
                                                                           components[
                                                                             id
                                                                           ]
                                                                             .type +
                                                                           " Copied To Clip Board"
                                                                       );
                                                                     }
                                                                     return;
                                                                   case 68:
                                                                     e.preventDefault();
                                                                     e.stopImmediatePropagation();
                                                                     deleteComponent(
                                                                       re
                                                                     );
                                                                     toast.success(
                                                                       "Deleted Successfully"
                                                                     );
                                                                     return;
                                                                   case 86: //Pasting will be done
                                                                     if (
                                                                       checkingIfLayout(
                                                                         id,
                                                                         components
                                                                       )
                                                                     ) {
                                                                       // e.stopImmediatePropagation();

                                                                       const re = window.store.getState()
                                                                         .designer
                                                                         .selectedPropertiesScreenID;
                                                                       window.navigator.clipboard
                                                                         .readText()
                                                                         .then(
                                                                           (
                                                                             data
                                                                           ) => {
                                                                             const ifExists =
                                                                               components[
                                                                                 data
                                                                               ] &&
                                                                               true;
                                                                             //(ifExists);
                                                                             if (
                                                                               data &&
                                                                               data !=
                                                                                 "null" &&
                                                                               Number(
                                                                                 data
                                                                               ) &&
                                                                               ifExists
                                                                             ) {
                                                                               duplicateComponent(
                                                                                 data,
                                                                                 re
                                                                               );
                                                                               toast.success(
                                                                                 "Pasting Successfull"
                                                                               );
                                                                             }
                                                                           }
                                                                         );
                                                                       if (
                                                                         isCut
                                                                       )
                                                                         revertCutStatus();
                                                                     } else {
                                                                       toast.error(
                                                                         "Components Can Only Be Pasted On layout"
                                                                       );
                                                                     }
                                                                     return;
                                                                   case 88: // Cutting will be done
                                                                     if (
                                                                       !ifBaseLayout
                                                                     ) {
                                                                       cutComponent(
                                                                         id
                                                                       );
                                                                     }
                                                                     return;
                                                                   default:
                                                                     return;
                                                                 }
                                                               }
                                                               if (
                                                                 e.which === 27
                                                               ) {
                                                                 e.stopImmediatePropagation();

                                                                 e.preventDefault();
                                                                 startStopBindings(
                                                                   false
                                                                 );
                                                               }
                                                               if (
                                                                 e.which === 46
                                                               ) {
                                                                 e.stopImmediatePropagation();

                                                                 e.preventDefault();
                                                                 deleteComponent(
                                                                   id
                                                                 );
                                                                 toast.success(
                                                                   "Deleted Successfully"
                                                                 );
                                                                 return;
                                                               }
                                                             };

export function performPasting(id, components, isCut) {
    if (checkingIfLayout(id, components)) {
        window.navigator.clipboard.readText().then((data) => {
            const ifExists = components[data] && true;
            if (data && data != "null" && Number(data) && ifExists) {
                duplicateComponent(data, id);
                toast.success("Pasting Successfull");
            }
        });
        if (isCut) revertCutStatus();
    }
}

export function checkingIfLayout(id, components) {
    const type = components[id].type;
    if (
        type === "GridView" ||
        type === "LinearLayout_Horizontal" ||
        type === "LinearLayout_Vertical"
    )
        return true;
    return false;
}