/** @format */

import { useDrag } from "react-dnd";
import { useSelector } from "react-redux";

export function useDraggableComponent(id) {
  const { disableDrag, transitionMode } = useSelector((state) => state.designer);
  const [{ isDragging, canDrag }, drag] = useDrag({
    item: { type: "modules", id: id },
    canDrag: !transitionMode,
    begin: () => {
      //("Dragging in process", id);
    },
    endDrag: () => {},
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
      canDrag: monitor.canDrag(),
    }),
  });
  return {
    isDragging,
    canDrag,
    drag,
  };
}
