import React from "react";
import AlphaDialogSimple from "../shared-components/AlphaDialogSimple";
import { Button, AppBar } from "@material-ui/core";
import TopBar from "../pages/layout/TopBar";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { submitLogin } from "../../auth/store/actions";
import authService from "./../../auth/services/authService";
const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: "75%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "2.5rem",
  },
  textField: {
    paddingBottom: "1.5rem",
  },
}));
const LoginDialog = ({ open, setOpen }) => {
  const auth = useSelector((state) => state.auth);

  const dispatch = useDispatch();
  const classes = useStyles();
  const [state, setState] = React.useState({
    email: "usman.akram@gmail.com",
    password: "usman",
  });
  return (
    <AlphaDialogSimple
      open={open}
      setOpen={setOpen}
      actions={<LoginActions />}
      onClose={(e) => {
        setOpen(false);
      }}
      title="LOGIN"
      fullWidth={true}
      maxWidth="sm"
    >
      <div className={classes.root}>
        <TextField
          value={state.email}
          label="Email"
          type="email"
          fullWidth
          onChange={(e) => {
            setState({ ...state, email: e.target.value });
          }}
          className={classes.textField}
        />
        <TextField
          value={state.password}
          label="Password"
          type="password"
          fullWidth
          onChange={(e) => {
            setState({ ...state, password: e.target.value });
          }}
          className={classes.textField}
        />
        <Button
          autoFocus
          color="primary"
          variant="contained"
          onClick={(e) => {
            dispatch(submitLogin(state));
          }}
        >
          Login
        </Button>
      </div>
    </AlphaDialogSimple>
  );
};
const LoginActions = () => {
  const dispatch = useDispatch();
  return <></>;
};
export default LoginDialog;
