import React, { Component } from "react";

import ReactJson from "react-json-view";
import { connect } from "react-redux";
import DesignerTreeHandler from "../../../store/reducers/handlers/DesignerTreeHandler";
class DesignerJSON extends Component {
  render() {
    const { components } = this.props.designer;
    return (
      <div className="DesignerJSON">
        <h3> Designer JSON </h3>
        <ReactJson
          src={DesignerTreeHandler.ConvertToDesignerTree(components)}
        />
      </div>
    );
  }
}
DesignerJSON.propTypes = {};
const mapStateToProps = state => {
  return state;
};
export default connect(mapStateToProps)(DesignerJSON);
