import { COMPONENT_DROPDOWN } from "../constants/typeConstants";
import common from "./common";
/**
 * factory function to produce DropDown Component Object
 * @param {String} name
 * @param {Array} children array
 */
export default function(name = "Dropdown", children = []) {
  let obj = {
    name: name,
    type: COMPONENT_DROPDOWN,
    children: children
  };
  return Object.assign(obj, common);
}
