import React, { Component } from "react";
import { connect } from "react-redux";
class DropDown extends Component {
  state = { collpased: false };
  render() {
    const { collpased, color } = this.state;
    const { label } = this.props;
    return (
      <React.Fragment>
        <p
          onClick={this.handleCollapse}
          style={{
            cursor: "pointer",
            margin: "0",
            backgroundColor: this.props.designer.color || "#0069D9",
            border: "1px solid white",
            color: "white",
            padding: "4px"
          }}
        >
          <span style={{ fontSize: "16px" }}>{label}</span>
          <span className="float-right">
            <i
              className={
                !collpased
                  ? "fas fa-chevron-circle-down"
                  : "fas fa-chevron-circle-up"
              }
              style={{ color: "white", fontSize: "22px" }}
            ></i>
          </span>
        </p>
        <div style={{ margin: "0", padding: "0" }} hidden={!collpased}>
          {this.props.children}
        </div>
      </React.Fragment>
    );
  }
  handleCollapse = () => {
    this.setState({ collpased: !this.state.collpased });
  };
}
const mapStateToProps = state => state;
export default connect(mapStateToProps)(DropDown);
