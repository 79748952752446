import React from "react";
import Logo from "./Logo";
//this is already wrapped in a toolbar
import { makeStyles } from "@material-ui/core/styles";
import { Button } from "@material-ui/core";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { toggleLoginDialog } from "./../../../auth/store/actions/login.actions";
import authService from "./../../../auth/services/authService";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    // position: "absolute",
    flexDirection: "row",
    justifyContent: "space-between",
    // backgroundColor: theme.palette.background.default,
    width: "100%",
    alignItems: "center",
    margin: 0,
  },
  left: {
    "& img": {
      width: theme.mixins.toolbar.minHeight,
      //   ...theme.mixins.toolbar,
      //   height: theme.typography.button.lineHeight,
    },
  },
  right: {},
  btnLogin: {
    margin: 0,
    minHeight: 56,
    [`${theme.breakpoints.up("xs")} and (orientation: landscape)`]: {
      minHeight: 48,
    },
    [theme.breakpoints.up("sm")]: {
      minHeight: 64,
    },
  },
  middle: {},
  navLink: {
    color: theme.palette.grey[500],
    paddingLeft: "1.2rem",
    paddingRight: "1.2rem",
    // fontWeight: "bold",
    fontSize: "1.1rem",
  },
}));
const NavbarLg = (props) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const isLoggedIn = authService.isLoggedin();
  return (
    <div className={classes.root}>
      <div className={classes.left}>
        <Logo />
      </div>
      <div className={classes.middle}>
        <Link to="/" className={classes.navLink}>
          News
        </Link>
        <Link to="/designer" className={classes.navLink}>
          Designer
        </Link>

        <Link to="/" className={classes.navLink}>
          About
        </Link>
        <Link to="/" className={classes.navLink}>
          Products
        </Link>
        <Link to="/" className={classes.navLink}>
          Tutorials
        </Link>
        <Link to="/" className={classes.navLink}>
          Community
        </Link>
        <Link to="/" className={classes.navLink}>
          App Store
        </Link>
      </div>
      <div className={classes.right}>
        {!isLoggedIn ? (
          <Button
            variant="contained"
            color="primary"
            className={classes.btnLogin}
            onClick={(e) => {
              dispatch(toggleLoginDialog());
            }}
          >
            Login
          </Button>
        ) : (
          <Button
            variant="contained"
            color="primary"
            className={classes.btnLogin}
            onClick={(e) => {
              authService.logout();
              window.location.reload();
            }}
          >
            Logout
          </Button>
        )}
      </div>
    </div>
  );
};

export default NavbarLg;
