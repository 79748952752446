/** @format */

import { COMPONENT_EDITTEXT } from "../constants/typeConstants";
import common from "./common";
/**
 * factory function to produce Text Field Component Object
 * @param {String} name
 * @param {Array} children array
 */
export default function (name = "Text_Field", children = []) {
  let obj = {
    name: name,
    type: COMPONENT_EDITTEXT,
    children: children,
  };
  obj = Object.assign({}, obj, common);
  delete obj.properties;
  const defaultStyles = {
    width: "match_parent",
    height: "8%",
    fontSize: "22px",
    fontFamily: "Helvetica",
  };
  obj.properties = {
    properties: {},
  };
  obj.properties.styles = defaultStyles;
  obj.properties.customClassName = "editText-2";
  return obj;
}
