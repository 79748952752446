import {
  DUPLICATE_COMPONENT_ACTION
} from "../../constants/action-types";
import produce from "immer";
//import Immutable from "./../../reducers/handlers/Immutable";
import DesignerTreeHandler from "../../reducers/handlers/DesignerTreeHandler";
// import { toast } from "react-toastify";

export function duplicateComponent(index, new_parent = null) {
  window.store.dispatch({
    type: DUPLICATE_COMPONENT_ACTION,
    payLoad: {
      index,
      new_parent
    }
  });
}

export function duplicateComponentReducer(state, action) {
  const id = action.payLoad.index; //id to duplicate;
  const parent = action.payLoad.new_parent;
  const componentToDuplicate = state.components[id];
  let parent_id = componentToDuplicate.parent_id;
  const index = state.components[parent_id].children.indexOf(id);
  if (parent) parent_id = parent;
  const componentObject = DesignerTreeHandler.componentToObject(
    state.components,
    id
  );
  state = produce(state, (draftState) => {
    draftState.components = DesignerTreeHandler.insertComponentObjectIntoComponentsArray(
      draftState.components,
      componentObject,
      parent_id,
      index + 1
    );
  });

  return state;
}