import React, { Component } from "react";
import { changeComponentAttribute } from "../../../../store/actions/designerActions";
import { SyncLoader } from "react-spinners";

class ResponseMapper extends Component {
  state = {};
  render() {
    const { responseJSON, component, handleShowMapper } = this.props;
    let renderError = false;
    let renderAnimation = responseJSON === "";
    let toBeDeployed = responseJSON && true;
    if (!responseJSON) toBeDeployed = false;
    if (responseJSON === "error") renderError = true;
    return (
      <React.Fragment>
        {toBeDeployed && !renderError ? (
          responseJSON && (
            <td colSpan={2}>
              <div className="text-center m-3">
                <button
                  onClick={() => handleShowMapper()}
                  className="btn btn-primary"
                >
                  Map Response
                </button>
              </div>
            </td>
          )
        ) : renderError ? (
          <td colSpan={2} style={{ margin: "10px" }}>
            <div className="text-center text-danger m-3">
              Error While Fetching Response
            </div>
          </td>
        ) : renderAnimation ? (
          <td colSpan={2}>
            <div className="text-center text-danger m-3">
              <SyncLoader
                sizeUnit={"px"}
                size={15}
                color={"#3472CD"}
                loading={responseJSON === ""}
              />
            </div>
          </td>
        ) : (
          ""
        )}
      </React.Fragment>
    );
  }
}

export default ResponseMapper;
