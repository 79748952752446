import {
    PROPERTY_CHANGE
} from "./../../constants/action-types";
/**
 * change the property of a component
 * @param {*} index
 * @param {*} property
 * @param {*} propertyValue
 */
export function changeProperty(index, property, propertyValue) {
    window.store.dispatch({
        type: PROPERTY_CHANGE,
        payLoad: {
            index,
            property,
            propertyValue
        }
    });
}
export function changePropertyReducer(state, action) {
    const {
        index,
        property,
        propertyValue
    } = action.payLoad;
    return {
        ...state,
        components: state.components.map((item, i) => {
            if (i !== index) return item;
            else
                return {
                    ...item,
                    properties: {
                        ...item.properties,
                        [property]: propertyValue
                    }
                };
        })
    };
}