/** @format */

import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { shortKeyManager } from "./utils/shortcutkey/shortcutmanager";
import Story from "./StoryBoard/Story";
import CustomPrompt from "../../shared/Prompt";

import Canvas from "./canvas/Canvas";
import DesignerContextMenu from "./DesignerNavigation/ContextMenu/DesignerContextMenu";
import useStyles from "./DesignerStyling";
import DesignerLeftMenu from "./DesignerMenus/LeftMenu/DesignerLeftMenu";
import "./customModules.css";
import RightMenu from "./DesignerMenus/RightMenu/DesignerRightMenu";
import ActivityCounter from "./ScreenCounter";
import DesignerMiniMenu from "./DesignMiniMenu/DesignerMiniMenu";
import { toast } from "react-toastify";
import LoadedSaveButton from "./utils/LoadedSaveButton";
const Designer = ({ id }) => {
  const designer = useSelector((state) => state.designer);
  const {
    selectedPropertiesScreenID,
    components,
    componentClipBoard,
    storyMode,
    color,
    transitionMode
  } = designer;

  const [sidemenus, setSidemnus] = useState({
    showTree: false,
    showElement: true,
    showTheme: true,
    showProperties: true,
    showData: false,
    showAssets: false,
  });

  const [apiBindings, setApiBindings] = useState(false);
  const classes = useStyles();
  useEffect(() => {
    document.addEventListener("keydown", (e) => {
      // toast.warn(selectedPropertiesScreenID);
      shortKeyManager(
        e,
        selectedPropertiesScreenID,
        components,
        componentClipBoard
      );
    });
    return () => {
      document.removeEventListener("keydown", null);
    };
  }, [1]);

  const handleBindings = () => setApiBindings(true);
  return (
    <div className={`${classes.root} ${"container-direction"}`}>
      {!transitionMode && <>
        <CustomPrompt message="Are you sure You want to Leave? Make Sure to Save Changes Before Leaving" />
        <DesignerContextMenu />

        <div className="left-side-menu">
          <DesignerLeftMenu />
        </div>
      </>}
      <div className="left-mini-menu position-relative">
        <DesignerMiniMenu />
      </div>
      <div class="canvas">
        {!transitionMode && <ActivityCounter />}
        {!transitionMode && <LoadedSaveButton />}
        <Canvas
          color={color}
          apiBindings={apiBindings}
          handleBindings={handleBindings}
          ids={id}
        />
        {storyMode && <Story></Story>}
      </div>
      {!transitionMode && <div className="right-side-menu">
        <RightMenu />
      </div>}
    </div>
  );
};

export default Designer;
