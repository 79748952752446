import React, { useState } from "react";
import { Typography, LinearProgress } from "@material-ui/core";
import PropTypes from "prop-types";
import useTimeout from "./../hooks/useTimeout";
import { makeStyles } from "@material-ui/core/styles";
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    WebkitBoxFlex: 1,
    flex: "1 1 0%",
    WebKitBoxOrient: "vertical",
    WebkitBoxDirection: "normal",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  text: {
    fintSize: "2rem",
    marginBottom: "1.6rem",
  },
  progress: {
    width: "32rem",
  },
}));
function AlphaLoading(props) {
  const classes = useStyles();
  const [showLoading, setShowLoading] = useState(!props.delay);

  useTimeout(() => {
    setShowLoading(true);
  }, props.delay);

  if (!showLoading) {
    return null;
  }

  return (
    <div className={classes.root}>
      <Typography className={classes.text} color="textSecondary">
        Loading...
      </Typography>
      <LinearProgress className={classes.progress} color="secondary" />
    </div>
  );
}

AlphaLoading.propTypes = {
  delay: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
};

AlphaLoading.defaultProps = {
  delay: false,
};

export default AlphaLoading;
