import React from "react";
import {
  Card,
  CardHeader,
  CardMedia,
  CardContent,
  ListItemIcon,
  DialogContentText,
} from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { makeStyles } from "@material-ui/styles";
import { red } from "@material-ui/core/colors";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { useState } from "react";
import SettingsIcon from "@material-ui/icons/Settings";
import ListItemText from "@material-ui/core/ListItemText";
import { withRouter } from "react-router-dom";
import SettingsCellIcon from "@material-ui/icons/SettingsCell";
import SettingsInputComponentIcon from "@material-ui/icons/SettingsInputComponent";
import DeviceHubIcon from "@material-ui/icons/DeviceHub";
import { Link } from "react-router-dom";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import AlphaDialogSimple from "./../AlphaDndApp/shared-components/AlphaDialogSimple";
import { useDispatch } from "react-redux";
import { deleteProject } from "./store/actions/projectsAPIActions";
const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    minHeight: "300px",
    padding: theme.spacing(3),
  },
  media: {
    height: 0,
    paddingTop: "100px", // 16:9
  },
  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
  avatar: {
    backgroundColor: red[500],
  },
  lastUpdated: {
    textAlign: "right",
    fontWeight: "bold",
  },
  listItemIcon: {
    minWidth: "auto",
    paddingRight: theme.spacing(1),
  },
}));

const ProjectSummary = ({ project, onEdit, match, location, history }) => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const details = project.details;
  const appId = details.appId;
  const classes = useStyles();
  const dispatch = useDispatch();
  const [openDeleteConfirm, setOpenDeleteConfirm] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleEdit = () => {
    handleClose();
    onEdit(project._id);
    // history.push("/");
    // //(project);
  };
  const handleLoadDesigner = () => {
    handleClose();
    history.push("/designer/" + appId);
  };
  const handleDelete = () => {
    dispatch(deleteProject(project._id));
  };
  return (
    <>
      <Card className={classes.root}>
        <CardHeader
          title={details.appName}
          action={
            <IconButton
              aria-label="actions"
              aria-controls="simple-menu"
              aria-haspopup="true"
              onClick={handleClick}
            >
              <MoreVertIcon />
            </IconButton>
          }
        />
        <CardMedia
          className={classes.media}
          image="/production/images/project.jpg"
          title="Paella dish"
        />
        <CardContent>
          <Typography variant="body2" color="textSecondary" component="p">
            {details.description}
          </Typography>
          <Typography
            variant="body2"
            color="textSecondary"
            component="p"
            className={classes.lastUpdated}
          >
            LastUpdated
          </Typography>
        </CardContent>
      </Card>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={handleEdit} button>
          <ListItemIcon className={classes.listItemIcon}>
            <SettingsIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText primary="Edit Settings" />
        </MenuItem>
        <MenuItem onClick={handleLoadDesigner} button>
          <ListItemIcon className={classes.listItemIcon}>
            <SettingsCellIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText primary="Load Designer" />
        </MenuItem>
        <ListItemLink
          href={apiUrl + "apps/json/" + appId}
          target="_blank"
          button
        >
          <ListItemIcon className={classes.listItemIcon}>
            <SettingsCellIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText primary="JSON" />
        </ListItemLink>
        <ListItemLink
          href={apiUrl + "apps/components/" + appId}
          target="_blank"
          button
        >
          <ListItemIcon className={classes.listItemIcon}>
            <SettingsInputComponentIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText primary="COMPONENTS" />
        </ListItemLink>
        <MenuItem
          button
          onClick={(e) => {
            history.push("/bll/" + appId);
          }}
          // component={(props) => <Link to={"/bll/" + appId} {...props} />}
        >
          <ListItemIcon className={classes.listItemIcon}>
            <DeviceHubIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText primary="Load BLL" />
        </MenuItem>
        <ListItemLink href={apiUrl + "bll/" + appId} target="_blank" button>
          <ListItemIcon className={classes.listItemIcon}>
            <DeviceHubIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText primary="BLL JSON" />
        </ListItemLink>
        <MenuItem
          onClick={() => {
            handleClose();
            setOpenDeleteConfirm(true);
          }}
          button
        >
          <ListItemIcon className={classes.listItemIcon}>
            <DeleteForeverIcon fontSize="small" color="secondary" />
          </ListItemIcon>
          <ListItemText primary="Delete" />
        </MenuItem>
      </Menu>
      <AlphaDialogSimple
        title="Are You Sure"
        open={openDeleteConfirm}
        setOpen={setOpenDeleteConfirm}
        maxWidth="md"
        fullWidth={true}
        actions={
          <>
            <Button
              autoFocus
              onClick={() => {
                setOpenDeleteConfirm(false);
              }}
              variant="contained"
              color="primary"
            >
              No Thanks
            </Button>
            <Button
              onClick={handleDelete}
              color="secondary"
              variant="contained"
            >
              Delete ForEver
            </Button>
          </>
        }
      >
        <DialogContentText>
          This will delete this project forever
        </DialogContentText>
      </AlphaDialogSimple>
    </>
  );
};

function ListItemLink(props) {
  return <MenuItem button component={"a"} {...props} />;
}

export default withRouter(ProjectSummary);
