import {
    ADD_CUSTOM_CLASS
} from "../../constants/action-types";
import produce from "immer";

/**
 * change the style of the component
 * @param {*} index
 * @param {*} property
 * @param {*} propertyValue
 */
export function addCustomClassName(index, className) {
    window.store.dispatch({
        type: ADD_CUSTOM_CLASS,
        payLoad: {
            index,
            className
        }
    });
}

export function addCustomClassNameReducer(state, action) {
    const {
        index,
        className
    } = action.payLoad;

    return produce(state, draftState => {
        draftState.components[index].properties.customClassName = className
    })

}