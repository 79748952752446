import React, { Component } from "react";

import ShowFewProperties from "./../showFewProperties";
// import ShowAllProperties from "./../ShowAllProperties";
// import "./../properties.css";
// import AlignContentBar from "../AligningContent";
class DefaultProperties extends Component {
  state = {
    fewProps: true,
    tempName: ""
  };
  nameHanlder = e => {};
  render() {
    const { components, id } = this.props;
    const component = components[id];
    return (
      <React.Fragment>
        <ShowFewProperties component={component} {...this.props} />
      </React.Fragment>
    );
  }
  toggleProps = () => {
    this.setState({
      fewProps: !this.state.fewProps
    });
  };
}

export default DefaultProperties;
