import {
    STYLE_OBJECT_CHANGE
} from "../../constants/action-types";
import produce from "immer";

/**
 * change the style of the component
 * @param {*} index
 * @param {*} property
 * @param {*} propertyValue
 */
export function changeStyleObject(index, styleObject) {
    window.store.dispatch({
        type: STYLE_OBJECT_CHANGE,
        payLoad: {
            index,
            styleObject
        }
    });
}
export function changeStyleObjectReducer(state, action) {

    return produce(state, draftState => {
        draftState.components[action.payLoad.index].properties.styles = action.payLoad.styleObject;
    })
}