export const COMPONENT_ACTIVITY = "Activity";
export const COMPONENT_ACTIVITY_NAVIGATION = "Navigation";
export const COMPONENT_ACTIVITY_TOLLBAR = "Toolbar";
export const COMPONENT_BASE = "Base";
export const COMPONENT_FREELAYOUT = "Component_FreeLayout";
export const COMPONENT_INIT = "Init";
export const COMPONENT_FRAGMENT = "Fragment";
export const COMPONENT_VIEWPAGER = "ViewPager";
export const COMPONENT_EDITTEXT = "EditText";
export const COMPONENT_DROPDOWN = "DropDown";
export const COMPONENT_RADIOGROUP = "RadioGroup";
export const COMPONENT_CHECKBOX = "CheckBox";
export const COMPONENT_RADIOBUTTON = "RadioButton";
export const COMPONENT_IMAGEVIEW = "ImageView";
export const COMPONENT_IMAGEBUTTON = "ImageButton";
export const COMPONENT_BUTTON = "Button";
export const COMPONENT_BUTTONGROUP = "ButtonGroup"
export const COMPONENT_TEXTVIEW = "TextView";
export const COMPONENT_LINEARLAYOUT_HORIZONTAL = "LinearLayout_Horizontal";
export const COMPONENT_LINEARLAYOUT_VERTICAL = "LinearLayout_Vertical";
export const COMPONENT_RECYCLERVIEW_GRID = "RecyclerView_Grid";
export const COMPONENT_RECYCLERVIEW_LIST = "RecyclerView_List";
export const COMPONENT_LISTVIEW = "ListView";
export const COMPONENT_GRIDVIEW = "GridView";
export const COMPONENT_TABLE = "Table";
export const COMPONENT_VIEW = "View";
export const COMPONENT_RELATIVELAYOUT = "RelativeLayout";
export const COMPONENT_DRAWABLE = "Drawable"
export const COMPONENT_ICON_BUTTON = "ICON_BUTTON"
export const COMPONENT_HORIZONTAL_LISTVIEW = "COMPONENT_HORIZONTAL_LISTVIEW"