export default function checking(name, component) {
  if (paddings.includes(name)) {
    let entireValue = component.properties.styles.padding;
    //("ENTIRE:" + entireValue);
    if (!typeof entireValue === undefined) return entireValue.split("px")[0];
  }
  if (margins.includes(name)) {
    let entireValue = component.properties.styles.margin;
    //("ENTIRE:" + entireValue);
    if (!typeof entireValue === undefined) return entireValue.split("px")[0];
  }
}
const paddings = [
  "padding-left",
  "padding-right",
  "padding-top",
  "padding-bottom"
];
const margins = ["margin-left", "margin-right", "margin-top", "margin-bottom"];
