/** @format */

import React, { Component } from "react";
import { connect } from "react-redux";
import ComponentNavigation from "./ComponentNavigation";
import { addScreen } from "../../../store/actions/action-functions/addScreen";
import TreeView from "@material-ui/lab/TreeView";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import TreeItem from "@material-ui/lab/TreeItem";
class DesignerNavigation extends Component {
  state = {};

  render() {
    const { components, selectedPropertiesScreenID } = this.props.designer;
    const { disableDrag } = this.props;
    const array = this.generateNumberedArray;
    return (
      <>
        <TreeView
          style={{ width: "auto", fontSize: "11px" }}
          defaultExpanded={["3", "4"]}
          defaultCollapseIcon={<ExpandMoreIcon />}
          defaultExpandIcon={<ChevronRightIcon></ChevronRightIcon>}
          onClick={(e) => e.stopPropagation()}
        >
          {components[2].children.map((screen) => (
            <ComponentNavigation
              disableDrag={disableDrag}
              id={screen}
              key={screen}
              selectedPropertiesScreenID={selectedPropertiesScreenID}
              Component={components[screen]}
              components={components}
            />
          ))}
        </TreeView>

        <TreeView
          style={{ width: "auto" }}
          defaultExpanded={array}
          defaultCollapseIcon={<ExpandMoreIcon />}
          defaultExpandIcon={<ChevronRightIcon></ChevronRightIcon>}
          onClick={(e) => e.stopPropagation()}
        >
          <TreeItem nodeId="0" label="Tool Bar">
            {components[components[2].toolbar].children.map((screen) => (
              <ComponentNavigation
                disableDrag={disableDrag}
                id={screen}
                key={screen}
                selectedPropertiesScreenID={selectedPropertiesScreenID}
                Component={components[screen]}
                components={components}
              />
            ))}
          </TreeItem>
        </TreeView>
        <TreeView
          style={{ width: "auto", fontSize: "11px" }}
          defaultExpanded={array}
          defaultCollapseIcon={<ExpandMoreIcon />}
          defaultExpandIcon={<ChevronRightIcon></ChevronRightIcon>}
          onClick={(e) => e.stopPropagation()}
        >
          <TreeItem nodeId="0" label="Navigation">
            {components[components[2].navigation].children.map((screen) => (
              <ComponentNavigation
                disableDrag={disableDrag}
                id={screen}
                key={screen}
                selectedPropertiesScreenID={selectedPropertiesScreenID}
                Component={components[screen]}
                components={components}
              />
            ))}
          </TreeItem>
        </TreeView>

        <TreeView
          style={{ width: "auto", fontSize: "11px" }}
          defaultExpanded={array}
          defaultCollapseIcon={<ExpandMoreIcon />}
          defaultExpandIcon={<ChevronRightIcon></ChevronRightIcon>}
          onClick={(e) => e.stopPropagation()}
        >
          <TreeItem
            nodeId="0"
            label="Dialog Boxes"
            onClick={(e) => e.stopPropagation()}
          >
            {components[components[2].dialog] &&
              components[components[2].dialog].children.map((screen) => (
                <TreeItem
                  onClick={(e) => e.stopPropagation()}
                  key={screen}
                  nodeId={screen + ""}
                  label={components[screen].name}
                >
                  <ComponentNavigation
                    disableDrag={disableDrag}
                    id={screen}
                    key={screen}
                    selectedPropertiesScreenID={selectedPropertiesScreenID}
                    Component={components[screen]}
                    components={components}
                  />
                </TreeItem>
              ))}
          </TreeItem>
        </TreeView>
      </>
    );
  }
  generateNumberedArray = () => {
    let array = [];
    for (let index = 1; index < 25; index++) {
      array.push("" + index);
    }
    return array;
  };
}
const mapStateToProps = (state) => {
  return state;
};
export default connect(mapStateToProps)(DesignerNavigation);
