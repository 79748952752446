/** @format */

import React from "react";
import { separator } from "./pxSeparator";
import { makeStyles } from "@material-ui/core";
import { basicProps } from "../ComponentDesigner/ComponentViewer/shared/BasicProps";
import { STYLE_CHANGE } from "../../../store/constants/action-types";
const useStyles = makeStyles((theme) => ({
  div: {
    textAlign: "center",
    display: "inline-block",
    marginLeft: theme.spacing(1),
    marginTop: theme.spacing(1),
  },
  input: {
    background: "#ECECEC",
    border: 0,
    outline: "none",
    width: "50px",
    padding: "1px",

    height: "18px",
    fontSize: "12px",
    fontWeight: "bold",
    marginLeft: "5px",
    "&$input[type=number]::-webkit-inner-spin-button": {
      display: "none",
    },
  },
  p: {
    lineHeight: 0.2,
    fontSize: "9px",
    marginBottom: "4px",
  },
  td: {
    border: 0,
  },
}));
const SubProperties = ({ property }) => {
  let { selectedPropertiesScreenID, components } = basicProps();
  let component = components[selectedPropertiesScreenID];
  let val = "";

  const styleValue = styleProperty => {
    try {
      return component.properties.styles[styleProperty]
    } catch (e) {
      return "";
    }
  }
  // try {
  //   val = component.properties.styles[property];
  // } catch (error) {
  // }

  const splittedValue = separator(val);
  val = Number(splittedValue) ? splittedValue : "";
  const classes = useStyles();
  const getNumberValue = styleProperty => Number(separator(styleValue(styleProperty)) ? separator(styleValue(styleProperty)) : "");
  return (
    <>
      <tr>
        <td className={classes.td}></td>
        <td className={classes.td}>
          <div className={classes.div}>
            <p className={classes.p}>Top</p>
            <input
              type="number"
              id={`${property}Top`}
              className={classes.input}
              name={`${property}Top`}
              value={getNumberValue(`${property}Top`)}
              onChange={(e) => dispatchAction(e)}
            />
          </div>
          {/* <td className={classes.td}> */}
          <div className={`${classes.div} `}>
            <p className={classes.p}>Left</p>
            <input
              type="number"
              className={classes.input}
              name={`${property}Left`}
              value={getNumberValue(`${property}Left`)}
              onChange={(e) => dispatchAction(e)}
            />
          </div>
        </td>
      </tr>
      <tr>
        <td className={classes.td}></td>
        <td className={classes.td}>
          <div className={classes.div}>
            <p className={classes.p}>Right</p>
            <input
              type="number"
              id={`${property}Right`}
              className={classes.input}
              name={`${property}Right`}
              value={getNumberValue(`${property}Right`)}
              onChange={(e) => dispatchAction(e)}
            />
          </div>

          <div className={`${classes.div} `}>
            <p className={classes.p}>Bottom</p>
            <input
              type="number"
              className={classes.input}
              name={`${property}Bottom`}
              value={getNumberValue(`${property}Bottom`)}
              onChange={(e) => dispatchAction(e)}
            />
          </div>
        </td>
      </tr>
    </>
  );

  function dispatchAction(e) {
    if (!e.target.value) return;
    window.store.dispatch({
      type: STYLE_CHANGE,
      payLoad: {
        index: component.id,
        propertyValue: e.target.value + "%",
        property: e.target.name,
      },
    });
  }
};

export default SubProperties;
