import React from "react";
import {
  ButtonGroup,
  TextField,
  IconButton,
  Modal,
  makeStyles
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import EditIcon from "@material-ui/icons/Edit";
import DescriptionSharpIcon from "@material-ui/icons/DescriptionSharp";
import CreateNewFolderSharpIcon from "@material-ui/icons/CreateNewFolderSharp";
import axiosInstance from "../../../../store/actions/APIHandler/axios";
import { useState } from "react";
const useStyles = makeStyles(theme => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  paper: {
    position: "absolute",
    width: 400,
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3)
  }
}));
const AssetUploadActions = ({ file, appId, parent_id, onChange }) => {
  console.log("parent_id: " + parent_id);
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [name, setName] = useState("");
  const hanldeUpload = (files, parent) => {
    // let files = event.target.files;
    console.log("Uploading to ");
    console.log(parent);
    Object.keys(files).map(key => {
      let file = files[key];
      const data = new FormData();
      data.append("asset", file);
      data.append("parent_id", parent);
      console.log("Starting upload");
      const config = {
        headers: {
          "content-type": "multipart/form-data"
        }
      };
      axiosInstance
        .post(
          process.env.REACT_APP_API_URL + "apps/assets/folder/upload/" + appId,
          // "http://localhost:3/000/upload",
          data,
          config
        )
        .then(res => {
          // console.log("upload Finished");
          // console.log(res.data);
          onChange(res.data);
        })
        .catch(err => console.log(err));
    });
  };
  const createFolder = (name, parent_id) => {
    if (!name) return;
    axiosInstance
      .post(process.env.REACT_APP_API_URL + "apps/assets/folder/" + appId, {
        name,
        parent_id
      })
      .then(res => {
        onChange(res.data); // inform parent that a change has occured in assets
        //handleClose(); //close modal
      });
  };
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setName("");
    setOpen(false);
  };
  return (
    <>
      <input
        type="file"
        name="file"
        onChange={e => {
          console.log("p passed from parent: " + parent_id);
          hanldeUpload(e.target.files, parent_id);
        }}
        style={{ display: "none" }}
        multiple
        id="raised-button-file"
      />
      <label htmlFor="raised-button-file">
        <IconButton
          color="primary"
          aria-label="Upload File"
          title="Upload File"
          component="span"
          size="small"
        >
          <DescriptionSharpIcon size="small" />
        </IconButton>
      </label>
      <IconButton
        color="primary"
        aria-label="Create Folder Here"
        title="Create Folder Here"
        component="span"
        size="small"
        onClick={e => setOpen(true)}
      >
        <CreateNewFolderSharpIcon size="small" />
      </IconButton>
      <Modal
        aria-labelledby="new-folder-modal"
        aria-describedby="new-folder-modal-desciption"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
      >
        <div className={classes.paper}>
          <form
            onSubmit={e => {
              e.preventDefault();
              createFolder(name, parent_id);
              handleClose();
            }}
          >
            <TextField
              value={name}
              onChange={e => setName(e.target.value)}
              label="name"
              // helperText="Add New Folder"
              variant="outlined"
            />
          </form>
        </div>
      </Modal>
    </>
  );
};

export default AssetUploadActions;
