import React from "react";
import { AppBar, Hidden, Toolbar } from "@material-ui/core";
import NavBarMobileToggleButton from "./NavBarMobileToggleButton";
import NavbarLg from "./NavbarLg";
import NavbarMd from "./NavbarMd";

const Navbar = (props) => {
  return (
    <AppBar position="static" elevation={0} color="default">
      <Toolbar disableGutters={true}>
        <Hidden smDown>
          <NavbarLg />
        </Hidden>
        <Hidden mdUp>
          <NavbarMd />
        </Hidden>
      </Toolbar>
    </AppBar>
  );
};

export default Navbar;
