/** @format */

import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    position: "relative",
    background: "#ececec",
    userSelect: "none",
    display: "flex",
    flexDirection: "row",
    minHeight: "100vh",
  },
  container: {
    width: "100%",
    minHeight: "100vh",
    display: "grid",
    gridTemplateColumns: "335px auto 350px",
    overflow: "hidden",
  },
}));

export default useStyles;
