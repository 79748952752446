export function separator(value) {
  let withOutPixel = "";
  let flag = true;
  try {
    if (value.indexOf("%") !== -1) {
      withOutPixel = value.split("%");
    } else {
      withOutPixel = value.split("px");
    }
    flag = true;
  } catch (error) {
    withOutPixel = value;
    flag = false;
  }

  return flag ? withOutPixel[0] : withOutPixel;
}
