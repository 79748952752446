import alphaActions from "./../actions/index";
const initialState = {
  backdrop: false,
  navbar: { mobileOpen: false },
};

export const alphaReducer = function(state = initialState, action) {
  switch (action.type) {
    case alphaActions.ALPHA_NAVIGATION_SET:
      return {
        ...state,
        navbar: { ...state.navbar, mobileOpen: action.payLoad },
      };
    case alphaActions.ALPHA_NAVIGATION_TOGGLE:
      return {
        ...state,
        navbar: { ...state.navbar, mobileOpen: !state.navbar.mobileOpen },
      };
    case alphaActions.ALPHA_SHOW_BACKDROP:
      return { ...state, backdrop: true };
    case alphaActions.ALPHA_HIDE_BACKDROP:
      return { ...state, backdrop: false };
    default: {
      return state;
    }
  }
};
