import produce from "immer"
import {
    ADD_CUSTOM_CSS
} from "../../constants/action-types"

export function addCustomCss(code) {
    window.store.dispatch({
        type: ADD_CUSTOM_CSS,
        payLoad: {
            code
        }
    })
}

export function addCustomCssReducer(state, action) {

    const code = action.payLoad.code
    document.head.insertAdjacentHTML(
        "beforeend",
        `<style>${code}</style>`
    );
    return produce(state, draftState => {
        draftState.cssCode = code
    })
}