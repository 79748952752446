export const entities = [
    {
        name: 'posts',
        coloumns: ['userId', 'id', 'title', 'body']
    },
    {
        name: 'comments',
        coloumns: ['postId', 'id', 'name', 'email', 'body']
    },
    {
        name: 'albums',
        coloumns: ['userId', 'id', 'title']
    },
    {
        name: 'photos',
        coloumns: ['albumId', 'id', 'title', 'url', 'thumbnailUrl']
    },
    {
        name: 'todos',
        coloumns: ['userId', 'id', 'title', 'body']
    },
    {
        name: 'users',
        coloumns: ['id', 'name', 'username', 'email', 'address']
    },
]