import {
  CHANGE_COMPONENT_ATTRIBUTE
} from "./../../constants/action-types";
/**
 * Change Component Attribute
 * @param {*} index of the component
 * @param {*} attribute to be changed
 * @param {*} value to be inserted
 */
export function changeComponentAttribute(index, attribute, value) {
  //(value)
  window.store.dispatch({
    type: CHANGE_COMPONENT_ATTRIBUTE,
    payLoad: {
      index,
      attribute,
      value,
    },
  });
}
export function changeComponentAttributeReducer(state, action) {
  ////(name);
  const { index, attribute, value } = action.payLoad;
  return {
    ...state,
    components: state.components.map((item, i) => {
      if (i !== index) return item;
      else
        return {
          ...item,
          [attribute]: value,
        };
    }),
  };
}