/** @format */

import { COMPONENT_LINEARLAYOUT_HORIZONTAL } from "../constants/typeConstants";
import common from "./common";
// import verticalFactory from "./verticalFactory";
/**
 * factory function to produce Linear horizontal Component Object
 * @param {String} name
 * @param {Array} children array
 */
export default function (name = "Horizontal_Layout", children = []) {
  let obj = {
    name: name,
    type: COMPONENT_LINEARLAYOUT_HORIZONTAL,
    children: children,
  };
  obj = Object.assign({}, obj, common);
  delete obj.properties;
  obj.properties = {
    properties: {},
  };
  const defaultStyles = {
    minWidth: "match_parent",
    height: "wrap_content",
  };
  obj.properties.styles = defaultStyles;
  return obj;
}
