import {
    MAKE_NAVIGATABLE_ACTIVITY
} from "../../constants/action-types";
import produce from "immer";


export function makeNavigation(index, value) {
    window.store.dispatch({
        type: MAKE_NAVIGATABLE_ACTIVITY,
        payLoad: {
            index,
            value
        }
    })
}
export function makeNavigationReducer(state, action) {
    const {
        index,
        value
    } = action.payLoad;
    return produce(state, draftState => {
        draftState.components[index].isNavigatable = value;
    })
}