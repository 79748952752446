import React, { Component } from "react";
class Mapper extends Component {
  state = {};
  render() {
    return (
      <div>
        <h3>Mapper</h3>
      </div>
    );
  }
}

export default Mapper;
