/** @format */

//This is the core array of component types. every kind of info required for a component type would be collected from here
//canHasChildren Mean whether a component can has children
import factories from "../factories";
import * as typeConstants from "./typeConstants";
const components = [
  {
    name: typeConstants.COMPONENT_LINEARLAYOUT_HORIZONTAL,
    display_name: "Horizontal Layout",
    canHasChildren: true,
    factory: factories.horizontalFactory,
    icon: "fa fa-arrows-h",
    isLayout: true,
    borderColor: "#f5f5f5",
  },

  // {
  //     name: typeConstants.COMPONENT_ICON_BUTTON,
  //     display_name: "ICON BUTTON",
  //     canHasChildren: false,

  //     factory: factories.iconButtonFactory,
  //     icon: "fas fa-icons",
  //     isLayout: false,
  //     isButton: true,
  // },
  {
    name: typeConstants.COMPONENT_EDITTEXT,
    display_name: "Text Field",
    htmlElement: "input",
    canHasChildren: false,
    factory: factories.editTextFactory,
    icon: "fa fa-file-text",
    isText: true,
  },
  // {
  //     name: typeConstants.COMPONENT_DROPDOWN,
  //     display_name: "Drop Down",
  //     canHasChildren: false,
  //     factory: factories.dropDownFactory,
  //     icon: "fa fa-caret-square-o-down",
  //     isLayout: false
  // },

  {
    name: typeConstants.COMPONENT_BUTTON,
    display_name: "Button",
    htmlElement: "Button",
    canHasChildren: false,
    factory: factories.buttonFactory,
    icon: "fa fa-arrow-circle-down",
    isButton: true,
  },
  {
    name: typeConstants.COMPONENT_BUTTONGROUP,
    display_name: "Button Group",
    htmlElement: "button",
    factory: factories.buttonFactory,
    icon: "",
    isButton: true,
    isGrouped: true,
  },

  {
    name: typeConstants.COMPONENT_TEXTVIEW,
    display_name: "Label",
    canHasChildren: false,
    htmlElement: "Label",
    factory: factories.textViewFactory,
    icon: "fa fa-tag",
    isText: true,
  },
  {
    name: typeConstants.COMPONENT_LINEARLAYOUT_VERTICAL,
    display_name: "Vertical Layout",
    canHasChildren: true,
    factory: factories.verticalFactory,
    icon: "fa fa-arrows-v",
    isLayout: true,
  },
  // {
  //   name: typeConstants.COMPONENT_INIT,
  //   display_name: "App",
  //   factory: factories.initFactory,
  //   canHasChildren: true,
  //   icon: "fa fa-mobile",
  //   isLayout: false
  // },
  // {
  //   name: typeConstants.COMPONENT_BASE,
  //   display_name: "Base",
  //   factory: factories.baseFactory,
  //   canHasChildren: true,
  //   icon: "fa fa-window-minimize",
  //   isLayout: false
  // },
  {
    name: typeConstants.COMPONENT_ACTIVITY,
    display_name: "Screen",
    factory: factories.activityFactory,
    canHasChildren: true,
    icon: "fa fa-clone",

    isLayout: false,
  },

  {
    name: typeConstants.COMPONENT_RADIOGROUP,
    display_name: "Radio Group",
    canHasChildren: false,
    factory: factories.radioButtonFactory,
    icon: "fa fa-list-ul",
    isButton: true,
    isGrouped: true,
  },
  {
    name: typeConstants.COMPONENT_CHECKBOX,
    display_name: "Check Box",
    canHasChildren: false,
    htmlElement: "checkbox",
    factory: factories.checkBoxFactory,
    icon: "fa fa-check-square-o",
    isButton: true,
  },
  {
    name: typeConstants.COMPONENT_RADIOBUTTON,
    display_name: "Radio Button",
    htmlElement: "radio",
    canHasChildren: false,
    factory: factories.radioButtonFactory,
    icon: "fa fa-dot-circle-o",
    isButton: true,
  },
  {
    name: typeConstants.COMPONENT_IMAGEVIEW,
    display_name: "Image",
    canHasChildren: false,
    htmlElement: "img",
    factory: factories.imageFactory,
    icon: "fa fa-picture-o",
    isText: true,
  },
  // {
  //     name: typeConstants.COMPONENT_IMAGEBUTTON,
  //     display_name: "Image Button",
  //     canHasChildren: false,
  //     factory: factories.imageButtonFactory,
  //     icon: "fa fa-file-image-o",
  //     isLayout: false
  // },
  {
    name: typeConstants.COMPONENT_LISTVIEW,
    display_name: "Classic Lists Layout",
    canHasChildren: true,
    factory: factories.listViewFactory,
    icon: "fa fa-th",
    isLayout: true,
  },
  // {
  //     name: typeConstants.COMPONENT_GRIDVIEW,
  //     display_name: "GRID LAYOUT",
  //     canHasChildren: true,
  //     factory: factories.gridViewFactory,
  //     icon: "fa fa-list",
  //     isLayout: true,
  // },
  // {
  //     name: typeConstants.COMPONENT_VIEW,
  //     display_name: "Divider",
  //     canHasChildren: false,
  //     factory: factories.dividerFactory,
  //     icon: "fa fa-deviantart",
  //     isLayout: false
  // },
  // {
  //   name: typeConstants.COMPONENT_RELATIVELAYOUT,
  //   display_name: "Relative Layout",
  //   canHasChildren: true,
  //   factory: factories.relativeLayoutFactory,
  //   icon: "fa fa-window-restore",
  //   isLayout: true
  // },
  // {
  //     name: typeConstants.COMPONENT_DRAWABLE,
  //     display_name: "Shapes",
  //     canHasChildren: true, //yet to decide
  //     factory: factories.drawableFactory,
  //     icon: "fa fa-diamond",
  //     isLayout: false
  // },
  // {
  //   name: typeConstants.COMPONENT_TABLE,
  //   display_name: "Tabled View",
  //   canHasChildren: true,
  //   factory: factories.tableLayoutFactory,
  //   icon: "fa fa-table",
  //   isLayout: true
  // }
];
export default components;
/**
 * get the component type object by its type
 * @param {*} type Component Type. It must come from type constants
 */
export const findComponentByType = (type) =>
    components.find(function(element) {
        return element.name === type ? element : false;
    });
export const findComponentByDisplayName = (type) =>
    components.find(function(element) {
        return element.display_name === type ? element : false;
    });