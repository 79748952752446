import componentTypes from "../../constants/component_list";
export default class DesignerTreeHandler {
    static insertItem(array, item, index = 0) {
        return [...array.slice(0, index), item, ...array.slice(index)];
    }
    static insertItemAtEnd(array, item) {
        return DesignerTreeHandler.insertItem(array, item, array.length);
    }
    static removeItem(array, action) {
        return [...array.slice(0, action.index), ...array.slice(action.index + 1)];
    }

    /**
     * Convert Flat Array to JSON Tree Object
     * @param {*} components
     * @param {*} rootID
     */
    static ConvertToDesignerTree(components, rootID = 1) {
            const treeNode = Object.assign({}, components[rootID]);
            const children = Array.from(treeNode.children);
            treeNode.children = [];
            for (var i = 0; i < children.length; i++) {
                treeNode.children.push(
                    DesignerTreeHandler.ConvertToDesignerTree(components, children[i])
                );
            }
            return treeNode;
        }
        /**
         *
         * @param {*} components existing components array
         * @param {*} jsonObject JSON Object to insert into Array
         * @param {*} parent_id ID of the parent where to inject
         */
    static getFlatTree(components, jsonObject, parent_id = 2) {
            jsonObject = Object.assign({}, jsonObject);
            const nextID = components.length;
            const children = jsonObject.children;
            jsonObject.children = [];
            jsonObject.parent_id = parent_id;
            jsonObject.id = nextID;
            components[nextID] = jsonObject;
            if (children) {
                children.map(element => {
                    jsonObject.children = DesignerTreeHandler.insertItemAtEnd(
                        jsonObject.children,
                        DesignerTreeHandler.getFlatTree(components, element, nextID)
                    );
                });
            }
            return nextID;
        }
        /**
         * Proces components of an activity
         * @param {*} components
         * @param {*} jsonObject
         * @param {*} parent_id
         */
    static processComponent(components, jsonObject, parent_id = 2) {
        let nextID = components.length;
        components[nextID] = jsonObject;
        components[nextID].id = nextID;
        components[nextID].parent_id = parent_id;
        let fragments = jsonObject.children;
        components[nextID].children = [];
        for (var i = 0, len = fragments.length; i < len; i++) {
            components[nextID].children = DesignerTreeHandler.insertItemAtEnd(
                components[nextID].children,
                DesignerTreeHandler.getFlatTree(components, fragments[i], nextID)
            );
        }
        return nextID;
    }
    static convertJSONToComponents(jsonObject) {
                                                 let components = [];
                                                 let root = Object.assign(
                                                   {},
                                                   jsonObject
                                                 );
                                                 root.id = 1;
                                                 root.parent_id = null;
                                                 root.children = [2];
                                                 components[1] = root;
                                                 //("LOgging In The Convert convertJSONToComponents")
                                                 //(jsonObject.children);
                                                 let app = Object.assign(
                                                   {},
                                                   jsonObject.children[0]
                                                 );
                                                 app.id = 2;
                                                 app.parent_id = 1;
                                                 app.children = [];
                                                 components[2] = app;
                                                 const activities =
                                                   jsonObject.children[0]
                                                     .children;
                                                 for (
                                                   var i = 0,
                                                     len = activities.length;
                                                   i < len;
                                                   i++
                                                 ) {
                                                   components[2].children.push(
                                                     DesignerTreeHandler.processComponent(
                                                       components,
                                                       activities[i],
                                                       2
                                                     )
                                                   );
                                                 }
                                                 components[2].navigation = DesignerTreeHandler.getFlatTree(
                                                   components,
                                                   jsonObject.children[0]
                                                     .navigation
                                                 );
                                                 components[2].toolbar = DesignerTreeHandler.getFlatTree(
                                                   components,
                                                   jsonObject.children[0]
                                                     .toolbar
                                                 );
                                                 components[2].dialog = DesignerTreeHandler.getFlatTree(
                                                   components,
                                                   jsonObject.children[0].dialog
                                                 );
                                                 //getFlatTree(components, jsonObject, null);
                                                 return components;
                                               }
        /**
         * convert a component to its array
         * @param {array} components
         * @param {*} id
         */
    static componentToObject(components, id) {
        const source = components[id];
        let obj = {};
        obj = Object.assign({}, source);
        obj.children = [];
        for (var i = 0; i < source.children.length; i++) {
            obj.children.push(
                DesignerTreeHandler.componentToObject(components, source.children[i])
            );
        }
        return obj;
    }
    static insertComponentObjectIntoComponentsArray(
        components,
        componentObject,
        parent_id,
        index
    ) {
        componentObject.id = components.length;
        componentObject.parent_id = parent_id;
        const children = [...componentObject.children];
        componentObject.children = [];
        // components[parent_id].children = DesignerTreeHandler.insertItem(
        //     components[parent_id].children,
        //     componentObject.id,
        //     index
        // );
        components[parent_id].children = DesignerTreeHandler.insertItemAtEnd(
            components[parent_id].children,
            componentObject.id,
            index
        );
        components[componentObject.id] = componentObject;
        for (var i = 0; i < children.length; i++) {
            components = DesignerTreeHandler.insertComponentObjectIntoComponentsArray(
                components,
                children[i],
                componentObject.id,
                i
            );
        }
        return components;
    }
    static isLayoutComponent(id) {
        const component = window.store.getState().designer.components[id];
        const type = DesignerTreeHandler.findComponentByType(component.type);
        return type.isLayout;
    }
    static findComponentType(id) {
        const component = window.store.getState().designer.components[id];
        return component.type;
    }

    static findComponentByType = type =>
        componentTypes.find(function(element) {
            return element.name === type ? element : false;
        });

    static findLayoutTypes() {
        return componentTypes.filter(type => type.isLayout);
    }
}