export const ALPHA_NAVIGATION_SET = "ALPHA_NAVIGATION_SET";
export const ALPHA_NAVIGATION_TOGGLE = "ALPHA_NAVIGATION_TOGGLE";

export function showMobileNavigation() {
  return {
    type: ALPHA_NAVIGATION_SET,
    payLoad: true,
  };
}
export function hideMobileNavigation() {
  return {
    type: ALPHA_NAVIGATION_SET,
    payLoad: false,
  };
}

export function toggleNavBar() {
  return { type: ALPHA_NAVIGATION_TOGGLE };
}
