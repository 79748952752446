import React, { Component } from "react";
import { Row, Col } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "font-awesome/css/font-awesome.min.css";
import "./Footer.css";

class Footer extends Component {
  state = {};
  render() {
    return (
      <footer className="footer">
        <Row>
          <Col className="section" col="4" sm="4">
            <h5 className="FooterHeadings">ABOUT US</h5>
            <hr />
            <p>
              Mobile application development tools are available but their scope
              is limited and they lack in business logic.User can design their
              mock up mobile applications by simply drag and drop components,
              style them and bind them with the API keys. The main aim is to
              provide coding-free application development environment to the
              non-technical user.
            </p>
          </Col>
          <Col className="section" col="4" sm="4">
            <h5 className="FooterHeadings">CATEGORIES</h5>
            <hr />
            <p>
              <a href="#!">Your Account</a>
            </p>
            <p>
              <a href="#!">Become an affiliate</a>
            </p>
            <p>
              <a href="/pricing">Pricing</a>
            </p>
            <p>
              <a href="#!">Help</a>
            </p>
          </Col>
          <Col className="section" col="4" sm="4">
            <h5 className="FooterHeadings">CONTACT</h5>
            <hr />
            <p>
              <i className="fa fa-home " /> comsats raiwind, lahore
            </p>
            <p>
              <i className="fa fa-envelope" /> Rajaosamainayat@gmail.com
            </p>
            <p>
              <i className="fa fa-phone" /> +92 303 9372815
            </p>
            <p>
              <i className="fa fa-print" /> +92 303 9372815
            </p>
          </Col>
        </Row>
        <hr />
        <div className="copyright">
          © 2018 Copyright:
          <a href="https://usmanlive.com"> ALPHA DND</a>
        </div>
      </footer>
    );
  }
}

export default Footer;
