import React, { Component } from "react";
import SingleKey from "../SingleKey";
import { connect } from "react-redux";

class SingleKeyMapping extends Component {
  state = {};
  render() {
    const {
      objectKey,
      objectValue,
      children,
      selectedPropertiesScreenID
    } = this.props;
    const childs = children && true;
    return (
      <SingleKey
        highLightKey={this.props.designer.highLightKey}
        tempKey={objectKey}
        value={objectValue}
        selectedPropertiesScreenID={selectedPropertiesScreenID}
      >
        {childs ? children : null}
      </SingleKey>
    );
  }
}
const mapStateToProps = state => state;
export default connect(mapStateToProps)(SingleKeyMapping);
