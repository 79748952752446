import {
  STYLE_CHANGE
} from "../../../store/constants/action-types";

export function styleChanged(value, property, component) {
                                                           // console.clear();
                                                           console.log(
                                                             "Value:" +
                                                               value +
                                                               "--Property:" +
                                                               property
                                                           );

                                                           return {
                                                             type: STYLE_CHANGE,
                                                             payLoad: {
                                                               index:
                                                                 component.id,
                                                               propertyValue: value,
                                                               property,
                                                             },
                                                           };
                                                         }