/** @format */

import React, { Component } from "react";
import { FormControlLabel, Switch } from "@material-ui/core";
import { makeNavigation } from "../../../store/actions/action-functions/makeActivityNavigatable";
import { changeComponentAttribute } from "../../../store/actions/designerActions";
class AddToolBar extends Component {
  state = {};
  render() {
    const { component } = this.props;
    const checked = component.hasToolBar ? component.hasToolBar : false;
    return (
      <FormControlLabel
        value={component.label}
        control={
          <Switch
            color="primary"
            checked={checked}
            onChange={(e) => this.handleChecking(e)}
          />
        }
        label={"Add Toolbar to activity"}
        labelPlacement="start"
      />
    );
  }
  handleChecking(e) {
    const value = e.target.checked;
    changeComponentAttribute(this.props.component.id, "hasToolBar", value);
  }
}

export default AddToolBar;
