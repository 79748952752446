export const ALPHA_SHOW_BACKDROP = "ALPHA_SHOW_BACKDROP";
export const ALPHA_HIDE_BACKDROP = "ALPHA_HIDE_BACKDROP";

export function showBackdrop() {
  return {
    type: ALPHA_SHOW_BACKDROP,
  };
}
export function hideBackdrop() {
  return {
    type: ALPHA_HIDE_BACKDROP,
  };
}
