import {
    FETCH_RESPONSE,
    CHANGE_COMPONENT_ATTRIBUTE
} from "./../../constants/action-types";
/**
 * fetch Response of the url
 * @param {*} index of the component whose url would be hit and response would be fetched
 */
// //("Network Called")
export function fetchResponse(index) {
    window.store.dispatch({
        type: CHANGE_COMPONENT_ATTRIBUTE,
        payLoad: {
            index,
            attribute: "responseJSON",
            value: ""
        }
    });
    window.store.dispatch({
        type: FETCH_RESPONSE,
        payLoad: {
            index
        }
    });
}

export function fetchResponseReducer(state, action) {
    const {
        index
    } = action.payLoad;
    const component = state.components[index];
    // old code commented by osama
    // fetch(component.url + component.requestPath)
    fetch(component.completePath)
        .then(response => response.json())
        .then(json => {
            window.store.dispatch({
                type: CHANGE_COMPONENT_ATTRIBUTE,
                payLoad: {
                    index,
                    attribute: "responseJSON",
                    value: json[0]
                }
            });
        })
        .catch(err => {
            window.store.dispatch({
                type: CHANGE_COMPONENT_ATTRIBUTE,
                payLoad: {
                    index,
                    attribute: "responseJSON",
                    value: "error"
                }
            });
        });
    return state;
}