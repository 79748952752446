import * as actionTypes from "../constants/bll-gui-action-types";
import produce from "immer";
import bllGUIActions from "./../actions/bll-gui-actions.js/bll-gui-actions";

import BLLreduxHandlerReducer from "../actions/BLLReduxHandlerReducer";
import {
  BLL_VARIABLE_TYPE_BOOLEAN,
  BLL_VARIABLE_TYPE_STRING
} from "./../../components/BusinessLogicLayer/utils/variableTypes";
const initialState = {
  apps: {
    test: {
      fetched: true,
      fetching: false,
      activeFunction: "func1",
      globalVariables: [
        { name: "a", type: BLL_VARIABLE_TYPE_BOOLEAN },
        { name: "b", type: BLL_VARIABLE_TYPE_STRING }
      ],
      bllFunctions: {
        func1: {
          localVariables: [
            { name: "x", type: BLL_VARIABLE_TYPE_BOOLEAN },
            { name: "y", type: BLL_VARIABLE_TYPE_STRING }
          ],
          name: "func1",
          inputs: [],
          outputs: [],
          model: {},
          identifier: "func1"
        }
      }
    }
  },
  loadedFunctions: [],
  selectedIndex: 0,
  selectedFunction: 0
};

export const bllGUIReducer = (state = initialState, action) => {
  switch (action.type) {
    case "bll_load_app":
      return BLLreduxHandlerReducer.loadAppReducer(state, action);
    case "bll_app_loaded":
      return BLLreduxHandlerReducer.bllReceived(state, action);
    case "bll_function_created":
      return BLLreduxHandlerReducer.bllFuncCreated(state, action);
    case "bll_function_deleted":
      return BLLreduxHandlerReducer.bllFuncDeleted(state, action);
    case "bll_function_active_changed":
      return BLLreduxHandlerReducer.bllFuncActiveChanged(state, action);
    case "bll_global_variables_received":
      return BLLreduxHandlerReducer.bllGlobalVarsReceived(state, action);
    case "bll_local_variables_received":
      return BLLreduxHandlerReducer.bllLocalVarsReceived(state, action);
    case "bll-update-default-value":
      return BLLreduxHandlerReducer.updatePortDefaultValueReducer(
        state,
        action
      );
    case "bll-node-add-pin":
      return BLLreduxHandlerReducer.addPinReducer(state, action);
    case "bll_update_model_to_function":
      return BLLreduxHandlerReducer.updateModelToFuncReducer(state, action);

    case "bll_add_nodes_object":
      return BLLreduxHandlerReducer.addNodesObjectReducer(state, action);
    case "bll-add-link":
      return BLLreduxHandlerReducer.addLinkReducer(state, action);
    case "bll-update-node":
      return BLLreduxHandlerReducer.updateNodeReducer(state, action);
    case "bll-delete-selections":
      return BLLreduxHandlerReducer.deleteSelectionsReducer(state, action);

    case "BLL_GUI_CHANGE_SELECTED_INDEX":
      return bllGUIActions.changeSelectedIndexReducer(state, action);
    case "BLL_GUI_LOAD_FUNCTION":
      return bllGUIActions.loadFunctionReducer(state, action);

    case "BLL_GUI_CLOSE_FUNCTION":
      return bllGUIActions.closeFunctionReducer(state, action);

    default:
      return state;
  }
};
