import React, { Component } from "react";
import ReactDOM from "react-dom";
import { changeLabel } from "../../../../store/actions/action-functions/changeLabel";
import ButtonProperties from "./ButtonProperties";
import {
  dropSpecs,
  dropCollect,
  dragSpecs,
  dragCollect
} from "./utils/dndMethods";
import { DragSource, DropTarget } from "react-dnd";
import { Resizable } from "re-resizable";

import flow from "lodash/flow";
import MaterialPopOver from "./Popover/MaterialPopOver";
import { highLightKey } from "../../../../store/actions/action-functions/addBindedId";
import { getDimensionValue } from "../../Properties/utils/changeToHtmlUnits";
import { connect } from "react-redux";
import {
  manageBindedId,
  removeBindedId
} from "../../../../store/actions/action-functions/manageBindedIds";
import { changeStyle } from "../../../../store/actions/designerActions";
import { separator } from "../../Properties/pxSeparator";
import {
  // disableSelectionReducer,
  disableSelectionFn
} from "../../../../store/actions/action-functions/disableSelection";
import "./baseStyleSheet.css";
import { changeStyleBulk } from "../../../../store/actions/action-functions/changeStyleBulk";
import IconProperties from "./IconsProperties";

class ComponentViewerIconButton extends Component {
  constructor() {
    super();
    this.IconbuttonRef = React.createRef();
    this.resizer = React.createRef();
    this.state = {
      editable: false,
      anchorEl: null,
      overMe: false
    };
  }

  componentDidMount() {
    // alert("Hi");
    // //("Component Mounted", this.props.id);
    // let oW = Math.round(this.buttonRef.clientWidth);
    // let oH = Math.round(this.buttonRef.clientHeight);
    // this.setState({ oW, oH });
    // //("OW", oW);
    // //("OH", oH);
  }
  componentDidUpdate(prevProps, prevState) {
    // //("DId Update Method");
    // //(prevProps);
    // //(this.props);
    // if (prevProps.id !== this.props.id) {
    //   let oW = Math.round(this.buttonRef.clientWidth);
    //   let oH = Math.round(this.buttonRef.clientHeight);
    //   this.setState({ oW, oH });
    //   //("Things Updated");
    // }
  }
  render() {
    const {
      id,
      components,
      hover,
      connectDragSource,
      connectDropTarget,
      disableDrag,
      heightLightComponent
    } = this.props;
    const component = components[id];
    const styles = {
      opacity: hover ? 0.5 : 1,
      ...component.properties.styles
    };
    const { disableSelection, idToBeBinded, idsBinded } = this.props.designer;
    const componentStyles = component.properties.styles;
    const width = getDimensionValue(componentStyles.width);
    const height = getDimensionValue(componentStyles.height);
    const className = component.properties.customClassName;
    const alreadyBinded = idsBinded.find(x => x == component.id);
    const border =
      heightLightComponent === id && disableDrag
        ? "3px solid black"
        : styles.border;
    const color = componentStyles.color;
    let arrangingStyles = {
      ...componentStyles,
      width,
      height,

      border,
      color,
      position: "relative"
    };
    const open = Boolean(this.state.anchorEl);
    const html_id = open ? "component" + id : "component" + id;

    return (
      <>
        <i
          ref={instance => {
            this.IconbuttonRef = instance;
            connectDragSource(instance);
            connectDropTarget(instance);
          }}
          className={className + " fa-2x" + html_id}
          //   aria-describedby={html_id}
          id={html_id}
          style={arrangingStyles}
          onDoubleClick={this.hanldeDoubleClick}
          onClick={e => {
            e.stopPropagation();
            if (
              disableSelection &&
              idToBeBinded !== component.id &&
              !alreadyBinded
            ) {
              manageBindedId(component.id);
            }
            if (
              disableSelection &&
              idToBeBinded !== component.id &&
              alreadyBinded
            ) {
              removeBindedId(component.id);
            }
            if (!disableDrag && !disableSelection) this.props.clickHandler(id);
            if (disableDrag)
              highLightKey(this.splittingAndMakingObject(component.node_id));
          }}
        >
          {typeof component.properties.customClassName !== "undefined"
            ? ""
            : "Double Click Me To Select Icon"}
        </i>
        {!disableDrag && (
          <MaterialPopOver
            handleClose={this.handleClose}
            anchorEl={this.state.anchorEl}
            component={component}
          >
            <IconProperties component={component}></IconProperties>
          </MaterialPopOver>
        )}
      </>
    );
  }
  splittingAndMakingObject(node) {
    if (node) {
      const split = node.split(":");
      const key = split[0];
      const value = split[1];
      return {
        key,
        value
      };
    }
  }
  handleStyle() {
    const { components, id } = this.props;
    const parent = components[components[id].parent_id].type;
    //(parent === "GridView");
    if (
      parent === "GridView" ||
      parent === "LinearLayout_Horizontal" ||
      parent === "LinearLayout_Vertical"
    )
      return true;
    return false;
  }

  getState = e => {
    return this.state;
  };
  handleClose = e => {
    this.setState({ anchorEl: null });
  };
  hanldeDoubleClick = e => {
    e.stopPropagation();
    this.setState({ anchorEl: e.currentTarget });
  };

  handleLabelChange = e => {
    changeLabel(this.props.id, e.currentTarget.innerHTML);
  };
}
const mapStateToProps = state => state;
export default flow(
  DropTarget("modules", dropSpecs, (connect, monitor) =>
    dropCollect(connect, monitor)
  ),
  DragSource("modules", dragSpecs, (connect, monitor) =>
    dragCollect(connect, monitor)
  )
)(connect(mapStateToProps)(ComponentViewerIconButton));
