import { API_LOAD_START, API_LOAD_END } from "../../constants/api-action-types";

export function loadingEnd() {
  window.store.dispatch({
    type: API_LOAD_END
  });
}
export function loadingStart() {
  window.store.dispatch({
    type: API_LOAD_START
  });
}
