/** @format */

import React, { useState, useRef, useEffect } from "react";

import { changeLabel } from "../../../../store/actions/action-functions/changeLabel";
import Popover from "@material-ui/core/Popover";

import TextFieldProperties from "./TextFieldProperties";
import ComponentViewer from "./ComponentViewer";
// import "./TextFieldStyles/textFieldDesigns.css";
import {
  dropSpecs,
  dropCollect,
  dragSpecs,
  dragCollect,
} from "./utils/dndMethods";
import { DragSource, DropTarget } from "react-dnd";
// import "./ButtonStyles/buttonStyle.css";
import "./css/alignment.css";
import flow from "lodash/flow";
import splittingAndMakingObject from "./utils/splitingKeys";
import { highLightKey } from "../../../../store/actions/action-functions/addBindedId";
import { getDimensionValue } from "../../Properties/utils/changeToHtmlUnits";
import "./baseStyleSheet.css";
import {
  changeStyle,
  changePropertiesComponent,
} from "../../../../store/actions/designerActions";

import {
  manageBindedId,
  removeBindedId,
} from "../../../../store/actions/action-functions/manageBindedIds";
import { basicProps } from "./shared/BasicProps";
import "./css/alignment.css";
import { useDraggableComponent } from "./utils/dragComponent";
import { useDropComponent } from "./utils/dropComponent";
import { toast } from "react-toastify";
import useTempComponent from "./utils/TempElements";

const ComponentViewerEditText = ({ id }) => {
  const ref = useRef();
  const basicObject = basicProps();
  const dragBehaviour = useDraggableComponent(id);
  const dropBehaviour = useDropComponent(id, ref);
  const { hovered } = dropBehaviour;
  const [state, setState] = useState({
    anchorEl: null,
  });
  const component = basicObject.components[id];
  let styles = { opacity: hovered ? 1 : 0.3 };
  if (component.properties) {
    if (component.properties.styles)
      styles = { ...component.properties.styles };
  }

  const open = Boolean(state.anchorEl);
  const html_id = open ? "component" + id : "";
  const className = component.properties.customClassName;
  const componentStyles = component.properties.styles;
  const width = getDimensionValue(componentStyles.width);
  const height = getDimensionValue(componentStyles.height);
  const alreadyBinded = basicObject.idsBinded.find((x) => x == component.id);
  const toggle = useTempComponent(id);
  // const border =
  //   (basicObject.heightLightComponent === id && basicObject.disableDrag) ||
  //   alreadyBinded
  //     ? "2px solid black"
  //     : styles.border;
  // const arrangingStyles = { ...componentStyles, width, height };
  // //("arrangingStyles", arrangingStyles);
  const handleClose = (e) => {
    setState({ anchorEl: null });
  };
  dropBehaviour.drop(dragBehaviour.drag(ref));

  return (
    <React.Fragment>
      <input
        id={"component-" + id}
        ref={ref}
        className={className}
        style={componentStyles}
        type={component.properties.styles["type"]}
        onDoubleClick={(e) => {
          e.stopPropagation();
          setState({ anchorEl: e.currentTarget });
        }}
        onClick={(e) => {
          e.stopPropagation();
          if (
            basicObject.disableSelection &&
            component.id !== basicObject.idToBeBinded &&
            !alreadyBinded
          ) {
            manageBindedId(component.id);
          }
          if (
            basicObject.disableSelection &&
            component.id !== basicObject.idToBeBinded &&
            alreadyBinded
          ) {
            removeBindedId(component.id);
          }
          if (!basicObject.disableDrag && !basicObject.disableSelection)
            changePropertiesComponent(id);
          if (basicObject.disableDrag)
            highLightKey(splittingAndMakingObject(component.node_id));
        }}
        value={component.properties.styles.value}
        onChange={(e) => changeStyle(component.id, "value", e.target.value)}
        placeholder={component.properties.styles["placeholder"]}
      />
      {!basicObject.disableDrag && (
        <Popover
          id={html_id}
          open={open}
          onClose={handleClose}
          anchorEl={state.anchorEl}
          onClick={(e) => e.stopPropagation()}
          onDoubleClick={(e) => e.stopPropagation()}
          anchorReference="anchorPosition"
          anchorPosition={{ top: 200, left: 1300 }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
        >
          <TextFieldProperties component={component} />
        </Popover>
      )}
    </React.Fragment>
  );
};

export default flow(
  DropTarget("modules", dropSpecs, (connect, monitor) =>
    dropCollect(connect, monitor)
  ),
  DragSource("modules", dragSpecs, (connect, monitor) =>
    dragCollect(connect, monitor)
  )
)(ComponentViewerEditText);
