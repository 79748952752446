// import React from "react";
import React, { Component } from "react";
import AllProperties from "./../utils/allPropertiesMethods";
import { changeLabel } from "./../../../store/actions/action-functions/changeLabel";
import Input from "@material-ui/core/Input";
import { checkingForLayout } from "../ComponentDesigner/ComponentViewer/utils/dndMethods";
import CompleteTable from "./utils/TableBodyGenerator";

class LabelProperty extends AllProperties {
  state = {};
  render() {
    const { component } = this.props;
    const isLayout =
      checkingForLayout(component.type) && component.type === "EditText";
    const dataArray = [
      {
        col_1: "Label",
        col_2: (
          <Input
            id="labelproperty"
            value={component.label}
            onChange={e => changeLabel(component.id, e.target.value)}
            placeholder="Label"
            error
          />
        )
      }
    ];
    if (isLayout) return null;
    return <CompleteTable tableData={dataArray} />;
  }
}

export default LabelProperty;
