import React, { Component } from "react";
import Modal from "react-bootstrap/Modal";
import { Button } from "@material-ui/core";
import { changeComponentAttribute } from "../../../../store/actions/designerActions";
import { toast } from "react-toastify";

class InputJSON extends Component {
  state = { tempJSON: "" };
  render() {
    const { show, handleClose, component, requestJson } = this.props;
    return (
      <Modal show={show} onHide={() => handleClose()}>
        <Modal.Title>Input JSON to Be Sent With Request</Modal.Title>
        <Modal.Body>
          <textarea
            className="form-control"
            name="inputjson"
            id="inputjson"
            cols="30"
            rows="20"
            value={requestJson ? requestJson : this.state.tempJSON}
            onChange={e => this.setState({ tempJSON: e.target.value })}
          ></textarea>
          <button
            onClick={e => {
              changeComponentAttribute(
                component.id,
                "requestJson",
                this.state.tempJSON
              );
              toast.success("Successfully Submitted");
            }}
            className="btn btn-info btn-block mt-2"
          >
            Submit JSON
          </button>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => handleClose()}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default InputJSON;
