import React from "react";
import Modal from "react-bootstrap/Modal";
import { Button } from "@material-ui/core";
import ActivityDesigner from "../../ComponentDesigner/ActivityDesigner";

import MappingResponse from "../NetworkPropertiesUtils/ResponseNavigation/ResponseMapping";

const BindingsMapper = ({
  responseJSON,
  show,
  handleClose,
  designer,
  selectedPropertiesScreenID,
}) => {
  let myDefaultWidth = 600 * 0.8;
  let myDefaultHeight = 960 * 0.99;
  const mobileResolutionWidth = myDefaultWidth / 1200 - 0.0001;
  const mobileResolutionHeight = myDefaultHeight / 1820 - 0.097;
  return (
    <div>
      {responseJSON && (
        <Modal size="xl" show={show} onHide={() => handleClose()}>
          <Modal.Header closeButton>
            <Modal.Title>Bind The Keys With The Components</Modal.Title>
          </Modal.Header>
          <Modal.Body>

              <div className="row">
                <div className="col-md-5">
                  <MappingResponse
                    selectedPropertiesScreenID={selectedPropertiesScreenID}
                    responseJSON={responseJSON}
                  />
                </div>
                <div className="col-md-7">

                  <div
                    className="app-designer-add-no-scroll"
                    style={{
                      width: "400px",
                      height: "700px",
                      // margin: "0px",
                      // padding: "0px",
                      // transform:
                      //   "scale(" +
                      //   mobileResolutionWidth +
                      //   "," +
                      //   mobileResolutionHeight +
                      //   ")",
                      transformOrigin: "top center",
                    }}
                  >
                    <ActivityDesigner designer={designer} disableDrag={true} />
                  </div>
                </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => handleClose()}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      )}
    </div>
  );
};

export default BindingsMapper;
