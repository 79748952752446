import {
    CHANGE_SELECTED_NODE
} from "../../constants/action-types";
import {
    changeCurrentSelectedActivity
} from "./changeCurrentSelectedActivity";

export function changeSelectedComponent(id) {











    window.store.dispatch({
        type: CHANGE_SELECTED_NODE,
        payLoad: {
            id: id
        }
    });
}

/**
 * Change selected Node
 * @param {*} state
 * @param {*} action
 */
export function changeSelectedCompoentReducer(state, action) {

    let id = action.payLoad.id;




    let idToBeSelected = id;
    if (idToBeSelected == 1 || idToBeSelected == 2) {
        idToBeSelected = 3;
    }
    let selectedPropertiesScreenID = id + 1;
    //selectedPropertiesScreenID = id;
    // if (state.components[id].type === typeConstants.COMPONENT_ACTIVITY) {
    //   idToBeSelected = state.components[id].children[0];
    // }
    state = {
        ...state,
        selectedScreenID: idToBeSelected,
        selectedPropertiesScreenID: selectedPropertiesScreenID
    };
    return state;
}