/** @format */

import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import TopBar from "./layout/TopBar";
import AlphaRoutes from "../AlphaRoutes";
import { useSelector, useDispatch } from "react-redux";
import LoginDialog from "../dialogs/LoginDialog";
import { setOpenLogin } from "../../auth/store/actions/login.actions";
const useStyles = makeStyles((theme) => {
  // //(theme.palette.background.default);
  return {
    root: {
      width: "100%",
      height: "100%",
      overflow: "hidden",
      backgroundColor: theme.palette.background.default,
      color: theme.palette.text.primary,
      minHeight: "400px",
    },
  };
});
const AlphaLayout = (props) => {
  const classes = useStyles(props);
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  // //(auth);
  return (
    <div>
      <TopBar />
      <LoginDialog
        open={auth.showLogin}
        setOpen={(value) => {
          dispatch(setOpenLogin(value));
        }}
      />
      <div className={classes.root}>
        <AlphaRoutes />
      </div>
    </div>
  );
};

export default AlphaLayout;
