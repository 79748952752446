/** @format */

import { COMPONENT_TEXTVIEW } from "../constants/typeConstants";
import common from "./common";
/**
 * factory to produce label
 * @param {String} name of the component
 * @param {String} label of the text View
 */
export default function (name = "Text_View_Component", label = null) {
  ////("name:" + name + "  label:" + label);
  let obj = {
    name: name,
    type: COMPONENT_TEXTVIEW,
    children: [],
  };

  obj = Object.assign({}, obj, common);
  delete obj.properties;
  const defaultStyles = {
    width: "wrap_content",
    height: "wrap_content",
    fontSize: "10px",
  };
  obj.properties = {
    properties: {},
  };
  obj.properties.styles = defaultStyles;
  obj.properties.customClassName = "label-2";
  if (label) obj.label = label;
  else obj.label = name;
  return obj;
}
