/** @format */

import React, { Component } from "react";
import { styleChanged } from "./../Properties/styleActions";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import "./styleselect.css";
import InputBase from "@material-ui/core/InputBase";
const BootstrapInput = withStyles((theme) => ({
  input: {
    borderRadius: 4,
    position: "relative",
    backgroundColor: theme.palette.background.paper,
    border: "1px solid #ced4da",
    fontSize: 12,
    // padding: "10px 26px 10px 12px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    // height: "11px",
    width: "40px",
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:focus": {
      borderRadius: 4,
      borderColor: "#80bdff",
      boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
    },
  },
}))(InputBase);

class MaterialSelect extends Component {
  state = {
    selected: "",
  };

  render() {
    const { options, name, label, component, ...rest } = this.props;
    let selected = "";
    if (component && component.properties) {
      selected = component.properties.styles[name];
    }
    return (
      <div style={{ marginTop: "4px", marginLeft: "7px", width: "100%" }}>
        {/* <label htmlFor={name} className="label label-primary">
          {label}
        </label> */}
        <select
          onChange={this.handlingSelect}
          value={selected || ""}
          name={name}
          className=""
          id={name}
          style={{ fontSize: "14.7px", width: "120px", background: "#ECECEC" }}
          {...rest}
        >
          <option value="" disabled>
            {label}
          </option>
          {options.map((option, i) => (
            <option key={option} value={option}>
              {option}
            </option>
          ))}
        </select>
      </div>
    );
  }

  handlingSelect = (e) => {
    window.store.dispatch(
      styleChanged(e.target.value, e.target.name, this.props.component)
    );
  };
}

export default MaterialSelect;
