import React from "react";
import NavBarMobileToggleButton from "./NavBarMobileToggleButton";
//this is already wrapped in a toolbar
const NavbarMd = (props) => {
  return (
    <>
      <NavBarMobileToggleButton />
    </>
  );
};

export default NavbarMd;
