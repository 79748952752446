const theme = {
  palette: {
    // background: {
    //   default: "#FFFFFF",
    // },
  },
};

export default theme;
// consult https://material-ui.com/customization/default-theme/#default-theme for details
