import {
    DELETE_COMPONENT_FROM_TREE
} from "./../../constants/action-types";
import produce from "immer";
import Immutable from "./../../reducers/handlers/Immutable";
import {
    COMPONENT_ACTIVITY
} from "./../../constants/typeConstants";
import {
    toast
} from "react-toastify";

/**
 * delete a component. Will be removed from parent. will not be placed on clipboard. will not be actually deleted so that we implement undo later
 * @param {*} index of the component to be deleted
 */
export function deleteComponent(index) {
    window.store.dispatch({
        type: DELETE_COMPONENT_FROM_TREE,
        payLoad: {
            index
        }
    });
}
export function deleteComponentReducer(state, action) {
    const {
        index
    } = action.payLoad;
    const parent_id = state.components[index].parent_id;
    const components = [...state.components];
    if (components[parent_id].type === COMPONENT_ACTIVITY) {
        // toast.warn("Activity Must Have a Layout");
        return state;
    }

    return produce(state, draftState => {
        draftState.components[parent_id].children = Immutable.removeItem(
            draftState.components[parent_id].children,
            draftState.components[parent_id].children.indexOf(index)
        );
        draftState.selectedPropertiesScreenID = parent_id;
        draftState.selectedComponentId = parent_id;
    });
}