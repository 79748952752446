import React from "react";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { changeComponentAttribute } from "../../../store/actions/designerActions";

const SelectTagRenderer = ({ value, options, component, ...rest }) => {
  return (
    <Select
      {...rest}
      onChange={e =>
        changeComponentAttribute(component.id, "requestType", e.target.value)
      }
      value={value ? value : options[0]}
    >
      {options.map(option => {
        return (
          <MenuItem key={option} value={option}>
            {option}
          </MenuItem>
        );
      })}
    </Select>
  );
};

export default SelectTagRenderer;
