import React, { Component } from "react";
import {
  dropSpecs,
  dropCollect,
  dragSpecs,
  dragCollect
} from "./utils/dndMethods";
import { DragSource, DropTarget } from "react-dnd";
import "./ButtonStyles/buttonStyle.css";
import "./css/alignment.css";
import flow from "lodash/flow";
import MaterialPopOver from "./Popover/MaterialPopOver";
import ComponentViewer from "./ComponentViewer";
import splittingAndMakingObject from "./utils/splitingKeys";
import { highLightKey } from "../../../../store/actions/action-functions/addBindedId";

class ComponentViewerDropDown extends ComponentViewer {
  render() {
    const {
      components,
      id,
      isDragging,
      connectDragSource,
      connectDropTarget,
      hover,
      disableDrag
    } = this.props;
    const component = components[id];
    return (
      // <div className="ComponentViewerDropDown">
      <div
        ref={instance => {
          connectDragSource(instance);
          connectDropTarget(instance);
        }}
        style={{ display: "inline" }}
      >
        <select
          disabled
          style={{
            ...components[id].properties.styles,
            opacity: hover ? 0 : 1
          }}
          onClick={e => {
            e.stopPropagation();
            if (!this.props.disableDrag) this.props.clickHandler(id);
            if (disableDrag)
              highLightKey(splittingAndMakingObject(component.node_id));
          }}
        >
          {/* <option value="">1</option> */}
        </select>
      </div>
    );
  }
}
export default flow(
  DropTarget("modules", dropSpecs, (connect, monitor) =>
    dropCollect(connect, monitor)
  ),
  DragSource("modules", dragSpecs, (connect, monitor) =>
    dragCollect(connect, monitor)
  )
)(ComponentViewerDropDown);
