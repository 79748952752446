/** @format */

import { getDimensionValue } from "./changeToHtmlUnits";
import { separator } from "../pxSeparator";

export function getLayoutStyleObject(
         id,
         components,
         hover,
         selectedPropertiesScreenID
       ) {
           const component = components[id];
           const { width: w, height: h } = component.properties.styles;
           const width = getDimensionValue(w);
           const height = getDimensionValue(h);

           const alreadyPadded = component.properties.styles["padding"];

           // const padding = hover ? "8px" : ((component.properties.styles["padding"]));
           // const paddingLeft = hover ? "8px" : ((component.properties.styles["padding"]) ) ;
           // const padding = hover? (alreadyPadded)+5+"px":

           // //(padding);
           // const paddingLeft = hover ? "8px" : ((component.properties.styles["paddingLeft"]));
           // const paddingRight = hover ? "8px" : ((component.properties.styles["paddingRight"]));
           // const paddingTop = hover ? "8px" : ((component.properties.styles["paddingTop"]));
           // const paddingBottom = hover ? "8px" : ((component.properties.styles["paddingBottom"]));

           const transitionDuration = "0.5s";
           // const backgroundColor = component.properties.styles["backgroundColor"];
           const backgroundImage =
             component.properties.styles["backgroundImage"];

           return {
             ...component.properties.styles,
             width,
             height,
             // backgroundColor,
             // backgroundImage,
           };
         }

function getRandomColor() {
    var letters = "34567F9ABCDEF";
    var color = "#";
    for (var i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 12)];
    }
    return color;
}