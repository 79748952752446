/** @format */

import droppedComponent from "./determiningComponentDragged";

import { addComponent } from "./../../../../../store/actions/action-functions/addComponent";

import {
  moveComponentToEmptyLayout,
  moveComponentByDraggingUp,
} from "../../../../../store/actions/action-functions/moveComponent";
import { isLayout } from "../../shared/utils";
import { toast } from "react-toastify";
import { UPDATE_RECENT_COMPONENTS } from "../../../../../store/constants/action-types";
var ReactDOM = require("react-dom");
export function checkingForGroup(monitor) {
  if (Boolean(monitor.getItem().grouped)) return true;
  return false;
}

export function useDropComponent(id, ref) {}
export function useDragComponent(id) {}

export const dropSpecs = {
  drop(props, monitor, component) {
    const {
      components,
      showExpectedPositionFor,
    } = window.store.getState().designer;
    if (monitor.didDrop()) return;

    // Checks whether component is not dropped over itsself
    if (Boolean(monitor.getItem().id)) {
      //What check below is doing ?
      // It is moving component from one layout to other layout by checking the condition
      // that layout is already not the parent of the current component
      if (props.id !== components[monitor.getItem().id].parent_id) {
        const id = monitor.getItem().id;
        moveComponentToEmptyLayout(id, props.id);
        // changeStyleBulk(id, {
        //   position: "relative",
        //   top: exactTop + "px",
        //   left: exactLeft + "px"
        // })
        // changeStyle(id, "position", "absolute");
        // changeStyle(id, "top", exactTop + "px");
        // changeStyle(id, "left", exactLeft + "px");

        return;
      }
    }
    if (
      monitor.isOver() &&
      monitor.isOver({
        shallow: true,
      })
    ) {
      if (Boolean(monitor.getItem().name)) {
        const COMPONENT = droppedComponent(monitor.getItem().name);
        window.store.dispatch({
          type: UPDATE_RECENT_COMPONENTS,
          component: monitor.getItem().name,
        });
        if (checkingForGroup(monitor)) {
          for (let i = 0; i < 3; i++) addComponent(props.id, COMPONENT);
        } else addComponent(props.id, COMPONENT);
      } else {
        let requiredId = props.id;
        if (!requiredId) {
          requiredId = component.props.id;
        }
        moveComponentByDraggingUp(monitor.getItem().id, requiredId);
      }
    }
  },
  canDrop(props, monitor) {
    // Checks whether component is not dropped over itsself

    const { components, disableDrag } = window.store.getState().designer;

    if (disableDrag) return false;
    if (Boolean(monitor.getItem().name)) return true;
    else {
      if (monitor.getItem().id == props.id) return false;
      if (Boolean(monitor.getItem().key)) return false;

      const component_dragged = monitor.getItem().id;
      if (component_dragged === components[props.id].parent_id) {
        return false;
      }

      let component_hovered = components[props.id];
      while (
        component_hovered &&
        (component_hovered.type !== "Activity" ||
          component_hovered.type !== "Init")
      ) {
        if (component_dragged === component_hovered.parent_id) return false;
        component_hovered = components[component_hovered.parent_id];
      }

      //("Component Type", components[props.id].type);
      if (components[props.id].type == "ListView") {
        if (
          monitor.getItem().name == "Horizontal Layout" ||
          monitor.getItem().name == "Vertical Layout"
        ) {
          return true;
        }
        toast.error("Only Layout Can be Dropped");
        return false;
      }

      return true;
    }
  },
};
export function dropCollect(connect, monitor) {
  let hover = monitor.isOver({ shallow: true });
  const { components } = window.store.getState().designer;
  if (monitor) {
    if (monitor.getItem() != undefined && Boolean(monitor.getItem().key))
      hover = false;
  } else hover = monitor.isOver();
  // //(monitor.getItem());
  return {
    connectDropTarget: connect.dropTarget(),
    hover: monitor.isOver({
      //   shallow: true,
    }),
  };
}

export const dragSpec = {
  beginDrag(props, monitor, component) {
    const rect = ReactDOM.findDOMNode(component).getBoundingClientRect();
    return {
      id: props.id,
      type: "layout",
      rect: rect,
    };
  },
  canDrag(props) {
    const { id } = props;
    const { components } = window.store.getState().designer;
    const component = components[id];
    const screens = components[2].children;
    const ifParentIsBaseLayout = screens.find(
      (screen) => screen === component.parent_id
    );
    if (ifParentIsBaseLayout) return false;
    return true;
  },
  isDragging(props, monitor) {},
};
export function dragCollect(connect, monitor) {
  return {
    connectDragSource: connect.dragSource(),
    isDragging: monitor.isDragging(),
  };
}
