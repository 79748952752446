import React, { Component } from "react";
import image1 from "../SiteImages/image1.jpeg";
import image2 from "../SiteImages/image2.jpeg";

import "./AboutUs.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "font-awesome/css/font-awesome.min.css";

class AboutUs extends Component {
  state = {};
  render() {
    return (
      <React.Fragment>
        <div className="about-page">
          <div className="container">
            <div className="row">
              <div className="col-md-10 mx-auto card-about">
                <h2 className="m-b text-center about">
                  <b>About Us</b>
                </h2>
                <div className="text-justify mx-auto">
                  <p className="m-b-xs content">
                    We all work together every day because we want
                    to solve the biggest problem in mobile.{" "}
                    <b>Everyone is guessing</b>. Publishers don’t know what apps
                    to build, how to monetize them, or even what to price them
                    at. Advertisers &amp; brands don’t know where their target
                    users are, how to reach them, or even how much they need to
                    spend in order to do so. Investors aren’t sure which apps
                    and genres are growing the quickest, and where users are
                    really spending their time (and money).
                  </p>
                  <p className="m-b-xs content">
                    Throughout the history of business, people use <b>data</b>{" "}
                    to make more informed decisions. Our mission at is
                    to make the app economy more transparent. Today we provide
                    the most actionable mobile app data &amp; insights in the
                    industry. We want to make this data available to as many
                    people as possible (not just the top 5%).
                  </p>
                </div>
                <div className="row faq">
                  <div className="col-lg-4 mx-auto">
                    <h3>
                      <b>Leadership</b>
                    </h3>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-6 col-xxl-3 mb-xxl  leader">
                    <img
                      className="img-circle img-fluid m-x-auto m-b"
                      src="https://scontent.flhe3-1.fna.fbcdn.net/v/t1.0-9/16174958_10155619675548765_1539941709321984911_n.jpg?_nc_cat=104&_nc_ht=scontent.flhe3-1.fna&oh=7a1a0f4f492d1e7f0d8e71f6b6335582&oe=5D8B64ED"
                    />
                    <h3 className="text-md text-bold">Usman Akram</h3>
                    <p className="text-xxxs mb-sm text-uppercase">Supervisor</p>
                    <p className="text-xs mb-sm mx-auto">
                      Sir Usman Akram is senior web developer and Assistant
                      Professor at COMSATS lhr. He is supervising this project
                      and guide about the path to improve complete project.
                    </p>
                  </div>
                  <div className="col-sm-6 col-xxl-3 mb-xxl  leader">
                    <img
                      className="img-circle img-fluid m-x-auto m-b"
                      src={image1}
                    />
                    <h3 className="text-md text-bold">Osama Inayat</h3>
                    <p className="text-xxxs mb-sm text-uppercase">Team Lead</p>
                    <p className="text-xs mb-sm mx-auto">
                      Osama Inayat is a student of computer science. He is team
                      leader of this project. He has 2 years experience in
                      web-development as a freelancer.
                    </p>
                  </div>
                  {/* <div className="col-sm-6 col-xxl-3 mb-xxl  leader">
                    <img
                      className="img-circle img-fluid m-x-auto m-b"
                      src="https://scontent.flhe3-1.fna.fbcdn.net/v/t1.0-9/14721522_790401091102515_8663903551009798842_n.jpg?_nc_cat=101&_nc_ht=scontent.flhe3-1.fna&oh=db2f286b56ec4b5c9393c973a168a683&oe=5D791EEE"
                    />
                    <h3 className="text-md text-bold">Awais Ayub</h3>
                    <p className="text-xxxs mb-sm text-uppercase">
                      Co-founder &amp; CEO
                    </p>
                    <p className="text-xs mb-sm mx-auto">
                      Serge fearlessly leads our engineering team and is
                      responsible for Apptopia’s underlying technology. His
                      focus & passion is on data acquisition and backend
                      architecture / infrastructure.
                    </p>
                  </div>
                  <div className="col-sm-6 col-xxl-3 mb-xxl  leader">
                    <img
                      className="img-circle img-fluid m-x-auto m-b"
                      src="https://scontent.flhe3-1.fna.fbcdn.net/v/t1.0-9/59698879_1992295710897115_6391036307111411712_n.jpg?_nc_cat=105&_nc_ht=scontent.flhe3-1.fna&oh=8bbeabfb5b31bb5869bacd77a2c84689&oe=5DB728AE"
                    />
                    <h3 className="text-md text-bold">Osama Inayat</h3>
                    <p className="text-xxxs mb-sm text-uppercase">Developer</p>
                    <p className="text-xs mb-sm mx-auto">
                      Christian leads revenue at Apptopia. His passion &
                      experience with high growth tech companies will help
                      ensure Apptopia provides the most powerful, flexible, and
                      hands-on experience to all of our customers.
                    </p>
                  </div> */}
                </div>
                <div className="row faq">
                  <div className="col-lg-4 mx-auto">
                    <h3>
                      <b>Team</b>
                    </h3>
                  </div>
                </div>
                <div className="row">
                  <div className="col-xs-6 col-sm-4 col-lg-3 mb-xxl mx-auto leader">
                    <img
                      className="img-circle img-fluid m-x-auto m-b"
                      src="https://scontent.flhe3-1.fna.fbcdn.net/v/t1.0-9/59698879_1992295710897115_6391036307111411712_n.jpg?_nc_cat=105&_nc_ht=scontent.flhe3-1.fna&oh=8bbeabfb5b31bb5869bacd77a2c84689&oe=5DB728AE"
                    />
                    <h3>
                      <b>Osama Inayat</b>
                    </h3>
                    <p>Front-End Developer</p>
                  </div>
                  <div className="col-xs-6 col-sm-4 col-lg-3 mb-xxl mx-auto leader">
                    <img
                      className="img-circle img-fluid m-x-auto m-b"
                      src={image1}
                    />
                    <h3>
                      <b>Musa Butt</b>
                    </h3>
                    <p>UI Designer</p>
                  </div>
                  <div className="col-xs-6 col-sm-4 col-lg-3 mb-xxl mx-auto leader">
                    <img
                      className="img-circle img-fluid m-x-auto m-b"
                      src={image2}                     
                    />
                    <h3>
                      <b>Mohkam Farid</b>
                    </h3>
                    <p>Front-End Designer</p>
                  </div>
                </div>
                {/* <div className="row faq">
                  <div className="col-lg-4 mx-auto">
                    <h3>
                      <b>Motivation</b>
                    </h3>
                  </div>
                </div>
                <div className="row">
                  <div className="col-xs-6 col-sm-4 col-lg-4 mb-xxl leader">
                    <img
                      className="img-investor img-fluid m-x-auto m-b"
                      src="https://d2sxfzw9cql5wy.cloudfront.net/assets-p/about/logo-evc-586c6d3f28908c73dbea76f536aa05bfe994b044938b7f26f8b61b0ac6b2338b.png"
                    />
                    <h3>Expansion Venture Capital</h3>
                  </div>
                  <div className="col-xs-6 col-sm-4 col-lg-4 mb-xxl leader">
                    <img
                      className="img-investor img-fluid m-x-auto m-b"
                      src="https://d2sxfzw9cql5wy.cloudfront.net/assets-p/about/500_startups-6b9a7660f13d8b30aff3e14ca36bbf42db7c71a2e7a8628d1ea81c942b28873e.png"
                    />
                    <h3>500 Start Ups</h3>
                  </div>
                  <div className="col-xs-6 col-sm-4 col-lg-4 mb-xxl leader">
                    <img
                      className="img-investor img-fluid m-x-auto m-b"
                      src="https://d2sxfzw9cql5wy.cloudfront.net/assets-p/about/rta_ventures-48fb456062d4ac4dd453343a65043828005f95c2a373418d5a3e868cadb827ad.png"
                    />
                    <h3>RTA Aventures</h3>
                  </div>
                  <div className="col-xs-6 col-sm-4 col-lg-4 mb-xxl leader">
                    <img
                      className="img-investor img-fluid m-x-auto m-b"
                      src="https://d2sxfzw9cql5wy.cloudfront.net/assets-p/about/logo-kima-72a48e97cbb805ad38c443066b69e2a83acb6a7d9c33d48eace9a2783cdb4e7c.png"
                    />
                    <h3>Klima Aventures</h3>
                  </div>
                  <div className="col-xs-6 col-sm-4 col-lg-4 mb-xxl leader">
                    <img
                      className="img-investor img-fluid m-x-auto m-b"
                      src="https://d2sxfzw9cql5wy.cloudfront.net/assets-p/about/rta_ventures-48fb456062d4ac4dd453343a65043828005f95c2a373418d5a3e868cadb827ad.png"
                    />
                    <h3>RTA Aventures</h3>
                  </div>
                  <div className="col-xs-6 col-sm-4 col-lg-4 mb-xxl leader">
                    <img
                      className="img-investor img-fluid m-x-auto m-b"
                      src="https://d2sxfzw9cql5wy.cloudfront.net/assets-p/about/thcap_logo_transperancy-6b07e4bc5c2226e77075180c6ce009528f07efdf592ec49123c039a216416d2d.png"
                    />
                    <h3>Telegraph Hill Capital</h3>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default AboutUs;
