/** @format */

import React from "react";
import DimensionWrapper from "../../Properties/DimensionWrapper";
import MenuDropDown from "../../shared/MenuDropDown";
import { makeStyles } from "@material-ui/core";
import SubProperties from "../../Properties/SubProperties";
import ExtendedProperties from "../../Properties/ExtendedProperties";
import { basicProps } from "../../ComponentDesigner/ComponentViewer/shared/BasicProps";
import {
  TOGGLE_MARGIN,
  TOGGLE_PADDING,
} from "../../../../store/constants/action-types";
import MaterialSelect from "../../utils/bootstrapSelect";
import FontProperties from "../../Properties/fontProperties";
import AlignContentBar from "../../Properties/AligningContent";
import CssEditor from "../../SideBar/CssEditor";
import { isActivity } from "../../ComponentDesigner/ComponentViewer/utils/labelAllowed";
import MakeNavigationActivity from "../../Properties/MakeNavigationActivity";
import MakeMainActivity from "../../Properties/makeMainActivity";
import AddToolBar from "../../Properties/AddToolBar";
import ColorProperties from "../../Properties/ColorProperties";
import BorderProperties from "../../Properties/borderProperties";
import NameProperty from "../../Properties/nameProperty";
import TransitionProp from "../../Properties/TransitionActivity";
import NetworkProperties from "../../Properties/NetworkProperties";
import { useSelector } from "react-redux";
import BoxShadow from "../../Properties/boxshadow";
const useStyles = makeStyles((theme) => ({
  root: {
    boxShadow: theme.shadows[3],
    boxSizing: "border-box",
    width: "100%",
    fontWeight: "bold",
    fontFamily: "Helvetica",
    fontSize: "14px",
  },
  table: {
    border: 0,
    display: "table",
  },
}));

const RightMenu = () => {
  const classes = useStyles();
  const {
    showPaddings,
    showMargins,
    selectedPropertiesScreenID,
    components,
    showCssEditor,
    cssCode,
  } = basicProps();
  const component = components[selectedPropertiesScreenID];
  const designer = useSelector((state) => state.designer);
  return (
    <div className={classes.root}>
      {showCssEditor ? (
        <CssEditor code={cssCode} />
      ) : isActivity(component.type) ? (
        <>
          <MakeNavigationActivity component={component} />
          <MakeMainActivity component={component} />
          <AddToolBar component={component} />
        </>
      ) : (
        <div className={classes.table}>
          <MenuDropDown title={"Dimensions"} wrap={true} leftMargin={true}>
            <tr>
              <DimensionWrapper
                title={"Width"}
                property={"width"}
              ></DimensionWrapper>
            </tr>

            <tr>
              <DimensionWrapper
                title={"Height"}
                property={"height"}
              ></DimensionWrapper>
            </tr>
            <tr>
              <ExtendedProperties
                title="Padding"
                property={"padding"}
                toggle={TOGGLE_PADDING}
                icon={showPaddings}
              ></ExtendedProperties>
            </tr>
            {showPaddings && (
              <SubProperties property={"padding"}></SubProperties>
            )}
            <tr>
              <ExtendedProperties
                title="Margin"
                property={"margin"}
                toggle={TOGGLE_MARGIN}
                icon={showMargins}
              ></ExtendedProperties>
            </tr>
            {showMargins && <SubProperties property={"margin"}></SubProperties>}
          </MenuDropDown>
          <MenuDropDown title={"Font"} wrap={true} leftMargin={true}>
            <tr>
              <DimensionWrapper
                title={"Size"}
                property={"fontSize"}
              ></DimensionWrapper>
            </tr>
            <tr>
              <td style={{ width: "60px" }}>Family</td>
              <td>
                <MaterialSelect
                  options={[
                        "Helvetica",
                    "Courier New",
                    "Patua One",
                    "Courier",
                    "Roboto",
                    "monospace",
                    "Franklin Gothic Medium",
                    "Arial Narrow",
                    "Arial",
                    "sans-serif",
                  ]}
                  name={"fontFamily"}
                  component={component}
                ></MaterialSelect>
              </td>
            </tr>
            <tr>
              <FontProperties component={component}></FontProperties>
            </tr>

            <tr>
              <AlignContentBar component={component}></AlignContentBar>
            </tr>
            <tr>
              <td></td>
              <td>
                <ColorProperties
                  component={component}
                  name={"backgroundColor"}
                ></ColorProperties>
                <ColorProperties
                  component={component}
                  name={"color"}
                ></ColorProperties>
              </td>
            </tr>
          </MenuDropDown>
          <MenuDropDown
                title={"Box Shadow"}
                wrap={true}
                leftMargin={true}
                isOpen={true}
              >
                <BoxShadow title={"Size"}
                  property={"fontSize"} />
              </MenuDropDown>
              <MenuDropDown
                title={"Border"}
                wrap={true}
                leftMargin={true}
                isOpen={true}
          >
            <tr>
              <DimensionWrapper
                title={"Width"}
                property={"borderWidth"}
              ></DimensionWrapper>
            </tr>
            <tr>
              <td style={{ width: "60px" }}>Style</td>
              <td>
                <MaterialSelect
                  options={["dotted", "dashed", "double", "solid"]}
                  name={"borderStyle"}
                  component={component}
                ></MaterialSelect>
              </td>
            </tr>
            <tr>
              <DimensionWrapper
                title={"Radius"}
                property={"borderRadius"}
              ></DimensionWrapper>
            </tr>
            <BorderProperties component={component} />
          </MenuDropDown>
          <MenuDropDown
            title={"Name"}
            wrap={true}
            leftMargin={true}
            isOpen={true}
          >
            <tr>
              <NameProperty component={component}></NameProperty>
            </tr>
          </MenuDropDown>
              <MenuDropDown
            title={"On Click"}
            wrap={true}
            leftMargin={true}
            isOpen={false}
          >
            <TransitionProp></TransitionProp>
          </MenuDropDown>
          <MenuDropDown
            title={"Network"}
            wrap={true}
            leftMargin={true}
            isOpen={true}
          >
            <NetworkProperties
              designer={designer}
            ></NetworkProperties>
          </MenuDropDown>
        </div>
      )}
    </div>
  );
};

export default RightMenu;
