/** @format */

import React from "react";
import GenericProperties from "../GenericPropertiesGenerator/GenericProperties";
import BackgroundProperties from "../../../Properties/backgroundProperties";
import GridLayoutProperties from "../../../Properties/Grid Properties/GridLayoutProperties";
import { isPositive } from "mathjs";

const LayoutProperties = ({ component }) => {
  return null;
  // <GenericProperties>

  //   <BackgroundProperties component={component} />
  //   <isPositive
  //   {gridProperties(component)}

  // </GenericProperties>
};
function gridProperties(component) {
  if (component.type == "GridView") {
    return <GridLayoutProperties component={component} />;
  }
}
export default LayoutProperties;
