import React, { Component } from "react";
import * as typeConstraints from "./../../../store/constants/typeConstants";
import { DragSource, DropTarget } from "react-dnd";
import flow from "lodash/flow";
import { dragSpecs } from "./utils/dragdrop";
import { dropSpecs } from "./../ComponentDesigner/ComponentViewer/utils/dndMethods";
import { connect } from "react-redux";
import TreeItem from "@material-ui/lab/TreeItem";
import { changePropertiesComponent } from "../../../store/actions/designerActions";
import { withStyles } from "@material-ui/styles";

const tempStyles = theme => ({
  selectComponent: props => ({
    color: props.designer.color,
    fontSize: "20px"
  })
});
class SingleNavigationItem extends Component {
  render() {
    // Activity is not draggable or droppable
    const {
      component,
      connectdragSourceComponents,
      connectDropTargetComponents,
      disableDrag,
      selectedPropertiesScreenID,
      classes
    } = this.props;
    const childs = this.props.children && true;
    return (
      <TreeItem
        variant="body2"
        nodeId={"" + component.id}
        label={
          <div
            ref={instance => {
              connectdragSourceComponents(instance);
              connectDropTargetComponents(instance);
            }}
            onClick={e => {
              e.stopPropagation();
              if (!disableDrag) changePropertiesComponent(component.id);
            }}
            className={
              selectedPropertiesScreenID === component.id
                ? classes.selectComponent
                : ""
            }
            style={{ color: "blue !important" }}
          >
            {"" + component.id + "" + "-" + component.name}
          </div>
        }
      >
        {childs ? this.props.children : null}
      </TreeItem>
    );
  }
  getIt(component) {
    if (component.node_id) {
      return {
        targetId: component.node_id,
        targetAnchor: "right",
        sourceAnchor: "left"
      };
    }
    return {};
  }
}
const mapStateToProps = state => {
  return state;
};
export default flow(
  DragSource("modules", dragSpecs, (connect, monior) => ({
    connectdragSourceComponents: connect.dragSource()
  })),
  DropTarget("modules", dropSpecs, (connect, monior) => ({
    connectDropTargetComponents: connect.dropTarget()
  }))
)(connect(mapStateToProps)(withStyles(tempStyles)(SingleNavigationItem)));
