import React, { Component } from "react";
import { DragSource } from "react-dnd";
import goingDeeperIntoObject from "./getFlattenedObject";
import "./ResponseMappingSingleKey.css";
import { heightLightComponent } from "../../../../store/actions/action-functions/addBindedId";
class SingleKey extends Component {
  constructor() {
    super();
    this.singleListRef = React.createRef();
  }
  state = {
    show: false,
    bindedWith: ""
  };

  componentDidMount() {}
  render() {
    const { show, bindedWith } = this.state;
    const {
      tempKey,
      connectDragSource,
      value,
      highLightKey,
      selectedPropertiesScreenID
    } = this.props;
    let ifObject = false;
    const defining_id = tempKey + ":" + value;
    let border = "";
    if (highLightKey) {
      if (tempKey == highLightKey.key && value == highLightKey.value) {
        border = "2px dashed black";
      }
    }
    if (typeof value == "object") ifObject = true;
    return (
      <React.Fragment>
        <li
          id={defining_id}
          ref={instance => connectDragSource(instance)}
          key={tempKey}
          style={{ border }}
        >
          {ifObject && (
            <i
              onClick={e => this.setState({ show: !show })}
              style={{ cursor: "pointer" }}
              className={!show ? "fas fa-caret-right" : "fas fa-caret-up"}
            >
              {tempKey + ":{" + Object.keys(value).length + "}"}
            </i>
          )}
          {!ifObject && (
            <>
              <span onClick={() => heightLightComponent(bindedWith)}>
                <span className="single-key"> {tempKey}</span>
                {":"}
                <span className="single-value"> {value}</span>
              </span>
            </>
          )}
          {show && (
            <ul style={{ listStyleType: "none" }}>{this.props.children}</ul>
          )}
        </li>
      </React.Fragment>
    );
  }
}
export default DragSource(
  "modules",
  {
    beginDrag(props, monitor, Component) {
      const defining_id = props.tempKey + ":" + props.value;
      const f = goingDeeperIntoObject(
        window.responseJSON,
        props.tempKey,
        props.value
      );
      let flat = "";
      for (var i in f) {
        if (i.endsWith(props.tempKey) && f[i] == props.value) {
          flat = i;
          break;
        }
      }
      return {
        key: props.tempKey,
        value: props.value,
        completeAdress: flat,
        node_id: defining_id
      };
    },
    canDrag(props, monitor) {
      if (typeof props.value === "object") return false;
      return true;
    },
    isDragging(props, monitor) {},
    endDrag(props, monitor, component) {
      if (monitor.didDrop()) {
        const bindedWith = monitor.getDropResult().dorppedId;
        component.setState({ bindedWith });
        return;
      }
    }
  },
  (connect, monitor) => {
    return {
      connectDragSource: connect.dragSource(),
      connectDragPreview: connect.dragPreview()
    };
  }
)(SingleKey);
