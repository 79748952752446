import React, { useState } from "react";
import AlphaDialog from "../AlphaDndApp/shared-components/AlphaDialog";
import { Grid, TextField, Button } from "@material-ui/core";
import { useEffect } from "react";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import MenuItem from "@material-ui/core/MenuItem";
import { androidPermissions } from "./utils/defaultFormData";
import AlphaMultiSelect from "../AlphaDndApp/shared-components/AlphaMultiSelect";
import IconButton from "@material-ui/core/IconButton";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import { makeStyles, useTheme } from "@material-ui/core/styles";

import { useDispatch } from "react-redux";
import {
  createProject,
  updateProject,
  getSingleProject,
} from "./store/actions/projectsAPIActions";
import projectsService from "./services/projectsService";

const useStyles = makeStyles((theme) => ({
  repoList: {
    width: "100%",
  },
}));
function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`project-form-tabpanel-${index}`}
      aria-labelledby={`project-form-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </Typography>
  );
}
function a11yProps(index) {
  return {
    id: `project-form-tab-${index}`,
    "aria-controls": `project-form-tabpanel-${index}`,
  };
}
const ProjectForm = ({ open, setOpen, projectId }) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const theme = useTheme();
  const defaultState = {
    appName: "FirstApp",
    userId: "areeb",
    companyName: "Test",
    basePackage: "com",
    fullPackage: "com.example.test",
    androidConfig: {
      gradleVersion: "2.1.2",
      targetSdkVersion: "25",
      minSdkVersion: "17",
      compileSdkVersion: "25",
      buildToolVersion: "26.0.0",
      permissions: ["ACCESS_FINE_LOCATION", "CAMERA"],
      libraries: {
        "Image Loading": "Universal Image Loader (1.9.5)",
        Network: "Volley (1.0.0)",
        Database: "SQLite",
        JSON: "Gson (2.8.5)",
        ORM: "Room (1.1.0)",
      },
      repositories: ["jcenter", "test"],
    },
    serverConfig: {
      groupId: "com.example",
      artifactId: "Project-1",
      version: "0.0.1-SNAPSHOT",
      appName: "Project",
      parentArtifactId: "spring-boot-starter-parent",
      parentVersion: "2.1.1.RELEASE",
      parentGroupId: "org.springframework.boot",
      javaVersion: "1.8",
    },
  };
  const [state, setState] = React.useState(defaultState);
  const [tabIndex, setTabIndex] = useState(3);
  useEffect(
    () => {
      //when record Id is Changed
      if (!projectId) setState(defaultState);
      else {
        //(dispatch(getSingleProject(projectId)));
        dispatch(getSingleProject(projectId)).then((data) => {
          setState({ _id: data._id, ...data.details });
        });
      }
    },
    [projectId]
  );
  useEffect(
    () => {
      if (!open) setState(defaultState);
    },
    [open]
  );
  const updateStateVariable = (variable, value) => {
    let dummyState = { ...state };
    const variableParts = variable.split(".");

    switch (variableParts.length) {
      case 2:
        dummyState[variableParts[0]][variableParts[1]] = value;
        break;
      case 3:
        dummyState[variableParts[0]][variableParts[1]][
          variableParts[2]
        ] = value;
        break;
      default:
        dummyState[variable] = value;
        break;
    }
    setState(dummyState);
  };
  const updateRepo = (index, value) => {
    let repos = [...state.androidConfig.repositories];
    repos[index] = value;
    updateStateVariable("androidConfig.repositories", repos);
  };
  const handleSave = () => {
    if (!state._id) {
      dispatch(createProject(state)).then((data) => {
        setState({ _id: data._id, ...data.details });
        //("Adding");
      });
    } else {
      dispatch(updateProject(state)).then((data) => {
        setState({ _id: data._id, ...data.details });
        //("Editing");
      });
    }
  };
  const handleSaveAndClose = () => {
    handleSave();
    setOpen(false);
  };
  //(state);
  return (
    <AlphaDialog
      open={open}
      setOpen={setOpen}
      onSave={handleSave}
      onSaveAndClose={handleSaveAndClose}
    >
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <TextField
            fullWidth
            label="App Name"
            value={state.appName}
            onChange={(e) => {
              updateStateVariable("appName", e.target.value);
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            fullWidth
            label="Company Name"
            value={state.companyName}
            onChange={(e) => {
              updateStateVariable("companyName", e.target.value);
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            fullWidth
            label="Base Package"
            value={state.basePackage}
            onChange={(e) => {
              updateStateVariable("basePackage", e.target.value);
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            fullWidth
            label="Full Package"
            value={state.fullPackage}
            onChange={(e) => {
              updateStateVariable("fullPackage", e.target.value);
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Project Description"
            value={state.description}
            placeholder="Enter Brief Description of your app"
            multiline
            rows="2"
            onChange={(e) => {
              updateStateVariable("description", e.target.value);
            }}
          />
        </Grid>

        <Grid item xs={12}>
          <AppBar position="static">
            <Tabs
              value={tabIndex}
              onChange={(event, newValue) => {
                setTabIndex(newValue);
              }}
              aria-label="Android Server Configurations"
            >
              <Tab label="Android Configurations" {...a11yProps(0)} />
              <Tab label="Android Libraries" {...a11yProps(1)} />
              <Tab label="Server Configurations" {...a11yProps(2)} />
              <Tab label="repositories" {...a11yProps(3)} />
            </Tabs>
          </AppBar>
          <TabPanel value={tabIndex} index={0}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  label="Gradle Version"
                  value={state.androidConfig.gradleVersion}
                  onChange={(e) => {
                    updateStateVariable(
                      "androidConfig.gradleVersion",
                      e.target.value
                    );
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  label="Target Sdk Version"
                  value={state.androidConfig.targetSdkVersion}
                  onChange={(e) => {
                    updateStateVariable(
                      "androidConfig.targetSdkVersion",
                      e.target.value
                    );
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  label="Min Sdk Version"
                  value={state.androidConfig.minSdkVersion}
                  onChange={(e) => {
                    updateStateVariable(
                      "androidConfig.minSdkVersion",
                      e.target.value
                    );
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  label="Compile Sdk Version"
                  value={state.androidConfig.compileSdkVersion}
                  onChange={(e) => {
                    updateStateVariable(
                      "androidConfig.compileSdkVersion",
                      e.target.value
                    );
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  label="Build Tool Version"
                  value={state.androidConfig.buildToolVersion}
                  onChange={(e) => {
                    updateStateVariable(
                      "androidConfig.buildToolVersion",
                      e.target.value
                    );
                  }}
                />
              </Grid>

              <Grid item xs={6}>
                <TextField
                  fullWidth
                  label="Libraries"
                  value={state.androidConfig.gradleVersion}
                  onChange={(e) => {
                    updateStateVariable(
                      "androidConfig.gradleVersion",
                      e.target.value
                    );
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <AlphaMultiSelect
                  label="Permissions"
                  type="Chip"
                  values={state.androidConfig.permissions}
                  onChange={(e) => {
                    updateStateVariable(
                      "androidConfig.permissions",
                      e.target.value
                    );
                  }}
                  options={androidPermissions}
                />
              </Grid>
            </Grid>
          </TabPanel>
          <TabPanel value={tabIndex} index={1}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  label="Image Loading"
                  value={state.androidConfig.libraries["Image Loading"]}
                  onChange={(e) => {
                    updateStateVariable(
                      "androidConfig.libraries.Image Loading",
                      e.target.value
                    );
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  label="Network"
                  value={state.androidConfig.libraries["Network"]}
                  onChange={(e) => {
                    updateStateVariable(
                      "androidConfig.libraries.Network",
                      e.target.value
                    );
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  label="Database"
                  value={state.androidConfig.libraries["Database"]}
                  onChange={(e) => {
                    updateStateVariable(
                      "androidConfig.libraries.Database",
                      e.target.value
                    );
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  label="JSON"
                  value={state.androidConfig.libraries["JSON"]}
                  onChange={(e) => {
                    updateStateVariable(
                      "androidConfig.libraries.JSON",
                      e.target.value
                    );
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  label="ORM"
                  value={state.androidConfig.libraries["ORM"]}
                  onChange={(e) => {
                    updateStateVariable(
                      "androidConfig.libraries.ORM",
                      e.target.value
                    );
                  }}
                />
              </Grid>
            </Grid>
          </TabPanel>
          <TabPanel value={tabIndex} index={2}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  label="Group Id"
                  value={state.serverConfig.groupId}
                  onChange={(e) => {
                    updateStateVariable("serverConfig.groupId", e.target.value);
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  label="Group Id"
                  value={state.serverConfig.artifactId}
                  onChange={(e) => {
                    updateStateVariable(
                      "serverConfig.artifactId",
                      e.target.value
                    );
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  label="Version"
                  value={state.serverConfig.version}
                  onChange={(e) => {
                    updateStateVariable("serverConfig.version", e.target.value);
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  label="Server App Name"
                  value={state.serverConfig.appName}
                  onChange={(e) => {
                    updateStateVariable("serverConfig.appName", e.target.value);
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  label="Parent Artifact Id"
                  value={state.serverConfig.parentArtifactId}
                  onChange={(e) => {
                    updateStateVariable(
                      "serverConfig.parentArtifactId",
                      e.target.value
                    );
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  label="Parent Version"
                  value={state.serverConfig.parentVersion}
                  onChange={(e) => {
                    updateStateVariable(
                      "serverConfig.parentVersion",
                      e.target.value
                    );
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  label="Parent Group Id"
                  value={state.serverConfig.parentGroupId}
                  onChange={(e) => {
                    updateStateVariable(
                      "serverConfig.parentGroupId",
                      e.target.value
                    );
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  label="Java Version"
                  value={state.serverConfig.javaVersion}
                  onChange={(e) => {
                    updateStateVariable(
                      "serverConfig.javaVersion",
                      e.target.value
                    );
                  }}
                />
              </Grid>
            </Grid>
          </TabPanel>
          <TabPanel value={tabIndex} index={3}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  //   label="New Repo"
                  placeholder="Enter New Repo "
                  value={state.androidConfig.repositories[0]}
                  onChange={(e) => {
                    updateRepo(0, e.target.value);
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <Button
                  onClick={(e) => {
                    let repos = [...state.androidConfig.repositories];
                    repos.splice(0, 0, "");
                    updateStateVariable("androidConfig.repositories", repos);
                  }}
                  variant="contained"
                  color="primary"
                  className={classes.button}
                  startIcon={<AddIcon />}
                >
                  Add Another Repo
                </Button>
              </Grid>
              <List className={classes.repoList}>
                {state.androidConfig.repositories.map((repo, index) => {
                  if (index == 0) return null;
                  else
                    return (
                      <ListItem key={index} style={{ position: "relative" }}>
                        <ListItemIcon>
                          <IconButton
                            variant="contained"
                            color="secondary"
                            edge="end"
                            onClick={(e) => {
                              let repos = [...state.androidConfig.repositories];
                              repos.splice(index, 1);
                              updateStateVariable(
                                "androidConfig.repositories",
                                repos
                              );
                            }}
                          >
                            <DeleteIcon aria-label="delete" />
                          </IconButton>
                        </ListItemIcon>
                        <ListItemText
                          primary={
                            <TextField
                              fullWidth
                              value={state.androidConfig.repositories[index]}
                              onChange={(e) => {
                                updateRepo(index, e.target.value);
                              }}
                            />
                          }
                        />
                      </ListItem>
                    );
                })}
              </List>
            </Grid>
          </TabPanel>
        </Grid>
      </Grid>
    </AlphaDialog>
  );
};

export default ProjectForm;
