export const themes = [{
        name: 'Default',
        path: '/themes/base-theme.css'
    },
    {
        name: 'Default Copy',
        path: '/themes/base-theme.1.css'
    },
    {
        name: 'Calculator',
        path: '/themes/calculator.css'
    },
    {
        name: 'Base',
        path: '/themes/basic.css'
    },
    {
        name: "Fifa",
        path: '/themes/fifa.css'
    }

]