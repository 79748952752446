/** @format */

import { useSelector } from "react-redux";

export default function useTempComponent(id) {
    const layout = document.getElementById("component-" + id);
    const { draggedComponent } = useSelector((state) => state.designer);

    function createNode() {
        if (draggedComponent == "checkbox" || draggedComponent == "radio") {
            let comp = document.createElement("input");
            comp.setAttribute("type", draggedComponent);
            comp.setAttribute("checked", true);
            comp.style.cssText = "opacity: 0.5;width:4rem;height:4rem";
            // comp.className = cssClass("input");
            comp.id = "temp";
            return comp;
        }
        let element = document.createElement(draggedComponent);
        if (draggedComponent == "Label") {
            element.style.cssText =
                "opacity: 0.5;width:auto;height:auto;font-size:50px;";
        } else {
            element.style.cssText = "opacity: 0.5;width:100%;height:50px";
        }
        element.id = "temp";
        const css = cssClass(draggedComponent);
        if (css) {
            element.className = css;
        }
        return element;
    }

    function addTextNode(text) {
        return document.createTextNode(text);
    }

    function cssClass(type) {
        switch (draggedComponent) {
            case "Button":
                return "example_12";
            case "input":
                return "editText-2";
            default:
                return null;
        }
    }

    function textNodeRequired() {
        switch (draggedComponent) {
            case "Button":
                return addTextNode("Button");
            case "Label":
                return addTextNode("Text View");
            default:
                return null;
        }
    }

    function toggle(hover) {
        if (hover) {
            const textNode = textNodeRequired();
            let newNode = createNode();

            if (textNode) {
                newNode.appendChild(textNode);
            }
            if (layout) layout.after(newNode);
        }
        if (!hover) {
            const s = document.getElementById("temp");
            if (s) {
                s.remove();
            }
        }
    }
    return toggle;
}