import React, { Component } from "react";
import screenSizes, {
  screenOrientation
} from "../../../store/constants/screen-info";
import "./CanvasToolbar.css";
import { connect } from "react-redux";
import LoadedSaveButton from "../utils/LoadedSaveButton";
// import Modal from "react-bootstrap/Modal";
import { Button, Popover } from "@material-ui/core";
// import Story from "../StoryBoard/Story";
import { ChromePicker } from "react-color";

import {
  addScreen,
  addDialog
} from "../../../store/actions/action-functions/addScreen";
import { changeBackgoundColor } from "../../../store/actions/action-functions/changeBackgroundColor";
class CanvasToolBar extends Component {
  state = {
    showMapper: false,
    anchorEl: null
  };
  handleClose = () => this.setState({ showMapper: false });
  handleOpen = () => this.setState({ showMapper: true });
  render() {
    const {
      screen,
      onScreenChange,
      onOrientationChange,
      handleIncrementDefaultScale,
      defaultScale,
      components
    } = this.props;
    const { anchorEl } = this.state;
    const open = Boolean(anchorEl);
    const html_id = open ? "mobilePhoneColorChanger" : undefined;
    const { color, storyMode } = this.props.designer;
    return (
      <div
        className="updateShadow"
        hidden={storyMode}
        style={{
          width: "100%",
          height: "50px",
          margin: "20px auto auto ",
          textAlign: "center",
          color: "black",
          backgroundColor: "#FEFEFA",
          border: "1px solid grey",
          transform: "scale(0.85)"
        }}
      >
        <button
          className="btn btn-sm float-left mt-2 ml-1"
          style={{ backgroundColor: color, color: "white" }}
          onClick={() => addScreen()}
        >
          <span style={{ fontSize: "13px" }}>Add Activiy</span>
          <i style={{ fontSize: "20px" }} className="fa fa-mobile ml-2"></i>
        </button>
        {components[2].dialog && (
          <button
            className="btn btn-sm float-left mt-2 ml-1"
            style={{ backgroundColor: color, color: "white" }}
            onClick={() => addDialog()}
          >
            <span style={{ fontSize: "13px" }}> Add Dialoge</span>
            {/* <i style={{ fontSize: "20px" }} className="fa fa-mobile ml-2 "></i> */}
          </button>
        )}
        <React.Fragment>
          <i
            title="Mobile Color"
            style={{ cursor: "pointer" }}
            className="fa fa-paint-brush mr-2"
            id={html_id}
            onClick={this.hanldeClick}
          ></i>
          <Popover
            id={html_id}
            open={open}
            onClose={() => this.handleClosePopUp()}
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: "top",
              horizontal: "left"
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "left"
            }}
          >
            <ChromePicker
              color={this.props.designer.color + ""}
              onChange={color => changeBackgoundColor(color.hex)}
            ></ChromePicker>
          </Popover>
        </React.Fragment>

        <span className="m-2">Screen:</span>
        <select
          name="screenSizez"
          value={screen}
          className="mt-2"
          onChange={onScreenChange}
          style={{
            height: "30px",
            width: "220px",
            outline: "none"
          }}
        >
          {screenSizes.map((screen, index) => (
            <option key={index} value={screen.value}>
              {screen.label}
            </option>
          ))}
        </select>

        <span className="orientation  ml-2 mr-2" onClick={onOrientationChange}>
          {/* {this.orientationIcon()} */}
        </span>
        <span>
          <i
            className="fas fa-minus-circle"
            style={{ color: color, cursor: "pointer" }}
            onClick={e => {
              this.props.handleDecrementDefaultScale();
            }}
          ></i>
          <span>{" " + this.gettingPercentage(defaultScale) + "% "}</span>
          <i
            className="fas fa-plus-circle "
            style={{ color: this.props.designer.color, cursor: "pointer" }}
            onClick={handleIncrementDefaultScale}
          ></i>
        </span>
        <span>
          <LoadedSaveButton />
        </span>
      </div>
    );
  }

  handleClosePopUp() {
    this.setState({ anchorEl: null });
  }
  hanldeClick = e => {
    this.setState({ anchorEl: e.currentTarget });
  };
  gettingPercentage = defaultScale => String(defaultScale * 100).split(".")[0];

  orientationIcon = () => {
    let iconClass = "fa fa-mobile fa-2x ";
    iconClass +=
      this.props.orientation === screenOrientation.Vertical
        ? ""
        : "fa-rotate-90";
    return (
      <i
        className={iconClass}
        style={{ fontSize: "30px", color: this.props.designer.color }}
      />
    );
  };
}
const mapStateToProps = state => {
  return state;
};

export default connect(mapStateToProps)(CanvasToolBar);
