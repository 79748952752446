/** @format */

import React, { useState } from "react";
import Toolbar from "./ActivityDesigner/Toolbar";
import DesignerToolbar from "./ActivityDesigner/DesignerToolbar";
import Navigation from "./ActivityDesigner/Navigation";
import ComponentViewerSelector from "./ComponentViewer/ComponentViewerSelector";
import Dialoge from "./ActivityDesigner/Dialoge";
import { useSelector } from "react-redux";

const ActivityDesigner = ({ storyScreen, disableDrag }) => {
  const [state, setState] = useState({
    openNavigation: false,
  });
  const {
    components,
    selectedScreenID,
    designerHeight,
    dialogID,
    handleToggleNavigation,
  } = useSelector((state) => state.designer);

  let activity = {};
  let activityLayout = null;
  let toolbar = null;
  let navigation = null;
  let dialoges = null;
  /******
   * *Agr Selected Screen Id ko mein null kr doon jab Dialogue Ho
   * ! Yahan Mein Check Laga Doon Ky Kiss ky components show krny
   * ! Navigation and Toolbar ko bhi hide kr doon
   * !
   */

  if (selectedScreenID) {
    if (!storyScreen) activity = components[selectedScreenID];
    else activity = components[storyScreen];
    activityLayout = components[activity.children[0]];
    toolbar = components[components[2].toolbar];
    navigation = components[components[2].navigation];
  } else {
    dialoges = components[dialogID];
  }
  const toolbarHeight = "150";

  return (
    <>
      {selectedScreenID ? (
        <>
          {activity.isNavigatable && (
            <DesignerToolbar
              openNavigation={state.openNavigation}
              toggleNavigation={handleToggleNavigation}
              toolbarHeight={toolbarHeight}
            />
          )}

          <div className="inherit-dimensions">
            {activity.isNavigatable && (
              <Navigation
                openNavigation={state.openNavigation}
                component={navigation}
                components={components}
                designerHeight={designerHeight}
                toolbarHeight={toolbarHeight}
              />
            )}
            {activity.hasToolBar && (
              <Toolbar
                component={toolbar}
                components={components}
                toolbarHeight={toolbarHeight}
              />
            )}

            <ComponentViewerSelector
              component={activityLayout}
              disableDrag={disableDrag}
              components={components}
            />
          </div>
        </>
      ) : (
        <div className="inherit-dimensions">
          <Dialoge
            component={dialoges}
            components={components}
            toolbarHeight={toolbarHeight}
          />
        </div>
      )}
    </>
  );
};

export default ActivityDesigner;
