import React from "react";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Input from "@material-ui/core/Input";
import { withStyles, InputAdornment } from "@material-ui/core";
import checking from "./measuring";
const styles = theme => ({
  fullWidth: {
    width: "100%"
  },
  margin: {
    margin: theme.spacing.unit
  }
});

const InputTagRenderer = props => {
  const {
    name,
    label,
    classes,
    component,
    icon,
    error,
    index,
    ...rest
  } = props;
  return (
    <FormControl className={classes.formControl}>
      {label && (
        <InputLabel shrink htmlFor={name}>
          {label}
        </InputLabel>
      )}
      <Input
        style={{ autoWidth: true }}
        className={classes.Input}
        name={name}
        value={
          component &&
          (name === "name" ? component.name : checking(name, component))
        }
        startAdornment={
          icon && (
            <InputAdornment position="start">
              <i className="material-icons">{icon}</i>
            </InputAdornment>
          )
        }
        {...rest}
      />
    </FormControl>
  );
};

export default withStyles(styles)(InputTagRenderer);
