/** @format */

import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    color: theme.palette.grey[800],
    transitionDuration: "1s",
    cursor: "pointer",
    fontSize: "1rem",
    width: "240px",

    // padding: 0,
  },
  icon: {
    float: "right",
    marginTop: "6px",
    marginRight: "10px",
  },
  backgroundWrapper: {
    padding: "0.1rem 0 0 0.6rem",
    background: "#ECECEC",
  },
  closed: {
    marginBottom: "0.2rem",
  },
  open: {
    marginBottom: "0.6rem",
  },
  leftMargin: {
    display: "inline-block",
    marginLeft: "7px",
  },
}));
const MenuDropDown = ({ title, isOpen, wrap, leftMargin, children }) => {
  const [open, setOpen] = useState(!isOpen);
  const classes = useStyles();

  return (
    <>
      <p
        className={`${classes.root} ${wrap ? classes.backgroundWrapper : ""} ${
          open ? classes.open : classes.closed
        }`}
        onClick={() => setOpen(!open)}
      >
        {title}
        <i className={`${classes.icon} ${getIcon()}`}></i>
      </p>
      {open && (
        <span className={leftMargin ? classes.leftMargin : ""}>
          {children && children}{" "}
        </span>
      )}
    </>
  );
  function getIcon() {
    let className = "fas fa-chevron-";
    if (open) {
      return (className += "down");
    }
    return (className += "right");
  }
};

export default MenuDropDown;
