export const API_LOAD_START = "API_LOAD_START";
export const API_LOAD_END = "API_LOAD_END";
export const API_LOAD_JSON = "API_LOAD_JSON";
export const API_LOAD_COMPLETED = "API_LOAD_COMPLETED";
export const API_SAVE_APP_JSON = "API_SAVE_APP_JSON";

export const API_REQUEST_APPS_PAGE = "API_REQUEST_APPS_PAGE";
export const API_REQUEST_APPS_PAGE_RECEIVED = "API_REQUEST_APPS_PAGE_RECEIVED";
export const API_REQUEST_APP_COMPONENTS = "API_REQUEST_APP_COMPONENTS";
export const API_REQUEST_APP_COMPONENTS_RECEIVED =
  "API_REQUEST_APP_COMPONENTS_RECEIVED";
export const API_REQUEST_APP_BLL = "API_REQUEST_APP_BLL";
export const API_REQUEST_APP_BLL_RECEIVED = "API_REQUEST_APP_BLL_RECEIVED";
export const API_REQUEST_APP = "API_REQUEST_APP";
export const API_REQUEST_APP_RECEIVED = "API_REQUEST_APP_RECEIVED";
export const API_DELETE_APP = "API_DELETE_APP";
export const API_APP_CREATED = "API_APP_CREATED";
