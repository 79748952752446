import React from "react";
import AssetFileListItem from "./AssetFileListItem";
const AssetFileList = ({
  files,
  isRoot,
  onFileDelete,
  onChange,
  appId,
  parent_id
}) => {
  let filesToShow = files.filter(f => f.parent_id == parent_id);
  return (
    <div style={{ paddingLeft: "10px" }}>
      {filesToShow.map((file, index) => (
        <AssetFileListItem
          key={index}
          file={file}
          onFileDelete={onFileDelete}
          files={files}
          onChange={onChange}
          appId={appId}
        />
      ))}
    </div>
  );
};

export default AssetFileList;
