import React, { Component } from "react";
import { DropTarget, DragSource } from "react-dnd";
import ComponentViewerSelector from "./ComponentViewerSelector";
import {
  dropSpecs,
  dropCollect,
  dragSpec,
  dragCollect
} from "./utils/dndForHorizentalLayouts";
import flow from "lodash/flow";
import LayoutProperties from "./LayoutProperties/LayoutProperties";
import MaterialPopOver from "./Popover/MaterialPopOver";
import { connect } from "react-redux";
import { highLightKey } from "../../../../store/actions/action-functions/addBindedId";
import splittingAndMakingObject from "./utils/splitingKeys";
class ComponentViewerSection extends Component {
  state = {
    editable: false,
    anchorEl: null
  };
  render() {
    const {
      id,
      components,
      connectDropTarget,
      hover,
      clickHandler,
      selectedPropertiesScreenID,
      disableDrag,
      heightLightComponent
    } = this.props;
    const component = components[id];
    //("Default Layout Parent id");
    const screens = components[2].children;
    //(screens);
    //(id);
    const defaultLayout = screens.find(screen => screen == component.parent_id);
    // //
    //(components);
    //(defaultLayout);
    //("Component id:", component.id);
    const styles = {
      width: "100%",
      height: defaultLayout
        ? "3000px"
        : component.children.length > 0
        ? "auto"
        : "40px",
      ...component.properties.styles,
      border:
        selectedPropertiesScreenID === id
          ? "2px solid " + this.getRandomColor()
          : hover
          ? "2px solid " + this.getRandomColor()
          : "",
      padding: hover ? "2px" : component.properties.styles["padding"],
      transitionDuration: "1s",
      ...component.properties.styles,
      backgroundColor: hover
        ? "#FEF8DD"
        : component.properties.styles["backgroundColor"]
    };
    return connectDropTarget(
      <div
        style={{ ...styles }}
        onClick={e => {
          e.stopPropagation();
          if (!disableDrag) clickHandler(id);
          if (disableDrag)
            highLightKey(splittingAndMakingObject(component.node_id));
        }}
        onDoubleClick={e => {
          e.stopPropagation();
          this.setState({ anchorEl: e.currentTarget });
        }}
      >
        {component.children &&
          component.children.map((child, index) => (
            <span>
              <ComponentViewerSelector
                key={index}
                components={components}
                component={components[child]}
                id={child}
                index={index}
                heightLightComponent={this.props.designer.heightLightComponent}
                disableDrag={disableDrag}
              />
            </span>
          ))}

        {!disableDrag && (
          <MaterialPopOver
            handleClose={this.handleClose}
            anchorEl={this.state.anchorEl}
            component={component}
          >
            <LayoutProperties component={component} />
          </MaterialPopOver>
        )}
      </div>
    );
  }
  getRandomColor() {
    var letters = "3456789ABCDEF";
    var color = "#";
    for (var i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 12)];
    }
    return color;
  }

  handleClose = e => {
    this.setState({ anchorEl: null });
  };
  hanldeDoubleClick = e => {
    e.stopPropagation();
    this.setState({ anchorEl: e.currentTarget });
  };
}
const mapStateToProps = state => state;

export default flow(
  DropTarget("modules", dropSpecs, (connect, monitor) =>
    dropCollect(connect, monitor)
  ),
  DragSource("modules", dragSpec, (connect, monitor) =>
    dragCollect(connect, monitor)
  )
)(connect(mapStateToProps)(ComponentViewerSection));
