//import {COMPONENT_ACTIVITY, COMPONENT_FRAGMENT, COMPONENT_VIEWPAGER, COMPONENT_EDITTEXT, COMPONENT_DROPDOWN, COMPONENT_RADIOGROUP, COMPONENT_CHECKBOX, COMPONENT_RADIOBUTTON, COMPONENT_IMAGEVIEW, COMPONENT_IMAGEBUTTON, COMPONENT_BUTTON} from "../models/typeConstants";
import * as typeConstants from "../store/constants/typeConstants";
const components = [];
const defaultConfigurations = {
    canHasChildren: false,
    styles: {},
    network: {}
}
components[typeConstants.COMPONENT_BASE] = {
    name: "Base",
    display_name: "Root",
    properties: {
    },
    settings: Array.from(defaultConfigurations)
};
components[typeConstants.COMPONENT_INIT] = {
    name: "App",
    display_name: "Init",
    properties: {
    },
    settings: Array.from(defaultConfigurations)
};
components[typeConstants.COMPONENT_ACTIVITY] = {
    name: "Activity",
    display_name: "Screen",
    settings: Array.from(defaultConfigurations),
    properties: {
        width: "100%",
        color: '',
        backgroundColor: ''
    }
};

components[typeConstants.COMPONENT_FRAGMENT] = {
    name: "Fragment",
    display_name: "Section",
    properties: {

    },
    settings: Array.from(defaultConfigurations)
};
components[typeConstants.COMPONENT_VIEWPAGER] = {
    name: "ViewPager",
    display_name: "Swipeable Section",
    properties: {

    },
    settings: Array.from(defaultConfigurations)
};
components[typeConstants.COMPONENT_EDITTEXT] = {
    name: "EditText",
    display_name: "Text Field",
    properties: {

    },
    settings: Array.from(defaultConfigurations)
};
components[typeConstants.COMPONENT_DROPDOWN] = {
    name: "DropDown",
    display_name: "Drop Down List",
    properties: {

    },
    settings: Array.from(defaultConfigurations)
};
components[typeConstants.COMPONENT_RADIOGROUP] = {
    name: "RadioGroup",
    display_name: "Radio Group",
    properties: {

    },
    settings: Array.from(defaultConfigurations)
};
components[typeConstants.COMPONENT_CHECKBOX] = {
    name: "CheckBox",
    display_name: "Check Box",
    properties: {

    },
    settings: Array.from(defaultConfigurations)
};
components[typeConstants.COMPONENT_RADIOBUTTON] = {
    name: "RadioButton",
    display_name: "Radio Button",
    properties: {

    },
    settings: Array.from(defaultConfigurations)
};
components[typeConstants.COMPONENT_IMAGEVIEW] = {
    name: "ImageView",
    display_name: "Image",
    properties: {

    },
    settings: Array.from(defaultConfigurations)
};
components[typeConstants.COMPONENT_IMAGEBUTTON] = {
    name: "ImageButton",
    display_name: "Image Button",
    properties: {

    },
    settings: Array.from(defaultConfigurations)
};
components[typeConstants.COMPONENT_BUTTON] = {
    name: "Button",
    display_name: "Button",
    properties: {

    },
    settings: Array.from(defaultConfigurations)
};
components[typeConstants.COMPONENT_TEXTVIEW] = {
    name: "TextView",
    display_name: "Label",
    properties: {

    },
    settings: Array.from(defaultConfigurations)
};
components[typeConstants.COMPONENT_LINEARLAYOUT_HORIZONTAL] = {
    name: "LinearLayout_Horizontal",
    display_name: "Horizontal Layout",
    properties: {

    },
    settings: Array.from(defaultConfigurations)
};
components[typeConstants.COMPONENT_LINEARLAYOUT_VERTICAL] = {
    name: "LinearLayout_Vertical",
    display_name: "Vertical Layout",
    properties: {

    },
    settings: Array.from(defaultConfigurations)
};
components[typeConstants.COMPONENT_RECYCLERVIEW_GRID] = {
    name: "RecyclerView_Grid",
    display_name: "Customizable Grid Layout",
    properties: {

    },
    settings: Array.from(defaultConfigurations)
};
components[typeConstants.COMPONENT_RECYCLERVIEW_LIST] = {
    name: "RecyclerView_List",
    display_name: "Customizable List Layout",
    properties: {

    },
    settings: Array.from(defaultConfigurations)
};
components[typeConstants.COMPONENT_LISTVIEW] = {
    name: "ListView",
    display_name: "Classic Grid Layout",
    properties: {

    },
    settings: Array.from(defaultConfigurations)
};
components[typeConstants.COMPONENT_GRIDVIEW] = {
    name: "GridView",
    display_name: "Classic List Layout",
    properties: {

    },
    settings: Array.from(defaultConfigurations)
};
components[typeConstants.COMPONENT_VIEW] = {
    name: "View",
    display_name: "Divider",
    properties: {

    },
    settings: Array.from(defaultConfigurations)
};
components[typeConstants.COMPONENT_RELATIVELAYOUT] = {
    name: "RelativeLayout",
    display_name: "Relative Layout",
    properties: {

    },
    settings: Array.from(defaultConfigurations)
};
components[typeConstants.COMPONENT_DRAWABLE] = {
    name: "Drawable",
    display_name: "Shapes",
    properties: {

    },
    settings: Array.from(defaultConfigurations)
};



export default components;
