import React, { Component } from "react";

import { withRouter } from "react-router";

import { connect } from "react-redux";
import BLLReduxHandler from "../../../store/actions/BLLReduxHandler";
// import BllRightMenu from "./right-menu/BllRightMenu";

// import BllEditorSingleCanvas from "./BLLEditorSingleCanvas";
// import TrayWidget from "./right-menu/TrayWidget";
// import TrayWrapper from "./right-menu/TrayWrapper";
// import BLLPropertiesWidget from "./BLLPropertiesWidget";
class BLLBodyWidget extends Component {
  state = { selectedNode: null, app: null, count: 0 };
  //bllApplication = new BllApplication();

  render() {
    let appId = this.props.match.params.id;
    //("Our App");
    //(appId);
    if (!this.props.bll.apps[appId]) {
      BLLReduxHandler.loadApp(appId);
      return null;
    }
    let selectedFunc = this.props.bll.apps[appId].bllFunctions[
      this.props.bll.apps[appId].activeFunction
    ];
    return (
      <div className="row">
        <div className="col-sm-2">{/* <TrayWidget app={appId} /> */}</div>
        <div className="col-sm-8">
          {selectedFunc ? (
            <div>
              {/* <BllEditorSingleCanvas
                appId={appId}
                func={selectedFunc}
                onNodeSelectionChanged={(entity) => {
                  //("Selected Node Changed: " + entity.options.name);
                  this.setState({ selectedNode: entity });
                  this.forceUpdate();
                }}
              /> */}
            </div>
          ) : (
            <p>No Function is loaded, Double Click on Any function to load</p>
          )}
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return state;
};
export default connect(mapStateToProps)(withRouter(BLLBodyWidget));
