import React from "react";
import { makeStyles } from "@material-ui/styles";
import { TextField, Button } from "@material-ui/core";
import { useDispatch } from "react-redux";
import authService from "./services/authService";
import { loginWithToken } from "./store/actions/login.actions";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "3rem",
    display: "flex",
    flexDirection: "column",
    width: "100%",
  },
  textField: { paddingBottom: theme.spacing(3) },
}));
const AlphaRegister = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [state, setState] = React.useState({
    name: "",
    email: "",
    password: "",
  });
  const [errors, setErrors] = React.useState({});
  const handleSubmit = () => {
    authService
      .register(state)
      .then((data) => {
        // //(data);
        dispatch(loginWithToken(data.access_token));
      })
      .catch((err) => {
        // //(err.response.data);
        setErrors(err.response.data);
      });
  };
  return (
    <div className={classes.root}>
      <TextField
        value={state.name}
        label="Display Name"
        onChange={(e) => {
          setState({ ...state, name: e.target.value });
        }}
        fullWidth
        error={errors.name}
        helperText={errors.name}
        className={classes.textField}
      />
      <TextField
        value={state.email}
        label="Email"
        fullWidth
        type="email"
        error={errors.email}
        helperText={errors.email}
        onChange={(e) => {
          setState({ ...state, email: e.target.value });
        }}
        className={classes.textField}
      />
      <TextField
        value={state.password}
        label="Password"
        fullWidth
        type="password"
        error={errors.password}
        helperText={errors.password}
        onChange={(e) => {
          setState({ ...state, password: e.target.value });
        }}
        className={classes.textField}
      />
      <Button color="primary" onClick={handleSubmit} variant="contained">
        Register{" "}
      </Button>
    </div>
  );
};

export default AlphaRegister;
