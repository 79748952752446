import {
    COMPONENT_HORIZONTAL_LISTVIEW
} from "../constants/typeConstants";
import common from "./common";

/**
 * factory to produce listview
 * @param {*} name
 * @param {*} layout of the listview
 */
export default function (name = "Horizontal_ListView", layout = {}) {
  let obj = {
    name: name,
    type: COMPONENT_HORIZONTAL_LISTVIEW,
    children: [],
  };
  obj = Object.assign(obj, common);
  delete obj.properties;
  obj.properties = {
    properties: {},
  };
  const defaultStyles = {
    minWidth: "match_parent",
    height: "match_parent",
    display: "flex",
    flexWrap: "nowrap",
  };
  obj.properties.styles = defaultStyles;
  return obj;
}
