/** @format */

import React from "react";
import MaterialSlider from "./MaterialSlider";
import InputTagRenderer from "../utils/inputRenderer";
import { styleChanged } from "./styleActions";
import { separator } from "./pxSeparator";
import { handlingChange } from "./changeHandler";
import DropDown from "../ComponentDesigner/ComponentViewer/GenericPropertiesGenerator/DropDrown";
import CompleteTable from "./utils/TableBodyGenerator";
import ColorProperties from "./ColorProperties";
import { isActivity } from "../ComponentDesigner/ComponentViewer/utils/labelAllowed";

const borderStyles = ["dotted", "dashed", "double", "solid"];

const BorderProperties = ({ component }) => {
  function giveMeSlider(value) {
    return <MaterialSlider name={value} component={component}></MaterialSlider>;
  }
  const tableData = [
    {
      col_1: "Color",
      col_2: giveMeColorPicker("borderColor"),
    },
  ];
  function giveMeColorPicker(name) {
    let color = component.properties.styles[name];
    if (!color) color = "";
    return (
      <div className="row">
        <div className="col-4 text-center">
          <ColorProperties component={component} name={name} />
        </div>
        <div className="col-8 text-center">
          <InputTagRenderer
            name={name}
            component={component}
            value={color}
            onChange={(e) => handlingChange(e, component)}
          />
        </div>
      </div>
    );
  }
  function dataGeneratorForBorders(side) {
    const makingSide = "border" + side;
    const label = "";
    const tableData = [
      {
        col_1: label + " Width",
        // col_2: giveMeSlider(makingSide + "Width"),
      },
      {
        col_1: label + " Style",
        // col_2: giveMeSelect(makingSide + "Style", component, borderStyles),
      },
      {
        col_1: label + " Radius",
        // col_2: giveMeSlider(makingSide + "Radius"),
      },
      {
        col_1: label + " Color",
        // col_2: giveMeColorPicker(makingSide + "Color"),
      },
    ];
    return tableData;
  }
  if (isActivity(component.type)) return null;
  return (
    <>
      <CompleteTable tableData={tableData} />
      {/* <DropDown label={"Left Border Props"}>
        <CompleteTable tableData={dataGeneratorForBorders("Left")} />
      </DropDown>
      <DropDown label={"Right Border Props"}>
        <CompleteTable tableData={dataGeneratorForBorders("Right")} />
      </DropDown>
      <DropDown label={"Top Border Props"}>
        <CompleteTable tableData={dataGeneratorForBorders("Top")} />
      </DropDown>
      <DropDown label={"Bottom Border Props"}>
        <CompleteTable tableData={dataGeneratorForBorders("Bottom")} />
      </DropDown> */}
    </>
  );
};

export function giveMeSelect(name, component, dataArray) {
  let value = component.properties.styles[name];
  if (!value) value = "";
  return (
    <select
      onChange={(e) => handlingChange(e, component)}
      value={value}
      name={name}
      className="form-control"
    >
      <option value=""></option>
      {dataArray.map((data, index) => (
        <option key={index} value={data}>
          {data}
        </option>
      ))}
    </select>
  );
}

export function giveMeInput(name, component) {
  let value = separator(component.properties.styles[name]);
  if (!value) value = "";
  return (
    <InputTagRenderer
      value={value}
      name={name}
      type="number"
      onChange={(e) => handlingChange(e, component)}
    />
  );
}
export function handlingSelect(number) {
  window.store.dispatch(
    styleChanged(number + "px", [this.props.name], this.props.component)
  );
}
export default BorderProperties;

// [
//     {col_1:"first",col_2:"<My Component Here>"},
// ]

//borderWidth
//borderSytle ===select ==> dotted ,dashed ,solid, double
//borderColor colorChooser
//borderRadius  inputfiled
