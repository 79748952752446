/** @format */

import React from "react";
import { Switch, Route } from "react-router-dom";
import LandingPage from "./pages/LandingPage";
import Projects from "./../Projects/Projects";
import ComingSoon from "./pages/ComingSoon";
import AlphaRegister from "./../auth/AlphaRegister";
import AppDesignerLoader from "./../components/AppDesigner/AppDesignerLoader";
import DesignerAssets from "../components/AppDesigner/SideBar/DesignerAssets";
import DesignerJSON from "./../components/AppDesigner/DesignerJSON/DesignerJSON";
import SchemaDesigner from "./../components/SchemaDesigner/SchemaDesigner";
import AppList from "./../components/Site/ClientArea/AppList";
import BLLBodyWidget from "./../components/BLL/widgets/BLLBodyWidget";

import Designer from "../components/AppDesigner/Designer";

const AlphaRoutes = () => {
  return (
    <Switch>
      <Route path="/projects" exact component={Projects} />
      <Route path="/register" exact component={AlphaRegister} />
      <Route path="/designer/:id" exact component={AppDesignerLoader} />
      <Route path="/assets/:id" exact component={DesignerAssets} />
      <Route path="/json" exact component={DesignerJSON} />
      <Route path="/schema-designer" exact component={SchemaDesigner} />
      <Route path="/client-area/:current_page?" exact component={AppList} />
      <Route path="/designer" exact component={Designer} />
      <Route path="/bll/:id" exact children={<BLLBodyWidget />} />
      <Route path="/" exact component={Projects} />
      <Route path="/" exact component={LandingPage} />
    </Switch>
  );
};

export default AlphaRoutes;
