/** @format */

import React, { Component } from "react";
import baseFactory from "../../../store/factories/baseFactory";
import axios from "axios";
import "./Applist.css";
import "bootstrap/dist/css/bootstrap.css";
import "font-awesome/css/font-awesome.min.css";
import { APPS_URL } from "./../../../store/constants/apiURLS";
import newAppComponents from "../../../store/data/newAppComponents";
import { NavLink } from "react-router-dom";

import { toast } from "react-toastify";
import { connect } from "react-redux";
import APIHandler from "./../../../store/actions/APIHandler/APIHandler";
import AppListPaginator from "./AppListPaginator";
class AppList extends Component {
  state = {
    apps: [],
    newAppName: "",
  };
  render() {
    let isLoading = false;
    let current_page = this.props.match.params.current_page;
    current_page = current_page ? current_page : 1;
    let page = this.props.api.pagination.apps.pages[current_page];
    // //("hiiii",page);

    if (!page) {
      APIHandler.requestAppsPage(current_page);
      return null;
    }
    const apiUrl = "https://dummy-alphadnd-server.herokuapp.com/api/apps";
    // const apiUrl = "http://localhost:5000/api/";
    return (
      <div className="main-div-ca">
        <div className="app-heading">
          <h3>Your Apps in DB</h3>
        </div>
        <div className="row heading-row">
          <div className="col-md-2 app-name-tag">
            <h5>App Name:</h5>
          </div>
          <div className="col-md-3">
            <input
              type="text"
              className="form-control"
              id="app-name"
              placeholder="enter name here"
              value={this.state.newAppName}
              onChange={(e) => this.setState({ newAppName: e.target.value })}
            />
          </div>

          <div className="col-md-7">
            <button
              className="btn btn-primary app-btn"
              onClick={(e) => {
                e.preventDefault();
                APIHandler.createApp(this.state.newAppName);
              }}
            >
              <i className="fa fa-plus" /> Create New app
            </button>
          </div>
        </div>

        <div className="container">
          {page.fetching === true ? (
            <h3>Loading ....</h3>
          ) : (
            <table className="table table-bordered">
              <thead>
                <tr>
                  <th>App Name</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {page.ids.map((app_id, index) => {
                  let app = this.props.api.apps[app_id];
                  localStorage.setItem("appId", app._id);
                  return (
                    <tr key={index} className="table-row">
                      <td>{app.name}</td>
                      <td>
                        <NavLink
                          to={"/designer/" + app._id}
                          className="btn btn-default btn-xs load-btn"
                        >
                          Load
                        </NavLink>
                        &nbsp;
                        <a
                          href={apiUrl + "/json/" + app._id}
                          target="_blank"
                          className="btn btn-default btn-xs json-btn"
                          rel="noopener noreferrer"
                        >
                          JSON
                        </a>
                        &nbsp;
                        <a
                          href={apiUrl + "/components/" + app._id}
                          target="_blank"
                          className="btn btn-default btn-xs json-btn"
                          rel="noopener noreferrer"
                        >
                          Components
                        </a>
                        &nbsp;
                        <NavLink
                          to={"/bll/" + app._id}
                          className="btn btn-default btn-xs json-btn"
                          rel="noopener noreferrer"
                        >
                          Load Bll
                        </NavLink>{" "}
                        &nbsp;
                        <a
                          href={
                            process.env.REACT_APP_API_URL + "bll/" + app._id
                          }
                          target="_blank"
                          className="btn btn-default btn-xs json-btn"
                        >
                          BLL JSON
                        </a>
                        &nbsp;
                        {/* <NavLink
                          to={"/upload-json/" + app._id}
                          className="btn btn-default btn-xs json-btn"
                        >
                          Update from JSON
                        </NavLink> */}
                        &nbsp;
                        <button
                          className="btn btn-danger btn-xs delete-btn"
                          onClick={() => {
                            APIHandler.deleteApp(app._id);
                          }}
                        >
                          Delete
                        </button>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          )}
          {!page.fetching && <AppListPaginator page={page} />}
        </div>
      </div>
    );
  }
  handleDeleteApp = (_id) => {
    let self = this;
    const url = APPS_URL + _id;
    //alert("deleting " + _id);
    //axios.defaults.headers.delete["Access-Control-Allow-Origin"] = "*";
    toast.error("app is deleted");
    axios
      .delete(url)
      .then(function (response) {
        self.loadAppsFromAPI();
      })
      .catch(function (error) {
        //(APPS_URL + _id);
        //(error);
      });
  };
  componentDidMount() {
    //this.loadAppsFromAPI();
  }
  loadAppsFromAPI = () => {
    fetch(APPS_URL)
      .then((res) => res.json())
      .then((data) => this.setState({ apps: data }))
      .catch(function (error) {
        //(error);
      });
  };
  handleCreateNewApp = () => {
    const dataToSubmit = {
      name: this.state.newAppName,
      appJSON: baseFactory(this.state.newAppName),
      components: newAppComponents,
    };
    let self = this;

    if (this.state.newAppName.length > 0) {
      toast.success("app is built. select it from list");
      axios
        .post(APPS_URL, dataToSubmit)
        .then(function (response) {
          //(response);
          self.loadAppsFromAPI();
        })
        .catch(function (error) {
          //(error);
        });
    }
  };
}
const mapStateToProps = (state) => {
  return state;
};
export default connect(mapStateToProps)(AppList);
