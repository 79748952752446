import React, { Component } from "react";
import InputTagRenderer from "./../utils/inputRenderer";
import SelectTagRenderer from "./../utils/selectRenderer";
import {
  changeComponentAttribute,
  fetchResponse
} from "../../../store/actions/designerActions";
import { connect } from "react-redux";
import { heightLightComponent } from "../../../store/actions/action-functions/addBindedId";
import ResponseMapper from "./NetworkUtlis/ResponseMapper";
import BindingsMapper from "./NetworkUtlis/BindingsMapper";
import InputJSON from "./NetworkUtlis/ModalForInputJson";
class NetworkProperties extends Component {
  state = {
    showMapper: false,
    getInput: false
  };
  handleClose = () => this.setState({ showMapper: false });
  handleOpen = () => this.setState({ showMapper: true });
  handleGetInputOpen = () => this.setState({ getInput: true });
  handleGetInputClose = () => this.setState({ getInput: false });
  render() {
    const { components, selectedPropertiesScreenID } = this.props.designer;
    const component = components[selectedPropertiesScreenID];
    const {
      url,
      completePath,
      requestPort,
      requestPath,
      requestType,
      responseJSON,
      requestJson
    } = component;
    window.responseJSON = responseJSON;
    return (
      <React.Fragment>
        <div>
          <table className="table table-collapsed " style={{ width: "100%" }}>
            <tbody>
              <tr>
                <td> URL </td>
                <td>
                  <InputTagRenderer
                    type="text"
                    value={completePath || ""}
                    onChange={e => {
                      this.handleBaseUrl(e, completePath);
                    }}
                    // value={component}
                  />
                </td>
              </tr>
              <tr>
                <td> PORT </td>
                <td>
                  <InputTagRenderer
                    type="text"
                    value={requestPort || ""}
                    onChange={e =>
                      changeComponentAttribute(
                        component.id,
                        "requestPort",
                        e.target.value
                      )
                    }
                  />
                </td>
              </tr>
              <tr>
                <td> PATH </td>
                <td>
                  <InputTagRenderer
                    type="text"
                    value={requestPath}
                    // onChange={e =>
                    //   changeComponentAttribute(
                    //     component.id,
                    //     "requestPath",
                    //     this.handleRequestPath(url)
                    //   )
                    // }
                  />
                </td>
              </tr>
              <tr>
                <td> Clear Bindings </td>
                <td>
                  <button
                    className="btn btn-sm btn-danger"
                    onClick={() => {
                      changeComponentAttribute(
                        component.id,
                        "responseJson",
                        JSON.stringify({
                          keys: {},
                          isArray: true
                        })
                      );
                      changeComponentAttribute(
                        component.id,
                        "isScopeNetwork",
                        false
                      );
                      changeComponentAttribute(component.id, "url", null);
                    }}
                  >
                    Clear All
                  </button>
                </td>
              </tr>
              <tr>
                <td> Method </td>
                <td>
                  <SelectTagRenderer
                    value={requestType}
                    component={component}
                    options={["GET", "POST"]}
                  />
                  {requestType === "POST" ? (
                    <i
                      title={"Add JSON"}
                      className="fa fa-plus-circle  "
                      style={{
                        cursor: "pointer",
                        color: "yellowgreen",
                        fontSize: "24px"
                      }}
                      onClick={this.handleGetInputOpen}
                    ></i>
                  ) : (
                    ""
                  )}
                </td>
              </tr>
              <tr>
                <td colSpan={2}>
                  <div className="text-center">
                    <button
                      className="btn btn-info btn-sm"
                      onClick={e => fetchResponse(component.id)}
                    >
                      Fetch Response
                    </button>
                  </div>
                </td>
              </tr>
              <tr>
                <ResponseMapper
                  responseJSON={responseJSON}
                  component={component}
                  handleShowMapper={this.handleShowMapper}
                />
              </tr>
            </tbody>
          </table>
          <BindingsMapper
            responseJSON={responseJSON}
            show={this.state.showMapper}
            handleClose={this.handleClose}
            selectedPropertiesScreenID={selectedPropertiesScreenID}
            designer={this.props.designer}
          ></BindingsMapper>
          <InputJSON
            show={this.state.getInput}
            handleClose={this.handleGetInputClose}
            component={component}
            requestJson={requestJson}
          />
        </div>
      </React.Fragment>
    );
  }

  // handleBaseUrl = url => {
  //   // const checkingForThePath
  // };

  handleBaseUrl = (e, url) => {
    const {
      components,
      selectedPropertiesScreenID,
      completePath
    } = this.props.designer;
    const component = components[selectedPropertiesScreenID];
    const value = e.target.value;
    changeComponentAttribute(component.id, "completePath", value);
    if (!value) return null;
    const regex = /(http|https)(:\/\/)[a-z\d+.|-]+/;
    const baseUrl = value.match(regex);
    if (baseUrl) {
      changeComponentAttribute(component.id, "url", baseUrl[0]);
      const spliting = value.replace(baseUrl[0] + "/", "");
      changeComponentAttribute(component.id, "requestPath", spliting);
    }
  };

  handleRequestPath = url => {
    if (!url) return null;
    const spliting = url.split("/");
    // alert(spliting[spliting.length - 1]);
    let required = spliting[spliting.length - 1];
    if (required === "") return spliting[spliting.length - 2];
    return required;
  };
  handleShowMapper = () => {
    this.setState({
      showMapper: true
    });
  };
  handleHideMapper = () => {
    this.setState({
      showMapper: false
    });
    heightLightComponent("");
  };
}
const mapPropsToState = state => {
  return state;
};
export default connect(mapPropsToState)(NetworkProperties);
