import axiosInstance from "./APIHandler/axios";
import uuidv4 from "uuid/v4";
import { RSA_NO_PADDING } from "constants";
import { createBllFunction } from "./../../components/BusinessLogicLayer/utils/constants/funcConstructors";
import functionTypes from "./../constants/function-types";
import { createPort } from "../../components/BusinessLogicLayer/utils/constants/portConstructors";
import portModels from "./../../components/BusinessLogicLayer/utils/constants/portModels";
export default class BLLReduxHandler {
  static loadApp(id, callback = null) {
    window.store.dispatch({ type: "bll_load_app", payLoad: { id } });
    axiosInstance.get("bll/" + id).then(response => {
      window.store.dispatch({
        type: "bll_app_loaded",
        payLoad: { data: response.data }
      });

      if (callback) callback();
    });
  }

  static addGlobalVariable(appId, variable) {
   
    axiosInstance
      .post("bll/" + appId + "/global/variable", variable)
      .then(res => {
        BLLReduxHandler.updateGlobalVariables(appId, res.data);
      });
  }
  static addLocalVariable(appId, funcId, variable) {
    axiosInstance
      .post("bll/" + appId + "/local/" + funcId + "/variable", variable)
      .then(res => {
        BLLReduxHandler.updateLocalVariables(appId, funcId, res.data);
      });
  }
  static updateGlobalVariables(appId, data) {
    window.store.dispatch({
      type: "bll_global_variables_received",
      payLoad: {
        id: appId,
        globalVariables: data
      }
    });
  }
  static updateLocalVariables(appId, funcId, data) {
    window.store.dispatch({
      type: "bll_local_variables_received",
      payLoad: {
        appId,
        variables: data,
        funcId
      }
    });
  }
  static addEventFunction(appId, componentID, eventType, history) {
    //write code to first load bll if its not yet loaded
    let app = window.store.getState().bll.apps[appId];
    let processEventFunctionCreation = () => {
      app = window.store.getState().bll.apps[appId];
      let func = Object.keys(app.bllFunctions).find(
        fKey =>
          app.bllFunctions[fKey].componentID == componentID &&
          app.bllFunctions[fKey].event == eventType
      );
      if (func) {
        this.changeActiveFunction(appId, func);
      } else {
        this.addFunctionAndLoadInBll(
          appId,
          componentID + "_" + eventType,
          functionTypes.FUNCTION_EVENT,
          { eventType, componentID }
        );
      }

      history.push("/business-logic-layer/" + appId);
    };
    if (!app) {
      this.loadApp(appId, processEventFunctionCreation);
      return;
    } else processEventFunctionCreation();
  }
  static AddPin(appId, funcIdentifier, node, newPinType) {
    let port = createPort(newPinType, "New Pin", true, {
      showLabel: false,
      enableDefault: true
    });
    //(port);
    window.store.dispatch({
      type: "bll-node-add-pin",
      payLoad: {
        appId,
        funcIdentifier,
        node,
        port
      }
    });
  }
  static updatePortDefaultValue(appId, funcIdentifier, node, portKey, value) {
    window.store.dispatch({
      type: "bll-update-default-value",
      payLoad: {
        appId,
        funcIdentifier,
        node,
        portKey,
        value
      }
    });
  }
  static addFunctionAndLoadInBll(
    appId,
    name,
    functionType = functionTypes.FUNCTION_SCRIPT,
    options = {}
  ) {
    //call the constructor method to create func
    let bllFunc = createBllFunction(name, functionType, options);
    window.store.dispatch({
      type: "bll_function_created",
      payLoad: { appId, data: bllFunc }
    }); // create func in bll first for good response
    // now actually create on server
    axiosInstance.post("bll/" + appId, bllFunc).then(res => {
      //(res.data);
      window.store.dispatch({
        type: "bll_function_created",
        payLoad: { appId, data: res.data }
      });
    });
  }
  static removeGlobalVariable(appId, variable) {
    //(appId);
    axiosInstance
      .delete("bll/global/variable/" + appId + "/" + variable._id)
      .then(res => {
        BLLReduxHandler.updateGlobalVariables(appId, res.data);
      });
  }
  static removeLocalVariable(appId, funcId, variable) {
    //(appId);
    //(funcId);
    axiosInstance
      .delete("bll/local/variable/" + appId + "/" + funcId + "/" + variable._id)
      .then(res => {
        BLLReduxHandler.updateLocalVariables(appId, funcId, res.data);
      });
  }
  static deleteFunction(appId, func) {
    axiosInstance.delete("bll/" + appId + "/" + func._id).then(res => {
      window.store.dispatch({
        type: "bll_function_deleted",
        payLoad: {
          func,
          appId
        }
      });
    });
  }
  static changeActiveFunction(appId, newActiveFunctionIdentifier) {
    window.store.dispatch({
      type: "bll_function_active_changed",
      payLoad: { appId, activeFunction: newActiveFunctionIdentifier }
    });
  }
  //model is used in old bll. now only graph is used. model will be removed later on

  static saveFunction(appId, funcId, model, graph) {
    axiosInstance
      .post("bll/" + appId + "/save/model/" + funcId, { model, graph })
      .then(res => (res.data));
  }
  static saveNodesToServer(appId, funcId, nodes, links, inputs, outputs) {
    axiosInstance
      .post("bll/" + appId + "/save/nodes/" + funcId, {
        nodes,
        links,
        inputs,
        outputs
      })
      .then(res => {
        window.store.dispatch({
          type: "bll_function_created",
          payLoad: { appId, data: res.data }
        });
      });
  }
  /**
   *
   * @param {*} appId
   * @param {*} funcIdentifier identifier of the function as in redux im distinguisihig funcs on the basis of unique identifier
   */
  static addNodesObject(appId, funcIdentifier) {
    // //(funcIdentifier);
    window.store.dispatch({
      type: "bll_add_nodes_object",
      payLoad: { appId, funcIdentifier }
    });
  }
  static updateNode(appId, funcIdentifier, node) {
    window.store.dispatch({
      type: "bll-update-node",
      payLoad: { appId, funcIdentifier, node }
    });
  }
  static deleteSelections(appId, funcIdentifier, selections) {
    window.store.dispatch({
      type: "bll-delete-selections",
      payLoad: { appId, funcIdentifier, selections }
    });
  }
  static addLink(appId, funcIdentifier, link) {
    link.id = uuidv4();
    window.store.dispatch({
      type: "bll-add-link",
      payLoad: { appId, funcIdentifier, link }
    });
  }
}
