import React, { Component } from "react";
import ComponentViewerSelector from "../ComponentViewer/ComponentViewerSelector";
class Toolbar extends Component {
  state = {};
  render() {
    const { component, components, toolbarHeight } = this.props;
    const styles = {
      height: component.children.length > 0 ? "auto" : toolbarHeight + "px",
      backgroundColor: "white"
    };
    return (
      <div style={{ ...styles }} className="designer-toolbar">
        <ComponentViewerSelector
          component={component}
          components={components}
        />
      </div>
    );
  }
}
export default Toolbar;
