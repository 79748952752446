import React from "react";

import Navbar from "./Navbar";
import NavbarDrawer from "./NavbarDrawer";

const TopBar = () => {
  return (
    <>
      <Navbar />
      <NavbarDrawer />
      {/* this is the drawer which opens on left side on mobiles */}
    </>
  );
};

export default TopBar;
