import { COMPONENT_FRAGMENT } from "../constants/typeConstants";
import common from "./common";
/**
 * factory function to produce Linear fragment Component Object
 * @param {String} name
 * @param {Array} children array
 */
export default function(name = "Section", children = []) {
  let obj = {
    name: name,
    type: COMPONENT_FRAGMENT,
    children: children
  };
  return Object.assign(obj, common);
}
