import {
    ADD_COMPONENT_TO_TREE
} from "../../constants/action-types";
import {
    findComponentByType
} from "../../constants/component_list";
import produce from "immer";
import Immutable from "./../../reducers/handlers/Immutable";

export const addComponentPosition = {
    BELOW: "BELOW",
    ABOVE: "ABOVE",
    ATSTART: "ATSTART",
    ATEND: "ATEND",
    CHILD: "CHILD",
    TOPARENT: "TOPARENT"
};
export function addComponent(parent_id, type, index = null) {
    window.store.dispatch({
        type: ADD_COMPONENT_TO_TREE,
        payLoad: {
            parent_id,
            type,
            // position,
            index
        }
    });
}
export function addComponentByPosition(id, type, position = {}) {
                                                                  let state = window.store.getState();
                                                                  const parent_id =
                                                                    state
                                                                      .designer
                                                                      .components[
                                                                      id
                                                                    ].parent_id;
                                                                  const index = state.designer.components[
                                                                    parent_id
                                                                  ].children.indexOf(
                                                                    id
                                                                  );
                                                                  //(position);
                                                                  switch (
                                                                    position
                                                                  ) {
                                                                    case addComponentPosition.ABOVE:
                                                                      addComponent(
                                                                        parent_id,
                                                                        type,
                                                                        index
                                                                      );
                                                                      break;
                                                                    case addComponentPosition.ATSTART:
                                                                      addComponent(
                                                                        parent_id,
                                                                        type,
                                                                        0
                                                                      );
                                                                      break;
                                                                    case addComponentPosition.ATEND:
                                                                      addComponent(
                                                                        parent_id,
                                                                        type,
                                                                        state
                                                                          .designer
                                                                          .components[
                                                                          parent_id
                                                                        ]
                                                                          .children
                                                                          .length
                                                                      );
                                                                      break;
                                                                    case addComponentPosition.CHILD:
                                                                      addComponent(
                                                                        id,
                                                                        type,
                                                                        0
                                                                      );
                                                                      break;

                                                                    default:
                                                                      addComponent(
                                                                        parent_id,
                                                                        type,
                                                                        index +
                                                                          1
                                                                      );
                                                                      break;
                                                                  }
                                                                }
/**
 *
 * @param {*} state must be passed from reducer
 * @param {*} parent_id id of the  parent component
 * @param {*} type of the component
 * @param {*} index where to add
 */
export function addComponentReducer(state, action) {
    const {
        parent_id,
        type,
        index,
    } = action.payLoad;
    const componentType = findComponentByType(type);
    let component = componentType.factory();
    state = addChild(component, state, parent_id, index);
    return state;
}

function addChild(component, state, parent_id, index = null, position = {}) {
    component.id = state.components.length;
    component.parent_id = parent_id;
    component.name = component.name + "_" + state.components.length;
    let childs = Object.assign({}, component.children);
    component.children = [];
    state = produce(state, draftState => {
        draftState.components[component.id] = component;
        let whereToPut = index;
        if (index === null) whereToPut = state.components.length;
        draftState.components[parent_id].children = Immutable.insertItem(
            draftState.components[parent_id].children,
            component.id,
            whereToPut
        );
    });
    //some how array is converted to object so converting back
    childs = Object.keys(childs).map(function(key) {
        return childs[key];
    });

    //check if factory has returned children also children are objects so enter them in components recursively
    if (childs.length > 0) {
        for (let i = 0; i < childs.length; i++) {
            state = addChild(childs[i], state, component.id, 0);
        }
    }

    return state;
}