import React from "react";
import { makeStyles } from "@material-ui/core/styles";
const useStyles = makeStyles((theme) => ({}));
const LandingPage = () => {
  const classes = useStyles();
  return (
    <div>
      <h1>Landing Page</h1>
    </div>
  );
};

export default LandingPage;
