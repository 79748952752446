import React, { Component } from "react";
import SingleObjectMapping from "./SingleObjectMapping";
class MappingResponse extends Component {
  state = { show: true };
  render() {
    let response = this.props.responseJSON;
    const { selectedPropertiesScreenID } = this.props;
    const { show } = this.state;
    return (
      <React.Fragment>
        <span>
          <i
            onClick={e => this.setState({ show: !show })}
            style={{ cursor: "pointer" }}
            className={!show ? "fas fa-caret-right" : "fas fa-caret-down"}
          >
            {"Root:{" + Object.keys(response).length + "}"}
          </i>
        </span>
        {show && (
          <ul style={{ listStyleType: "none" }}>
            {Object.keys(response).map((keys, index) => (
              <SingleObjectMapping
                key={index}
                selectedPropertiesScreenID={selectedPropertiesScreenID}
                objectKey={keys}
                objectValue={response[keys]}
              />
            ))}
          </ul>
        )}{" "}
      </React.Fragment>
    );
  }
}

export default MappingResponse;
