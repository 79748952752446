import React, { Component } from "react";
import image1 from "../SiteImages/carousel1.png";
import image2 from "../SiteImages/carousel2.png";
import image3 from "../SiteImages/carousel3.jpg";
import image4 from "../SiteImages/mobile1.png";
import video1 from "../SiteImages/demo.mp4";
import "./HomePage.css";
import Footer from "../Footer/Footer";
import "bootstrap/dist/css/bootstrap.min.css";

class HomePage extends Component {
  state = {};
  render() {
    return (
      <div className="Main">
        <div
          id="carouselExampleIndicators"
          className="carousel slide"
          data-ride="carousel"
          data-interval="3000"
        >
          <ol class="carousel-indicators">
            <li data-target="#myCarousel" data-slide-to="0" class="active" />
            <li data-target="#myCarousel" data-slide-to="1" />
            <li data-target="#myCarousel" data-slide-to="2" />
          </ol>
          <div className="carousel-inner">
            <div className="carousel-item active">
              <img className="d-block w-100" src={image2} alt="First slide" />
              <div class="carousel-caption">
                <a href="/login">
                  <button className="carouselButton">
                    <b>Get Started Now</b>
                  </button>
                </a>
                <p>Simplest Way To Create Mobile Applications</p>
              </div>
            </div>
            <div className="carousel-item">
              <img className="d-block w-100" src={image1} alt="Second slide" />
              <div class="carousel-caption">
                <a href="/login">
                  <button className="carouselButton">
                    <b>Get Started Now</b>
                  </button>
                </a>
                <p>Create Mobile Applications without coding</p>
              </div>
            </div>
            <div className="carousel-item">
              <img className="d-block w-100" src={image3} alt="Third slide" />
              <div class="carousel-caption">
                <a href="/login">
                  <button className="carouselButton">
                    <b>Get Started Now</b>
                  </button>
                </a>
                <p>More than 10000 Satisfactory Customers</p>
              </div>
            </div>
          </div>
        </div>
        <a
          className="carousel-control-prev"
          href="#myCarousel"
          role="button"
          data-slide="prev"
        >
          <span className="carousel-control-prev-icon" aria-hidden="true" />
          <span className="sr-only">Previous</span>
        </a>
        <a
          className="carousel-control-next"
          href="#myCarousel"
          role="button"
          data-slide="next"
        >
          <span className="carousel-control-next-icon" aria-hidden="true" />
          <span className="sr-only">Next</span>
        </a>
        <div className="container mt-4">
          <div className="row" />
          <div className="col-md-12 col-sm-12 text-center">
            <h2 className="fiveStepsHeading">
              <b>5 Simple steps to create your own application</b>
            </h2>
            <p className="fiveSteps">
              1. Select component from component list.
              <br />
              2. Drag components to the designer screen.
              <br />
              3. Apply styling properties to selected components.
              <br />
              4. Manage Screen hierarchies and link screens with each other.
              <br />
              5. Generate APK file in no time.
            </p>
          </div>
        </div>
        {/* *************Video Section************** */}
        <div className="videoDiv">
          <div className="row">
            <div className="col-md-1" />
            <div className="col-md-5 col-sm-12">
              <h2 style={{ color: "white" }}>See, Its so simple and easy</h2>
              <p>
                No coding skills or experience is required. It helps you to
                easily create applications by simple dragging and dropping your
                desire components
              </p>
              <button className="VideoDivButton">Get Started Now</button>
            </div>
            <div className="col-md-1 col-sm-2" />
            <div className="col-md-5 col-sm-10">
              <video width="400" controls>
                <source src={video1} type="video/mp4" />
              </video>
            </div>
          </div>
        </div>
        {/* ******************* 4 ICONS DIV ******************** */}
        <div className="container iconsDiv">
          <div className="row">
            <div className="col-md-1" />
            <div className="col-md-5 col-sm-12 text-center pb-4">
              <i className="fas fa-hand-point-up iconsHome" />
              <h5 className="pt-2">Drag and Drop components</h5>
              <p>
                Drag and drop components of your choice and create applications
                in minutes
              </p>
            </div>
            <div className="col-md-5 col-sm-12 text-center pb-4">
              <i className="fas fa-pencil-ruler iconsHome" />
              <h5 className="pt-2">Apply Styling properties</h5>
              <p>
                Set styling properties to the selected components to give your
                application a new look
              </p>
            </div>
            <div className="col-md-1" />
            <div className="col-md-1" />
            <div className="col-md-5 col-sm-12 text-center">
              <i className="fas fa-mobile-alt iconsHome" />
              <h5 className="pt-2">Zero Coding Skills Required</h5>
              <p>
                Create your own mobile applications by simple drag and drop
                without any coding skills or experience
              </p>
            </div>
            <div className="col-md-5 col-sm-12 text-center">
              <i className="fas fa-plus-circle iconsHome" />
              <h5 className="pt-2">10 Times Faster</h5>
              <p>Application development is easier and ten times faster</p>
            </div>
            <div className="col-md-1" />
          </div>
        </div>
        {/* ****************** BANNER ********************* */}
        <div className="row banner">
          <div className="col-md-12" />
          <div className="col-md-6">
            <h4 className="Banner-text">
              Application Development has never been <b>SIMPLER</b>
            </h4>
          </div>
          <div className="col-md-6">
            <a href="/designer">
              <button className="Banner-Btn">
                <b>Start Developing Now</b>
              </button>
            </a>
          </div>
          <div className="col-md-12" />
        </div>
        {/* *************Mobile Div Section************** */}
        <div className="container MobileDiv">
          <div className="row">
            <div className="col-md-3 col-sm-4" />
            <div className="col-md-3 col-sm-8">
              <img src={image4} alt="mobile" />
            </div>
            <div className="col-md-4 col-sm-12 mobDivStyle">
              <h1>Start Developing for free or Join our affordable packages</h1>
              <br />
              <a href="/designer">
                <button className="mobDivButton">
                  <b>FREE Register!</b>
                </button>
              </a>
              <a href="/pricing">
                <button className="mobDivButton2">
                  <b>PACKAGES</b>
                </button>
              </a>
            </div>
          </div>
        </div>
        {/*********FAQ home Page*********/}
        <div className="container">
          <div className="row faqHome">
            <div className="col-md-4" />
            <div className="col-md-4">
              <h3>
                <b>FAQ's</b>
              </h3>
            </div>
            <div className="col-md-4" />
          </div>
          <div className="row faqHomeQues">
            <div className="col-sm-12 col-md-4">
              <h5>What forms of payment do you accept?</h5>
              <p>
                We accept any major credit card. For annual subscriptions with
                over 25 users we can also issue an invoice payable by bank
                transfer or check. Your account executive can arrange an invoice
                purchase.
              </p>
            </div>
            <div className="col-sm-12 col-md-4">
              <h5>Do all users have to be on the same plan?</h5>
              <p>
                Yes, we do require all users to be on the same pricing tier.
                It’s not possible to have some users on Professional and others
                on Enterprise (for example).
              </p>
            </div>
            <div className="col-sm-12 col-md-4">
              <h5>Are there fees for in-app calling?</h5>
              <p>
                Every trial comes with $1 in call credit which is good for about
                50 minutes of talk time. Additional call credit can be purchased
                as needed.
              </p>
            </div>
            <div className="col-sm-12 col-md-4">
              <h5>Can I use Reach prospecting without using Sell?</h5>
              <p>
                Reach is deeply integrated prospecting experience and
                unfortunatelly cannot be used independently of Sell.
              </p>
            </div>
            <div className="col-sm-12 col-md-4">
              <h5>What features does the trial include?</h5>
              <p>
                The 14-day evaluation trial includes all Sell features. When
                you're ready to activate your account, you'll have the option to
                select which plan you want to use.
              </p>
            </div>
            <div className="col-sm-12 col-md-4">
              <h5>Can't find the answer you’re looking for?</h5>
              <p>
                Feel free to
                <a href="https://usmanlive.com">contact us</a>
                for more information or visit our
                <a href="https://usmanlive.com">support center</a>. We’re here
                to help.
              </p>
            </div>
          </div>
        </div>

        <div className="row motivationBackground">
          <div className="container">
            <div className="col-md-1" />
            <div className="col-md-5 col-sm-12 motivation-text">
              <h2 className="MotivationHeading">Our motivation</h2>
              <h5>
                Building cross-platform web-based mock up mobile application
                developer that is for both professionals and newbies. The goal
                is to provide users an easy drag and drop feature and custom
                styling of components for creating mock up mobile applications.
              </h5>
            </div>
          </div>
        </div>

        <Footer />
      </div>
    );
  }
}

export default HomePage;
