import produce from "immer";
import Immutable from "./../../reducers/handlers/Immutable";

function loadFunction(index) {
  window.store.dispatch({ type: "BLL_GUI_LOAD_FUNCTION", payLoad: { index } });
}
function loadLastFunction(index) {
  window.store.dispatch({
    type: "BLL_GUI_LOAD_LAST_FUNCTION",
    payLoad: { index }
  });
}
function loadLastFunctionReducer(state, action) {
  return produce(state, (derivedState) => {
    //derivedState.selectedIndex = loadedFunctions.length;
    //derivedState.loadedFunctions[loadedFunctions.length] = index;
  });
}
function closeFunction(index) {
  window.store.dispatch({ type: "BLL_GUI_CLOSE_FUNCTION", payLoad: { index } });
}
function changeSelectedIndex(index) {
  window.store.dispatch({
    type: "BLL_GUI_CHANGE_SELECTED_INDEX",
    payLoad: { index }
  });
}
function changeSelectedIndexReducer(state, action) {
  return produce(state, (derivedState) => {
    derivedState.selectedIndex = action.payLoad.index;
  });
}
function closeFunctionReducer(state, action) {
  return produce(state, (derivedState) => {
    derivedState.loadedFunctions = Immutable.removeItem(
      derivedState.loadedFunctions,
      action.payLoad.index
    );
    //derivedState.selectedIndex = index-1;
  });
}
function loadFunctionReducer(state, action) {
                                              const { index } = action.payLoad;
                                              const { loadedFunctions } = state;
                                              //(index + " to load");
                                              //("loaded" + loadedFunctions);
                                              const foundResult = loadedFunctions.indexOf(
                                                index
                                              );
                                              return produce(
                                                state,
                                                (derivedState) => {
                                                  if (foundResult === -1) {
                                                    derivedState.selectedIndex =
                                                      loadedFunctions.length;
                                                    derivedState.loadedFunctions[
                                                      loadedFunctions.length
                                                    ] = index;
                                                  } else
                                                    derivedState.selectedIndex = foundResult;
                                                }
                                              );
                                            }
const bllGUIActions = {
  loadFunction,
  loadFunctionReducer,
  closeFunction,
  closeFunctionReducer,
  changeSelectedIndex,
  changeSelectedIndexReducer
};
export default bllGUIActions;
