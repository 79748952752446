import createNodeByType from "../createNodeByType";
import nodeModels from "./nodeModels";
import uuidv4 from "uuid/v4";
import functionTypes from "./../../../../store/constants/function-types";
export const createBllFunction = (
  name,
  type = functionTypes.FUNCTION_SCRIPT,
  options = {}
) => {
  let func = {
    identifier: uuidv4(),
    name,
    links: [],
    inputs: [],
    outputs: [],
    localVariables: [],
    componentID: null,
    event: null,
    type
  };
  switch (type) {
    case functionTypes.FUNCTION_EVENT:
      func.componentID = options.componentID;
      func.event = options.eventType;
      break;

    default:
      break;
  }
  let startNode = createNodeByType(nodeModels.START);
  startNode.x = 25;
  startNode.y = 25;
  let finishNode = createNodeByType(nodeModels.FINISH);
  finishNode.x = 500;
  finishNode.y = 25;
  func.nodes = {};
  func.nodes[startNode.id] = startNode;
  func.nodes[finishNode.id] = finishNode;
  return func;
};
