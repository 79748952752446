/** @format */

import React, { Component } from "react";
import { DragSource } from "react-dnd";
import { DRAG_IN_PROGRESS } from "../../../../store/constants/action-types";
import "./singleComponet.css";

class SingleComponent extends Component {
  previousPropsDraggingStatus = null;
  // getSnapshotBeforeUpdate(prevProps, prevState) {
  //   return prevProps.isDragging;
  // }

  // component
  // componentDidUpdate(prevProps, prevState, snapshot) {
  //   //("Logging snapshot", snapshot);
  // }
  render() {
    const { connectDragSource, color, module, isDragging } = this.props;
    //("Logging is Dragging", isDragging);
    window.store.dispatch({
      type: DRAG_IN_PROGRESS,
      payLoad: {
        isDragging: isDragging,
      },
    });
    return (
      <div className="single_component_wrapper">
        <div className="SingleComponent" ref={connectDragSource}>
          <div className="icon-style">
            <i className={this.props.module.icon} />
          </div>
          <div className="icon-text">{this.props.module.display_name}</div>
        </div>
      </div>
    );
  }
}

const spec = {
  beginDrag(props, monitor, component) {
    var ReactDOM = require("react-dom");
    window.store.dispatch({
      type: "DRAGGING_STARTED",
      payLoad: {
        name: props.module.htmlElement,
      },
    });
    return {
      name: props.module.display_name,
      type: "items",
      // type: props.module.name,
      grouped: props.module.isGrouped,
      rect: ReactDOM.findDOMNode(component).getBoundingClientRect(),
    };
  },
  endDrag(props, monitor, component) {},
};

//props which should be injected by this drag source
function collect(connect, monitor) {
  return {
    connectDragSource: connect.dragSource(),
    isDragging: monitor.isDragging(),
  };
}
export default DragSource("modules", spec, collect)(SingleComponent);
