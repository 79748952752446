import React, { Component } from "react";
import FontProperties from "../../Properties/fontProperties";
import NameProperty from "../../Properties/nameProperty";
import LabelProperties from "../../Properties/SpecificProperties/LabelProperties";
import LabelProperty from "../../Properties/labelProperty";
import ButtonDesigns from "./ButtonStyles/buttonDesigns";
import Positioning from "../../Properties/positioning";
import ImageProperties from "../../Properties/ImageProperties";
import MarginProperties from "../../Properties/marginProperties";
class ButtonProperties extends Component {
  state = {};
  render() {
    const { component } = this.props;
    return (
      <div style={{ width: "300px", borderRadius: "5px" }}>
        <h4
          style={{
            backgroundColor: "#3472CD",
            textAlign: "center",
            padding: "8px",
            fontFamily: "sans-serif",
            fontWeight: "bold",
            color: "white"
          }}
        >
          Properties
        </h4>
        <div style={{ paddingLeft: "10px", paddingRight: "10px" }}>
          <ImageProperties component={component} />
          <br />

          <LabelProperty component={component} />
          {/* <hr style={{ width: "100%", color: "black" }} /> */}
          <FontProperties component={component}></FontProperties>
          {/* <hr style={{ width: "100%", color: "black" }} /> */}
          <ButtonDesigns component={component}></ButtonDesigns>
        </div>
      </div>
    );
  }
}

export default ButtonProperties;
