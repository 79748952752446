/** @format */

import { COMPONENT_BUTTON } from "../constants/typeConstants";
import common from "./common";
/**
 * factory to produce button
 * @param {String} name of the component
 * @param {String} label to display on button Default is "Button"
 */
export default function (name = "Button", label = "Button") {
  let obj = {
    name: name,
    type: COMPONENT_BUTTON,
    children: [],
  };
  const defaultStyles = {
    width: "30%",
    height: "wrap_content",
    fontSize: "10px",
    borderRadius: "5px",
    color: "white",
  };
  obj = Object.assign({}, obj, common);
  delete obj.properties;
  obj.properties = {
    properties: {},
  };
  obj.properties.styles = defaultStyles;
  obj.properties.customClassName = "example_12 button";
  obj.label = label;
  return obj;
}
