/** @format */

import React from "react";
import { basicProps } from "./ComponentDesigner/ComponentViewer/shared/BasicProps";

const ActivityCounter = () => {
  const { components, selectedPropertiesScreenID } = basicProps();
  let currentIndexNumber = components[2].children.indexOf(
    selectedPropertiesScreenID
  );
  if (currentIndexNumber <= -1) {
    currentIndexNumber = 1;
  } else {
    currentIndexNumber++;
  }
  const totalScreens = components[2].children.length;
  return <span>{`Screen ${currentIndexNumber} of ${totalScreens} `}</span>;
};

export default ActivityCounter;
