/** @format */

import React, { useState, useRef } from "react";

import { Popover } from "@material-ui/core";
import ButtonProperties from "./ButtonProperties";

import flow from "lodash/flow";
import { highLightKey } from "../../../../store/actions/action-functions/addBindedId";
import splittingAndMakingObject from "./utils/splitingKeys";
import { getDimensionValue } from "../../Properties/utils/changeToHtmlUnits";
import "./baseStyleSheet.css";
import {
  manageBindedId,
  removeBindedId,
} from "../../../../store/actions/action-functions/manageBindedIds";
import { basicProps } from "./shared/BasicProps";
import { changePropertiesComponent } from "../../../../store/actions/designerActions";
import { useDraggableComponent } from "./utils/dragComponent";
import { useDropComponent } from "./utils/dropComponent";
import TextFieldProperties from "./TextFieldProperties";
const ComponentViewerTextView = ({
  id,
  hover,
  connectDragSource,
  connectDropTarget,
}) => {
  const ref = useRef();
  const [state, setState] = useState({
    anchorEl: null,
  });
  const dragBehaviour = useDraggableComponent(id);
  const dropBehaviour = useDropComponent(id, ref);
  const basicObject = basicProps();
  const handleClose = (e) => {
    setState({ anchorEl: null });
  };
  const hanldeDoubleClick = (e) => {
    e.stopPropagation();
    setState({ anchorEl: e.currentTarget });
  };
  // const { selectedPropertiesScreenID } = this.props.designer;
  const component = basicObject.components[id];
  const { transitionMode } = basicObject;

  let styles = { opacity: hover ? 0.7 : 1 };
  styles = Object.assign(styles, component.properties.styles);
  let hasLabel = true;
  try {
    hasLabel = component.label && true;
  } catch (err) {
    hasLabel = false;
  }
  const alreadyBinded = basicObject.idsBinded.find((x) => x == component.id);

  const open = Boolean(state.anchorEl);
  const html_id = open ? "component" + component.id : "";
  const componentStyles = component.properties.styles;
  const width = getDimensionValue(componentStyles.width);
  const height = getDimensionValue(componentStyles.height);
  const customClassName = component.properties.customClassName;
  dropBehaviour.drop(dragBehaviour.drag(ref));
  return (
    <>
    <span
      className={customClassName}
      style={{
        ...styles,
        ...{
          border:
            (basicObject.heightLightComponent === id &&
              basicObject.disableDrag) ||
              alreadyBinded
              ? "3px solid black"
              : styles.border,
        },
        width: width,
        height: height,
      }}
      id={html_id}
      ref={ref}
      onClick={(e) => {
        e.stopPropagation();
        if (transitionMode) {
          const target = basicObject.components[2].children.find((screen, key) => basicObject.components[screen].name == component.transitionTarget);
          changePropertiesComponent(target);
        } else {
        if (
          basicObject.disableSelection &&
          basicObject.idToBeBinded !== component.id &&
          !alreadyBinded
        ) {
          manageBindedId(component.id);
        }
        if (
          basicObject.disableSelection &&
          component.id !== basicObject.idToBeBinded &&
          alreadyBinded
        ) {
          removeBindedId(component.id);
        }
        if (!basicObject.disableDrag && !alreadyBinded)
          changePropertiesComponent(id);
        if (basicObject.disableDrag === true)
          highLightKey(splittingAndMakingObject(component.node_id));
        }
      }}
      onDoubleClick={hanldeDoubleClick}
    >
      {hasLabel ? component.label : "Text Component"}
    </span>
      {!transitionMode && (
        <Popover
          id={html_id}
          open={open}
          onClose={handleClose}
          anchorEl={state.anchorEl}
          onClick={e => e.stopPropagation()}
          onDoubleClick={e => e.stopPropagation()}
          anchorReference="anchorPosition"
          anchorPosition={{ top: 200, left: 1300 }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center"
          }}
        >
          <ButtonProperties component={component}></ButtonProperties>
        </Popover>
      )
      }
    </>
  );
};

ComponentViewerTextView.propTypes = {};
export default ComponentViewerTextView;
