import React from "react";
import { Typography } from "@material-ui/core";
import clsx from "clsx";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
    "& .logo-icon": {
      width: 56,
      [`${theme.breakpoints.up("xs")} and (orientation: landscape)`]: {
        minHeight: 48,
      },
      [theme.breakpoints.up("sm")]: {
        minHeight: 64,
      },
      minHeight: 56,
      [`${theme.breakpoints.up("xs")} and (orientation: landscape)`]: {
        minHeight: 48,
      },
      [theme.breakpoints.up("sm")]: {
        minHeight: 64,
      },
      transition: theme.transitions.create(["width", "height"], {
        duration: theme.transitions.duration.shortest,
        easing: theme.transitions.easing.easeInOut,
      }),
    },
  },
  logoText: {
    fontSize: "1.6rem",
    marginLeft: "1.2rem",
    marginRight: "1.2",
    fontWeight: "300",
  },
}));

function Logo() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <img className="logo-icon" src="/production/images/logo.png" alt="logo" />
      <Typography className={classes.logoText} color="inherit">
        AlphaDND
      </Typography>
    </div>
  );
}

export default Logo;
