import {
    CHANGE_BACKGROUND_COLOR,
    RESET_BACKGROUND_COLOR
} from "../../constants/action-types";
import produce from "immer";
import Cookies from "universal-cookie";

const cookies = new Cookies();

export function changeBackgoundColor(color) {
    window.store.dispatch({
        type: CHANGE_BACKGROUND_COLOR,
        payLoad: {
            color
        }
    })
}
export function changeBackgoundColorReducer(state, action) {
    const {
        color
    } = action.payLoad;
    cookies.remove("borderColor")
    return produce(state, draftState => {
        draftState.color = color;
        cookies.set("borderColor", "" + color);
    })
}
export function resetBackgroundColor() {
    window.store.dispatch({
        type: RESET_BACKGROUND_COLOR,
    })
}
export function resetBackgoundColorReducer(state, action) {
    cookies.remove("borderColor")
    return produce(state, draftState => {
        draftState.color = "#3472cd";
    })
}