export default function labelAllowed(type) {
    switch (type) {
        case "LinearLayout_Horizontal":
            return true;
        case "LinearLayout_Vertical":
            return true;
        case "ListView":
            return true;
        case "EditText":
            return true;
        case "Activity":
            return true;
        case "ImageView":
            return true;
        default:
            return false;
    }
}

export function isActivity(type) {
    return type === "Activity" || type === "Init";
}