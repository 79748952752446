import React, { Component } from "react";
import Popover from "@material-ui/core/Popover";
import { DragSource } from "react-dnd";
import flow from "lodash/flow";
class MaterialPopOver extends Component {
  state = {
    editable: false,
    anchorEl: null,
    top: 200,
    left: 1300
  };

  render() {
    const { component, anchorEl, handleClose, connectDragSoruce } = this.props;
    const open = Boolean(anchorEl);
    const html_id = open ? "component" + component.id : "";
    return (
      <Popover
        id={html_id}
        open={open}
        onClick={e => e.stopPropagation()}
        onDoubleClick={e => e.stopPropagation()}
        onClose={handleClose}
        anchorEl={anchorEl}
        anchorReference="anchorPosition"
        anchorPosition={{ top: 200, left: 1300 }}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right"
        }}
        style={{ overflow: "auto" }}
      >
        {this.props.children}
      </Popover>
    );
  }
}

export default DragSource(
  "popover",
  {
    beginDrag(props, monitor, component) {
      return props;
    },
    endDrag(props, monitor, component) {
      // const dom = require("react-dom");
      // //("Logging END DRag");
      // //(component);
      // //(dom.findDOMNode(component).getBoundingClientRect());
    }
  },
  function collect(connect, monitor) {
    return { connectDragSoruce: connect.dragSource() };
  }
)(MaterialPopOver);
