export default function goingDeeperIntoObject(ob, key, value) {

    var toReturn = {};
    for (var i in ob) {
        if (!ob.hasOwnProperty(i)) continue;
        if (typeof ob[i] == "object" && ob[i] !== null) {
            var flatObject = goingDeeperIntoObject(ob[i], key, value);
            for (var x in flatObject) {
                if (!flatObject.hasOwnProperty(x)) continue;
                toReturn[i + "." + x] = flatObject[x];
            }
        } else {
            toReturn[i] = ob[i];
        }
    }

    return toReturn;
}