import * as Actions from "../actions";

const initialState = {
  showLogin: false,
  login: {
    success: false,
    error: {
      username: null,
      password: null,
    },
  },
};

export const authReducer = function(state = initialState, action) {
  switch (action.type) {
    case Actions.AUTH_SHOW_LOGIN: {
      return {
        ...state,
        showLogin: true,
      };
    }
    case Actions.AUTH_HIDE_LOGIN: {
      return {
        ...state,
        showLogin: false,
      };
    }
    case Actions.AUTH_TOGGLE_LOGIN: {
      return {
        ...state,
        showLogin: !state.showLogin,
      };
    }

    default: {
      return state;
    }
  }
};
