import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import axiosInstance from "../../../store/actions/APIHandler/axios";
import AssetsNewFolderForm from "./DesignerAssets/NewFolderForm";
import AssetFileList from "./DesignerAssets/AssetFileList";
import AssetUploadDropZone from "./DesignerAssets/AssetUploadDropZone";
import AssetUploadActions from "./DesignerAssets/AssetUploadActions";
const DesignerAssets = props => {
  const appId = props.match.params.id;
  const [newFolderStatus, setNewFolderStatus] = useState(false);
  const [files, setFiles] = useState([]);
  useEffect(() => {
    loadFiles();
  }, []);
  const loadFiles = () => {
    axiosInstance
      .get(process.env.REACT_APP_API_URL + "apps/assets/" + appId)
      .then(res => setFiles(res.data));
  };
  const deleteFileFolder = _id => {
    axiosInstance
      .delete(
        process.env.REACT_APP_API_URL +
          "apps/assets/folder/" +
          appId +
          "/" +
          _id
      )
      .then(res => loadFiles());
  };
  const createFolder = (name, parent_id) => {
    setNewFolderStatus(false);
    ////("sending: " + name);
    axiosInstance
      .post(process.env.REACT_APP_API_URL + "apps/assets/folder/" + appId, {
        name,
        parent_id
      })
      .then(res => loadFiles());
  };
  // let rootFiles = files.filter(f => !f.parent_id);
  return (
    <div>
      {/* <AssetUploadDropZone appId={appId} onUpload={setFiles} /> */}
      {/* <AssetUploadActions appId={appId} parent_id="" onChange={setFiles} /> */}

      <AssetFileList
        files={files}
        isRoot={true}
        onFileDelete={deleteFileFolder}
        onChange={setFiles}
        appId={appId}
        parent_id=""
      />
    </div>
  );
};

export default withRouter(DesignerAssets);
