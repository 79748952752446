/** @format */

import { COMPONENT_CHECKBOX } from "../constants/typeConstants";
import common from "./common";
/**
 * factory function to produce CheckBox Component Object
 * @param {String} name
 * @param {Array} children array
 */
export default function(name = "CheckBox") {
    let obj = {
        name: name,
        type: COMPONENT_CHECKBOX,
        children: [],
    };
    obj = Object.assign(obj, common);
    const defaultStyles = {
        // width: "custom",
        // height: "cus",
    };
    obj.properties.styles = defaultStyles;
    obj.properties.customClassName = "checbox_default";
    return obj;
}