import React, { Component } from "react";
import { Link } from "react-router-dom";
const AppListPaginator = ({ page }) => {
  let totalPages = page.total / page.per_page;
  totalPages = page.total % page.per_page == 0 ? totalPages : totalPages + 1;
  //(page);
  //(totalPages);
  const pages = [];
  for (var i = 1; i <= totalPages; i++) pages.push(i);
  return (
    <div className="row">
      <div className="col-sm-8">
        <nav aria-label="...">
          <ul className="pagination pagination-sm">
            {pages.map(p => (
              <li
                className={
                  page.current_page == p ? "page-item active" : "page-item"
                }
                key={p}
              >
                <Link to={"/client-area/" + p} className="page-link">
                  {p}
                </Link>
              </li>
            ))}
            {/* <li className="page-item active">
              <a className="page-link" href="#">
                2
              </a>
            </li>
            <li className="page-item">
              <a className="page-link" href="#">
                3
              </a>
            </li> */}
          </ul>
        </nav>
      </div>
      <div className="col-sm-4">
        <div className="pull-right">
          Showing Records from {page.from} to {page.to} of {page.total}
        </div>
      </div>
    </div>
  );
};

export default AppListPaginator;
