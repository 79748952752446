export const androidPermissions = [
  { value: "ACCESS_FINE_LOCATION", label: "Fine Location" },
  { value: "ACCESS_COARSE_LOCATION", label: "Coarse Location" },
  { value: "ACCESS_WIFI_STATE", label: "Wifi State" },
  { value: "INTERNET", label: "Internet" },
  { value: "ACCESS_NETWORK_STATE", label: "Network State" },
  { value: "READ_EXTERNAL_STORAGE", label: "Read External Storage" },
  { value: "WRITE_EXTERNAL_STORAGE", label: "Write External Storage" },
  { value: "CAMERA", label: "Camera" },
  { value: "READ_PHONE_STATE", label: "Read Phone State" }
];
