import * as apiActionTypes from "./../constants/api-action-types";
import { loadJSONReducer } from "../actions/action-functions-api/loadComponent";
import { apiSaveAppJSONReducer } from "../actions/action-functions-api/apiSaveAppJSON";
import { loadCompletedReducer } from "./../actions/action-functions-api/loadComponent";
import APIHandlerReducer from "./../actions/APIHandler/APIHandlerReducer";
const initialState = {
  loading: false,
  loaded: false,
  loadedAppName: "default",
  id: "",
  apps: {},
  loadedApps: [],
  pagination: { apps: { pages: {} } }
};
export const apiReducer = (state = initialState, action) => {
  switch (action.type) {
    // Designing new api -redux here
    case apiActionTypes.API_REQUEST_APP:
      return APIHandlerReducer.requestAppReducer(state, action);
    case apiActionTypes.API_APP_CREATED:
      return APIHandlerReducer.appCreatedReducer(state, action);
    case "API_NEW_FUNCTION_ADDED":
      return APIHandlerReducer.newFunctionAddedReducer(state, action);
    case "API_BLL_FUNCTION_DELETED":
      return APIHandlerReducer.deleteFunctionReducer(state, action);
    case apiActionTypes.API_DELETE_APP:
      return APIHandlerReducer.deleteAppReducer(state, action);
    case apiActionTypes.API_REQUEST_APP_RECEIVED:
      return APIHandlerReducer.requestAppReceivedReducer(state, action);
    case apiActionTypes.API_REQUEST_APPS_PAGE:
      return APIHandlerReducer.requestAppsPageReducer(state, action);
    case apiActionTypes.API_REQUEST_APPS_PAGE_RECEIVED:
      return APIHandlerReducer.appsPageReceivedReducer(state, action);
    case apiActionTypes.API_LOAD_JSON:
      return loadJSONReducer(state, action);
    case apiActionTypes.API_LOAD_COMPLETED:
      return loadCompletedReducer(state, action);
    case apiActionTypes.API_SAVE_APP_JSON:
      return apiSaveAppJSONReducer(state, action);
    case apiActionTypes.API_LOAD_START:
      return { ...state, loading: true };
    case apiActionTypes.API_LOAD_END:
      return { ...state, loading: false };
    default:
      return state;
  }
};
