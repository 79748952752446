import React, { Component } from "react";
import { styleChanged } from "../../../Properties/styleActions";

import { addCustomClassName } from "../../../../../store/actions/action-functions/addCustomClassName";

class TextFieldDesigns extends Component {
  state = { collpased: true };
  render() {
    const { collpased } = this.state;
    return (
      <React.Fragment>
        <p
          onClick={this.handleCollapse}
          htmlFor="textFieldDesigns"
          style={{ cursor: "pointer" }}
        >
          Text Field Designs
          <span className="float-right">
            <i
              className="fas fa-chevron-circle-down"
              style={{ color: "#7A86BB", fontSize: "20px" }}
            ></i>
          </span>
        </p>
        <div
          hidden={!collpased}
          id="textFieldDesigns"
          style={{
            display: "grid",
            gridTemplateColumns: "250px",
            gridColumnGap: "5px",
            gridRowGap: "5px"
          }}
        >
          {this.getTextField("editText", "...")}
          {this.getTextField("editText-2", "..")}
          {this.getTextField("form-control", "Default Text Field")}
          {this.getTextField("textField_1", "Black Curved Borders")}
          {this.getTextField("textField_2", "Black Border")}
          {this.getTextField("textField_3", "Red Text Field")}
          {this.getTextField("textField_4", "Green Text Field")}
          {this.getTextField("textField_5", "Blue Border Text field")}
          {this.getTextField("textField_6", "left Border Style")}
        </div>
      </React.Fragment>
    );
  }

  handleCollapse = () => {
    this.setState({ collpased: !this.state.collpased });
  };
  getTextField(name, placeholder) {
    return (
      <input
        // disabled
        placeholder={placeholder}
        contentEditable="false"
        className={name}
        type="text"
        onClick={e => {
          e.stopPropagation();
          this.changeHandler(name);
        }}
      ></input>
    );
  }
  changeHandler = name => {
    //("Name:" + name);
    addCustomClassName(this.props.component.id, name);
  };
}
export default TextFieldDesigns;
