import { ADD_STYLES_OBJECT } from "../../constants/action-types";

/**
 * Add a missing style object.
 * @param {*} index of the component
 */
export function addStylesObjectToComponent(id) {
  window.store.dispatch({
    type: ADD_STYLES_OBJECT,
    payLoad: {
      id: id
    }
  });
}

export function addStylesObjectToComponentReducer(state, action) {
  return {
    ...state,
    components: state.components.map((item, i) => {
      if (i != action.payLoad.id) return item;
      else
        return {
          ...item,
          properties: { ...item.properties, styles: {} }
        };
    })
  };
}
