/** @format */

import React, { Component } from "react";
// import { Slider } from "material-ui-slider";
import { styleChanged } from "./styleActions";
import { separator } from "./pxSeparator";
import {
  Slider,
  // , InputNumber, Row, Col
} from "antd";
import "antd/dist/antd.css";
import InputTagRenderer from "../utils/inputRenderer";
import MyCustomerizedSlider, {
  PropertiesSlider,
} from "./utils/SliderForProperties";
import { connect } from "react-redux";
import DimensionWrapper from "./DimensionWrapper";
class MaterialSlider extends Component {
  state = { value: undefined };
  componentDidMount() {
    const { component, name, label } = this.props;
    let value = separator(component.properties.styles[name]);
    this.setState({ value: value });
  }
  componentDidUpdate(props, pro) {
    const { value: old } = this.state;
    const { component, name, label } = this.props;
    let value = separator(component.properties.styles[name]);
    if (old !== value) this.setState({ value });
  }
  render() {
    const { name, designer } = this.props;
    let value = this.state.value;
    if (value === undefined) value = 0;
    return (
      <React.Fragment>
        {/* <div className="row"> */}
        {/* <div className="col-md-7">
            <MyCustomerizedSlider
              myColor={designer.color}
              max={200}
              name={name}
              onChange={(e, number) => this.handlingSelect(number)}
              value={value}
            />
          </div> */}
        {/* <div className="col-md-5"> */}
        {/* <InputTagRenderer
          value={value}
          type="number"
          onChange={(e) => this.handlingSelect(e.target.value)}
        /> */}
        {/* </div> */}
        {/* </div> */}
        <DimensionWrapper title={"Width"} property={"width"}></DimensionWrapper>
      </React.Fragment>
    );
  }
  handlingSelect = (number) => {
    //(number);
    window.store.dispatch(
      styleChanged(number + "px", [this.props.name], this.props.component)
    );
  };
}
const mapStateToProps = (state) => state;

export default connect(mapStateToProps)(MaterialSlider);
