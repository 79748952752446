/** @format */

const screenSizes = [{
        width: 280,
        height: 280,
        label: "Android Wear Square (280x280:hdpi)",
        value: "280X280",
    },
    {
        width: 320,
        height: 320,
        label: "Android Wear Round (320x320:hdpi)",
        value: "320x320",
    },
    {
        width: 384,
        height: 640,
        label: "Nexus 4 ",
        value: "384x640",
    },

    {
        width: 360,
        height: 640,
        label: "Nexus 5 According To Chrome",
        value: "360x640",
    },
    {
        width: 412,
        height: 732,
        label: "Nexus 5x According To Chrome",
        value: "412x732",
    },
    {
        width: 412,
        height: 732,
        label: "Nexus 6 According To Chrome",
        value: "412x732",
    },
    {
        width: 412,
        height: 732,
        label: "Nexus 6P According To Chrome",
        value: "412x732",
    },
    {
        width: 600,
        height: 1200,
        label: "Nexus 7 According To Chrome",
        value: "600x960",
    },
    {
        width: 411,
        height: 731,
        label: "PIXEL 2 According To Chrome",
        value: "411x731",
    },
    {
        width: 411,
        height: 823,
        label: "PIXEL 2 XL According To Chrome",
        value: "411x823",
    },
    {
        width: 600,
        height: 960,
        label: 'Nexus 7 (7.0",1200x1920:xhdpi)',
        value: "1200x1920",
    },
    {
        width: 384,
        height: 640,
        label: 'Nexus 4 (4.7",768x1280:xhdpi)',
        value: "768x1280",
    },
];

function calculateScale(ppi, factor) {
    return (90 / ppi) * factor;
}
export default screenSizes;
export const screenOrientation = {
    Vertical: "Vertical",
    Horizontal: "Horizontal",
};