import factories from "../factories";
import {
    convertJSONToComponents
} from "./utils";
const text =
  "On the other hand, we denounce with righteous indignation and dislike men who are so beguiled and demoralized by the charms of pleasure of the moment, so blinded by desire, that they cannot foresee the pain and trouble that are bound to ensue; and equal blame belongs to those who fail in their duty through weakness of will,";
const fifa = factories.baseFactory(
  "Base",
  factories.initFactory(
    "App",
    [
      factories.activityFactory(
        "Main",
        factories.verticalFactory("base layout", [
          factories.textViewFactory("lblTitle", text),
          factories.buttonFactory("btnTitle", "Designer Sample"),
        ])
      ),
      factories.activityFactory(
        "AllMatch",
        factories.verticalFactory("base layout", [
          factories.listViewFactory(
            "AllMatchList",

            factories.verticalFactory("ListView Layout", [
              factories.horizontalFactory("11h1", [
                factories.verticalFactory("llv2", [
                  factories.textViewFactory(),
                  factories.textViewFactory(),
                  factories.textViewFactory(),
                ]),
                factories.verticalFactory("llv3", [
                  factories.textViewFactory(),
                  factories.textViewFactory(),
                ]),
              ]),
              factories.horizontalFactory("11h2", [
                factories.textViewFactory(),
                factories.textViewFactory(),
              ]),
              factories.horizontalFactory("11h3", [
                factories.textViewFactory(),
                factories.textViewFactory(),
              ]),
            ])
          ),
        ])
      ),
    ],
    factories.horizontalFactory("toolbar Layout", [
      factories.buttonFactory("btnToolbar", "Toggle Navigation"),
      factories.textViewFactory("btnToolbar1", "Surprise Me"),
    ]),
    factories.verticalFactory("Navigation Layout", [
      factories.textViewFactory("lblNavigation", "Navigation"),
      factories.buttonFactory("btnNavigation", "Home"),
      factories.buttonFactory("btnNavigation1", "All Matches"),
      factories.buttonFactory("btnNavigation2", "Settings"),
    ]),
    //below is for dialouge Boxes
    factories.horizontalFactory("DialogeBox", [
      factories.horizontalFactory("DialogeLayout", [
        factories.buttonFactory("btnNavigation2", "Settings"),
      ]),
      factories.horizontalFactory("DialogeLayout", []),
    ])
  )
);
const components = convertJSONToComponents(fifa);
////(fifa);
export default components;