import {
  COMPONENT_LINEARLAYOUT_VERTICAL
} from "../constants/typeConstants";
import common from "./common";
/**
 * factory function to produce Linear Vertical Component Object
 * @param {String} name 
 * @param {Array} children array
 */
export default function (name = "base", children = []) {
  let obj = {
    name: name,
    type: COMPONENT_LINEARLAYOUT_VERTICAL,
    children: children
  };

  obj = Object.assign(obj, common);
  delete obj.properties;
  obj.properties = {
    properties: {}
  }
  obj.properties.styles = {}
  return obj;
}