import produce from "immer"
import {
    SHOW_CSS_EDITOR
} from "../../constants/action-types"
// SHOW_CSS_EDITOR


export function cssEditor() {
    // alert("hi")
    window.store.dispatch({
        type: SHOW_CSS_EDITOR,

    })
}
export function cssEditorReducer(state, action) {
    const oldValue = state.showCssEditor;
    // alert(oldValue);
    return produce(state, draftState => {
        draftState.showCssEditor = !oldValue
    })
}