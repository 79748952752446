import {
    separator
} from "../pxSeparator";

export function getDimensionValue(length) {

    switch (length) {
        case "match_parent":
            return "100%";
        case "wrap_content":
            return "auto";
        default:
            return (length);
    }
}