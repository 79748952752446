/** @format */

import { STYLE_CHANGE } from "../../constants/action-types";

/**
 * change the style of the component
 * @param {*} index
 * @param {*} property
 * @param {*} propertyValue
 */
export function changeStyle(index, property, propertyValue) {
  //(index, property, propertyValue);
  window.store.dispatch({
    type: STYLE_CHANGE,
    payLoad: {
      index,
      property,
      propertyValue,
    },
  });
}

export function changeStyleReducer(state, action) {
  const { index, property, propertyValue } = action.payLoad;
  //("Action Payload:" + action.payLoad);
  //("I AM BEING CALLED");
  //   ("Logging Property:" + property);
  //(propertyValue);
  console.log("Loggin Value", propertyValue);
  return {
    ...state,
    components: state.components.map((item, i) => {
      if (i !== index) return item;
      else
        return {
          ...item,
          properties: {
            ...item.properties,
            styles: {
              ...item.properties.styles,

              [property]: propertyValue,
            },
          },
        };
    }),
  };
}