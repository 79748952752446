import portModels from "./portModels";
import PortDirection from "./portDirections";
import uuidv4 from "uuid/v4";
import {
  BLL_VARIABLE_TYPE_STRING,
  BLL_VARIABLE_TYPE_BOOLEAN,
  BLL_VARIABLE_TYPE_OBJECT
} from "./../variableTypes";
export const createPort = (
  model,
  label = null,
  direction = PortDirection.In,
  options = {}
) => {
  model = getDesignerPortModel(model);
  return Object.assign(
    {
      id: uuidv4(),
      model,
      label,
      in: direction,
      showLabel: true,
      enableDefault: false,
      defaultValue: ""
    },
    options
  );
};
export const flowPorts = () => {
  return { flowIn: flowInPort(), flowOut: flowOutPort() };
};
export const flowInPort = () =>
  createPort(portModels.flow, "In", PortDirection.In, {
    showLabel: true
  });
export const flowOutPort = () =>
  createPort(portModels.flow, "Out", PortDirection.Out, {
    showLabel: true
  });
export const loopBodyPort = () =>
  createPort(portModels.flow, "Loop Body", PortDirection.Out);
export const loopCompletedPort = () =>
  createPort(portModels.flow, "Completed", PortDirection.Out);
export const conditionPort = () =>
  createPort(
    portModels[BLL_VARIABLE_TYPE_BOOLEAN],
    "Condition",
    PortDirection.In,
    { enableDefault: true }
  );

const getDesignerPortModel = model => {
  model = model.toLowerCase();
  let models = Object.keys(portModels);
  return models.includes(model) ? model : BLL_VARIABLE_TYPE_OBJECT;
};
