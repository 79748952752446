import React from "react";
import { Icon, IconButton } from "@material-ui/core";

import { useDispatch, useSelector } from "react-redux";
import { toggleNavBar } from "./../../store/actions/navigation.actions";

function NavbarFoldedToggleButton(props) {
  const dispatch = useDispatch();
  const navbar = useSelector((state) => state.alpha.navbar);

  return (
    <IconButton
      className={props.className}
      onClick={() => {
        dispatch(toggleNavBar());
      }}
      color="inherit"
    >
      {props.children}
    </IconButton>
  );
}

NavbarFoldedToggleButton.defaultProps = {
  children: <Icon>arrow_back</Icon>,
};

export default NavbarFoldedToggleButton;
