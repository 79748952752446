/** @format */

import { useDrop } from "react-dnd";
import { useSelector } from "react-redux";
import {
  moveComponentByDraggingDown,
  moveComponentFromLayouts,
  moveComponentToEmptyLayout,
} from "../../../../../store/actions/action-functions/moveComponent";
import { toast } from "react-toastify";
import { isLayout } from "../../shared/utils";
import { addComponentByPosition } from "../../../../../store/actions/action-functions/addComponent";
import droppedComponent from "./determiningComponentDragged";
import { addBindings } from "../../../../../store/actions/action-functions/updateBinding";

export function useDropComponent(id, ref) {
  const { components, disableDrag } = useSelector((state) => state.designer);
  // //(;
  const [{ hovered, parent, droppedPosition }, drop] = useDrop({
    accept: "modules",
    drop: (item, monitor) => {
      // item gives what drag item is sending
      // ensure element is not already dropped somewhere else
      if (monitor.didDrop()) return;
      const { id: droppedId } = monitor.getItem();
      const monitorItem = monitor.getItem();
      //  Following Condition Performs Drag Drop For
      //  Navigation Tree or Component Hirarchy
      if (monitorItem.hasOwnProperty("from") && isLayout(components[id].type)) {
        moveComponentToEmptyLayout(droppedId, id);
      }
      //adding data bindings
      else if (monitorItem.hasOwnProperty("key")) {
        const {
          key,
          value,
          completeAdress,
          node_id,
          selectedPropertiesScreenID,
        } = monitorItem;
        addBindings(
          key,
          value,
          id,
          completeAdress,
          node_id,
          selectedPropertiesScreenID
        );
      }

      // movement in same layout
      else if (droppedId && isSibling(droppedId)) {
        moveComponentByDraggingDown(droppedId, id);
        return;
      }
      //new component to app
      else if (monitorItem.hasOwnProperty("name")) {
        // toast.info("Welcome To The App Mr. New Component");
        addingNewComponent(monitor);
      }
      //movement from one layout to another layout
      else if (!isSibling(droppedId) && !isLayout(components[id].type)) {
        moveComponentFromLayouts(droppedId, id);
      }
    },
    collect: (monitor) => ({
      hovered: monitor.isOver(),
    }),
  });
  return {
    drop,
    hovered,
    parent,
  };
  /**
   * This Function checks if source is dropped on
   * and element with in same layout
   */

  function isSibling(source) {
    return components[id].parent_id === components[source].parent_id;
  }
  function isNewComponent() {}

  function addingNewComponent(monitor) {
    const DRAGGED_POSITION = calculateWhereDropped(monitor);
    const INCOMING_COMPONENT = droppedComponent(monitor.getItem().name);

    if (DRAGGED_POSITION.draggingLeft) {
      addComponentByPosition(id, INCOMING_COMPONENT, "ABOVE");
      return;
    } else if (DRAGGED_POSITION.draggingRight) {
      addComponentByPosition(id, INCOMING_COMPONENT, "BELOW");
      return;
    }
  }

  function calculateWhereDropped(monitor) {
    if (!monitor.getClientOffset()) return {};
    const hoverBoundingRect = ref.current.getBoundingClientRect();
    const clientOffset = monitor.getClientOffset();
    const hoverClientX = clientOffset.x - hoverBoundingRect.left;
    const hoveredComponentCenter =
      (hoverBoundingRect.right - hoverBoundingRect.left) / 2;
    const leftwards = hoverClientX > hoveredComponentCenter;
    const rightwards = hoverClientX < hoveredComponentCenter;
    if (leftwards) {
      return {
        draggingLeft: true,
        draggingRight: false,
      };
    }
    if (rightwards) {
      return {
        draggingLeft: false,
        draggingRight: true,
      };
    }
  }
}
