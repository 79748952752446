import {
    BULK_STYLE_CHANGE
} from "../../constants/action-types";
import produce from "immer";

/**
 * change the style of the component
 * @param {*} index
 * @param {*} property'
 * @param {*} propertyValue
 */
export function changeStyleBulk(index, styleObject) {
                                                      //("Loggin Style Object")
                                                      //(styleObject)
                                                      window.store.dispatch({
                                                        type: BULK_STYLE_CHANGE,
                                                        payLoad: {
                                                          index,
                                                          styleObject,
                                                        },
                                                      });
                                                    }
export function changeStyleBulkReducer(state, action) {
    let tempStyles = state.components[action.payLoad.index].properties.styles;
    return produce(state, draftState => {
        draftState.components[action.payLoad.index].properties.styles = Object.assign({}, tempStyles, action.payLoad.styleObject)
    })
}