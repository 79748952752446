import React, { Component } from "react";
import EntitiesList from "./EntitiesList";
import { connect } from "react-redux";
import * as actions from "../../store/constants/schema-actions";
import ColoumnsEditor from "./ColoumnsEditor";
class SchemaDesigner extends Component {
  state = {};
  handleAddEntity = (name) => {
    //(name);
    window.store.dispatch({
      type: actions.ADD_ENTITY,
      payLoad: {
        name: name,
      },
    });
  };
  handleRemoveEntity = (index) => {
    ////(name);
    //244 237 210
    window.store.dispatch({
      type: actions.REMOVE_ENTITY,
      payLoad: index,
    });
  };
  render() {
    const { entities, selectedEntityIndex } = this.props.schema;
    const selectedEntity = entities[selectedEntityIndex];
    return (
      <React.Fragment>
        <div className="main-div">
          <div className="heading">
            <h2>
              <b>Schema Designing</b>
            </h2>
          </div>
          <div className="row">
            <div className="col-md-3">
              <EntitiesList
                entities={entities}
                onAddEntity={this.handleAddEntity}
                onRemoveEntity={this.handleRemoveEntity}
              />
              '
            </div>
            <div className="col-md-5">
              <ColoumnsEditor entity={selectedEntity} />
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
const mapStateToProps = state => {
  return state;
};
export default connect(mapStateToProps)(SchemaDesigner);
