import React from "react";
import {
  AppBar,
  useTheme,
  Button,
  Toolbar,
  Paper,
  Drawer,
  Hidden,
} from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Logo from "./Logo";
import NavbarFoldedToggleButton from "./NavbarFoldedToggleButton";
const navbarWidth = 280;
const useStyles = makeStyles((theme) => ({
  logoContainer: {
    display: "flex",
    flex: "1 1",
  },
  closeIcon: {
    width: "40px",
    height: "40px",
    padding: "0px",
  },
  navbarMobile: {
    display: "flex",
    overflow: "hidden",
    flexDirection: "column",
    width: navbarWidth,
    minWidth: navbarWidth,
    height: "100%",
    zIndex: 4,
    transition: theme.transitions.create(["width", "min-width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.shorter,
    }),
    boxShadow: theme.shadows[3],
  },
}));

const NavbarDrawer = () => {
  const classes = useStyles();
  const theme = useTheme();
  const dispatch = useDispatch();
  const navbar = useSelector((state) => state.alpha.navbar);
  return (
    <Hidden lgUp>
      <Drawer
        anchor="left"
        variant="temporary"
        open={navbar.mobileOpen}
        classes={{
          paper: classes.navbarMobile,
        }}
        // onClose={(ev) => dispatch(Actions.navbarCloseMobile())}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
      >
        <AppBar position="static" elevation={0} color="default">
          <Toolbar>
            <div className={classes.logoContainer}>
              <Logo />
            </div>
            <NavbarFoldedToggleButton className={classes.closeIcon} />
          </Toolbar>
        </AppBar>
        Navbar mobile
      </Drawer>
    </Hidden>
  );
};

export default NavbarDrawer;
