export const ON_CLICK_EVENT = "ON_CLICK_EVENT";
export const ON_LONG_CLICK_EVENT = "ON_LONG_CLICK_EVENT";
export const ON_FOCUS_CHANGE_EVENT = "ON_FOCUS_CHANGE_EVENT";
const componentEvents = {
  ON_CLICK_EVENT: {
    name: "onClick"
  },
  ON_LONG_CLICK_EVENT: {
    name: "onLongClick"
  },
  ON_FOCUS_CHANGE_EVENT: {
    name: "onFocusChange"
  }
};

export default componentEvents;
