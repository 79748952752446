/** @format */

import React from "react";
import inches from "./../inches.svg";
import publish from "./../publish.svg";
import { useSelector } from "react-redux";
import { storyMode } from "../../../store/actions/action-functions/enableStoryMode";
import { makeStyles, FormControlLabel, Switch } from "@material-ui/core";
import { IN_TRANSITION_MODE } from "../../../store/constants/action-types";

const DesignerMiniMenu = () => {
  const { storyMode: showStory, transitionMode } = useSelector((state) => state.designer);
  const handleChecking = (event) => {
    window.store.dispatch({
      type: IN_TRANSITION_MODE,
      payLoad: {
        mode: !transitionMode
      }
    });
  };
  return (
    <>
      <div>
        Transition Mode
        <FormControlLabel
          value={transitionMode}
          control={
            <Switch
              color="primary"
              checked={transitionMode}
              onChange={(e) => handleChecking(e)}
            />
          }
        ></FormControlLabel>
      </div>

      <img src={inches} alt="" style={{ position: "absolute", top: "10%" }} />
      <img src={publish} alt="" style={{ position: "absolute", bottom: "0" }} />
      {!transitionMode && <p
        style={{ position: "absolute", bottom: "60px", cursor: "pointer" }}
        onClick={(e) => storyMode(!showStory)}
      >
        Screens
      </p>}
    </>
  );
};

export default DesignerMiniMenu;
