/** @format */

import React from "react";

import DimensionWrapper from "./DimensionWrapper";
import { makeStyles } from "@material-ui/core";
import { useSelector } from "react-redux";

import { basicProps } from "../ComponentDesigner/ComponentViewer/shared/BasicProps";
import { separator } from "./pxSeparator";
import {
  TOGGLE_PADDING,
  TOGGLE_MARGIN,
} from "../../../store/constants/action-types";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "40px",
    textAlign: "center",
    border: 0,
    cursor: "pointer",
  },
}));
const ExtendedProperties = ({ title, property, toggle, icon }) => {
  const classes = useStyles();
  let { selectedPropertiesScreenID, components } = basicProps();
  let component = components[selectedPropertiesScreenID];

  let val = {};
  if (component.properties) {
    val = component.properties.styles[property];
  }
  const splittedValue = separator(val);
  val = Number(splittedValue) ? splittedValue : "";

  const displayIcon = icon ? "down" : "right";
  //(displayIcon);
  // const prop = () => (property == "padding" ? TOGGLE_PADDING : TOGGLE_MARGIN);

  return (
    <React.Fragment>
      <DimensionWrapper title={title} property={property}></DimensionWrapper>
      <td
        className={classes.root}
        onClick={() =>
          window.store.dispatch({
            type: toggle,
          })
        }
      >
        <i className={`fas fa-chevron-${displayIcon}  mt-2`}></i>
      </td>
    </React.Fragment>
  );
};

export default ExtendedProperties;
