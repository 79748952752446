import React, { Component } from "react";
import Input from "@material-ui/core/Input";
import ColorPicker from "material-ui-color-picker";
// https://www.npmjs.com/package/material-ui-color-picker
// import List from "@material-ui/core/List";
// import ListItem from "@material-ui/core/ListItem";
// import ListItemText from "@material-ui/core/ListItemText";
// import Collapse from "@material-ui/core/Collapse";
// import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
// import ListSubheader from "@material-ui/core/ListSubheader";
// import ExpandLess from "@material-ui/icons/ExpandLess";
// import ExpandMore from "@material-ui/icons/ExpandMore";

import {
  //PROPERTY_CHANGE,
  //ADD_MISSING_PROPERTY,
  STYLE_CHANGE,
  PROPERTY_CHANGE_NAME
} from "../../../store/constants/action-types";

class TagMethods extends Component {
  selectRender(options) {}

  renderColorPicker() {
    return (
      <ColorPicker
        name="color"
        defaultValue="#000"
        onChange={color => this.handleColorChange(color)}
        value={this.state.color}
      />
    );
  }

  // renderDropDown(dataToBeRenderedInsideList) {
  //   const { classes, listFor, component } = this.props;
  //   return (
  //     <List component="nav" className={classes.root}>
  //       <ListItem button>
  //         <ListItemText
  //           inset
  //           primary="Paddings"
  //           onClick={this.handleDropDown}
  //         />

  //         {this.state.listOpen ? <ExpandLess /> : <ExpandMore />}
  //       </ListItem>
  //       <Collapse in={this.state.listOpen} timeout="auto" unmountOnExit>
  //         <List component="div" disablePadding>
  //           {dataToBeRenderedInsideList.map(property => {
  //             return (
  //               <ListItem
  //                 className={classes.nested}
  //                 onChange={property => //(property)}
  //                 key={property}
  //               >
  //                 <ListItemSecondaryAction>
  //                   {this.inputRender({ property, component })}
  //                 </ListItemSecondaryAction>
  //               </ListItem>
  //             );
  //           })}
  //         </List>
  //       </Collapse>
  //     </List>
  //   );
  // }

  inputRender(name) {
    const { classes, property, component, ...rest } = this.props;
    ////("Here");
    return (
      <Input
        name={name ? name : property}
        onChange={
          property === "name" ? this.onNameChange : this.handleOnStyleChange
        }
        {...rest}
        value={component.properties.styles[name ? name : property]}
        className={classes.input}
      />
    );
  }

  // checkUndefined(value) {
  //   if (typeof value === undefined) return true;
  //   return false;
  // }

  onNameChange = e => {
    window.store.dispatch({
      type: PROPERTY_CHANGE_NAME,
      payLoad: {
        id: this.props.id,
        name: e.target.value
      }
    });
  };

  handleOnStyleChange = e => {
    //(e.target.name);
    const { component, property } = this.props;
    const value = e.target.value;
    window.store.dispatch({
      type: STYLE_CHANGE,
      payLoad: {
        index: component.id,
        propertyValue: value,
        property
      }
    });
    //("COMPONENT ID IS:" + component.id);
  };
  handleColorChange = color => {
    //("Color Changed");
    const { component, property } = this.props;
    window.store.dispatch({
      type: STYLE_CHANGE,
      payLoad: {
        index: component.id,
        propertyValue: color,
        property
      }
    });
    this.state.color = color;
  };
}
export default TagMethods;
