import React from "react";
import ReactDOM from "react-dom";

import store from "./store";
import configurations from "./configurations";
import * as serviceWorker from "./serviceWorker";
import { Provider } from "react-redux";
import "font-awesome/css/font-awesome.min.css";
import App from "./App";
import "./css/designer-navigation.css";
import AlphaApp from "./AlphaDndApp/AlphaApp";

//import store and set to window object as it is needed in many places

window.store = store;
//we may use configurations. Im still not sure about it.
window.configurations = configurations;
//load Root component and pass sthe store
// addFunction(functionTypes.FUNCTION_EVENT, 2, "ON_CLICK");
ReactDOM.render(<AlphaApp />, document.getElementById("root"));
// ReactDOM.render(
//   <Provider store={store}>
//     <App />
//   </Provider>,
//   document.getElementById("root")
// );

serviceWorker.unregister();
