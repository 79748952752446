import { ADD_NETWORK_OBJECT } from "./../../constants/action-types";
export function addMissingNetworkObject(index) {
  window.store.dispatch({
    type: ADD_NETWORK_OBJECT,
    payLoad: { index }
  });
}
export function addMissingNetworkObjectReducer(state, action) {
  const { index } = action.payLoad;
  return {
    ...state,
    components: state.components.map((item, i) => {
      if (i != index) return item;
      else
        return {
          ...item,
          properties: { ...item.properties, network: {} }
        };
    })
  };
}
