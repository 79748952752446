import components from "./components";

const configurations = {
  components: components,
  tree: {
    depth: 8
  }
};

export default configurations;
