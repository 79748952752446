import {
    API_SAVE_APP_JSON
} from "../../constants/api-action-types";
import axios from "axios";
import {
    LOAD_JSON_URL
} from "./../../constants/apiURLS";
import DesignerTreeHandler from "../../reducers/handlers/DesignerTreeHandler";
import {
    apiCallError
} from "./apiCallError";
import {
    loadingEnd
} from "./loading";
import {
    toast
} from "react-toastify";

export function apiSaveAppJSON(_id, components, isJSON = false) {
    window.store.dispatch({
        type: API_SAVE_APP_JSON,
        payLoad: {
            _id,
            components,
            isJSON
        }
    });
}

export function apiSaveAppJSONReducer(state, action) {
    const {
        _id,
        components,
        isJSON
    } = action.payLoad;
    let json = components;
    if (!isJSON) {
      // //();
      //("Investigating Components...");
      //(components);
      //("Ending Save");
      json = DesignerTreeHandler.ConvertToDesignerTree(components);
    }
    axios
      .put(LOAD_JSON_URL + _id, {
        appJSON: json,
        components: components,
      })
      .then(function (response) {
        //(response.data);
        toast.info("JSON Saved to Heroku");
        //loadDesignerFromJSONOObject(response.data);
      })
      .catch(apiCallError)
      .then(() => {
        loadingEnd();
      });
    return state;
}