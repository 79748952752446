import GenericService from "./../../services/GenericService";
import jwtDecode from "jwt-decode";
class AuthService extends GenericService {
  constructor() {
    super();
  }
  register = (data) => this.post("/users", data);
  signInWithEmailAndPassword = (email, password) =>
    new Promise((resolve, reject) => {
      this.post("auth", { email, password })
        .then((data) => {
          //(data);
          this.setSession(data.access_token);
          resolve(data);
        })
        .catch((err) => {
          //(err);
          reject(err.response.data);
        });
    });

  setSession = (access_token) => {
    if (access_token) {
      localStorage.setItem("jwt_access_token", access_token);
    } else {
      localStorage.removeItem("jwt_access_token");
    }
  };
  isLoggedin() {
    if (localStorage.getItem("jwt_access_token")) return true;
    else return false;
  }
  logout = () => {
    this.setSession(null);
  };
}

const authService = new AuthService();
export default authService;
