import React from "react";
import "./../showFewProperties.css";
const CompleteTable = ({ tableData }) => {
  return (
    <table className="table table-fixed table-center zero_padding">
      <tbody>
        {tableData.map((row, index) => (
          <tr key={index + "_" + row}>
            {Object.keys(row).map((i, k) => (
              <td key={k}>{row[i]}</td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default CompleteTable;
