import React from "react";
import { PropTypes } from "prop-types";
import clsx from "clsx";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import ListItemText from "@material-ui/core/ListItemText";
import Select from "@material-ui/core/Select";
import Checkbox from "@material-ui/core/Checkbox";
import Chip from "@material-ui/core/Chip";
import TextField from "@material-ui/core/TextField";
const useStyles = makeStyles(theme => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120
    // maxWidth: 100%
  },
  chips: {
    display: "flex",
    flexWrap: "wrap"
  },
  chip: {
    margin: 2
  },
  noLabel: {
    marginTop: theme.spacing(3)
  }
}));
function getStyles(option, values, theme, type) {
  return {
    fontWeight:
      values.findIndex(o => o.value == option.value) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium
  };
}
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250
    }
  }
};
const AlphaMultiSelect = ({ label, values, onChange, options, type }) => {
  const classes = useStyles();
  const theme = useTheme();
  return (
    <FormControl className={classes.formControl} fullWidth={true}>
      <InputLabel id="demo-mutiple-name-label">{label}</InputLabel>
      <Select
        // labelId="demo-mutiple-name-label"
        id="demo-mutiple-name"
        multiple
        value={values}
        onChange={onChange}
        input={<Input />}
        MenuProps={MenuProps}
        renderValue={selected =>
          getRenderValueBytype(selected, type, options, classes)
        }
      >
        {options.map(option => getMenyItemByType(type, option, values, theme))}
      </Select>
    </FormControl>
  );
};
function getRenderValueBytype(selected, type, options, classes) {
  switch (type) {
    case "Chip":
      return (
        <div className={classes.chips}>
          {selected.map(value => (
            <Chip
              key={value}
              label={options.find(o => o.value == value).label}
              className={classes.chip}
            />
          ))}
        </div>
      );

    default:
      return selected
        .map(sel => {
          let opt = options.find(o => o.value == sel);
          return opt.label;
        })
        .join(", ");
  }
}
function getMenyItemByType(type, option, values, theme) {
  switch (type) {
    case "Tag":
      return (
        <MenuItem
          key={option.label}
          value={option.value}
          style={getStyles(option, values, theme)}
        >
          <Checkbox checked={values.findIndex(v => v == option.value) > -1} />
          <ListItemText primary={option.label} />
          {option.label}
        </MenuItem>
      );

    default:
      return (
        <MenuItem
          key={option.label}
          value={option.value}
          style={getStyles(option, values, theme)}
        >
          {option.label}
        </MenuItem>
      );
  }
}
AlphaMultiSelect.propTypes = {
  label: PropTypes.string,
  values: PropTypes.array,
  options: PropTypes.array,
  onChange: PropTypes.func,
  type: PropTypes.oneOf(["Text", "Tag", "Chip"])
};
// Specifies the default values for props:
AlphaMultiSelect.defaultProps = {
  type: "Text"
};
export default AlphaMultiSelect;
