import React, { Component } from "react";
import "./schema.css";
import * as actions from "../../store/constants/schema-actions";
class EntitiesList extends Component {
  state = {
    newEntity: ""
  };
  handleAddNewOnChange = e => {
    this.setState({ newEntity: e.target.value });
  };
  handleChangeEntity = index => {
    window.store.dispatch({
      type: actions.CHANGE_SELECTED_ENTITY,
      payLoad: index
    });
  };
  render() {
    const { entities, onAddEntity, onRemoveEntity } = this.props;
    return (
      <React.Fragment>
        <div className="left-side col-12">
          <div className="left-side-heading">
            <p>
              <b>Entities</b>
            </p>
          </div>
          <input
            type="text"
            className="add-input col-7"
            placeholder="New Entity"
            onChange={this.handleAddNewOnChange}
            value={this.state.newEntity}
          />
          <button
            className="btn btn-success btn-sm add-btn col-3"
            onClick={() => {
              this.setState({ newEntity: "" });
              onAddEntity(this.state.newEntity);
            }}
          >
            Add
          </button>
          <ul className="list-group">
            {entities.map((entity, index) => (
              <li
                key={index}
                className="list-group-item item"
                onClick={() => this.handleChangeEntity(index)}
              >
                {entity.name}
                <button
                  className="btn btn-default btn-sm float-right remove-btn"
                  onClick={() => onRemoveEntity(index)}
                >
                  Remove
                </button>
              </li>
            ))}
          </ul>
          {/* <li className="list-group-item add-btn-section item" key="actions"> */}
          {/* </li> */}
        </div>
      </React.Fragment>
    );
  }
}

export default EntitiesList;
