import {
  changeComponentAttribute
} from "./changeComponentAttribute";
/**
 * change the name of the component
 * @param {*} index
 * @param {*} name
 */
export function changeName(index, name) {
  changeComponentAttribute(index, "name", name);
}