import React, { Component } from "react";
import "bootstrap/dist/css/bootstrap.css";
import "font-awesome/css/font-awesome.min.css";
import "./Login.css";
import { toast } from "react-toastify";
import axios from "axios";
import { LOGIN_URL } from "../../../../../../store/constants/apiURLS";
import jwt from "jsonwebtoken";
axios.interceptors.response.use(
  function(response) {
    return response;
  },
  function(error) {
    return Promise.reject(error.response.data);
  }
);
class Login extends Component {
  state = {
    email: "",
    password: "",
    showPassword: false
  };
  handleEmail = event => {
    this.setState({ email: event.target.value });
  };
  handlePassword = event => {
    this.setState({ password: event.target.value });
  };
  handleClick = async e => {
    e.preventDefault();
    try {
      const { email, password } = this.state;
      const { data } = await axios.post(LOGIN_URL, { email, password });
      localStorage.setItem("token", data);
      this.props.history.push("/client-area");
    } catch (error) {
      toast.error(error);
    }
  };
  render() {
    return (
      <>
        <div className="login-page">
          <div class="d-flex justify-content-center h-100">
            <div className="card">
              <div className="card-header">
                <b>Log In</b>
              </div>
              <div className="card-body">
                <form onSubmit={this.handleClick}>
                  <div className="input-group form-group">
                    <div className="input-group-prepend extra-styles">
                      <span className="input-group-text">
                        <i className="fa fa-user" />
                      </span>
                    </div>
                    <input
                      type="email"
                      className="form-control"
                      placeholder="Email"
                      pattern="[^@\s]+@[^@\s]+\.[^@\s]+"
                      onChange={this.handleEmail}
                      required
                    />
                  </div>
                  <div className="input-group form-group">
                    <div className="input-group-prepend extra-styles">
                      <span className="input-group-text">
                        <i className="fa fa-key" />
                      </span>
                    </div>
                    <input
                      type={this.state.showPassword ? "password" : "text"}
                      className="form-control"
                      placeholder="password"
                      onChange={this.handlePassword}
                      style={{ borderRight: "none" }}
                    />
                    <div
                      onClick={e =>
                        this.setState({
                          showPassword: !this.state.showPassword
                        })
                      }
                      className="input-group-prepend  sample"
                    >
                      <span className="input-group-text">
                        <i
                          className={
                            this.state.showPassword
                              ? "fa fa-eye"
                              : "fa fa-eye-slash"
                          }
                        />
                      </span>
                    </div>
                  </div>
                  <div className="row align-items-center remember" />
                  <div className="form-group d-flex justify-content-center">
                    <button className="login_btn" type="submit">
                      <b>Login</b>
                    </button>
                  </div>
                </form>
                {/* <div className="d-flex justify-content-center social link">
                  <p>Login with:</p>
                  <span>
                    <i className="fa icon fa-facebook-square" />
                  </span>
                  <span>
                    <i className="fa icon fa-google-plus-square" />
                  </span>
                  <span>
                    <i className="fa icon fa-twitter-square" />
                  </span>
                  <span>
                    <i className="fa icon fa-github-square" />
                  </span>
                </div> */}
              </div>
              {/* <div className="card-footer">
                <div className="d-flex justify-content-center link">
                  <p>Don't have an account?</p>
                  <a href="/">Sign Up</a>
                </div>
                <div className="d-flex justify-content-center">
                  <a href="/">Forgot your password?</a>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Login;
