/** @format */

import React, { Component } from "react";
import PropTypes from "prop-types";
import ComponentViewer from "./ComponentViewer";
import ComponentViewerSelector from "./ComponentViewerSelector";
import { DropTarget, DragSource } from "react-dnd";
import {
  dropSpecs,
  dropCollect,
  dragSpec,
  dragCollect,
} from "./utils/dndForHorizentalLayouts";

import flow from "lodash/flow";
import { connect } from "react-redux";
import { getDimensionValue } from "../../Properties/utils/changeToHtmlUnits";
import { toast } from "react-toastify";
import { basicProps } from "./shared/BasicProps";
import { changePropertiesComponent } from "../../../../store/actions/designerActions";

const ComponentViewerListView = ({
  id,
  connectDropTarget,
  connectDragSource,
  hover,
}) => {
  const basicObject = basicProps();
  const component = basicObject.components[id];
  const backgroundColor = hover
    ? "lightgrey"
    : component.properties.styles.backgroundColor;
  const style = {
    backgroundColor,
    ...component.properties.styles,
    border: "2px solid black",
    width: getDimensionValue(component.properties.styles.width),
    height: getDimensionValue(component.properties.styles.height),
  };
  const list = loopIt(basicObject.components, component);
  function loopIt(components, component) {
    let loop = [];
    const disableDrag = basicObject.disableDrag;
    if (component.children.length === 0) return [];
    for (let i = 0; i < 3; i++)
      loop.push(
        component.children.map((child, index) => (
          <ComponentViewerSelector
            key={index}
            components={components}
            component={components[child]}
            id={child}
            index={index}
            heightLightComponent={basicObject.heightLightComponent}
            disableDrag={disableDrag}
          />
        ))
      );
    return loop;
  }
  return connectDropTarget(
    <div
      onClick={(e) => {
        e.stopPropagation();
        if (basicObject.disableSelection) {
          toast.warn("Layouts Cannot Be Data Binded");
        }
        if (!basicObject.disableDrag && !basicObject.disableSelection)
          changePropertiesComponent(id);
      }}
      style={style}
    >
      {list.map((c) => c)}
    </div>
  );
};

export default flow(
  DropTarget("modules", dropSpecs, (connect, monitor) =>
    dropCollect(connect, monitor)
  ),
  DragSource("modules", dragSpec, (connect, monitor) =>
    dragCollect(connect, monitor)
  )
)(ComponentViewerListView);
