import { COMPONENT_DRAWABLE } from "../constants/typeConstants";
import common from "./common";
/**
 * factory function to produce Drawable Shapes Component Object
 * @param {String} name
 * @param {Array} children array
 */
export default function(name = "Drawable", children = []) {
  let obj = {
    name: name,
    type: COMPONENT_DRAWABLE,
    children: children
  };
  return Object.assign(obj, common);
}
