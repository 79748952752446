import common from "./common";
import {
    COMPONENT_INIT
} from "./../constants/typeConstants";
import activityFactory from "./activityFactory";
import horizontalFactory from "./horizontalFactory";

/**
 * factory function to create an app with toolbar and navigation
 * @param {String} name of the app
 * @param {Array} activities App must have some activities
 * @param {Object} toolbar
 * @param {Object} navigation
 */
export default function(
    name = "App",
    activities = [activityFactory()],
    toolbar = horizontalFactory(),
    navigation = horizontalFactory(),
    dialog = horizontalFactory(),
) {
    let obj = {
        name: name,
        type: COMPONENT_INIT,
        toolbar,
        navigation,
        dialog,
        children: activities
    };
    return Object.assign(obj, common);
}