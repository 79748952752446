import React, { Component } from "react";
import SingleKeyMapping from "./SingleKeyMapping";
class SingleObjectMapping extends Component {
  state = {};
  render() {
    const { objectKey, objectValue, selectedPropertiesScreenID } = this.props;
    if (objectValue == undefined) return null;
    const isObject = typeof objectValue === "object";
    //(isObject);
    return (
      <SingleKeyMapping objectKey={objectKey} objectValue={objectValue}>
        {isObject &&
          Object.keys(objectValue).map((keys, index) => (
            <SingleObjectMapping
              selectedPropertiesScreenID={selectedPropertiesScreenID}
              objectKey={keys}
              objectValue={objectValue[keys]}
            />
          ))}
      </SingleKeyMapping>
    );
  }
}

export default SingleObjectMapping;
