/** @format */

import React, { Component } from "react";
import { loadComponentJSON } from "../../store/actions/action-functions-api/loadComponent";
import { connect } from "react-redux";

import { DotLoader } from "react-spinners";
import { loadDesignerFromComponentsObject } from "../../store/actions/action-functions/loadDesignerFromJSONOObject";
import Designer from "./Designer";
// import { apiSaveAppJSON } from "./../../store/actions/action-functions-api/apiSaveAppJSON";
class AppDesignerLoader extends Component {
  state = {};
  componentDidMount() {
    const { id } = this.props.match.params;
    window.appId = id;
    const loadedApps = this.props.designer.loadedApps;
    const appAlreadyExists = loadedApps.find((app) => app._id == id);
    if (appAlreadyExists) {
      loadDesignerFromComponentsObject(appAlreadyExists.components, id);
    } else loadComponentJSON(id);
  }
  render() {
    const { id } = this.props.match.params;

    return (
      <div>
        {this.props.api.loading ? (
          <div style={{ position: "absolute", top: "50%", left: "50%" }}>
            <DotLoader
              sizeUnit={"px"}
              size={150}
              color={"#123abc"}
              loading={true}
            />
          </div>
        ) : (
          <div>
            <Designer id={id} />
          </div>
        )}
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return state;
};
export default connect(mapStateToProps)(AppDesignerLoader);
