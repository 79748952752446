import {
    themes
} from './../data/themes';
import * as actionTypes from './../constants/theme-actions';
const initialState = {
    themeCollection: themes,
    selectedTheme: 'Fifa'
}


export const themeReducer = (state = initialState, action) => {
    switch (action.type) {
        case (actionTypes.CHANGE_THEME):
            return {
                ...state, selectedTheme: action.payLoad
            };
        default:
            return state;
    }
}