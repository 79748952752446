/** @format */

import React, { useState, useRef } from "react";
import tempImage from "./images/temp_image_viewer.jpg";
import MaterialPopOver from "./Popover/MaterialPopOver";
import ButtonProperties from "./ButtonProperties";
import {
  dropSpecs,
  dropCollect,
  dragSpecs,
  dragCollect,
} from "./utils/dndMethods";
import { DragSource, DropTarget } from "react-dnd";
import "./ButtonStyles/buttonStyle.css";
import "./css/alignment.css";
import flow from "lodash/flow";
import splittingAndMakingObject from "./utils/splitingKeys";
import { highLightKey } from "../../../../store/actions/action-functions/addBindedId";
import { getDimensionValue } from "../../Properties/utils/changeToHtmlUnits";
import {
  manageBindedId,
  removeBindedId,
} from "../../../../store/actions/action-functions/manageBindedIds";
import { basicProps } from "./shared/BasicProps";
import { changePropertiesComponent } from "../../../../store/actions/designerActions";
import { useDraggableComponent } from "./utils/dragComponent";
import { useDropComponent } from "./utils/dropComponent";

const ComponentViewerImage = ({
  id,
  hover,
  connectDragSource,
  connectDropTarget,
}) => {
  const [state, setState] = useState({
    anchorEl: null,
  });
  const ref = useRef();
  const basicObject = basicProps();
  const component = basicObject.components[id];
  let styles = component.properties.styles;
  const alreadyBinded = basicObject.idsBinded.find((x) => x == component.id);
  const dragBehaviour = useDraggableComponent(id);
  const dropBehaviour = useDropComponent(id, ref);
  //   const component = basicObject.components[id];
  //   const { hovered } = dropBehaviour;
  const handleClose = (e) => {
    setState({ anchorEl: null });
  };

  const hanldeDoubleClick = (e) => {
    e.stopPropagation();
    setState({ anchorEl: e.currentTarget });
  };

  if (!Boolean(styles.src) && !Boolean(styles.src == "")) {
    styles = { ...styles, height: "100px", width: "100px" };
  }
  styles = {
    ...styles,
    height: getDimensionValue(styles.height),
    width: getDimensionValue(styles.width),
    opacity: hover ? 0.7 : 1,
  };
  dropBehaviour.drop(dragBehaviour.drag(ref));
  return (
    <React.Fragment>
      <img
        ref={ref}
        // ref={(instance) => {
        //   connectDragSource(instance);
        //   connectDropTarget(instance);
        // }}
        style={{
          ...styles,
          border:
            basicObject.heighLightComponent === id && basicObject.disableDrag
              ? "3px solid black"
              : styles.border,
        }}
        onDoubleClick={hanldeDoubleClick}
        onClick={(e) => {
          e.stopPropagation();
          if (
            basicObject.disableSelection &&
            component.id !== basicObject.idToBeBinded &&
            !alreadyBinded
          ) {
            manageBindedId(component.id);
          }
          if (
            basicObject.disableSelection &&
            component.id !== basicObject.idToBeBinded &&
            alreadyBinded
          ) {
            removeBindedId(component.id);
          }
          if (!basicObject.disableDrag && !basicObject.disableSelection)
            changePropertiesComponent(id);
          if (basicObject.disableDrag)
            highLightKey(splittingAndMakingObject(component.node_id));
        }}
        src={
          Boolean(component.properties.styles.src)
            ? component.properties.styles.src
            : tempImage
        }
        alt={component.properties.styles.altText}
      />

      {!basicObject.disableDrag && (
        <MaterialPopOver
          handleClose={handleClose}
          anchorEl={state.anchorEl}
          component={component}
        >
          <ButtonProperties component={component} />
        </MaterialPopOver>
      )}
    </React.Fragment>
  );
};

export default flow(
  DropTarget("modules", dropSpecs, (connect, monitor) =>
    dropCollect(connect, monitor)
  ),
  DragSource("modules", dragSpecs, (connect, monitor) =>
    dragCollect(connect, monitor)
  )
)(ComponentViewerImage);
