import {
    API_LOAD_JSON,
    API_LOAD_COMPLETED
} from "../../constants/api-action-types";
// import { LOAD_JSON_URL } from "../../constants/apiURLS";
import {
    apiCallError
} from "./apiCallError";
import {
    loadingEnd,
    loadingStart
} from "./loading";
import axios from "axios";
import {
    // loadDesignerFromJSONOObject,
    loadDesignerFromComponentsObject
} from "../action-functions/loadDesignerFromJSONOObject";
import produce from "immer";
import {
    APPS_URL
} from "./../../constants/apiURLS";
import {
    apiSaveAppJSON
} from "./apiSaveAppJSON";
import DesignerTreeHandler from "../../reducers/handlers/DesignerTreeHandler";
export function loadComponentJSON(_id) {
    loadingStart();
    window.store.dispatch({
        type: API_LOAD_JSON,
        payLoad: {
            _id: _id
        }
    });
    //("Launching Action");
}
export function loadCompleted(_id, name) {
    window.store.dispatch({
        type: API_LOAD_COMPLETED,
        payLoad: {
            _id: _id,
            name
        }
    });
}
export function loadCompletedReducer(state, action) {
    const {
        _id,
        name
    } = action.payLoad;
    return produce(state, (derivedState) => {
        derivedState.id = _id;
        derivedState.loaded = true;
        derivedState.loadedAppName = name;
    });
}

export function loadJSONReducer(state, action) {
  const { _id } = action.payLoad;

  axios
    .get(APPS_URL + _id)
    .then(function (response) {
      if (response.data.components === null || !response.data.components) {
        apiSaveAppJSON(
          _id,
          DesignerTreeHandler.convertJSONToComponents(response.data.appJSON)
        );
        return state;
      }
      loadDesignerFromComponentsObject(response.data.components, _id);
      loadCompleted(_id, response.data.name);
      loadingEnd();
    })
    .catch(apiCallError);
  return state;
}
