/** @format */

import React, { useState, useEffect } from "react";
import AceEditor from "react-ace";

import "ace-builds/src-noconflict/mode-css";
import "ace-builds/src-noconflict/theme-github";
// import fs from "browserify-fs";
import { Button } from "@material-ui/core";
import { addCustomCss } from "../../../store/actions/action-functions/cssCodeEditor";
import { toast } from "react-toastify";
const CssEditor = ({ code }) => {
  const [cssCode, setCssCode] = useState(code);
  //   useEffect(() => {

  //     return () => {
  //       "";
  //     };
  //   }, [1]);
  const saveTheCode = () => {
    //(cssCode);
    addCustomCss(cssCode);
    toast.success("Code Added Successfully");
  };
  return (
    <>
      <AceEditor
        placeholder="Write Your Own Custom Css"
        mode="css"
        theme="github"
        name="blah2"
        onChange={(value) => {
          setCssCode(value);
          addCustomCss(value);
        }}
        fontSize={16}
        showPrintMargin={true}
        showGutter={true}
        highlightActiveLine={true}
        value={cssCode}
        setOptions={{
          enableBasicAutocompletion: true,
          enableLiveAutocompletion: true,
          enableSnippets: true,
          showLineNumbers: true,
          tabSize: 2,
        }}
      />
    </>
  );
};

export default CssEditor;
