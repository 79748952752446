import produce from "immer";
import nodeModels from "../../components/BusinessLogicLayer/utils/constants/nodeModels";
import { toast } from "react-toastify";

export default class BLLreduxHandlerReducer {
  //this will start loading App
  static loadAppReducer(state, action) {
    const { id } = action.payLoad;
    return produce(state, (draftState) => {
      draftState.apps[id] = {};
      draftState.apps[id].fatching = true;
      draftState.apps[id].fetched = false;
      draftState.apps[id].activeFunction = null;
      draftState.apps[id].globalVariables = [];
      draftState.apps[id].bllFunctions = [];
    });
  }
  static bllGlobalVarsReceived(state, action) {
    const { id, globalVariables } = action.payLoad;
    return produce(state, (draftState) => {
      draftState.apps[id].globalVariables = globalVariables;
    });
  }
  static bllLocalVarsReceived(state, action) {
    //("Updating Local Variables");
    const { appId, variables, funcId } = action.payLoad;
    let identifier = "";
    Object.keys(state.apps[appId].bllFunctions).map((key) => {
      if (state.apps[appId].bllFunctions[key]._id == funcId) {
        identifier = key;
        return;
      }
    });

    return produce(state, (draftState) => {
      draftState.apps[appId].bllFunctions[
        identifier
      ].localVariables = variables;
    });
  }
  static bllReceived(state, action) {
    const { data } = action.payLoad;
    const id = data._id;
    // //(data);
    return produce(state, (draftState) => {
      // draftState.apps[id] = {};
      draftState.apps[id].fatching = false;
      draftState.apps[id].fetched = true;
      // draftState.apps[id].activeFunction = null;
      draftState.apps[id].globalVariables = data.globalVariables;
      draftState.apps[id].bllFunctions = {};
      data.bllFunctions.map((func) => {
        draftState.apps[id].bllFunctions[func.identifier] = func;
      });
      draftState.apps[id].bllLoadedFunctions = data.bllLoadedFunctions;
      draftState.apps[id].activeFunction = data.activeFunction;
      // //(data);
      if (!data.activeFunction && data.bllFunctions.length > 0) {
        draftState.apps[id].activeFunction = data.bllFunctions[0].identifier;
        draftState.apps[id].bllLoadedFunctions = [
          draftState.apps[id].activeFunction,
        ];
      }
    });
  }

  static bllFuncCreated(state, action) {
    const { appId, data } = action.payLoad;
    return produce(state, (draftState) => {
      draftState.apps[appId].bllFunctions[data.identifier] = data;
      draftState.apps[appId].activeFunction = data.identifier;
      draftState.apps[appId].bllLoadedFunctions.push(data.identifier);
    });
  }
  static bllFuncDeleted(state, action) {
    const { appId, func } = action.payLoad;
    return produce(state, (draftState) => {
      delete draftState.apps[appId].bllFunctions[func.identifier];
      // //(draftState.apps[appId].bllLoadedFunctions);
      let indexToRemove = draftState.apps[appId].bllLoadedFunctions.indexOf(
        func.identifier
      );
      if (indexToRemove >= 0) {
        //func is loaded so remove it from loaded functions
        draftState.apps[appId].bllLoadedFunctions.splice(indexToRemove, 1);
        if (indexToRemove > 0)
          draftState.apps[appId].activeFunction =
            draftState.apps[appId].bllLoadedFunctions[indexToRemove - 1];
        if (
          indexToRemove == 0 &&
          draftState.apps[appId].bllLoadedFunctions.length > 0
        )
          draftState.apps[appId].activeFunction =
            draftState.apps[appId].bllLoadedFunctions[0];
        if (
          indexToRemove == 0 &&
          draftState.apps[appId].bllLoadedFunctions.length == 0
        ) {
          draftState.apps[appId].activeFunction = null;
        }
      }
    });
  }
  static bllFuncActiveChanged(state, action) {
    const { appId, activeFunction } = action.payLoad;
    return produce(state, (draftState) => {
      draftState.apps[appId].activeFunction = activeFunction;
      let funcs = [];
      Object.keys(draftState.apps[appId].bllLoadedFunctions).map((key) =>
        funcs.push(draftState.apps[appId].bllLoadedFunctions[key])
      );
      let index = funcs.indexOf(activeFunction);
      if (index < 0) funcs.push(activeFunction);
      draftState.apps[appId].bllLoadedFunctions = funcs;
    });
  }
  static updateModelToFuncReducer(state, action) {
    const { appId, funcIdentifier, model, graph } = action.payLoad;
    return produce(state, (draftState) => {
      if (draftState.apps[appId].bllFunctions[funcIdentifier]) {
        draftState.apps[appId].bllFunctions[funcIdentifier].model = model;
        draftState.apps[appId].bllFunctions[funcIdentifier].graph = graph;
      }
    });
  }
  static newFunc(state, action) {
    return produce(state, (draftState) => {});
  }
}
