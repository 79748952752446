const FUNCTION_SCRIPT = "FUNCTION_SCRIPT";
const FUNCTION_EVENT = "FUNCTION_EVENT";
const FUNCTION_QUERY = "FUNCTION_QUERY";
const FUNCTION_NETWORK = "FUNCTION_NETWORK";
const FUNCTION_BUILTIN = "FUNCTION_BUILTIN";
const FUNCTION_COMPONENT = "FUNCTION_COMPONENT";
// built in functions dont have model attribute
// instead they have inputs and outputs
const functionTypes = {
  FUNCTION_SCRIPT,
  FUNCTION_EVENT,
  FUNCTION_QUERY,
  FUNCTION_NETWORK,
  FUNCTION_BUILTIN,
  FUNCTION_COMPONENT
};
export default functionTypes;
