/** @format */

import React from "react";
import { Router } from "react-router-dom";
import AlphaContext from "./AlphaContext";
import Provider from "react-redux/es/components/Provider"; //for high performance
import store from "./../store/index";
import * as history from "history";
import { DndProvider } from "react-dnd";
import Backend from "react-dnd-html5-backend";
import AlphaLayout from "./pages/AlphaLayout";
import AlphaTheme from "./Theme/AlphaTheme";
import AlphaBackdrop from "./shared-components/AlphaBackdrop";
const AlphaApp = () => {
  return (
    // <AlphaContext>
    <Provider store={store}>
      <DndProvider backend={Backend}>
        <Router history={history.createBrowserHistory()}>
          <AlphaTheme>
            <AlphaLayout />
            <AlphaBackdrop />
          </AlphaTheme>
        </Router>
      </DndProvider>
    </Provider>
    // </AlphaContext>
  );
};

export default AlphaApp;
