/** @format */

import React, { Component } from "react";
import { connect } from "react-redux";
import { apiSaveAppJSON } from "../../../store/actions/action-functions-api/apiSaveAppJSON";
class LoadedSaveButton extends Component {
  state = {};
  render() {
    return (
      <React.Fragment>
        {this.props.api.loaded && (
          <React.Fragment>
            <div style={{ width: "400px" }}>
              <span
                style={{
                  display: "inline-block",
                  width: "200px",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                {this.props.api.loadedAppName}
              </span>
              <button
                style={{ width: "150px" }}
                className="btn-sm save ml-1"
                onClick={() =>
                  apiSaveAppJSON(
                    this.props.api.id,
                    this.props.designer.components
                  )
                }
              >
                Save
              </button>
            </div>
          </React.Fragment>
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return state;
};
export default connect(mapStateToProps)(LoadedSaveButton);
