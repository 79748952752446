import produce from "immer";
import {
    CHANGE_SELECTED_DIALOGE
} from "../../constants/action-types";

/**
 * Change the current selected activity
 * @param {*} index of the new activity. SelectedScreenId would be changed in redux
 */
export function changeCurrentSelectedDialog(index) {
    window.store.dispatch({
        type: CHANGE_SELECTED_DIALOGE,
        payLoad: {
            index
        }
    });
}

export function changeCurrentSelectedDialogReducer(state, action) {
                                                                    let activityIndex =
                                                                      action
                                                                        .payLoad
                                                                        .index;
                                                                    // alert(activityIndex.id)
                                                                    // const dialogeContainerId = state.components[2].dialog;
                                                                    // let selectedId = activityIndex;
                                                                    // let myFlag = false;
                                                                    // alert(state.components[selectedId].parent_id)
                                                                    // while (selectedId !== 2) {
                                                                    //     //("Componients ID");
                                                                    //     //(state.components[selectedId].parent_id);
                                                                    //     if (state.components[selectedId].parent_id == dialogeContainerId) {
                                                                    //         myFlag = true;
                                                                    //         break;
                                                                    //     }
                                                                    //     selectedId = state.components[selectedId].parent_id;
                                                                    // }
                                                                    // if (myFlag) activityIndex = null;
                                                                    return produce(
                                                                      state,
                                                                      (
                                                                        drafState
                                                                      ) => {
                                                                        drafState.dialogID =
                                                                          activityIndex.id;
                                                                      }
                                                                    );
                                                                  }