import { produce } from "immer";
export function addBindings(
    key,
    value,
    fieldBind,
    completeAddress,
    node_id,
    selectedPropertiesScreenID
) {
    window.store.dispatch({
        type: "ADD_BINDING",
        payLoad: {
            key,
            fieldBind,
            value,
            completeAddress,
            node_id
        }
    });
}

export function addBindingsReducer(state, action) {
                                                    let oldBinds = JSON.parse(
                                                      state.components[
                                                        state
                                                          .selectedPropertiesScreenID
                                                      ].responseJson
                                                    );
                                                    //(oldBinds);
                                                    const {
                                                      key,
                                                      fieldBind,
                                                      completeAddress,
                                                      node_id,
                                                    } = action.payLoad;
                                                    const flatObject = completeAddress.split(
                                                      "."
                                                    );
                                                    var ob = oldBinds;
                                                    for (
                                                      var i = 0;
                                                      i < flatObject.length;
                                                      i++
                                                    ) {
                                                      if (
                                                        oldBinds.keys.hasOwnProperty(
                                                          key
                                                        )
                                                      ) {
                                                        if (
                                                          i ==
                                                          flatObject.length - 1
                                                        ) {
                                                          oldBinds.keys[
                                                            flatObject[i]
                                                          ].fieldId = fieldBind;
                                                          oldBinds = {
                                                            ...oldBinds.keys[
                                                              flatObject[i]
                                                            ].keys,
                                                          };
                                                          break;
                                                        } else {
                                                          oldBinds = {
                                                            ...oldBinds,
                                                            ...oldBinds.keys[
                                                              flatObject[i]
                                                            ].keys,
                                                          };
                                                        }
                                                      } else {
                                                        if (
                                                          i ==
                                                          flatObject.length - 1
                                                        ) {
                                                          oldBinds.keys[
                                                            flatObject[i]
                                                          ] = {
                                                            fieldId: fieldBind,
                                                            type: "String",
                                                          };
                                                          oldBinds = {
                                                            ...oldBinds.keys[
                                                              flatObject[i]
                                                            ].keys,
                                                          };
                                                          break;
                                                        } else {
                                                          if (
                                                            !Boolean(
                                                              oldBinds.keys[
                                                                flatObject[i]
                                                              ]
                                                            )
                                                          )
                                                            oldBinds.keys[
                                                              flatObject[i]
                                                            ] = {
                                                              keys: {
                                                                ...oldBinds
                                                                  .keys[
                                                                  flatObject[i]
                                                                ],
                                                              },
                                                            };
                                                        }
                                                      }
                                                      oldBinds =
                                                        oldBinds.keys[
                                                          flatObject[i]
                                                        ];
                                                    }
                                                    //("Field Binded Id:" + fieldBind);
                                                    return produce(
                                                      state,
                                                      (draftState) => {
                                                        draftState.components[
                                                          fieldBind
                                                        ].isStatic = false;
                                                        draftState.components[
                                                          fieldBind
                                                        ].node_id = node_id;
                                                        draftState.components[
                                                          state.selectedPropertiesScreenID
                                                        ].isScopeNetwork = true;
                                                        draftState.components[
                                                          state.selectedPropertiesScreenID
                                                        ].responseJson = JSON.stringify(
                                                          {
                                                            ...ob,
                                                            ...oldBinds,
                                                          }
                                                        );
                                                      }
                                                    );
                                                  }