/** @format */

import * as actionTypes from "../constants/action-types";
import * as designerActions from "../actions/designerActionsReducers.js";
import fifa from "../data/fifa";
import { changeName } from "./../actions/action-functions/changeName";
import { addScreen } from "./../actions/action-functions/addScreen";
import { duplicateComponentReducer } from "../actions/action-functions/duplicateComponent";
import {
  loadDesignerFromJSONOObjectReducer,
  loadDesignerFromComponentsReducer,
} from "../actions/action-functions/loadDesignerFromJSONOObject";
import {
  moveComponentUpReducer,
  moveComponentDownReducer,
  moveComponentByDraggingDownReducer,
  moveComponentByDraggingUpReducer,
  moveComponentFromLayoutsReducer,
  moveComponentToEmptyLayoutReducer,
} from "../actions/action-functions/moveComponent";
import { changeLabelReducer } from "./../actions/action-functions/changeLabel";
import { addBindingsReducer } from "../actions/action-functions/updateBinding";
import { changeStyleBulkReducer } from "../actions/action-functions/changeStyleBulk";
import { changeStyleObjectReducer } from "../actions/action-functions/changeStyleObject";
import {
  heightLightComponentReducer,
  highLightKeyReducer,
} from "../actions/action-functions/addBindedId";
import { addCustomClassNameReducer } from "../actions/action-functions/addCustomClassName";
import { makeNavigationReducer } from "../actions/action-functions/makeActivityNavigatable";
import {
  changeBackgoundColorReducer,
  resetBackgoundColorReducer,
} from "../actions/action-functions/changeBackgroundColor";
import Cookies from "universal-cookie";
import { revertCutStatusReducer } from "../actions/action-functions/cutComponent";
import { pasteComponentReducer } from "../actions/action-functions/pasteComponent";
import { makeMainReducer } from "../actions/action-functions/makeMainActivity";
import {
  startStopBindings,
  startStopBindingsReducer,
} from "../actions/action-functions/startStopBindingProcess";
import {
  managedBindedIdReducer,
  removeBindedIdsReducer,
} from "../actions/action-functions/manageBindedIds";
import {
  disableSelectionReducer,
  disableSelectionFnReducer,
} from "../actions/action-functions/disableSelection";
import { cssEditorReducer } from "../actions/action-functions/showCssEditor";
import { addCustomCssReducer } from "../actions/action-functions/cssCodeEditor";
import { changeCurrentSelectedDialogReducer } from "../actions/action-functions/selectedDialogeId";
import { storyModeReducer } from "../actions/action-functions/enableStoryMode";
import produce from "immer";
const cookies = new Cookies();
const initialState = {
  components: fifa,
  // components: newAppComponents,
  activeIndex: 3, //not being used
  //selectedActivityID: 3,
  selectedScreenID: 3,
  componentClipBoard: 0,
  selectedPropertiesScreenID: 4,
  selectedComponentId: 4,
  dragStart: false,
  isActivityBeingDragged: false,
  bindings: JSON.stringify({ keys: {}, isArray: true }),
  heightLightComponent: "",
  highLightKey: "",
  color: cookies.get("borderColor") || "#3472cd",
  disableSelection: false,
  idToBeBinded: null,
  idsBinded: [],
  disableDrag: false,
  showCssEditor: false,
  cssCode: "",
  storyMode: false,
  loadedApps: [],
  draggedComponent: null,
  showPaddings: true,
  showMargins: true,
  recentComponents: [],
  dragInProcess: false,
  transitionMode: false
};
export const designerReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.DRAG_START:
      return { ...state, dragStart: action.payLoad.dragStatus };
    case actionTypes.DRAG_END:
      return { ...state, dragStart: action.payLoad.dragStatus };
    case actionTypes.DRAG_ACTIVITY_START:
      return { ...state, isActivityBeingDragged: action.payLoad };
    case actionTypes.DRAG_ACTIVITY_END:
      return { ...state, isActivityBeingDragged: action.payLoad };
    case actionTypes.CHANGE_SELECTED_NODE:
      return designerActions.changeSelectedCompoentReducer(state, action);
    case actionTypes.CHANGE_SELECTED_NODE_PROPERTIES:
      return designerActions.changePropertiesComponentReducer(state, action);
    case actionTypes.ADD_ACTIVITY_ACTION:
      return addScreen();
    case actionTypes.ADD_COMPONENT_TO_TREE:
      return designerActions.addComponentReducer(state, action);
    case actionTypes.MOVE_COMPONENT_UP:
      return moveComponentUpReducer(state, action);
    case actionTypes.MOVE_COMPONENT_DOWN:
      return moveComponentDownReducer(state, action);
    case actionTypes.ADD_PROPERTIES_OBJECT:
      return designerActions.addPropertiesObjectToComponentReducer(
        state,
        action
      );
    case actionTypes.PROPERTY_CHANGE_NAME:
      changeName(action.payLoad.id, action.payLoad.name);
      return state;
    case actionTypes.DUPLICATE_COMPONENT_ACTION:
      return duplicateComponentReducer(state, action);
    case actionTypes.CHANGE_COMPONENT_ATTRIBUTE:
      return designerActions.changeComponentAttributeReducer(state, action);
    case actionTypes.FETCH_RESPONSE:
      return designerActions.fetchResponseReducer(state, action);
    case actionTypes.PROPERTY_CHANGE:
      return designerActions.changePropertyReducer(state, action);
    case actionTypes.STYLE_CHANGE:
      return designerActions.changeStyleReducer(state, action);
    case actionTypes.ADD_MISSING_PROPERTY:
      return designerActions.changePropertyReducer(state, action);
    case actionTypes.ADD_NETWORK_OBJECT:
      return designerActions.addMissingNetworkObjectReducer(state, action);
    case actionTypes.ADD_STYLES_OBJECT:
      return designerActions.addStylesObjectToComponentReducer(state, action);
    case actionTypes.ADD_NETWORK_OBJECT_PROPERTY:
      return designerActions.addStylesObjectToComponentReducer(state, action);
    case actionTypes.DELETE_COMPONENT_FROM_TREE:
      return designerActions.deleteComponentReducer(state, action);
    case actionTypes.CHANGE_SELECTED_ACTIVITY:
      return designerActions.changeSelectedActivityReducer(state, action);
    case actionTypes.CUT_COMPONENT_ACTION:
      return designerActions.cutComponentReducer(state, action);
    case actionTypes.LOAD_DESIGNER_FROM_JSON_OBJECT:
      return loadDesignerFromJSONOObjectReducer(state, action);
    case actionTypes.LOAD_DESIGNER_FROM_COMPONENTS:
      return loadDesignerFromComponentsReducer(state, action);
    //added by osama inayat
    case "ADD_BINDING":
      return addBindingsReducer(state, action);
    case "EXCHANGE_POSITIONS_UP":
      return moveComponentByDraggingUpReducer(state, action);
    case "EXCHANGE_POSITIONS_DOWN":
      return moveComponentByDraggingDownReducer(state, action);
    case actionTypes.CHANGE_LABEL:
      return changeLabelReducer(state, action);
    case "MOVE_FROM_LAYOUT":
      return moveComponentFromLayoutsReducer(state, action);
    case "MOVE_TO_EMPTY_LAYOUT":
      return moveComponentToEmptyLayoutReducer(state, action);
    case actionTypes.MOVE_FROM_LAYOUT_TO_OTHER:
      return state;

    case actionTypes.BULK_STYLE_CHANGE:
      return changeStyleBulkReducer(state, action);
    case actionTypes.HEIGHLIGHT:
      return heightLightComponentReducer(state, action);
    case actionTypes.HIGHLIGHT_KEY:
      return highLightKeyReducer(state, action);
    case actionTypes.STYLE_OBJECT_CHANGE:
      return changeStyleObjectReducer(state, action);
    case actionTypes.ADD_CUSTOM_CLASS:
      return addCustomClassNameReducer(state, action);
    case actionTypes.MAKE_NAVIGATABLE_ACTIVITY:
      return makeNavigationReducer(state, action);
    case actionTypes.CHANGE_BACKGROUND_COLOR:
      return changeBackgoundColorReducer(state, action);
    case actionTypes.RESET_BACKGROUND_COLOR:
      return resetBackgoundColorReducer(state, action);
    case actionTypes.CUT_PASTE_SUCCESSFULL:
      return revertCutStatusReducer(state, action);
    case actionTypes.EXECUTE_PASTE_ACTION:
      return pasteComponentReducer(state, action);
    case actionTypes.MAKE_MAIN_ACTIVITY:
      return makeMainReducer(state, action);
    case actionTypes.START_BINDING_PROCESS:
      return startStopBindingsReducer(state, action);
    case actionTypes.BINDED_IDS:
      return managedBindedIdReducer(state, action);
    case actionTypes.REMOVE_BINDED_IDS:
      return removeBindedIdsReducer(state, action);
    case actionTypes.DISABLE_SELECTING:
      return disableSelectionFnReducer(state, action);

    case actionTypes.SHOW_CSS_EDITOR:
      return cssEditorReducer(state, action);
    case actionTypes.ADD_CUSTOM_CSS:
      return addCustomCssReducer(state, action);

    case actionTypes.CHANGE_SELECTED_DIALOGE:
      return changeCurrentSelectedDialogReducer(state, action);
    case actionTypes.STORY_MODE:
      return storyModeReducer(state, action);
    case "DRAGGING_STARTED":
      return produce(state, (draftState) => {
        draftState.draggedComponent = action.payLoad.name;
      });
    case "DRAGGING_ENDED":
      return produce(state, (draftState) => {
        draftState.draggedComponent = null;
      });
    case actionTypes.TOGGLE_PADDING:
      return produce(state, (draftState) => {
        draftState.showPaddings = !draftState.showPaddings;
      });
    case actionTypes.TOGGLE_MARGIN:
      console.log("HELLOW");
      return produce(state, (draftState) => {
        draftState.showMargins = !draftState.showMargins;
      });
    case actionTypes.UPDATE_RECENT_COMPONENTS:
      return produce(state, (draftState) => {
        const alreadyAdded = draftState.recentComponents.indexOf(
          action.component
        );
        if (alreadyAdded >= 0) {
          draftState.recentComponents.splice(alreadyAdded, 1);
          draftState.recentComponents.splice(0, 0, action.component);
        } else {
          if (draftState.recentComponents.length >= 6)
            draftState.recentComponents.length = 5;
          draftState.recentComponents.splice(0, 0, action.component);
        }
      });
    case actionTypes.ENABLE_TRANSITION_ACTIVITY:
      return produce(state, (draftState) => {
        draftState.components[
          draftState.selectedPropertiesScreenID
        ].isActivityTransition = action.payLoad.status;
        draftState.components[
          draftState.selectedPropertiesScreenID
        ].isTransitionAttached = action.payLoad.status;
      });
    case actionTypes.SET_TRANSITION_TARGET:
      return produce(state, (draftState) => {
        draftState.components[
          draftState.selectedPropertiesScreenID
        ].transitionTarget = action.payLoad.name;
      });
    case actionTypes.DRAG_IN_PROGRESS:
      return produce(state, (draftState) => {
        console.log("DRAG_IN_PROGRESS", action.payLoad.isDragging);
        draftState.dragInProcess = action.payLoad.isDragging;
      })
    case actionTypes.IN_TRANSITION_MODE:
      return produce(state, (draftState) => {
        draftState.transitionMode = action.payLoad.mode;
      })
    default:
      return state;
  }
};
// CUT_PASTE_SUCCESSFULL
//export designerReducer;
