import {
    DISABLE_SELECTING
} from "../../constants/action-types";
import produce from "immer"
export function disableSelectionFn(value) {
    window.store.dispatch({
        type: DISABLE_SELECTING,
        payLoad: {
            value
        }
    })
}
export function disableSelectionFnReducer(state, action) {
    return produce(state, draftState => {
                                          draftState.disableDrag =
                                            action.payLoad.value;
                                          //(draftState.disableDrag);
                                        })
}