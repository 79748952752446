import {
  MOVE_COMPONENT_UP,
  MOVE_COMPONENT_DOWN
} from "./../../constants/action-types";
import produce from "immer";
import update from "immutability-helper";

export function moveComponentUp(id) {
  window.store.dispatch({
    type: MOVE_COMPONENT_UP,
    payLoad: {
      id
    }
  });
}
/**
 * * FUNCTION BELOW IS WRITTEN BY OSAMA INAYAT  */
export function moveComponentByDraggingUp(
  firstComponentId,
  secondComponentId,
  position = {}
) {
  window.store.dispatch({
    type: "EXCHANGE_POSITIONS_UP",
    payLoad: {
      firstComponentId,
      secondComponentId,
      position
    }
  });
}

export function moveComponentByDraggingDown(
  firstComponentId,
  secondComponentId
) {
  window.store.dispatch({
    type: "EXCHANGE_POSITIONS_DOWN",
    payLoad: {
      firstComponentId,
      secondComponentId
    }
  });
}
export function moveComponentFromLayouts(
  firstComponent,
  secondComponent,
  position = {}
) {
  window.store.dispatch({
    type: "MOVE_FROM_LAYOUT",
    payLoad: {
      firstComponent,
      secondComponent,
      position
    }
  });
}
export function moveComponentToEmptyLayout(component, layout) {
  window.store.dispatch({
    type: "MOVE_TO_EMPTY_LAYOUT",
    payLoad: {
      component,
      layout
    }
  });
}
export function moveComponentToEmptyLayoutReducer(state, action) {
  //("I M HERE AND FINDING WHERE I AM LOST");
  const componentId = action.payLoad.component;
  const layoutId = action.payLoad.layout;
  const position = action.payLoad.position;
  const componentParentId = state.components[componentId].parent_id;
  const layoutComponent = state.components[layoutId];
  const parentComponent = state.components[componentParentId];
  const componentIndex = state.components[componentParentId].children.indexOf(
    componentId
  );
  if (layoutComponent.children.length > 0) {
    return produce(state, (drafState) => {
      drafState.components[componentParentId].children.splice(
        componentIndex,
        1
      );
      drafState.components[layoutComponent.id].children.splice(
        drafState.components[layoutComponent.id].children.length - 1,
        0,
        componentId
      );
      drafState.components[componentId].parent_id = layoutId;
    });
  } else
    return produce(state, (drafState) => {
      drafState.components[componentParentId].children.splice(
        componentIndex,
        1
      );
      drafState.components[layoutComponent.id].children.splice(
        0,
        0,
        componentId
      );
      drafState.components[componentId].parent_id = layoutId;
    });
}
export function moveComponentFromLayoutsReducer(state, action) {
  const firstComponentId = action.payLoad.firstComponent;
  const secondComponentId = action.payLoad.secondComponent;
  const firstComponent = state.components[firstComponentId];
  const secondComponent = state.components[secondComponentId];
  const firstComponentParent =
    state.components[state.components[firstComponent.id].parent_id];
  const firstComponentIndex = firstComponentParent.children.indexOf(
    firstComponentId
  );
  const secondComponentParent =
    state.components[state.components[secondComponent.id].parent_id];
  const secondComponentIndex = secondComponentParent.children.indexOf(
    secondComponentId
  );
  return produce(state, (drafState) => {
    drafState.components[firstComponentParent.id].children.splice(
      firstComponentIndex,
      1
    );
    drafState.components[secondComponentParent.id].children.splice(
      secondComponentIndex,
      0,
      firstComponentId
    );
    drafState.components[firstComponentId].parent_id = secondComponentParent.id;
    // drafState.components[firstComponentId].properties.styles = Object.assign(
    //   {},
    //   drafState.components[firstComponentId].properties.styles,
    //   {
    //     position: "absolute",
    //     top: position.top,
    //     left: position.left
    //   }
    // );
  });
}

// function checkingForSameParent(firstComponentId, secondComponentId, state) {
//   return (
//     state.components[firstComponentId].parent_id ===
//     state.components[secondComponentId].parent_id
//   );
// }

export function moveComponentByDraggingDownReducer(state, action) {
  // first is the one being dragged
  const firstComponentId = action.payLoad.firstComponentId;
  const secondComponentId = action.payLoad.secondComponentId;
  const firstComponent = state.components[firstComponentId];
  const secondComponent = state.components[secondComponentId];
  let parent = state.components[firstComponent.parent_id];
  // let secondComponentParent = state.components[secondComponent.parent_id];
  // Lets Check If Dragging In Same Layout Or To Other
  // const sameParent = checkingForSameParent(
  //   firstComponentId,
  //   secondComponentId,
  //   state
  // );
  // if (
  //   !sameParent &&
  //   !secondComponentParent.children.includes(firstComponentId)
  // ) {
  //   let components = { ...state.components };
  //   let firstComponentIndex = parent.children.indexOf(firstComponent.id);
  //   let secondComponentIndex = secondComponentParent.children.indexOf(
  //     secondComponent.id
  //   );
  //   if (secondComponentParent.children.indexOf(firstComponentId.id) < 0) {
  //     components[parent.id].children.splice(firstComponentIndex, 1);
  //     components[secondComponentParent.id].children.splice(
  //       secondComponentIndex,
  //       0,
  //       firstComponentId
  //     );
  //     return produce(state, drafState => {
  //       drafState.components = components;
  //     });
  //   }
  //   return state;
  // }

  let children = [...parent.children];
  // //("All Of My Children:" + children);
  let firstComponentIndex = children.indexOf(firstComponent.id);
  let secondComponentIndex = children.indexOf(secondComponent.id);
  var child = update(children, {
    $splice: [
      [firstComponentIndex, 1],
      [secondComponentIndex, 0, firstComponentId],
    ],
  });
  // //("After");
  // //(child);
  return produce(state, (drafState) => {
    drafState.components[parent.id].children = child;
  });
}

export function moveComponentByDraggingUpReducer(state, action) {
  const firstComponentId = action.payLoad.firstComponentId;
  const secondComponentId = action.payLoad.secondComponentId;
  const firstComponent = state.components[firstComponentId];
  const secondComponent = state.components[secondComponentId];
  const parent = state.components[firstComponent.parent_id];
  const position = action.payLoad.position;

  let children = [...parent.children];
  let firstComponentIndex = children.indexOf(firstComponent.id);
  let secondComponentIndex = children.indexOf(secondComponent.id);
  // //("Before");
  // //(children);
  let child = update(children, {
    $splice: [
      [firstComponentIndex, 1],
      [secondComponentIndex, 0, firstComponentId],
    ],
  });
  // //("After");
  // //(child);
  // children.splice(firstComponentIndex, 1);
  // children.splice(secondComponentIndex, 0, firstComponent.id);
  return produce(state, (drafState) => {
    drafState.components[parent.id].children = child;
    // drafState.components[firstComponentId].properties.styles = Object.assign({},
    // drafState.components[firstComponentId].properties.styles, {
    //   position: "absolute",
    //   top: position.top,
    //   left: position.left
    // }
    // );
  });
}

// CODE WRITTEN BY OSAMA INAYAT ENDS HERE
export function moveComponentDown(id) {
  window.store.dispatch({
    type: MOVE_COMPONENT_DOWN,
    payLoad: {
      id
    }
  });
}
export function moveComponentUpReducer(state, action) {
  const id = action.payLoad.id;
  const component = state.components[id];
  const parent = state.components[component.parent_id];
  let children = [...parent.children];
  let index = children.indexOf(component.id);
  if (index === 0) return state;
  children.splice(index, 1);
  children.splice(index - 1, 0, component.id);
  return produce(state, drafState => {
    drafState.components[parent.id].children = children;
  });
}
export function moveComponentDownReducer(state, action) {
  const id = action.payLoad.id;
  const component = state.components[id];
  const parent = state.components[component.parent_id];
  let children = [...parent.children];
  let index = children.indexOf(component.id);
  if (index === children.length - 1) return state;
  children.splice(index, 1);
  children.splice(index + 1, 0, component.id);
  return produce(state, drafState => {
    drafState.components[parent.id].children = children;
  });
}
