import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import { connect } from "react-redux";
import axios from "axios";
import "font-awesome/css/font-awesome.min.css";
import "./NavBar.css";
import { resetBackgroundColor } from "../../../../../store/actions/action-functions/changeBackgroundColor";
import { LOAD_JSON_URL } from "../../../../../store/constants/apiURLS";
import { toast } from "react-toastify";

import "bootstrap/js/dist/carousel";
class NavBar extends Component {
  state = {
    isFull: false
  };
  render() {
    const token = localStorage.getItem("token");
    const appId = localStorage.getItem("appId");
    if (this.props.designer.storyMode) return null;

    return (
      // <nav className="navbar navbar-expand-lg navbar-dark bg-dark nav-color top">
      <nav className="navbar navbar-expand-lg nav-color  navbar-fixed-top ">
        <NavLink className="navbar-brand heading" to="/">
          <h5>
            <span id="dnd">Alpha</span>DnD
          </h5>
        </NavLink>
        <button
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon" />
        </button>
        <div
          className="collapse navbar-collapse nav-bar-items"
          id="navbarSupportedContent"
        >
          {token ? (
            <ul className="navbar-nav mr-auto">
              {appId && (
                <li className="nav-item links">
                  <NavLink className="nav-link " to={"/designer/" + appId}>
                    Designer
                  </NavLink>
                </li>
              )}
              <li className="nav-item links">
                <NavLink className="nav-link " to="/schema-designer">
                  Schema
                </NavLink>
              </li>
              {/* {appId && (
                <li className="nav-item links">
                  <NavLink className="nav-link " to={"/json/" + appId}>
                    JSON
                  </NavLink>
                </li>
              )} */}

              <li className="nav-item links">
                <NavLink className="nav-link " to="/client-area">
                  Client Area
                </NavLink>
              </li>

              {/* <li className="nav-item">
                <NavLink
                  className="nav-link"
                  to="#"
                  onClick={() => document.body.requestFullscreen()}
                >
                  Full Screen
                </NavLink>
              </li> */}

              <li
                className="nav-item nav-link"
                onClick={() => resetBackgroundColor()}
                style={{ cursor: "pointer" }}
              >
                Default Theme
              </li>

              <li className="nav-item links">
                <NavLink
                  className="nav-link btn btn-sm"
                  to="#"
                  onClick={() => {
                    localStorage.clear();
                    window.location = "/login";
                  }}
                >
                  Logout
                </NavLink>
              </li>
            </ul>
          ) : (
            <ul className="navbar-nav mr-auto">
              <li className="nav-item links">
                <NavLink className="nav-link increase-width" to="/">
                  Home
                </NavLink>
              </li>
              <li className="nav-item links">
                <NavLink className="nav-link increase-width" to="/Pricing">
                  Pricing
                </NavLink>
              </li>
              <li className="nav-item links">
                <NavLink className="nav-link increase-width" to="/about">
                  About
                </NavLink>
              </li>
              <li className="nav-item links">
                <NavLink className="nav-link increase-width" to="/contact-us">
                  Contact Us
                </NavLink>
              </li>
              {/* <li className="nav-item">
                <NavLink
                  className="nav-link"
                  to="#"
                  onClick={() => document.body.requestFullscreen()}
                >
                  Full Screen
                </NavLink>
              </li> */}

              {/* <li className="nav-item">
                <NavLink
                  className="nav-link"
                  to="#"
                  onClick={() => resetBackgroundColor()}
                >
                  Default Theme
                </NavLink>
              </li> */}

              {/*  */}
              <li className="nav-item links">
                <NavLink
                  className="nav-link increase-width  btn btn-sm"
                  to="#"
                  onClick={() => {
                    localStorage.removeItem("token");
                    window.location = "/login";
                  }}
                >
                  Login
                </NavLink>
              </li>

              <li className="nav-item links">
                <NavLink
                  className="nav-link increase-width btn btn-sm"
                  to="#"
                  onClick={() => {
                    localStorage.removeItem("token");
                    window.location = "/signup";
                  }}
                >
                  Sign Up
                </NavLink>
              </li>

              {/*  */}
            </ul>
          )}
        </div>
      </nav>
    );
  }
  async generateApp() {
    // //();
    // const { id } = this.props;
    // try {
    //   const { data: appJson } = await axios.get(LOAD_JSON_URL + id);
    //   //(appJson);
    //   const { data: isSuccessful } = await axios.post(
    //     "http://localhost:8080/generateApp",
    //     {
    //       projectId: 1,
    //       userId: "osama",
    //       wbsNode: appJson
    //     }
    //   );
    // } catch (error) {
    //   toast.error("Something went Wrong Try Later");
    // }
  }
}
const mapStateToProps = state => state;
export default connect(mapStateToProps)(NavBar);
