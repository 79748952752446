import Immutable from "./Immutable";

export default class SchemaHandler {
    static addTable(state, name) {

        state = { ...state, entities: Immutable.insertItem(state.entities, { name: name, coloumns: [] }, state.entities.length) }
        return state;
    }
    static removeTable(state, index) {
        state = { ...state, entities: Immutable.removeItem(state.entities, index) };
        return state;
    }

}