import {
  COMPONENT_IMAGEVIEW
} from "../constants/typeConstants";
import common from "./common";
/**
 * factory function to produce Image Component Object
 * @param {String} name
 * @param {Array} children array
 */
export default function (name = "Image", children = []) {
  let obj = {
    name: name,
    type: COMPONENT_IMAGEVIEW,
    children: children
  };
  const defaultStyles = {
    width: "wrap_content",
    height: "wrap_content"
  }
  obj = Object.assign({}, obj, common);
  delete obj.properties;
  obj.properties = {
    properties: {}
  }
  obj.isStatic = false;
  obj.properties.styles = defaultStyles;
  return obj;
}