/** @format */

import React from "react";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme, props) => ({
  root: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    border: "2px solid #83C8F2",
    boxSizing: "border-box",
    overflow: "visible",
    zIndex: 1000000,
    pointerEvents: "none",
  },
  leftCorner: {
    top: 0,
    left: 0,
  },
  rightCorner: {
    top: 0,
    right: 0,
  },
  topCenter: {
    top: 0,
    left: "50%",
  },
  bottomCenter: {
    bottom: 0,
    left: "50%",
  },
  bottomLeftCorner: {
    bottom: 0,
    left: 0,
  },
  bottomRightCorner: {
    bottom: 0,
    right: 0,
  },
  leftCenter: {
    top: "50%",
    left: 0,
  },
  RightCenter: {
    top: "50%",
    right: 0,
  },
  hoverBorder: {
    border: "5px solid #7b1700"
  }
}));
const BorderLayout = ({ hover }) => {
  const classes = useStyles();
  const borderClasses = hover ? `${classes.hoverBorder}` : "";
  return (
    <>
      <div className={`${classes.root} ${"border-parent"} ${borderClasses}`}></div>
      <div className={`${classes.leftCorner} ${"border-childs"}`}></div>
      <div className={`${classes.rightCorner} ${"border-childs"}`}></div>
      <div className={`${classes.topCenter} ${"border-childs"}`}></div>
      <div className={`${classes.bottomCenter} ${"border-childs"}`}></div>
      <div className={`${classes.bottomLeftCorner} ${"border-childs"}`}></div>
      <div className={`${classes.bottomRightCorner} ${"border-childs"}`}></div>
      <div className={`${classes.leftCenter} ${"border-childs"}`}></div>
      <div className={`${classes.rightCenter} ${"border-childs"}`}></div>
    </>
  );
};

export default BorderLayout;
