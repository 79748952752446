import {
  deleteComponent
} from "./deleteComponent";
import {
  CUT_COMPONENT_ACTION,
  CUT_PASTE_SUCCESSFULL
} from "../../constants/action-types";
import produce from "immer";
/**
 * Cut a component. ID would be placed in clipboard. Component will be removed from its parent but will not be deleted
 * @param {*} index of the component to be cut
 */
export function cutComponent(index) {
  deleteComponent(index);
  window.store.dispatch({
    type: CUT_COMPONENT_ACTION,
    payLoad: {
      index
    }
  });
}

export function cutComponentReducer(state, action) {
  window.navigator.clipboard.writeText(action.payLoad.index)
  return produce(state, drafState => {
    drafState.componentClipBoard = true;
  });

}

export function revertCutStatus() {
  window.store.dispatch({
    type: CUT_PASTE_SUCCESSFULL,
  })
}
export function revertCutStatusReducer(state, action) {
  window.navigator.clipboard.writeText(null);
  return produce(state, drafState => {
    drafState.componentClipBoard = false;
  })
}