import { toast } from "react-toastify";
toast.configure({
  autoClose: 8000,
  draggable: false
  //etc you get the idea
});
class MessageService {
  success = message => {
    toast.success(message);
  };
  warn = message => {
    toast.warn(message);
  };
  info = message => {
    toast.info(message);
  };
  error = message => {
    toast.error(message);
  };
  notify = message => {
    toast(message);
  };
}

const messageService = new MessageService();

export default messageService;
