/** @format */

import React from "react";
import { FormControlLabel, Switch } from "@material-ui/core";
import { makeMain } from "../../../store/actions/action-functions/makeMainActivity";
const MakeMainActivity = ({ component }) => {
  const checked = component.isMainActivity ? component.isMainActivity : false;
  function handleChecking(e) {
    const value = e.target.checked;
    makeMain(component.id, value);
  }

  return (
    <FormControlLabel
      value={component.label}
      control={
        <Switch
          color="primary"
          checked={checked}
          onChange={(e) => handleChecking(e)}
        />
      }
      label={"Make Main Activity"}
      labelPlacement="start"
    />
  );
};

export default MakeMainActivity;
// class MakeNavigationActivity extends Component {
//   state = {};
//   render() {
//     const { component } = this.props;
//     const checked = component.isNavigatable ? component.isNavigatable : false;
//     return (
//       <FormControlLabel
//         value={component.label}
//         control={
//           <Switch
//             color="primary"
//             checked={checked}
//             onChange={e => this.handleChecking(e)}
//           />
//         }
//         label={"Make Main Activity"}
//         labelPlacement="start"
//       />
//     );
//   }
//   handleChecking(e) {
//     const value = e.target.checked;
//     makeNavigation(this.props.component.id, value);
//   }
// }

// export default MakeNavigationActivity;
