import {
  COMPONENT_RADIOGROUP
} from "../constants/typeConstants";
import common from "./common";
/**
 * factory function to produce Radio Group Component Object
 * @param {String} name
 * @param {Array} children array
 */
export default function (name = "Radio_Group", children = []) {
  let obj = {
    name: name,
    type: COMPONENT_RADIOGROUP,
    children: children
  };
  return Object.assign(obj, common);
}