/** @format */

import React from "react";
import ComponentViewerSelector from "./ComponentViewerSelector";
import { DropTarget, DragSource } from "react-dnd";
import {
  dropSpecs,
  dropCollect,
  dragSpec,
  dragCollect,
} from "./utils/dndForHorizentalLayouts";
import flow from "lodash/flow";
import { toast } from "react-toastify";
import { basicProps } from "./shared/BasicProps";
import { changePropertiesComponent } from "../../../../store/actions/designerActions";

const ComponentViewerHorizontalListView = ({
  id,
  connectDropTarget,
  connectDragSource,
  hover,
}) => {
  const basicObject = basicProps();
  const component = basicObject.components[id];

  const style = {
    ...component.properties.styles,

    width: "100%",
    height: component.children.length > 0 ? "auto" : "100px",
    overflow: "auto",
  };

  const list = loopIt(basicObject.components, component);

  function loopIt(components, component) {
    let loop = [];
    const disableDrag = basicObject.disableDrag;
    if (component.children.length === 0) return [];
    for (let i = 0; i < 1; i++)
      loop.push(
        component.children.map((child, index) => (
          <ComponentViewerSelector
            key={index}
            components={components}
            component={components[child]}
            id={child}
            index={index}
            heightLightComponent={basicObject.heightLightComponent}
            disableDrag={disableDrag}
          />
        ))
      );
    return loop;
  }
  return connectDropTarget(
    <div
      onClick={(e) => {
        e.stopPropagation();
        if (basicObject.disableSelection) {
          toast.warn("Layouts Cannot Be Data Binded");
        }
        if (!basicObject.disableDrag && !basicObject.disableSelection)
          changePropertiesComponent(id);
      }}
      className="app-designer-add-no-scroll noScrollBar"
      style={style}
    >
      {list.map((c) => c)}
    </div>
  );
};

export default flow(
  DropTarget("modules", dropSpecs, (connect, monitor) =>
    dropCollect(connect, monitor)
  ),
  DragSource("modules", dragSpec, (connect, monitor) =>
    dragCollect(connect, monitor)
  )
)(ComponentViewerHorizontalListView);
