/** @format */

import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { ContextMenu, MenuItem, connectMenu, SubMenu } from "react-contextmenu";
import { COMPONENT_CONTEXT_MENU } from "./ContextMenuTypes";
import {
  changePropertiesComponent,
  deleteComponent,
  cutComponent,
  changeComponentAttribute,
  changeStyle,
} from "../../../../store/actions/designerActions";
import componentTypes from "../../../../store/constants/component_list.js";
import { duplicateComponent } from "../../../../store/actions/action-functions/duplicateComponent";
import {
  addComponentByPosition,
  addComponentPosition,
} from "./../../../../store/actions/action-functions/addComponent";
import DesignerTreeHandler from "./../../../../store/reducers/handlers/DesignerTreeHandler";
import {
  moveComponentUp,
  moveComponentDown,
} from "../../../../store/actions/action-functions/moveComponent";
import componentEvents from "../../../../store/constants/component-events";
import { pasteComponent } from "../../../../store/actions/action-functions/pasteComponent";

class DesignerContextMenu extends Component {
  state = {};
  handleClick = (trigger) => {
    //changePropertiesComponent(trigger.componentID);
    //(trigger);
  };
  render() {
    const { trigger } = this.props;
    ////("'trigger'");
    ////(trigger);
    //const id = trigger.componentID;
    const layoutTypes = DesignerTreeHandler.findLayoutTypes();
    return (
      <ContextMenu id={COMPONENT_CONTEXT_MENU}>
        {" "}
        {trigger &&
          trigger.componentID &&
          DesignerTreeHandler.isLayoutComponent(trigger.componentID) && (
            <SubMenu title="Change Layout To">
              {" "}
              {layoutTypes
                .filter(
                  (type) =>
                    !(
                      type.name ===
                      DesignerTreeHandler.findComponentType(trigger.componentID)
                    )
                )
                .map((type, index) => (
                  <MenuItem
                    key={index}
                    onClick={() =>
                      changeComponentAttribute(
                        trigger.componentID,
                        "type",
                        type.name
                      )
                    }
                  >
                    {type.display_name}{" "}
                  </MenuItem>
                ))}{" "}
            </SubMenu>
          )}{" "}
        <MenuItem onClick={() => alert("yet to implement")}> Rename </MenuItem>{" "}
        <MenuItem onClick={() => duplicateComponent(trigger.componentID)}>
          Duplicate{" "}
        </MenuItem>{" "}
        <MenuItem onClick={() => cutComponent(trigger.componentID)}>
          Cut{" "}
        </MenuItem>{" "}
        <MenuItem
          onClick={() => {
            window.navigator.clipboard.writeText(trigger.componentID);
          }}
        >
          Copy{" "}
        </MenuItem>{" "}
        <MenuItem onClick={() => pasteComponent()}> Paste </MenuItem>{" "}
        <MenuItem onClick={() => deleteComponent(trigger.componentID)}>
          Delete{" "}
        </MenuItem>{" "}
        <MenuItem onClick={() => moveComponentUp(trigger.componentID)}>
          Move Up{" "}
        </MenuItem>{" "}
        <MenuItem onClick={() => moveComponentDown(trigger.componentID)}>
          Move Down{" "}
        </MenuItem>{" "}
        <MenuItem
          onClick={() => {
            changeStyle(trigger.componentID, "width", "match_parent");
            changeStyle(trigger.componentID, "height", "match_parent");
          }}
        >
          Fit To Parent{" "}
        </MenuItem>{" "}
        <SubMenu title="Insert Component">
          <SubMenu title="Child">
            {" "}
            {componentTypes.map((c, index) => (
              <MenuItem
                key={index}
                onClick={() =>
                  addComponentByPosition(
                    trigger.componentID,
                    c.name,
                    addComponentPosition.CHILD
                  )
                }
              >
                {c.display_name}{" "}
              </MenuItem>
            ))}{" "}
          </SubMenu>{" "}
          <SubMenu title="Below">
            {" "}
            {componentTypes.map((c, index) => (
              <MenuItem
                key={index}
                onClick={() => {
                  addComponentByPosition(
                    trigger.componentID,
                    c.name,
                    addComponentPosition.BELOW
                  );
                  // //();
                }}
              >
                {c.display_name}{" "}
              </MenuItem>
            ))}{" "}
          </SubMenu>{" "}
          <SubMenu title="Above">
            {" "}
            {componentTypes.map((c, index) => (
              <MenuItem
                key={index}
                onClick={() =>
                  addComponentByPosition(
                    trigger.componentID,
                    c.name,
                    addComponentPosition.ABOVE
                  )
                }
              >
                {c.display_name}{" "}
              </MenuItem>
            ))}{" "}
          </SubMenu>{" "}
          <SubMenu title="At Start">
            {" "}
            {componentTypes.map((c, index) => (
              <MenuItem
                key={index}
                onClick={() =>
                  addComponentByPosition(
                    trigger.componentID,
                    c.name,
                    addComponentPosition.ATSTART
                  )
                }
              >
                {c.display_name}{" "}
              </MenuItem>
            ))}{" "}
          </SubMenu>{" "}
          <SubMenu title="At End">
            {" "}
            {componentTypes.map((c, index) => (
              <MenuItem
                key={index}
                onClick={() => {
                  addComponentByPosition(
                    trigger.componentID,
                    c.name,
                    addComponentPosition.ATEND
                  );
                  alert(trigger.componentID);
                }}
              >
                {c.display_name}{" "}
              </MenuItem>
            ))}{" "}
          </SubMenu>{" "}
        </SubMenu>{" "}
        <SubMenu title="Events">
          {" "}
          {Object.keys(componentEvents).map((event, index) => (
            <MenuItem
              key={index}
              onClick={(e) => {
                //("Not Implemented");
              }}
            >
              {componentEvents[event].name}{" "}
            </MenuItem>
          ))}{" "}
        </SubMenu>{" "}
        <MenuItem
          onClick={() => changePropertiesComponent(trigger.componentID)}
        >
          Properties{" "}
        </MenuItem>{" "}
      </ContextMenu>
    );
  }
}

export default connectMenu(COMPONENT_CONTEXT_MENU)(
  withRouter(DesignerContextMenu)
);
