import common from "./common";
import {
  COMPONENT_VIEWPAGER
} from "./../constants/typeConstants";
/**
 * factory function to produce Swipeable Section Object
 * @param {String} name
 * @param {Array} children array
 */
export default function (name = "Swipeable_Section", children = []) {
  let obj = {
    name: name,
    type: COMPONENT_VIEWPAGER,
    children: children
  };
  return Object.assign(obj, common);
}