/** @format */

import React, { useState } from "react";
import MenuDropDown from "../../shared/MenuDropDown";
import components, {
  findComponentByDisplayName,
} from "../../../../store/constants/component_list";
import SingleComponent from "../../ComponentSelector/SingleComponent/SingleComponent";
import { makeStyles } from "@material-ui/core";
import { useSelector } from "react-redux";
import DesignerTreeHandler from "../../../../store/reducers/handlers/DesignerTreeHandler";
import ComponentNavigation from "../../DesignerNavigation/ComponentNavigation";
import DesignerNavigation from "../../DesignerNavigation/DesignerNavigation";

const useStyles = makeStyles((theme) => ({
  root: {
    boxShadow: theme.shadows[3],
    maxWidth: "240px",
    overflow: "auto",
  },
  headingsRoot: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    color: "black",
  },
  headingsChild: (isActive) => ({
    width: "calc(100% * (1/2))",
    padding: "4px",
    textAlign: "center",
    cursor: "pointer",
    backgroundColor: isActive ? "lightgray" : "#ECECEC",
    color: isActive ? "white" : "#8f8f8f",
  }),

  pipe: {
    borderRight: "1px solid black",
  },
}));
const DesignerLeftMenu = () => {
  const [state, setState] = useState({
    activeTab: 0,
  });
  const classes = useStyles(state.activeTab == 0);
  const classes2 = useStyles(state.activeTab == 1);
  const layouts = components.filter((component) => component.isLayout);
  const buttons = components.filter((component) => component.isButton);
  const text = components.filter((component) => component.isText);
  const rc = useSelector((state) => state.designer.recentComponents);
  const recentComponents = rc.map((comp) => findComponentByDisplayName(comp));
  return (
    <div className={classes.root}>
      <div className={classes.headingsRoot}>
        <h5
          className={`${classes.headingsChild} ${classes.pipe}`}
          onClick={() => setState({ activeTab: 0 })}
        >
          Designer
        </h5>
        <h5
          className={classes2.headingsChild}
          onClick={() => setState({ activeTab: 1 })}
        >
          Hierarchy
        </h5>
      </div>
      {state.activeTab == 1 ? (
        <div>
          <MenuDropDown title={"Hirarchy"} wrap={true} isOpen={false}>
            <DesignerNavigation />
          </MenuDropDown>
        </div>
      ) : (
        <div>
          <MenuDropDown title={"Recent"}>
            <div
              style={{ display: "flex", flexWrap: "wrap", padding: "0 3px" }}
            >
              {recentComponents.map(
                (component, index) =>
                  index < 6 && (
                    <div
                      style={{
                        width: "calc(100% * (1/3))",
                      }}
                    >
                      <SingleComponent color={"black"} module={component} />
                    </div>
                  )
              )}
            </div>
            {/* </GridLeftMenu> */}
          </MenuDropDown>
          {/* <br /> */}
          <MenuDropDown title={"Components"}>
            <MenuDropDown title={"Layouts"} wrap={true}>
              <div
                style={{ display: "flex", flexWrap: "wrap", padding: "0 0px" }}
              >
                {layouts.map((component, index) => (
                  <div
                    style={{
                      width: "calc(100% * (1/3))",
                    }}
                  >
                    <SingleComponent color={"black"} module={component} />
                  </div>
                ))}
              </div>
            </MenuDropDown>

            <MenuDropDown title={"Buttons"} wrap={true}>
              <div
                style={{ display: "flex", flexWrap: "wrap", padding: "0 3px" }}
              >
                {buttons.map((component, index) => (
                  <div
                    style={{
                      width: "calc(100% * (1/3))",
                    }}
                  >
                    <SingleComponent color={"black"} module={component} />
                  </div>
                ))}
              </div>
            </MenuDropDown>
            <MenuDropDown title={"Text Fields"} wrap={true}>
              <div
                style={{ display: "flex", flexWrap: "wrap", padding: "0 3px" }}
              >
                {text.map((component, index) => (
                  <div
                    style={{
                      width: "calc(100% * (1/3))",
                    }}
                  >
                    <SingleComponent color={"black"} module={component} />
                  </div>
                ))}
              </div>
            </MenuDropDown>

            <MenuDropDown
              title={"Custom"}
              wrap={true}
              isOpen={true}
            ></MenuDropDown>

            <MenuDropDown
              title={"Paid"}
              wrap={true}
              isOpen={true}
            ></MenuDropDown>
          </MenuDropDown>
        </div>
      )}
    </div>
  );
};

export default DesignerLeftMenu;
