import authService from "../../services/authService";

export const LOGIN_ERROR = "LOGIN_ERROR";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const AUTH_SHOW_LOGIN = "AUTH_SHOW_LOGIN";
export const AUTH_HIDE_LOGIN = "AUTH_HIDE_LOGIN";
export const AUTH_TOGGLE_LOGIN = "AUTH_TOGGLE_LOGIN";

//this func is a redux thunk function
export function setOpenLogin(value) {
  return (dispatch, getState) => {
    if (value) dispatch(hideLoginDialog());
    else dispatch(hideLoginDialog());
  };
}
export function showLoginDialog() {
  return {
    type: AUTH_SHOW_LOGIN,
  };
}
export function hideLoginDialog() {
  return {
    type: AUTH_HIDE_LOGIN,
  };
}
export function toggleLoginDialog() {
  return {
    type: AUTH_TOGGLE_LOGIN,
  };
}
export function loginWithToken(token) {
  return (dispatch) => {
    //(token);
  };
}
export function submitLogin({ email, password }) {
  return (dispatch, getState) => {
    authService
      .signInWithEmailAndPassword(email, password)
      .then((data) => {
        window.location.reload();
      })
      .catch((err) => {
        //(err);
      });
  };
}
