import React, { Component } from "react";
class ComponentViewer extends Component {
  render() {
    return (
      <div className="ComponentViewer">
        {/* <div className="component-viewer-title">{this.props.components[this.props.id].name}</div> */}
      </div>
    );
  }
}
ComponentViewer.propTypes = {};

export default ComponentViewer;
