/** @format */

import { COMPONENT_LINEARLAYOUT_VERTICAL } from "../constants/typeConstants";
import common from "./common";
/**
 * factory function to produce Linear Vertical Component Object
 * @param {String} name
 * @param {Array} children array
 */
export default function (name = "base", children = []) {
  let obj = {
    name: name,
    type: COMPONENT_LINEARLAYOUT_VERTICAL,
    children: children,
  };
  let asReceived = Object.assign({}, common);
  delete asReceived.properties;
  let commonProps = Object.assign({}, common);
  obj = Object.assign(obj, commonProps);
  obj.properties = {
    properties: {},
  };
  obj.properties.styles = {
    width: "match_parent",
    height: "wrap_content",
  };
  return obj;
}
