import {
    MAKE_MAIN_ACTIVITY
} from "../../constants/action-types";

import produce from "immer";


export function makeMain(index, value) {
    window.store.dispatch({
        type: MAKE_MAIN_ACTIVITY,
        payLoad: {
            index,
            value
        }
    })
}
export function makeMainReducer(state, action) {
    const activityID = action.payLoad.index;
    const value = action.payLoad.value;
    return produce(state, draftState => {
        draftState.components[2].children.forEach(activity => {
            if (draftState.components[activity].id !== activityID) {
                draftState.components[activity].isMainActivity = false
            }
        });
        draftState.components[activityID].isMainActivity = value
    })
}