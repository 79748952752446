import React, { Component } from "react";
import { addCustomClassName } from "../../../../../store/actions/action-functions/addCustomClassName";
import "./../baseStyleSheet.css";
class ButtonDesigns extends Component {
  state = { collpased: false };
  render() {
    const { collpased } = this.state;
    return (
      <React.Fragment>
        <p
          onClick={this.handleCollapse}
          htmlFor="buttonDesigns"
          style={{ cursor: "pointer" }}
        >
          Button Designs
          <span className="float-right">
            <i
              className="fas fa-chevron-circle-down"
              style={{ color: "#7A86BB", fontSize: "20px" }}
            ></i>
          </span>
        </p>
        <div
          hidden={!collpased}
          id="buttonDesigns"
          style={{
            display: "grid",
            gridTemplateColumns: "90px 90px 90px",
            gridColumnGap: "5px",
            gridRowGap: "20px"
          }}
        >
          {this.getButton("example_1 ")}
          {this.getButton("example_2 ")}
          {this.getButton("example_10 ")}
          {this.getButton("example_3 ")}
          {this.getButton("example_4 ")}
          {this.getButton("example_5 ")}
          {this.getButton("example_6 ")}
          {/* {this.getButton("example_7 btn btn-primary ")} */}
          {this.getButton("example_8   ")}
          {this.getButton("example_9   ")}
          {this.getButton("example_12  ")}
          {/* {this.getButton("btn btn-default button")}
          {this.getButton("btn btn-primary button")}
          {this.getButton("btn btn-success button")}
          {this.getButton("btn btn-info button")}
          {this.getButton("btn btn-warning button")}
          {this.getButton("btn btn-danger button")}
          {this.getButton("btn btn-link button")} */}
        </div>
      </React.Fragment>
    );
  }
  handleCollapse = () => {
    this.setState({ collpased: !this.state.collpased });
  };
  getButton(name) {
    return (
      <button className={name} onClick={() => this.changeHandler(name)}>
        Button
      </button>
    );
  }
  changeHandler = name => {
    addCustomClassName(this.props.component.id, name);
  };
}

export default ButtonDesigns;
