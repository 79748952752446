/** @format */

import React, { useState } from "react";
import { makeStyles } from "@material-ui/core";
import { separator } from "./pxSeparator";
import { useSelector } from "react-redux";
import { STYLE_CHANGE } from "../../../store/constants/action-types";
import { basicProps } from "../ComponentDesigner/ComponentViewer/shared/BasicProps";

const useStyles = makeStyles((theme) => ({
  root: {
    background: "#ECECEC",
    display: "inline-block",
    padding: "1px",
    marginLeft: "7px",
    userSelect: "none",
  },
  icons: {
    cursor: "pointer",
    fontSize: "11px",
    marginLeft: "6px",
  },
  input: {
    background: "#ECECEC",
    border: 0,
    outline: "none",
    width: "45px",
    padding: "1px",
    height: "16px",
    fontSize: "12px",
    fontWeight: "bold",
    marginLeft: "5px",
    "&$input[type=number]::-webkit-inner-spin-button": {
      display: "none",
    },
  },
  selectSpan: {
    display: "inline-block",
    marginLeft: "4px",
  },
  select: {
    cursor: "not-allowed",
    background: "#ECECEC",
    fontSize: "14.7px",
  },
  noBorder: {
    border: 0,
    height: "20px",
    paddingTop: "4px",
  },
  title: {
    width: "60px",
  },
}));

const DimensionWrapper = ({ title, property }) => {
  const classes = useStyles();
  let { selectedPropertiesScreenID, components } = basicProps();
  let component = components[selectedPropertiesScreenID];
  let val = null;
  if (component.properties) {
    val = component.properties.styles[property];
  }
  const splittedValue = separator(val);
  val = Number(splittedValue) ? splittedValue : "";
  return (
    <>
      <td className={`${classes.noBorder} ${classes.title}`}>{title}</td>
      <td className={classes.noBorder}>
        <div className={classes.root}>
          <i
            className={`${classes.icons} fas fa-chevron-left`}
            onClick={() => handleDecrease()}
          ></i>
          <i
            className={`${classes.icons} fas fa-chevron-right`}
            onClick={() => handleIncrease()}
          ></i>
          <input
            type="number"
            className={classes.input}
            value={val}
            onChange={(e) => updateAndDispatch(e.target.value)}
          />
        </div>
        <span className={classes.selectSpan}>
          <select disabled class={classes.select} name="unit" id="">
            <option value="">{title == 'Size' ? 'px' : '%'}</option>
          </select>
        </span>
      </td>
    </>
  );
  function handleDecrease() {
    dispatchAction(Number(val) - 1);
  }
  function handleIncrease() {
    dispatchAction(Number(val) + 1);
  }
  function updateAndDispatch(val) {
    dispatchAction(val);
  }
  function dispatchAction(value) {
    //();
    //("Propervety", property);
    //(value);
    if (property == "left") {
      window.store.dispatch({
        type: STYLE_CHANGE,
        payLoad: {
          index: component.id,
          propertyValue: "",
          property: "right",
        },
      });
    }
    if (property == "right") {
      window.store.dispatch({
        type: STYLE_CHANGE,
        payLoad: {
          index: component.id,
          propertyValue: "",
          property: "left",
        },
      });
    }

    if (property == "top") {
      window.store.dispatch({
        type: STYLE_CHANGE,
        payLoad: {
          index: component.id,
          propertyValue: "",
          property: "bottom",
        },
      });
    }

    if (property == "bottom") {
      window.store.dispatch({
        type: STYLE_CHANGE,
        payLoad: {
          index: component.id,
          propertyValue: "",
          property: "top",
        },
      });
    }
    window.store.dispatch({
      type: STYLE_CHANGE,
      payLoad: {
        index: component.id,
        propertyValue: value + (title == 'Size' ? "px" : "%"),
        property: property,
      },
    });
  }
};

export default DimensionWrapper;
