import React, { Component } from "react";
import Toolbar from "./ActivityDesigner/Toolbar";
import DesignerToolbar from "./ActivityDesigner/DesignerToolbar";
import Navigation from "./ActivityDesigner/Navigation";
import ComponentViewerSelector from "./ComponentViewer/ComponentViewerSelector";
import Dialoge from "./ActivityDesigner/Dialoge";
class ActivityDesigner extends Component {
  state = { openNavigation: false, animationName: "slit-in-vertical" };
  handleToggleNavigation = () => {
    this.setState({ openNavigation: this.state.openNavigation ? false : true });
  };

  render() {
    const {
      components,
      selectedScreenID,
      designerHeight,
      dialogID,
    } = this.props.designer;
    const { storyScreen, disableDrag } = this.props;
    let activity = {};
    let activityLayout = null;
    let toolbar = null;
    let navigation = null;
    let dialoges = null;
    //("Disable Drag", disableDrag);
    /******
     * * Agr Selected Screen Id ko mein null kr doon jab Dialogue Ho
     * ! Yahan Mein Check Laga Doon Ky Kiss ky components show krny
     * ! Navigation and Toolbar ko bhi hide kr doon
     * !
     */

    if (selectedScreenID) {
      if (!storyScreen) activity = components[selectedScreenID];
      else activity = components[storyScreen];
      activityLayout = components[activity.children[0]];
      toolbar = components[components[2].toolbar];
      navigation = components[components[2].navigation];
    } else dialoges = components[dialogID];
    const toolbarHeight = "150";

    return (
      <>
        {selectedScreenID ? (
          <>
            {activity.isNavigatable && (
              <DesignerToolbar
                openNavigation={this.state.openNavigation}
                toggleNavigation={this.handleToggleNavigation}
                toolbarHeight={toolbarHeight}
              />
            )}

            <div
              style={{
                height: "inherit",
                width: "inherit"
              }}
            >
              {activity.isNavigatable && (
                <Navigation
                  openNavigation={this.state.openNavigation}
                  component={navigation}
                  components={components}
                  designerHeight={designerHeight}
                  toolbarHeight={toolbarHeight}
                />
              )}
              {activity.hasToolBar && (
                <Toolbar
                  component={toolbar}
                  components={components}
                  toolbarHeight={toolbarHeight}
                />
              )}

              <ComponentViewerSelector
                component={activityLayout}
                disableDrag={disableDrag}
                components={components}
              />
            </div>
          </>
        ) : (
          <div
            style={{
              height: "inherit",
              width: "inherit"
            }}
          >
            <Dialoge
              component={dialoges}
              components={components}
              toolbarHeight={toolbarHeight}
            />
          </div>
        )}
      </>
    );
  }
}

export default ActivityDesigner;
