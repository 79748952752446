//a factory function to create node by its type
import uuidv4 from "uuid/v4";
import nodeModels from "./constants/nodeModels";
import portModels from "./constants/portModels";
import * as BLLVariableTypes from "./variableTypes";
import { BLL_VARIABLE_TYPE_STRING } from "./variableTypes";
import PortDirection from "./constants/portDirections";
import {
  createPort,
  flowPorts,
  flowInPort,
  flowOutPort,
  loopCompletedPort,
  loopBodyPort,
  conditionPort
} from "./constants/portConstructors";

const createNodeByType = (nodeType, options = {}) => {
  let node = getDefaultNodeObject(nodeType);
  node.connections = getPortsByNodeType(nodeType, options, node);
  // //();
  // //("creating: ");
  // //(node.type);
  switch (nodeType) {
    case nodeModels.START:
      node.label = "Start";
      node.locked = true;
      break;
    case nodeModels.FINISH:
      node.label = "Return";
      node.locked = true;
      node.enableAddNewPin = true;
      break;
    case nodeModels.INPUT:
      node.label = "input";
      node.showLabel = false;
      break;
    case nodeModels.OUTPUT:
      node.label = "output";
      node.showLabel = false;
      break;
    case nodeModels.PRINT:
      node.label = "Print";
      break;
    case nodeModels.PLUS:
      node.label = "+";
      node.enableAddNewPin = true;
      node.newPinType = BLLVariableTypes.BLL_VARIABLE_TYPE_INTEGER;
      break;
    case nodeModels.MINUS:
      node.label = "-";
      node.enableAddNewPin = true;
      node.newPinType = BLLVariableTypes.BLL_VARIABLE_TYPE_INTEGER;
      break;
    case nodeModels.MUL:
      node.label = "*";
      node.enableAddNewPin = true;
      node.newPinType = BLLVariableTypes.BLL_VARIABLE_TYPE_INTEGER;
      break;
    case nodeModels.DIV:
      node.label = "/";
      node.enableAddNewPin = true;
      node.newPinType = BLLVariableTypes.BLL_VARIABLE_TYPE_INTEGER;
      break;
    case nodeModels.AND:
      node.label = "&";
      node.enableAddNewPin = true;
      node.newPinType = BLLVariableTypes.BLL_VARIABLE_TYPE_INTEGER;
      break;
    case nodeModels.OR:
      node.label = "|";
      node.enableAddNewPin = true;
      node.newPinType = BLLVariableTypes.BLL_VARIABLE_TYPE_INTEGER;
      break;
    case nodeModels.LESSTHAN:
      node.label = "<";
      node.enableAddNewPin = true;
      node.newPinType = BLLVariableTypes.BLL_VARIABLE_TYPE_INTEGER;
      break;
    case nodeModels.GREATERTHAN:
      node.label = ">";
      node.enableAddNewPin = true;
      node.newPinType = BLLVariableTypes.BLL_VARIABLE_TYPE_INTEGER;
      break;
    case nodeModels.FOR:
      node.label = "for";
      break;
    case nodeModels.FOREACH:
      node.label = "for each";
      break;
    case nodeModels.WHILE:
      node.label = "while";
      break;
    case nodeModels.SWITCH:
      node.label = "switch";
      break;
    case nodeModels.BRANCH:
      node.label = "BRANCH";
      break;
    case nodeModels.VARIABLE_GETTER:
    case nodeModels.VARIABLE_SETTER:
      node.variable = options;
      node.func = options;
      node.label = options.name;
      node.showLabel = false;
      break;
    case nodeModels.UTILITY_FUNCTION:
    case nodeModels.BLL_FUNCTION:
      node.label = options.name;
      node.func = options;
      break;
    case nodeModels.VARIABLE_INCREMENT:
      node.label = "++ " + options.name;
      node.variable = options;
      break;
    case nodeModels.VARIABLE_DECREMENT:
      node.label = "-- " + options.name;
      node.variable = options;
      break;
    case nodeModels.COMPONENT:
      node.label = options.name;
      node.component = options;
      break;
    default:
      break;
  }
  //(options);
  // return Object.assign(node, options);
  return node;
};
const getDefaultNodeObject = (nodeType) => {
  let id = uuidv4();
  return {
    id,
    type: nodeType,
    label: nodeType,
    showLabel: true,
    enableAddNewPin: false,
    newPinType: BLL_VARIABLE_TYPE_STRING,
    connections: {},
    locked: false,
    x: 50,
    y: 50,
  };
};
const getPortsByNodeType = (type, options = {}, node) => {
  let ports = {};
  switch (type) {
    case nodeModels.START:
      ports.flowOut = flowOutPort();
      break;
    case nodeModels.FINISH:
      ports.flowIn = flowInPort();
      break;
    case nodeModels.PRINT:
      ports = flowPorts();
      ports.print = createPort(
        portModels[BLLVariableTypes.BLL_VARIABLE_TYPE_STRING],
        "",
        PortDirection.In,
        { showLabel: false, enableDefault: true }
      );
      break;
    case nodeModels.UTILITY_FUNCTION:
    case nodeModels.BLL_FUNCTION:
      ports = flowPorts();
      ////(options);
      options.inputs.map(({ name, type }) => {
        ports[name] = createPort(type, name, PortDirection.In, {
          showLabel: true,
          enableDefault: true,
        });
      });
      options.outputs.map(({ name, type }) => {
        ports[name] = createPort(type, name, PortDirection.Out);
      });
      break;
    case nodeModels.VARIABLE_DECREMENT:
    case nodeModels.VARIABLE_INCREMENT:
      ports = flowPorts();
      ports.use = createPort(options.type, options.name, PortDirection.Out);
      break;
    case nodeModels.INPUT:
      ports.use = createPort(
        BLLVariableTypes.BLL_VARIABLE_TYPE_STRING,
        options.name,
        PortDirection.Out
      );
      break;
    case nodeModels.VARIABLE_GETTER:
      ports.use = createPort(options.type, options.name, PortDirection.Out);
      break;
    case nodeModels.OUTPUT:
    case nodeModels.VARIABLE_SETTER:
      ports = flowPorts();
      ports.use = createPort(
        BLLVariableTypes.BLL_VARIABLE_TYPE_STRING,
        options.name,
        PortDirection.In,
        { showLabel: true, enableDefault: true }
      );
      break;
    case nodeModels.MINUS:
    case nodeModels.MUL:
    case nodeModels.DIV:
    case nodeModels.PLUS:
    case nodeModels.AND:
    case nodeModels.OR:
    case nodeModels.LESSTHAN:
    case nodeModels.GREATERTHAN:
      let port1 = createPort(
        portModels[BLLVariableTypes.BLL_VARIABLE_TYPE_INTEGER],
        "",
        PortDirection.In,
        { showLabel: false, enableDefault: true }
      );

      let port2 = createPort(
        portModels[BLLVariableTypes.BLL_VARIABLE_TYPE_INTEGER],
        "",
        PortDirection.In,
        { showLabel: false, enableDefault: true }
      );
      ports[port1.id] = port1;
      ports[port2.id] = port2;
      node.portList = [];
      node.portList.push(port1.id);
      node.portList.push(port2.id);
      ports.print = createPort(
        portModels[BLLVariableTypes.BLL_VARIABLE_TYPE_INTEGER],
        "",
        PortDirection.Out,
        { showLabel: false }
      );
      break;
    case nodeModels.FOR:
      ports.flowIn = flowInPort();
      ports.body = loopBodyPort();
      ports.completed = loopCompletedPort();
      ports.firstIndex = createPort(
        portModels[BLLVariableTypes.BLL_VARIABLE_TYPE_INTEGER],
        "First Index",
        PortDirection.In,
        { showLabel: true, enableDefault: true }
      );
      ports.lastIndex = createPort(
        portModels[BLLVariableTypes.BLL_VARIABLE_TYPE_INTEGER],
        "Last Index",
        PortDirection.In,
        { showLabel: true, enableDefault: true }
      );
      break;
    case nodeModels.FOREACH:
      ports.flowIn = flowInPort();
      ports.array = createPort(
        portModels[BLLVariableTypes.BLL_VARIABLE_TYPE_ARRAY],
        "Array",
        PortDirection.In,
        { showLabel: true, enableDefault: true }
      );
      ports.body = loopBodyPort();
      ports.completed = loopCompletedPort();
      ports.element = createPort(
        portModels[BLLVariableTypes.BLL_VARIABLE_TYPE_OBJECT],
        "Array Element",
        PortDirection.Out
      );
      ports.firstIndex = createPort(
        portModels[BLLVariableTypes.BLL_VARIABLE_TYPE_INTEGER],
        "Array Index",
        PortDirection.Out
      );
      break;
    case nodeModels.WHILE:
      ports.flowIn = flowInPort();
      ports.body = loopBodyPort();
      ports.completed = loopCompletedPort();
      ports.condition = conditionPort();
      break;
    case nodeModels.SWITCH:
      break;
    case nodeModels.BRANCH:
      ports.flowIn = flowInPort();
      ports.true = flowOutPort();
      ports.true.label = "True";
      ports.true.name = "true";
      ports.false = flowOutPort();
      ports.false.label = "False";
      ports.false.name = "false";
      ports.condition = conditionPort();
      break;
    case nodeModels.COMPONENT:
      ports = flowPorts();
      break;
    default:
      break;
  }
  return ports;
};
export default createNodeByType;
