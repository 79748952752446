import React from "react";
import * as typeConstants from "../../../../../store/constants/typeConstants";
import ComponentViewerEditText from "../ComponentViewerEditText";
import ComponentViewerButton from "../ComponentViewerButton";
import ComponentViewerDropDown from "../ComponentViewerDropDown";
import ComponentViewerCheckBox from "../ComponentViewerCheckBox";
import ComponentViewerTextView from "../ComponentViewerTextView";
import ComponentViewerHorizontalLayout from "../ComponentViewerHorizontalLayout";
import ComponentViewerListView from "../ComponentViewerListView";
import ComponentViewerVertical from "../ComponentViewerVertical";
import ComponentViewer from "../ComponentViewer";
import ComponentViewerRadioButton from "../ComponentViewerRadioButton";
import ComponentViewerImage from "../ComponentViewerImage";
import ComponentViewerSection from "../ComponentViewerSection";
import ComponentViewerIconButton from "../ComponentViewerIconButton";
import ComponentViewerHorizontalListView from "../ComponentViewerHorizontalListView";

export default function componentSelecter(id, type, hover, isDragging, disableDrag=false) {
  switch (type) {
    case typeConstants.COMPONENT_EDITTEXT:
      return <ComponentViewerEditText id={id} hover={hover} disableDrag={disableDrag}/>;
    case typeConstants.COMPONENT_ICON_BUTTON:
      return <ComponentViewerIconButton id={id} hover={hover} disableDrag={disableDrag}/>;
    case typeConstants.COMPONENT_RADIOBUTTON:
      return (
        <ComponentViewerRadioButton
          id={id}
          hover={hover}
          isDragging={isDragging}
          disableDrag={disableDrag}
        />
      );
    case typeConstants.COMPONENT_BUTTON:
      return (
        <ComponentViewerButton isDragging={isDragging} id={id} hover={hover} disableDrag={disableDrag}/>
      );
    case typeConstants.COMPONENT_HORIZONTAL_LISTVIEW:
      return <ComponentViewerHorizontalListView id={id} hover={hover} disableDrag={disableDrag}/>;
    case typeConstants.COMPONENT_DROPDOWN:
      return <ComponentViewerDropDown id={id} hover={hover} disableDrag={disableDrag}/>;

    case typeConstants.COMPONENT_CHECKBOX:
      return <ComponentViewerCheckBox hover={hover} id={id} disableDrag={disableDrag}/>;
    case typeConstants.COMPONENT_IMAGEVIEW:
      return <ComponentViewerImage hover={hover} id={id} disableDrag={disableDrag}/>;
    case typeConstants.COMPONENT_TEXTVIEW:
      return <ComponentViewerTextView hover={hover} id={id} disableDrag={disableDrag}/>;

    case typeConstants.COMPONENT_LINEARLAYOUT_HORIZONTAL:
      return <ComponentViewerHorizontalLayout id={id} disableDrag={disableDrag}/>;

    case typeConstants.COMPONENT_LINEARLAYOUT_VERTICAL:
      return <ComponentViewerVertical id={id} disableDrag={disableDrag}/>;

    case typeConstants.COMPONENT_LISTVIEW:
      return <ComponentViewerListView hover={isDragging} id={id} disableDrag={disableDrag}/>;

    case typeConstants.COMPONENT_FRAGMENT:
      return <ComponentViewerSection id={id} hover={isDragging} disableDrag={disableDrag}/>;
    default:
      return <ComponentViewer id={id} disableDrag={disableDrag}/>;
  }
}
