/** @format */

import axios from "axios";

let axiosInstance = axios.create({
    // .. where we make our configurations
    baseURL: process.env.REACT_APP_API_URL,
});
axiosInstance.defaults.headers.post["Content-Type"] = "application/json";
axiosInstance.interceptors.response.use(
    (response) => {
        //console.log(response);
        // Edit response config
        return response;
    },
    (error) => {
        console.log(error);
        return Promise.reject(error);
    }
);
export default axiosInstance;
