import {
    COMPONENT_BUTTON,
    COMPONENT_ICON_BUTTON
} from "../constants/typeConstants";
import common from "./common";
/**
 * factory to produce button
 * @param {String} name of the component
 * @param {String} label to display on button Default is "Button"
 */
export default function (name = "IconButton", label = "IconButton") {
  let obj = {
    name: name,
    type: COMPONENT_ICON_BUTTON,
    children: [],
  };
  const defaultStyles = {
    width: "wrap_content",
    height: "wrap_content",
    fontSize: "26%",
  };
  obj = Object.assign({}, obj, common);
  delete obj.properties;
  obj.properties = {
    properties: {},
  };
  obj.properties.styles = defaultStyles;
  obj.properties.customClassName = undefined;
  return obj;
}
