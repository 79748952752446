import produce from "immer";
import { toast } from "react-toastify";
export default class APIHandlerReducer {
  static requestAppReducer(state, action) {
    return produce(state, draftState => {
      let app = {};
      app.fetching = true;
      app.componentsLoaded = false;
      app.bllLoaded = false;
      app.dirty = false;
      app.componentsDirty = false;
      app.bllDirty = false;
      app._id = action.payLoad.id;
      draftState.apps[app._id] = app;
    });
  }
  static deleteAppReducer(state, action) {
    return produce(state, draftState => {
      delete draftState.apps[action.payLoad.id];
      draftState.pagination.apps.pages = {};
    });
  }
  static requestAppReceivedReducer(state, action) {
    let app = action.payLoad.app;
    //("requested app received");
    return produce(state, draftState => {
      draftState.apps[app._id] = Object.assign(draftState.apps[app._id], app);
      draftState.apps[app._id].fetching = false;
      draftState.apps[app._id].componentsLoaded = true;
      draftState.apps[app._id].bllLoaded = true;
    });
  }
  static appCreatedReducer(state, action) {
    const { app } = action.payLoad;
    toast.success("app is built. select it from list");
    return produce(state, draftState => {
      draftState.apps[app._id] = app;
      draftState.pagination.apps.pages = {};
      draftState.apps[app._id].fetching = false;
      draftState.apps[app._id].componentsLoaded = true;
      draftState.apps[app._id].bllLoaded = true;
    });
  }
  static newFunctionAddedReducer(state, action) {
    const { id, func } = action.payLoad;
    //("func received in reducer");
    //(func);
    return produce(state, draftState => {
      draftState.apps[id].bllFunctions = [
        ...draftState.apps[id].bllFunctions,
        func
      ];
    });
  }
  static deleteFunctionReducer(state, action) {
    const { id, funcId } = action.payLoad;
    //("received in delete reducer" + action.payLoad);
    return produce(state, draftState => {
      const deleteIndex = draftState.apps[id].bllFunctions.findIndex(
        func => func._id == funcId
      );
      draftState.apps[id].bllFunctions.splice(deleteIndex, 1);
    });
  }
  static dummy(state, action) {
    return produce(state, draftState => {});
  }
  static requestAppsPageReducer(state, action) {
    return produce(state, draftState => {
      draftState.pagination.apps.pages[action.payLoad.page] = {
        fetching: true,
        ids: []
      };
    });
  }
  /**
   * Apps Paged Data Received Reducer Function
   * @param {*} state
   * @param {*} action
   */
  static appsPageReceivedReducer(state, action) {
    //(action);
    return produce(state, draftState => {
      draftState.pagination.apps.pages[action.payLoad.page] = {
        fetching: false,
        ids: action.payLoad.data.data.map(app => app._id),
        per_page: action.payLoad.data.per_page,
        current_page: action.payLoad.data.current_page,
        from: action.payLoad.data.from,
        to: action.payLoad.data.to,
        total: action.payLoad.data.total
      };
      action.payLoad.data.data.map(app => {
        app.fetching = false;
        app.componentsLoaded = false;
        app.bllLoaded = false;
        app.dirty = false;
        app.componentsDirty = false;
        app.bllDirty = false;
        if (!draftState.apps[app._id]) draftState.apps[app._id] = app;
        else
          draftState.apps[app._id] = Object.assign(
            draftState.apps[app._id],
            app
          );
      });
    });
  }
}
