export default class Immutable {
    /**
     * insert item in specific location
     * @param {array} array in which to insert Item
     * @param {object} item to be inserted
     * @param {int} index 
     */
    static insertItem(array, item, index = 0) {
        return [
            ...array.slice(0, index),
            item,
            ...array.slice(index)
        ]
    }
    /**
     * removes Item immutable from array
     * @param {array} array from which to remove
     * @param {int} index index
     */
    static removeItem(array, index) {
        return [...array.slice(0, index), ...array.slice(index + 1)]
    }
}