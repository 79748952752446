/** @format */

import React, { Component } from "react";

import { Switch, Route, Redirect } from "react-router-dom";
import ContactUs from "../views/pages/ContactUs/ContactUs";

import HomePage from "../views/pages/AlphaDnd/HomePage/HomePage";
import Login from "../views/pages/AlphaDnd/LoginPage/Login";
import Signup from "../views/pages/AlphaDnd/SignupPage/Signup";
import Pricing from "../views/pages/AlphaDnd/Pricing/Pricing";
import Mapper from "./../../AppDesigner/Properties/Mapper/Mapper";

import AboutUs from "./../views/pages/AlphaDnd/AboutUs/AboutUs";
import ProtectedRoutes from "./ProtectedRoutes";

class Main extends Component {
  render() {
    const token = localStorage.getItem("token");

    return (
      <div className="Main">
        {!token ? (
          <Switch>
            <Route path="/contact-us" exact component={ContactUs} />
            <Route path="/" exact component={HomePage} />
            <Route path="/login" exact component={Login} />
            <Route path="/signup" exact component={Signup} />
            <Route path="/pricing" exact component={Pricing} />
            <Route path="/about" exact component={AboutUs} />
            <Route path="/mapper" exact component={Mapper} />
            <ProtectedRoutes />

            {/* <Route path="*" component={HomePage} /> */}
            <Redirect path="*" to="/" />
          </Switch>
        ) : (
          <ProtectedRoutes />
        )}
      </div>
    );
  }
}
Main.propTypes = {};

export default Main;
