import React, { Component } from "react";
import ComponentViewerSelector from "../ComponentViewer/ComponentViewerSelector";
import flow from "lodash/flow";
import { connect } from "react-redux";
import {
  dropSpecs,
  dropCollect,
  dragSpec,
  dragCollect
} from "./../ComponentViewer/utils/dndForHorizentalLayouts";
import { DropTarget, DragSource } from "react-dnd";

// import { getDimensionValue } from "../../Properties/utils/changeToHtmlUnits";
// import { getLayoutStyleObject } from "../../../Properties/utils/layoutStyleObject";
class Navigation extends Component {
  state = {};
  render() {
    const { openNavigation, component, components } = this.props;
    const styles = {
      backgroundColor: "aqua",
      display: openNavigation ? "block" : "none",
      position: "absolute",
      height: "100%",
      left: "0px",
      width: "60%",
      transition: "0.9s",
      // overFlow: "auto",
      padding: 0,
      zindex: 10000
    };
    return (
      <div
        style={styles}
        ref={instance => {
          this.props.connectDropTarget(instance);
          this.props.connectDragSource(instance);
        }}
      >
        <ComponentViewerSelector
          component={component}
          components={components}
        />
      </div>
    );
  }
}
const mapStateToProps = state => state;
export default flow(
  DropTarget("modules", dropSpecs, (connect, monitor) =>
    dropCollect(connect, monitor)
  ),
  DragSource("modules", dragSpec, (connect, monitor) =>
    dragCollect(connect, monitor)
  )
)(connect(mapStateToProps)(Navigation));
