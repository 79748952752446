/** @format */

export const dragSpecs = {
    beginDrag(monitor, props, component) {
        // debugger;
        return {
            id: monitor.component.id,
            parent_id: monitor.component.parent_id,
            index: "",
            type: "component",
            from: "tree",
        };
    },
    canDrag(props, monitor) {
        const disableActivityDragging = props.components[2].children.find(
            (id) => id === props.id
        );
        if (
            props.components[props.components[props.id].parent_id].type === "Activity"
        )
            return false;
        if (disableActivityDragging) return false;
        if (props.disableDrag === true) return false;
        return true;
    },
};

export const dropSpecs = {
    drop(props, monitor, component) {
                                      // //("l")
                                    },
};