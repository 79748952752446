import React, { Component } from "react";
import "./Pricing.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "font-awesome/css/font-awesome.min.css";
import Footer from "../Footer/Footer";
// import { Container, Row, Col, Button } from "react-bootstrap";
// import NavBar from "./../NavBar";

class Pricing extends Component {
  state = {};
  render() {
    return (
      <div className="pricing">
        <div className="row">
          <div className="col-md-2" />
          <div className="col-md-8">
            <div className="pricing-text">
              <b id="paragraph">
                Choose a plan that is <br />
                suitable for you.
              </b>
              <h4>
              The most powerful no-code platform in the market. With the most unbelievable pricing.
              </h4>
              {/* <Button variant="light" id="started">
                Get Started
              </Button> */}
            </div>
          </div>
          <div className="col-md-2" />
        </div>
        <div className="banner">
          <div className="row">
            <div className="col-md-12" />
            <div className="col-md-6">
              <h4 className="text">
                Develope for <b>FREE</b>, Pay when you want to publish
              </h4>
            </div>
            <div className="col-md-6">
              <a href="/signup" >
              <button className="btn btn-primary pricing-btn">
                <b>Get Started</b>
              </button>
              </a>
            </div>

            <div className="col-md-12" />
          </div>
        </div>
        <div className="price-table-wrapper">
          <div className="pricing-table">
            <h2 className="pricing-table-header">- STANDARD -</h2>
            <h3 className="pricing-table-price">$150</h3>
            <a
              target="_blank"
              className="pricing-table-button"
              href="http://www.usmanlive.com"
              rel="noopener noreferrer"
            >
              Join Now!
            </a>
            <ul className="pricing-table-list">
              <li>30 day free trial</li>
              <li>5GB storage space</li>
              <li> Upto 5 Free Built-in Themes </li>
              <li>20% discount</li>
              <li>24 hour support</li>
            </ul>
          </div>
          <div className="pricing-table featured-table">
            <h2 className="pricing-table-header">- BUSINESS -</h2>
            <h3 className="pricing-table-price">$300</h3>
            <a
              target="_blank"
              className="pricing-table-button"
              href="http://www.usmanlive.com"
              rel="noopener noreferrer"
            >
              Join Now!
            </a>
            <ul className="pricing-table-list">
              <li>40 day free trial</li>
              <li>15GB storage space</li>
              <li>Built-in Themes + Animations</li>
              <li>25% discount</li>
              <li>24 hour support</li>
            </ul>
          </div>
          <div className="pricing-table">
            <h2 className="pricing-table-header">- PREMIUM -</h2>
            <h3 className="pricing-table-price">$500</h3>
            <a
              target="_blank"
              className="pricing-table-button"
              href="http://www.usmanlive.com"
              rel="noopener noreferrer"
            >
              Join Now!
            </a>
            <ul className="pricing-table-list">
              <li>50 day free trial</li>
              <li>Unlimited storage space</li>
              <li>Custom Themes + Animations</li>
              <li>40% discount</li>
              <li>24 hour support</li>
            </ul>
          </div>
        </div>
        <div className="container">
          <div className="row faqs">
            {/* <div className="col-lg-4" /> */}
            <div className="col-lg-4 mx-auto">
              <h3>
                <b>FAQ's</b>
              </h3>
            </div>
            {/* <div className="col-lg-4" /> */}
          </div>
          <div className="row ques">
            <div className="col-sm-12 col-md-6 col-lg-4">
              <h5>What forms of payment do you accept?</h5>
              <p>
                We accept any major credit card. For annual subscriptions with
                over 25 users we can also issue an invoice payable by bank
                transfer or check. Your account executive can arrange an invoice
                purchase.
              </p>
            </div>
            <div className="col-sm-12 col-md-6 col-lg-4">
              <h5>Do all users have to be on the same plan?</h5>
              <p>
                Yes, we do require all users to be on the same pricing tier.
                It’s not possible to have some users on Professional and others
                on Enterprise (for example).
              </p>
            </div>
            <div className="col-sm-12 col-md-6 col-lg-4">
              <h5>Are there fees for in-app calling?</h5>
              <p>
                Every trial comes with $1 in call credit which is good for about
                50 minutes of talk time. Additional call credit can be purchased
                as needed.
              </p>
            </div>
          </div>
          <div className="row ques">
            <div className="col-sm-12 col-md-6 col-lg-4">
              <h5>Can I use Reach prospecting without using Sell?</h5>
              <p>
                Reach is deeply integrated prospecting experience and
                unfortunatelly cannot be used independently of Sell.
              </p>
            </div>
            <div className="col-sm-12 col-md-6 col-lg-4">
              <h5>What features does the trial include?</h5>
              <p>
                The 14-day evaluation trial includes all Sell features. When
                you're ready to activate your account, you'll have the option to
                select which plan you want to use.
              </p>
            </div>
            <div className="col-sm-12 col-md-6 col-lg-4">
              <h5>Can't find the answer you’re looking for?</h5>
              <p>
                Feel free to
                <a href="https://usmanlive.com">contact us</a>
                for more information or visit our
                <a href="https://usmanlive.com">support center</a>. We’re here
                to help.
              </p>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

export default Pricing;
