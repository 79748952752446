import React, { Component } from "react";
import ColorPicker from "material-ui-color-picker";
import Input from "./../utils/bootstapInputTag";
import { styleChanged } from "./../Properties/styleActions";
import { changeLabel } from "./../../../store/actions/action-functions/changeLabel";
import { changeName } from "../../../store/actions/action-functions/changeName";
import MaterialSelect from "./../utils/bootstrapSelect";
import "./../Properties/properties.css";
class AllProperties extends Component {
  state = {
    collapsed: false
  };
  render() {
    return null;
  }
  giveMeColorPicker = property => {
    return (
      <ColorPicker
        style={{
          autoWidth: true
        }}
        name={property}
        defaultValue="#000"
        onChange={color => {
          this.handlingColor(color);
        }}
      />
    );
  };

  giveMeSelect = (name, options, component) => {
    return (
      <MaterialSelect name={name} options={options} component={component} />
    );
  };

  giveMeInput = (
    name,
    component,
    type = "text",
    step = "",
    min = "1",
    max = "200"
  ) => {
    return (
      <Input
        name={name}
        value={component != undefined && component.properties.styles[name]}
        component={component}
        type={"text"}
        className="small btn"
        min={min}
        max={max}
        step={step}
        onChange={(component, e => this.handlingChange(component, e))}
      />
    );
  };

  giveMeLabelChangeInput = (name, component, type = "text", step = "") => {
    return (
      <Input
        name={name}
        value={component && component.label}
        // className="small"
        component={component}
        type={type}
        step={step}
        onChange={(component, e => this.handlingLabelChange(component, e))}
      />
    );
  };
  handlingLabelChange = (component, e) => {
    changeLabel(component.id, e.target.value);
  };
  // giveMeNameChangeInput =
  handlingChange = (component, e) => {
    if (e.target.name === "name") {
      changeName(component.id, e.target.value);
      return;
    }
    //("Handling Change:", e.target.name);
    const value = e.target.value;
    const property = e.target.name;
    window.store.dispatch(styleChanged(value, property, this.props.component));
  };

  giveMeRow = (property, value, value1) => {
    return (
      <tr hidden={this.state.collapsed}>
        <td colSpan={1} className="property">
          {property}
        </td>
        <td colSpan={3}>{value}</td>
      </tr>
    );
  };
  giveMeRowWithTwoProperties = (property, value, property2, value2) => {
    return (
      <tr hidden={this.state.collapsed}>
        <td className="property">{property}</td>
        <td>{value}</td>
        <td className="property">{property2}</td>
        <td>{value2}</td>
      </tr>
    );
  };
  handlingColor = color => {
    //("Ny property is " + color);
    // //(color);
    window.store.dispatch(
      styleChanged(color.hex, "backgroundColor", this.props.component)
    );
  };
  handleCollpase = () => {
    this.setState({
      collapsed: !this.state.collapsed
    });
  };
}

export default AllProperties;
