/** @format */

import React, { Component } from "react";
import { ChromePicker } from "react-color";

import { changeStyle } from "./../../../store/actions/action-functions/changeStyle";
import { Popover } from "@material-ui/core";
import InputTagRenderer from "../utils/inputRenderer";
import { connect } from "react-redux";
class ColorProperties extends Component {
  state = {
    displayColorPicker: false,
    editable: false,
    anchorEl: null,
    color: {
      r: "241",
      g: "112",
      b: "19",
      a: "1",
    },
  };

  handleClick = () => {
    this.setState({ displayColorPicker: !this.state.displayColorPicker });
  };

  handleClose = () => {
    this.setState({ displayColorPicker: false });
  };

  handleChange = (color, name) => {
    changeStyle(this.props.component.id, name, color.hex);
  };

  render() {
    const { component, name, designer } = this.props;
    const open = Boolean(this.state.anchorEl);
    const html_id = open ? "component" + component.id : "";
    let color = component.properties.styles[name];
    if (!color) color = "#ffffff00";
    return (
      <React.Fragment>
        {this.chooseIcon(name)}
        <Popover
          id={html_id}
          open={open}
          onClose={this.handleClose}
          anchorEl={this.state.anchorEl}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
        >
          <ChromePicker
            color={color}
            onChange={(color) => this.handleChange(color, name)}
          />
        </Popover>
      </React.Fragment>
    );
  }
  hanldeDoubleClick = (e) => {
    this.setState({ anchorEl: e.currentTarget });
  };
  handleClose = () => {
    this.setState({ anchorEl: null });
  };
  chooseIcon(name) {
    let color = "#A19F9F";
    // if (!color) color = "#0069D9";
    switch (name) {
      case "color":
        return (
          <i
            title={"Choose Color"}
            style={{
              fontSize: "24px",
              color: color,
              cursor: "pointer",
            }}
            onClick={(e) => this.setState({ anchorEl: e.currentTarget })}
            className="fas fa-tint ml-3 mt-2"
          ></i>
        );
      case "backgroundColor":
        return (
          <i
            title={"Choose Background Color"}
            style={{
              color: color,
              fontSize: "20px",
              cursor: "pointer",
            }}
            onClick={(e) => this.setState({ anchorEl: e.currentTarget })}
            className="fas fa-palette  ml-3"
          ></i>
        );
      default:
        return (
          <i
            title={"Choose Background Color"}
            style={{
              color: color,
              fontSize: "24px",
              cursor: "pointer",
            }}
            onClick={(e) => this.setState({ anchorEl: e.currentTarget })}
            className="fas fa-palette fas-2x mt-2 "
          ></i>
        );
    }
  }
}
const mapStateToProps = (state) => state;
export default connect(mapStateToProps)(ColorProperties);
