/** @format */

import React, { Component } from "react";
import { changeStyle } from "../../../store/actions/designerActions";

class AlignContentBar extends Component {
  state = {};
  render() {
    const style = {
      paddingTop: "7px",
      fontSize: "20px",
      width: "30px",
      height: "30px",
      background: "#ECECEC",
      marginLeft: "7px",
      cursor: "pointer",
    };

    return (
      <>
        <td></td>
        <td>
          <div
            className="mt-2"
            style={{
              textAlign: "center",
              alignContent: "center",
              justifyContent: "center",
            }}
          >
            {/* <i
          style={style}
          className="fa fa-retweet makeClickable"
          onClick={toggleProps}
        /> */}
            <i
              style={style}
              className="fas fa-align-left "
              onClick={() => this.handlingContentAlign("flex-start")}
            />
            <i
              style={style}
              className="fas fa-align-center "
              onClick={() => this.handlingContentAlign("center")}
            />
            <i
              style={style}
              className="fas fa-align-right "
              name="right"
              onClick={() => this.handlingContentAlign("flex-end")}
            />
          </div>
        </td>
      </>
    );
  }
  // handleCenter = (e) => {
  //   changeStyle(this.props.component.id, "float", null);
  //   changeStyle(this.props.component.id, "textAlign", e);
  // };
  handlingContentAlign = (value) => {
    // changeStyle(this.props.component.id, "textAlign", null);
    changeStyle(this.props.component.id, "alignSelf", value);
  };
}

export default AlignContentBar;
