/** @format */

import React from "react";
import flow from "lodash/flow";
import { DragSource, DropTarget } from "react-dnd";
import {
  dropSpecs,
  dropCollect,
  dragSpecs,
  dragCollect,
} from "./utils/dndMethods";
import splittingAndMakingObject from "./utils/splitingKeys";
import { highLightKey } from "../../../../store/actions/action-functions/addBindedId";
import {
  removeBindedId,
  manageBindedId,
} from "../../../../store/actions/action-functions/manageBindedIds";
import { getDimensionValue } from "../../Properties/utils/changeToHtmlUnits";
import { basicProps } from "./shared/BasicProps";
import { changePropertiesComponent } from "../../../../store/actions/designerActions";
const ComponentViewerRadioButton = ({ id, hover, connectDragSource }) => {
  const basicObject = basicProps();
  const component = basicObject.components[id];
  const componentStyles = component.properties.styles;
  const width = getDimensionValue(componentStyles.width);
  const height = getDimensionValue(componentStyles.height);
  const className = component.properties.customClassName;
  const alreadyBinded = basicObject.idsBinded.find((x) => x == component.id);
  let arrangingStyles = {
    ...componentStyles,
    width,
    height,
    position: "relative",
  };
  return (
    <input
      style={{
        ...component.properties.styles,
        opacity: hover ? 0 : 1,
        border:
          (basicObject.heightLightComponent === id &&
            basicObject.disableDrag) ||
          alreadyBinded
            ? "3px solid black"
            : component.properties.styles.border,
        ...arrangingStyles,
      }}
      className={className}
      ref={(instance) => connectDragSource(instance)}
      type="radio"
      value={component.properties.styles.value}
      onClick={(e) => {
        e.stopPropagation();
        if (
          basicObject.disableSelection &&
          component.id !== basicObject.idToBeBinded &&
          !alreadyBinded
        ) {
          manageBindedId(component.id);
        }
        if (
          basicObject.disableSelection &&
          component.id !== basicObject.idToBeBinded &&
          alreadyBinded
        ) {
          removeBindedId(component.id);
        }
        if (!basicObject.disableDrag && !basicObject.disableSelection)
          changePropertiesComponent(id);
        if (basicObject.disableDrag)
          highLightKey(splittingAndMakingObject(component.node_id));
      }}
    />
  );
};

export default flow(
  DropTarget("modules", dropSpecs, (connect, monitor) =>
    dropCollect(connect, monitor)
  ),
  DragSource("modules", dragSpecs, (connect, monitor) =>
    dragCollect(connect, monitor)
  )
)(ComponentViewerRadioButton);
