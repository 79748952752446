/** @format */

export const ADD_ARTICLE = "ADD_ARTICLE";
export const DRAG_START = "DRAGSTART";
export const DRAG_END = "DRAGEND";
export const DRAG_ACTIVITY_START = "DRAG_ACTIVITY_START";
export const DRAG_ACTIVITY_END = "DRAG_ACTIVITY_END";
export const CHANGE_SELECTED_NODE = "CHANGE_SELECTED_NODE";

//desiner Tree Operations
export const ADD_ACTIVITY_ACTION = "ADD_ACTIVITY_ACTION";
export const CHANGE_SELECTED_ACTIVITY = "CHANGE_SELECTED_ACTIVITY";
export const DUPLICATE_COMPONENT_ACTION = "DUPLICATE_COMPONENT_ACTION";
export const CUT_COMPONENT_ACTION = "CUT_COMPONENT_ACTION";
export const PASTE_COMPONENT_ACTION = "CUT_COMPONENT_ACTION";

export const ADD_COMPONENT_TO_TREE = "ADD_COMPONENT_TO_TREE";
export const DELETE_COMPONENT_FROM_TREE = "DELETE_COMPONENT_FROM_TREE";

export const ADD_PROPERTIES_OBJECT = "ADD_PROPERTIES_OBJECT";

// properties operations
export const CHANGE_COMPONENT_ATTRIBUTE = "CHANGE_COMPONENT_ATTRIBUTE";
export const PROPERTY_CHANGE_NAME = "PROPERTY_CHANGE_NAME";
export const PROPERTY_CHANGE = "PROPERTY_CHANGE";
export const STYLE_CHANGE = "STYLE_CHANGE";
export const ADD_MISSING_PROPERTY = "ADD_MISSING_PROPERTY";
export const BULK_STYLE_CHANGE = "BULK_STYLE_CHANGE";
export const STYLE_OBJECT_CHANGE = "STYLESTY_OBJECT_CHANGE";
//Network operations
export const CHANGE_SELECTED_NODE_PROPERTIES =
    "CHANGE_SELECTED_NODE_PROPERTIES";
export const ADD_NETWORK_OBJECT = "ADD_NETWORK_OBECT";
export const ADD_STYLES_OBJECT = "ADD_STYLES_OBECT";
export const ADD_NETWORK_OBJECT_PROPERTY = "ADD_NETWORK_OBECT_PROPERTY";
//Screen Addition

export const CHANGE_THEME = "CHANGE_THEME";

export const FETCH_RESPONSE = "FETCH_RESPONSE";
export const LOAD_DESIGNER_FROM_JSON_OBJECT = "LOAD_DESIGNER_FROM_JSON_OBJECT";
export const LOAD_DESIGNER_FROM_COMPONENTS = "LOAD_DESIGNER_FROM_COMPONENTS";

export const MOVE_COMPONENT_UP = "MOVE_COMPONENT_UP";
export const MOVE_COMPONENT_DOWN = "MOVE_COMPONENT_DOWN";

// Moving components between the layouts
export const MOVE_FROM_LAYOUT_TO_OTHER = "MOVE_FROM_LAYOUT_TO_OTHER";
// changing label
export const CHANGE_LABEL = "CHANGE_LABEL";

// BLL Actions
export const ADD_FUNCTION = "ADD_FUNCTION";
export const UPDATE_MODEL_TO_FUNCTION = "UPDATE_MODEL_TO_FUNCTION";
export const UPDATE_NODE_TO_FUNCTION_MODEL = "UPDATE_NODE_TO_FUNCTION_MODEL";
//Highlighting The Binded Component on click of key

export const HEIGHLIGHT = "HEIGHLIGHT";
export const HIGHLIGHT_KEY = "HIGHLIGHT_KEY";
export const ADD_CUSTOM_CLASS = "ADD_CUSTOM_CLASS";

export const MAKE_NAVIGATABLE_ACTIVITY = "MAKE_NAVIGATABLE_ACTIVITY";
export const CHANGE_BACKGROUND_COLOR = "BACKGROUND_COLOR";
export const RESET_BACKGROUND_COLOR = "RESET_BACKGROUND_COLOR";

export const COPY_TO_CLIPBOARD = "COPY_TO_CLIPBOARD";
export const CUT_PASTE_SUCCESSFULL = "CUT_PASTE_SUCCESSFULL";
export const EXECUTE_PASTE_ACTION = "EXECUTE_PASTE_ACTION";
export const MAKE_MAIN_ACTIVITY = "MAKE_MAIN_ACTIVITY";
export const START_BINDING_PROCESS = "START_BINDING_PROCESS";
export const BINDED_IDS = "BINDED_IDS";
export const REMOVE_BINDED_IDS = "REMOVE_BINDED_IDS";

// disabling Selection and dragging for resizing
export const DISABLE_SELECTING = "DISABLE_SELECT";

//Custom Css Files Adding

export const SHOW_CSS_EDITOR = "SHOW_CSS_EDITOR";
export const ADD_CUSTOM_CSS = "ADD_CUSTOM_CSS";

export const CHANGE_SELECTED_DIALOGE = "CHANGE_SELECTED_DIALOGE";
export const STORY_MODE = "STORY_MODE";

export const TOGGLE_PADDING = "TOGGLE_PADDING";
export const TOGGLE_MARGIN = "TOGGLE_MARGIN";

export const UPDATE_RECENT_COMPONENTS = "UPDATE_RECENT_COMPONENTS";
export const ENABLE_TRANSITION_ACTIVITY = "ENABLE_TRANSITION_ACTIVITY";
export const SET_TRANSITION_TARGET = "SET_TRANSITION_TARGET";

export const DRAG_IN_PROGRESS = "DRAG_IN_PROGRESS";
export const IN_TRANSITION_MODE = "IN_TRANSITION_MODE";
