import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import ButtonGroup from "@material-ui/core/ButtonGroup";
const useStyles = makeStyles(theme => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
      width: 200
    }
  }
}));
const AssetsNewFolderForm = ({ newFolderStatus, onCancel, onSave }) => {
  const classes = useStyles();
  const [folderName, setFolderName] = useState("");
  if (!newFolderStatus) return null;
  return (
    <div>
      <TextField
        id="standard-basic"
        label="New Folder"
        value={folderName}
        onChange={e => setFolderName(e.target.value)}
      />
      <ButtonGroup size="small" aria-label="small outlined primarybutton group">
        <Button
          onClick={e => {
            onSave(folderName);
            setFolderName("");
          }}
        >
          Save
        </Button>
        <Button
          onClick={e => {
            setFolderName("");
            onCancel();
          }}
        >
          Cancel
        </Button>
      </ButtonGroup>
    </div>
  );
};

export default AssetsNewFolderForm;
