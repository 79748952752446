// we should have factory functions for all types of components to produce objects
//every factory is a function with all of the default parameters
//e.g. you can call just a function without any parameter
import baseFactory from "./baseFactory";
import initFactory from "./initFactory";
import activityFactory from "./activityFactory";
import verticalFactory from "./verticalFactory";
import verticalFactoryBase from "./verticalFactoryBase";
import textViewFactory from "./textViewFactory";
import buttonFactory from "./buttonFactory";
import listViewFactory from "./listViewFactory";
import horizontalFactory from "./horizontalFactory";
import fragmentFactory from "./fragmentFactory";
import viewPagerFactory from "./viewPagerFactory";
import editTextFactory from "./editTextFactory";
import dropDownFactory from "./dropDownFactory";
import radioGroupFactory from "./radioGroupFactory";
import checkBoxFactory from "./checkBoxFactory";
import radioButtonFactory from "./radioButtonFactory";
import imageFactory from "./imageFactory";
import imageButtonFactory from "./imageButtonFactory";
import recycleViewGridFactory from "./recycleViewGridFactory";
import recycleViewListFactory from "./recycleViewListFactory";
import dividerFactory from "./dividerFactory";
import relativeLayoutFactory from "./relativeLayoutFactory";
import drawableFactory from "./drawableFactory";
import tableLayoutFactory from "./tableLayoutFactory";
import gridViewFactory from "./gridViewFactory";
import freelayoutFactory from "./freelayoutfactory";
import iconButtonFactory from "./iconButtonFactory";
import horizontalListViewFactory from "./horizontalListViewFactory";
export default {
    baseFactory,
    initFactory,
    activityFactory,
    verticalFactory,
    verticalFactoryBase,
    textViewFactory,
    buttonFactory,
    listViewFactory,
    horizontalFactory,
    fragmentFactory,
    viewPagerFactory,
    editTextFactory,
    dropDownFactory,
    radioGroupFactory,
    checkBoxFactory,
    radioButtonFactory,
    imageFactory,
    imageButtonFactory,
    recycleViewGridFactory,
    recycleViewListFactory,
    dividerFactory,
    relativeLayoutFactory,
    drawableFactory,
    tableLayoutFactory,
    gridViewFactory,
    freelayoutFactory,
    iconButtonFactory,
    horizontalListViewFactory
};