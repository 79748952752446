/** @format */

import common from "./common";
import { COMPONENT_ACTIVITY } from "../constants/typeConstants";

import verticalFactory from "./verticalFactory";

/**
 * factory for activity creation
 * @param {String} name of the activity
 * @param {Object} layout of the activity. Default is horizontal
 */
export default function(name = "Activity", layout = verticalFactory()) {
    delete layout.properties;
    layout.properties = {};
    layout.properties.styles = {};
    layout.properties.styles = {
        width: "100%",
        height: "100%",
        display: "inline-block",
    };
    let obj = {
        name: name,
        type: COMPONENT_ACTIVITY,
        children: [layout],
    };

    obj = Object.assign(obj, common);
    delete obj.properties;
    obj.properties = {};
    obj.properties.styles = {};
    obj.properties.styles = {
        // width: "100%",
        // height: "100%",
        // display: "inline-block",
    };
    obj.isNavigatable = false;
    return obj;
}