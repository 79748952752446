/** @format */

export default {
    properties: {
        styles: {},
        network: {},
        customClassName: "",
    },
    time: "1h",
    query: "",
    variableName: "",
    variableType: "String",
    adapterReference: "",
    className: "",
    accessModifier: "",
    rulesAssociated: "",
    actionType: "",
    transitionTarget: "",
    fieldsForDatabase: "",
    responseJson: JSON.stringify({
        keys: {},
        isArray: true,
    }),
    requestJSON: null,
    responseJSON: null,
    requestPort: "",
    requestPath: "",
    requestType: "GET",
    url: null,
    completePath: "",
    isTransitionAttached: false,
    isScopeDB: false,
    isScopeNetwork: false,
    isActivityTransition: false,
    isMainActivity: false,
    filePath: "",
    responseBindings: null,
    isStatic: true,
};