export const BLL_VARIABLE_TYPE_BOOLEAN = "boolean";
export const BLL_VARIABLE_TYPE_BYTE = "byte";
export const BLL_VARIABLE_TYPE_INTEGER = "integer";
export const BLL_VARIABLE_TYPE_LONG = "long";
export const BLL_VARIABLE_TYPE_FlOAT = "float";
export const BLL_VARIABLE_TYPE_NAME = "name";
export const BLL_VARIABLE_TYPE_STRING = "string";
export const BLL_VARIABLE_TYPE_ARRAY = "array";
export const BLL_VARIABLE_TYPE_OBJECT = "object";
let BllVariableTypes = {};
BllVariableTypes[BLL_VARIABLE_TYPE_BOOLEAN] = {
  name: "Boolean",
  color: "#404EE6",
  type: BLL_VARIABLE_TYPE_BOOLEAN
};
BllVariableTypes[BLL_VARIABLE_TYPE_BYTE] = {
  name: "Byte",
  color: "#404EE6",
  type: BLL_VARIABLE_TYPE_BYTE
};
BllVariableTypes[BLL_VARIABLE_TYPE_INTEGER] = {
  name: "Integer",
  color: "#404EE6",
  type: BLL_VARIABLE_TYPE_INTEGER
};
BllVariableTypes[BLL_VARIABLE_TYPE_LONG] = {
  name: "Long",
  color: "#404EE6",
  type: BLL_VARIABLE_TYPE_LONG
};
BllVariableTypes[BLL_VARIABLE_TYPE_FlOAT] = {
  name: "Float",
  color: "#404EE6",
  type: BLL_VARIABLE_TYPE_FlOAT
};
BllVariableTypes[BLL_VARIABLE_TYPE_NAME] = {
  name: "Name",
  color: "#404EE6",
  type: BLL_VARIABLE_TYPE_NAME
};
BllVariableTypes[BLL_VARIABLE_TYPE_STRING] = {
  name: "String",
  color: "#404EE6",
  type: BLL_VARIABLE_TYPE_STRING
};
BllVariableTypes[BLL_VARIABLE_TYPE_ARRAY] = {
  name: "Array",
  color: "#404EE6",
  type: BLL_VARIABLE_TYPE_ARRAY
};
BllVariableTypes[BLL_VARIABLE_TYPE_OBJECT] = {
  name: "Object",
  color: "#404EE6",
  type: BLL_VARIABLE_TYPE_OBJECT
};

export function getVariableTypeColorByType(type) {
  let keys = Object.keys(BllVariableTypes);
  let color = "black";
  keys.map(key => {
    if (BllVariableTypes[key].type === type)
      color = BllVariableTypes[key].color;
  });
  return color;
}
/**
 * return the bll implemented type
 * @param {String} type
 */
export function getVariableTypeByType(type) {
  let keys = Object.keys(BllVariableTypes);
  for (var i = 0; i < keys.length; i++) {
    if (type == keys[i]) return type;
  }
  return BLL_VARIABLE_TYPE_STRING;
}
export default BllVariableTypes;
