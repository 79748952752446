import React, { Component } from "react";
//import { ADD_ENTITY } from "../../store/constants/schema-actions";

class coloumnsEditor extends Component {
  state = { newColoumnName: "", newEntity: "" };
  handleAddNewOnChange = e => {
    this.setState({ newEntity: e.target.value });
  };
  render() {
    const { entity } = this.props;
    return (
      <React.Fragment>
        <div className="right-side col-12">
          <div className="left-side-heading">
            <h5>
              Columns of: <b>"{entity.name}"</b> Entity
            </h5>
          </div>
          <input
            type="text"
            placeholder="Update Column Name"
            className="column-input col-6"
            value={entity.name}
            onChange={this.handleAddNewOnChange}
          />
          <button className="btn btn-success btn-sm update-btn col-4">
            Update Name
          </button>
          <input
            type="text"
            placeholder="New Column"
            className="column-input col-4"
            value={this.state.newColoumnName}
            onChange={e => this.setState({ newColoumnName: e.target.value })}
          />
          <select className="select_list col-3" placeholder="select data type">
            <option value="varchar">Varchar</option>
            <option value="int">Int</option>
            <option value="float">Float</option>
            <option value="binary">Binary</option>
            <option value="date/time">Date/Time</option>
          </select>

          <button className="btn btn-success btn-sm column-add-btn col-2">
            Add
          </button>

          <ul className="list-group">
            {entity.coloumns.map((col, index) => (
              <li key={index} className="list-group-item column-item">
                {col}
                <button className="btn btn-default btn-sm float-right remove-btn">
                  Remove
                </button>
              </li>
            ))}
          </ul>

          {/* {entity.coloumns.map((col, index) => (
                <tr>
                  <td>
                    <div className="form-group">
                     
                      <input
                        type="text"
                        placeholder="New Coloumn"
                        className="form-control column-input"
                        value={col}
                        onChange={(e) =>
                          this.setState({ newColoumnName: e.target.value })
                        }
                      />
                    </div>
                  </td>
                  <td />
                </tr>
              ))} */}
        </div>
      </React.Fragment>
    );
  }
}

export default coloumnsEditor;
