import React, { Component } from "react";
import "./Signup.css";
import "bootstrap/dist/css/bootstrap.css";
import "font-awesome/css/font-awesome.min.css";
import { toast } from "react-toastify";
import image from "./Capture.PNG";
import axios from "axios";
import { SIGNUP_URL } from "../../../../../../store/constants/apiURLS";
import { Tooltip } from "@material-ui/core";

// import { Container } from "react-bootstrap";
axios.interceptors.response.use(
  function(response) {
    return response;
  },
  function(error) {
    return Promise.reject(error);
  }
);
class Signup extends Component {
  state = {
    name: "",
    email: "",
    password: "",
    confirmPassword: "",
    showPassword: false,
    agree: false

    // showPassword: false,
  };
  handleChange = e => {
    const name = e.target.name;
    const value = e.target.value;
    this.setState({ [name]: value });
  };
  handleConfirmPassword = event => {
    this.setState({ confirmPassword: event.target.value });
  };
  resetState() {
    const name = "",
      password = "",
      email = "",
      confirmPassword = "";
    this.setState({ name, password, email, confirmPassword });
  }
  handleClick = async e => {
    e.preventDefault();
    if (
      (this.state.name &&
        this.state.password &&
        this.state.email &&
        this.state.confirmPassword) !== ""
    ) {
      if (this.state.password !== this.state.confirmPassword) {
        toast.error("password does'nt matches");
      } else if (!this.state.agree) {
        toast.error("Please Agree ");
      } else {
        try {
          const { name, email, password } = this.state;
          const requestData = { name, email, password };
          const { data } = await axios.post(SIGNUP_URL, requestData);
          // //("");
          toast.success("Sign Up Successfull Login Now");
          this.resetState();
          setTimeout(() => (window.location = "/login"), 1000);
          // //(data);
        } catch (error) {
          // //(error);
          toast.error(error);
        }
      }
    } else {
      toast.error("kindly fill all fields");
    }
  };
  render() {
    return (
      <>
        <div className="signup-page">
          <div className="row">
            <div className="col-md-4 text-side">
              <h2>
                <b>You are few steps away from building your app</b>
                <br />
                The most beautiful and efficient way to develop hybrid and
                mobile web apps.
              </h2>
            </div>
            <div className="col-md-4 picture-side">
              <img src={image} alt="Mobile Photo Here" />
            </div>
            <div class="d-flex h-100 col-md-4">
              <div className="signup-card ">
                <div className="d-flex justify-content-center signup-card-header">
                  <b>Sign Up</b>
                </div>
                <div className="signup-card-body">
                  <form onSubmit={this.handleClick}>
                    <div className="input-group form-group">
                      <div className="input-group-prepend extra-styles">
                        <span className="input-group-text">
                          <i className="fa fa-user" />
                        </span>
                      </div>
                      <input
                        type="text"
                        value={this.state.name}
                        className="form-control"
                        placeholder="Name"
                        name="name"
                        pattern="^(\w\w+)\s(\w+)$"
                        onChange={this.handleChange}
                        required
                      />
                    </div>
                    <div className="input-group form-group">
                      <div className="input-group-prepend extra-styles">
                        <span className="input-group-text">
                          <i className="fa fa-at" />
                        </span>
                      </div>
                      <input
                        type="email"
                        value={this.state.email}
                        className="form-control"
                        placeholder="Email"
                        name="email"
                        pattern="[^@\s]+@[^@\s]+\.[^@\s]+"
                        onChange={this.handleChange}
                        required
                      />
                    </div>
                    <div className="input-group form-group">
                      <div className="input-group-prepend extra-styles">
                        <span className="input-group-text">
                          <i className="fa fa-key" />
                        </span>
                      </div>
                      <input
                        type={this.state.showPassword ? "text" : "password"}
                        className="form-control"
                        placeholder="password"
                        name="password"
                        value={this.state.password}
                        style={{ borderRight: "none" }}
                        pattern="^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{5,20}$"
                        onChange={this.handleChange}
                        required
                      />
                      <div
                        onClick={e =>
                          this.setState({
                            showPassword: !this.state.showPassword
                          })
                        }
                        className="input-group-prepend  sample"
                      >
                        <span className="input-group-text">
                          <i
                            className={
                              this.state.showPassword
                                ? "fa fa-eye"
                                : "fa fa-eye-slash"
                            }
                            style={{ fontSize: "10px" }}
                          />
                        </span>
                      </div>
                      <Tooltip
                        title={
                          "Password Must include a character number upper case alphabet Length should be greater than 4 "
                        }
                      >
                        <div className="input-group-prepend  info ">
                          <span
                            className="input-group-text"
                            style={{
                              borderRadius: 2,
                              borderTopRightRadius: 2,
                              borderBottomRightRadius: 2
                            }}
                          >
                            <i
                              className="fa fa-info"
                              style={{
                                fontSize: "10px",
                                color: "white"
                              }}
                            />
                          </span>
                        </div>
                      </Tooltip>
                      {/*  */}

                      {/*  */}
                    </div>
                    <div className="input-group form-group">
                      <div className="input-group-prepend extra-styles">
                        <span className="input-group-text">
                          <i className="fa fa-lock" />
                        </span>
                      </div>
                      <input
                        type="password"
                        className="form-control"
                        placeholder="Confirm password"
                        value={this.state.confirmPassword}
                        onChange={this.handleConfirmPassword}
                        required
                      />
                    </div>
                    <div className="agree-group">
                      <input
                        className="agree-check"
                        onClick={e => this.setState({ agree: e.target.value })}
                        type="checkbox"
                        value={this.state.agree}
                        required
                      />
                      <span className="agreement">I agree to</span>
                      <a target="_blank" href="/terms-of-use">
                        Terms of Use
                      </a>
                      <span className="agreement"> and </span>
                      <a target="_blank" href="/privacy-statement">
                        Privacy Statement
                      </a>
                    </div>
                    {/* <div className="agree-group">
                      <input className="agree-check" type="checkbox" />
                      <span className="agreement">
                        I agree to recieve emails
                      </span>
                    </div> */}
                    <div className="form-group d-flex justify-content-center">
                      <button
                        className="btn signup_btn"
                        // onClick={this.handleClick}
                      >
                        <b>Sign Up</b>
                      </button>
                    </div>
                  </form>
                </div>
                {/* <div className="signup-card-footer">
                    <div className="d-flex justify-content-center social link">
                      <p>Signup with:</p>
                      <span>
                        <i className="fa icon fa-facebook-square" />
                      </span>
                      <span>
                        <i className="fa icon fa-google-plus-square" />
                      </span>
                      <span>
                        <i className="fa icon fa-twitter-square" />
                      </span>
                      <span>
                        <i className="fa icon fa-github-square" />
                      </span>
                    </div>
                  </div> */}
                <div className="signup-card-footer">
                  <div className="d-flex justify-content-center link">
                    <p>Already have an account?</p>
                    <a href="/login">LogIn</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Signup;
