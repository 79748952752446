import {
    LOAD_DESIGNER_FROM_JSON_OBJECT
} from "../../constants/action-types";
import DesignerTreeHandler from "../../reducers/handlers/DesignerTreeHandler";
import {
    LOAD_DESIGNER_FROM_COMPONENTS
} from "./../../constants/action-types";
import produce from "immer";

export function loadDesignerFromJSONOObject(jsonObject) {
    window.store.dispatch({
        type: LOAD_DESIGNER_FROM_JSON_OBJECT,
        payLoad: jsonObject,

    });
}
export function loadDesignerFromComponentsObject(components, _id = null) {
                                                                           // //(components[7].label);
                                                                           window.store.dispatch(
                                                                             {
                                                                               type: LOAD_DESIGNER_FROM_COMPONENTS,
                                                                               payLoad: {
                                                                                 components,
                                                                                 _id,
                                                                               },
                                                                             }
                                                                           );
                                                                         }

export function loadDesignerFromComponentsReducer(state, action) {
    const appId = action.payLoad._id;
    const alreadyLoaded = state.loadedApps.find(app => app._id == appId)
    let components = action.payLoad.components;
    return produce(state, draftState => {
        draftState.components = components;
        draftState.selectedScreenID = 3;
        draftState.componentClipBoard = 0;
        draftState.selectedPropertiesScreenID = 4;
        draftState.selectedComponentId = 4;
        if (!alreadyLoaded)
            draftState.loadedApps.push({
                _id: appId,
                components
            })
    });
}
export function loadDesignerFromJSONOObjectReducer(state, action) {
    const components = DesignerTreeHandler.convertJSONToComponents(
        action.payLoad
    );
    loadDesignerFromComponentsObject(components);
    return state;
}