import React, { Component } from "react";
import "./ContactUs.css";
import { toast } from "react-toastify";
class ContactUs extends Component {
  state = {
    name: "",
    email: "",
    message: ""
  };
  sendEmail = e => {
                     e.preventDefault();
                     /* SmtpJS.com - v3.0.0 */
                     var Email = {
                       send: function (a) {
                         return new Promise(function (n, e) {
                           a.nocache = Math.floor(1e6 * Math.random() + 1);
                           a.Action = "Send";
                           var t = JSON.stringify(a);
                           Email.ajaxPost(
                             "https://smtpjs.com/v3/smtpjs.aspx?",
                             t,
                             function (e) {
                               n(e);
                             }
                           );
                         });
                       },
                       ajaxPost: function (e, n, t) {
                         var a = Email.createCORSRequest("POST", e);
                         a.setRequestHeader(
                           "Content-type",
                           "application/x-www-form-urlencoded"
                         );
                         a.onload = function () {
                           var e = a.responseText;
                           null != t && t(e);
                         };
                         a.send(n);
                       },
                       ajax: function (e, n) {
                         var t = Email.createCORSRequest("GET", e);
                         t.onload = function () {
                           var e = t.responseText;
                           null != n && n(e);
                         };
                         t.send();
                       },
                       createCORSRequest: function (e, n) {
                         var t = new XMLHttpRequest();
                         return (
                           "withCredentials" in t
                             ? t.open(e, n, !0)
                             : "undefined" != typeof XDomainRequest
                             ? (t = new window.XDomainRequest()).open(e, n)
                             : (t = null),
                           t
                         );
                       },
                     };
                     //(this.state.email);
                     Email.send({
                       SecureToken: "dc8239e6-f3a1-43be-a107-252162a90ee9", // write your secure token
                       To: "rajaosamainayat@gmail.com",
                       From: "rajaosamainayat@gmail.com", // to include multiple emails you need to mention an array
                       Subject: "Contact Us Email",
                       Body: this.state.email + " " + this.state.message,
                     })
                       .then((message) => {
                         toast("Thank You For Your Response.");
                         this.setState({ email: "", message: "", name: "" });
                       })
                       .catch((e) => {
                         //(e);
                       });
                   };
  render() {
    return (
      <div className="contactUs row">
        <div className="col-md-2" />
        <div className="col-md-4">
          <div
            style={{
              padding: 5,
              margin: 10,
              marginLeft: 0
              // paddingLeft: 10,
            }}
          >
            <h3>Contact Us</h3>
          </div>
          <form id="contact_form" onSubmit={this.sendEmail}>
            <div>
              <label>Your name:</label>
              <br />
              <input
                type="input"
                className="form-control"
                placeholder="Name"
                style={{
                  width: "80%"
                }}
                onChange={text => this.setState({ name: text.target.value })}
                // required
              />

              <br />
              <span id="name_validation" class="error_message"></span>
            </div>
            <div>
              <label for="email">Your email:</label>
              <br />
              <input
                type="email"
                className="form-control"
                placeholder="Email"
                pattern="[^@\s]+@[^@\s]+\.[^@\s]+"
                style={{
                  width: "80%"
                }}
                onChange={text => this.setState({ email: text.target.value })}
              />

              <br />
              <span id="email_validation" class="error_message"></span>
            </div>
            <div>
              <label class="required" for="message">
                Your message:
              </label>
              <br />
              <textarea
                id="message"
                class="input"
                name="message"
                style={{ borderRadius: 5 }}
                rows="7"
                cols="40"
                onChange={text => this.setState({ message: text.target.value })}
              ></textarea>
              <br />
              <span id="message_validation" class="error_message"></span>
            </div>

            <input id="submit_button" type="submit" value="Send email" />
          </form>
        </div>
        <div className="col-md-4">
          <div style={{ marginTop: 40 }}>
            <h4>How can we help You?</h4>
            <text>
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Culpa est
              asperiores quia eaque similique quam consequatur illum! Dolor hic
              maiores voluptate fugiat earum non, molestias, numquam, a illo
              atque alias.
            </text>
          </div>
          <div style={{ marginTop: 40 }}>
            <h4>What are your suggestions?</h4>
            <text>
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Culpa est
              asperiores quia eaque similique quam consequatur illum! Dolor hic
              maiores voluptate fugiat earum non, molestias, numquam, a illo
              atque alias.
            </text>
          </div>
        </div>
        <div className="col-md-2" />
      </div>
    );
  }
}
ContactUs.propTypes = {};

export default ContactUs;
