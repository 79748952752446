import React from "react";

import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import CancelIcon from "@material-ui/icons/Cancel";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core";
import { PropTypes } from "prop-types";
const useStyles = makeStyles((theme) => ({
  root: { position: "relative", paddingTop: "20px" },
  topBar: {
    minHeight: "50px",
    margin: 0,
    padding: 0,
    position: "relative",
  },
  closeButton: {
    position: "absolute",
    right: 0,
    top: 0,
    // color: theme.palette.grey[500],
  },
  title: { position: "relative", height: "auto", top: "20px" },
  titleContent: {
    paddingLeft: theme.spacing(3),
    fontWeight: "bold",
    fontSize: "1.2rem",
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  leftBar: {
    width: "5px",
    backgroundColor: theme.palette.primary.main,
    float: "left",
    height: "100%",
    position: "absolute",
  },
  content: {
    margin: theme.spacing(2),
    padding: theme.spacing(1),
  },
}));

const AlphaDialogSimple = ({
  open,
  setOpen,
  onClose,
  title,
  children,
  actions,
  fullWidth,
  maxWidth,
}) => {
  // //(maxWidth);
  // //(fullWidth);
  const classes = useStyles();
  const handleClose = () => {
    setOpen(false);
    if (onClose) onClose();
  };
  // //(actions);
  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={open}
      fullWidth={fullWidth}
      maxWidth={maxWidth}
    >
      {/* {onClose && <div className={classes.topBar} />} */}
      <div className={classes.root}>
        {onClose && (
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={onClose}
            color="primary"
          >
            <CancelIcon />
          </IconButton>
        )}
      </div>
      {title && (
        <div className={classes.title}>
          <div className={classes.leftBar}>&nbsp; </div>
          <div className={classes.titleContent}>{title}</div>
        </div>
      )}
      <div className={classes.content}>{children}</div>
      {actions && <MuiDialogActions>{actions}</MuiDialogActions>}
    </Dialog>
  );
};
AlphaDialogSimple.propTypes = {};
AlphaDialogSimple.defaultProps = {
  maxWidth: "sm",
  fullWidth: false,
};
export default AlphaDialogSimple;
