import {
    EXECUTE_PASTE_ACTION
} from "../../constants/action-types";
import produce from "immer";
import {
    duplicateComponent
} from "./duplicateComponent";
import {
    toast
} from "react-toastify";
import {
    checkingIfLayout
} from "../../../components/AppDesigner/utils/shortcutkey/shortcutmanager";
import {
    revertCutStatus
} from "./cutComponent";


export function pasteComponent() {
    window.store.dispatch({
        type: EXECUTE_PASTE_ACTION,
    })
}


export function pasteComponentReducer(state, action) {
    const id = state.selectedPropertiesScreenID;
    const components = state.components;
    const isCut = state.componentClipBoard;
    if (checkingIfLayout(id, components)) {
        window.navigator.clipboard.readText().then((data) => {
                                                               const ifExists =
                                                                 components[
                                                                   data
                                                                 ] && true;
                                                               //(ifExists);
                                                               if (
                                                                 data &&
                                                                 data !=
                                                                   "null" &&
                                                                 Number(data) &&
                                                                 ifExists
                                                               ) {
                                                                 duplicateComponent(
                                                                   data,
                                                                   id
                                                                 );
                                                                 toast.success(
                                                                   "Pasting Successfull"
                                                                 );
                                                               }
                                                             })
        if (isCut) {
            window.navigator.clipboard.writeText(null);
            return produce(state, drafState => {
                drafState.componentClipBoard = false;
            })
        };
    }
    return state;
}