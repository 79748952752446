/** @format */

import React from "react";
import { findDOMNode } from "react-dom";
import { addComponentByPosition } from "./../../../../../store/actions/action-functions/addComponent";
import {
    moveComponentByDraggingUp,
    moveComponentByDraggingDown,
    moveComponentFromLayouts,
    moveComponentToEmptyLayout,
} from "./../../../../../store/actions/action-functions/moveComponent";
import droppedComponent from "./../utils/determiningComponentDragged";

import { addBindings } from "../../../../../store/actions/action-functions/updateBinding";
import { toast } from "react-toastify";

export function dragCollect(connect, monitor) {
    const { components, disableDrag } = window.store.getState().designer;

    return {
        connectDragSource: connect.dragSource(),
        isDragging: monitor.isDragging(),
    };
}

export const dragSpecs = {
    beginDrag(props, monitor, component) {
        const { components, disableDrag } = window.store.getState().designer;

        var ReactDOM = require("react-dom");
        const rect = ReactDOM.findDOMNode(component).getBoundingClientRect();

        return {
            id: props.id,
            parent_id: components[props.id].parent_id,
            index: props.id,
            type: "modules",
            rect: rect,
            dragging: monitor.isDragging(),
        };
    },
    canDrag(props, monitor) {
        const {
          components,
          disableDrag,
          transitionMode,
        } = window.store.getState().designer;

        if (disableDrag === true && transitionMode) return false;
        return true;
    },
    endDrag(props, monitor, component) {},
};

export function dropCollect(connect, monitor) {
    const { components, disableDrag } = window.store.getState().designer;

    return {
        connectDropTarget: connect.dropTarget(),
        hover: monitor.isOver({
            shallow: true,
        }),
    };
}

export function calculateWhereDropped(component, monitor) {
    const { components, disableDrag } = window.store.getState().designer;

    const hoverBoundingRect = findDOMNode(component).getBoundingClientRect();
    const clientOffset = monitor.getClientOffset();
    const hoverClientX = clientOffset.x - hoverBoundingRect.left;
    const hoverClientY = clientOffset.y - hoverBoundingRect.top;
    const hoveredComponentCenter =
        (hoverBoundingRect.right - hoverBoundingRect.left) / 2;
    const hoveredComponentCenterVertical =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
    const upwards = hoverClientY > hoveredComponentCenterVertical;
    const downwards = hoverClientY < hoveredComponentCenterVertical;
    const leftwards = hoverClientX > hoveredComponentCenter;
    const rightwards = hoverClientX < hoveredComponentCenter;
    if (leftwards) {
        return {
            draggingLeft: true,
            draggingRight: false,
        };
    }
    if (rightwards) {
        return {
            draggingLeft: false,
            draggingRight: true,
        };
    }
}

// Method below will check for if component is dragged
// from a component already having the parent
// it will take the dragged component--> monitor.getItem()
function swapingComponents(component, monitor) {
    const { components, disableDrag } = window.store.getState().designer;

    const DRAGGED_POSITION = calculateWhereDropped(component, monitor);
    if (DRAGGED_POSITION.draggingRight) {
        moveComponentByDraggingDown(
            monitor.getItem().id,
            component.props.component.id
        );
        return;
    }
    if (DRAGGED_POSITION.draggingLeft) {
        moveComponentByDraggingUp(
            monitor.getItem().id,
            component.props.component.id
        );
        return;
    }
}

function addingNewComponent(component, monitor) {
    const DRAGGED_POSITION = calculateWhereDropped(component, monitor);
    const INCOMING_COMPONENT = droppedComponent(monitor.getItem().name);
    if (DRAGGED_POSITION.draggingLeft) {
        addComponentByPosition(
            component.props.component.id,
            INCOMING_COMPONENT,
            "ABOVE"
        );
        return;
    } else if (DRAGGED_POSITION.draggingRight) {
        addComponentByPosition(
            component.props.component.id,
            INCOMING_COMPONENT,
            "BELOW"
        );
        return;
    }
}

export function checkingForLayout(type) {
    const { components, disableDrag } = window.store.getState().designer;

    switch (type) {
        case "LinearLayout_Horizontal":
            return true;
        case "LinearLayout_Vertical":
            return true;
        case "ListView":
            return true;
        default:
            return false;
    }
}
export const dropSpecs = {
    drop(props, monitor, component) {
                                      const {
                                        components,
                                        disableDrag,
                                      } = window.store.getState().designer;

                                      if (monitor.didDrop()) return;
                                      if (Boolean(monitor.getItem().from)) {
                                        if (
                                          checkingForLayout(
                                            component.props.component.type
                                          )
                                        ) {
                                          toast.error(
                                            "Dropped On Layout:" +
                                              component.props.component.id
                                          );
                                          moveComponentToEmptyLayout(
                                            monitor.getItem().id,
                                            component.props.component.id
                                          );
                                          return;
                                        }

                                        if (
                                          !checkingForLayout(
                                            monitor.getItem().id
                                          ) &&
                                          !checkingForLayout(
                                            component.props.component.type
                                          )
                                        ) {
                                          toast.success(
                                            "Swapping Will be Done.."
                                          );
                                          if (
                                            component.props.component
                                              .parent_id ==
                                            components[monitor.getItem().id]
                                              .parent_id
                                          )
                                            swapingComponents(
                                              component,
                                              monitor
                                            );
                                          if (
                                            component.props.component
                                              .parent_id !=
                                            components[monitor.getItem().id]
                                              .parent_id
                                          ) {
                                            moveComponentFromLayouts(
                                              monitor.getItem().id,
                                              component.props.component.id
                                            );
                                            return;
                                          }
                                        }
                                        return;
                                      }
                                      //(component);
                                      if (Boolean(monitor.getItem().key)) {
                                        //("Entered 1.1");
                                        if (monitor.didDrop()) return;
                                        addBindings(
                                          monitor.getItem().key,
                                          monitor.getItem().value,
                                          component.props.component.id,
                                          monitor.getItem().completeAdress,
                                          monitor.getItem().node_id,
                                          monitor.getItem()
                                            .selectedPropertiesScreenID
                                        );
                                        return {
                                          dorppedId: component.props.id,
                                        };
                                      }
                                      if (
                                        Boolean(monitor.getItem().id) &&
                                        component.props.component.parent_id ==
                                          components[monitor.getItem().id]
                                            .parent_id &&
                                        !Boolean(monitor.getItem().from)
                                      ) {
                                        moveComponentFromLayouts(
                                          monitor.getItem().id,
                                          component.props.component.id
                                        );
                                        return;
                                      }
                                      //();
                                      //("Component is");
                                      //(component);
                                      if (Boolean(monitor) && component) {
                                        if (
                                          !monitor.didDrop() &&
                                          component.props.component.parent_id !=
                                            components[monitor.getItem().id]
                                              .parent_id &&
                                          component.props.component.type !==
                                            "layout"
                                        ) {
                                          moveComponentFromLayouts(
                                            monitor.getItem().id,
                                            component.props.component.id
                                          );
                                          return;
                                        }
                                      }
                                    },

    canDrop(props, monitor) {
        /**    
                                          * *checks if element is dropped over itself
                                          * *if (monitor.getItem().id == props.component.id) return false;
                                          * * Parent cannt be dropped
                                          * * //("Here hi")

                                          * * Following condition checking for if the
                                          * * dragged element is the parent of hovered

                                          * * if yes then it will not let it dropp over it
                                          */
        // //("logging Props In the Can Drop Dnd mETHODS")
        // const screen  =
        const { components, disableDrag } = window.store.getState().designer;

        const checkingForActivity = components[2].children.find(
            (id) => id === props.id
        );
        if (checkingForActivity) return false;

        if (
            components[props.id].name === "Main" ||
            components[props.id].name === "Activity"
        )
            return false;
        const component_dragged = monitor.getItem().id;
        if (component_dragged === components[props.id].parent_id) {
            return false;
        }
        // checking for the grand children
        let component_hovered = props.component;
        while (
            component_hovered &&
            (component_hovered.type !== "Activity" ||
                component_hovered.type !== "Init")
        ) {
            if (component_dragged === component_hovered.parent_id) return false;
            component_hovered = props.components[component_hovered.parent_id];
        }
        if (monitor.getItem().id === components[props.id]) return false;
        if (props.disableDrag) return false;
        return true;
    },

    hover(props, monitor, component) {
        // if (Boolean(monitor.getItem().key)) return;
        // if (Boolean(monitor.getItem().type) && monitor.getItem().type == "layout")
        //   return;
        // if (
        //   !Boolean(monitor.getItem().name) &&
        //   !monitor.didDrop() &&
        //   component.props.component.parent_id !=
        //   props.components[monitor.getItem().id].parent_id &&
        //   component.props.component.type !== "layout"
        // )
        //   return;
        // if (!Boolean(monitor.getItem().name)) {
        //   // const DRAGGED_POSITION = calculateWhereDropped(component, monitor);
        //   hoveredId = component.props.component.id;
        //   const id = monitor.getItem().id;
        //   // parent cannt be dropped over the child component
        //   if (component.props.component.parent_id == monitor.getItem().id) {
        //     //(
        //       component.props.component.parent_id + "__" + monitor.getItem().id
        //     );
        //     return;
        //   }
        //   // const id = monitor.getItem().id;
        //   // Same element check
        //   if (hoveredId === id) {
        //     return;
        //   }
        //   // swapingComponents(component, monitor);
        // }
    },
};
export let hoveredId = -1;