/** @format */

import React, { Component } from "react";
import AllProperties from "./../utils/allPropertiesMethods";
import { giveMeInput } from "./borderProperties";
import CompleteTable from "./utils/TableBodyGenerator";
import { Input } from "@material-ui/core";
import { changeName } from "../../../store/actions/designerActions";
class NameProperty extends AllProperties {
  render() {
    const { component } = this.props;
    return (
      <>
        <td>Name</td>
        <td>
          <Input
            placeholder="Change Component Name"
            onChange={(e) => changeName(component.id, e.target.value)}
            value={component.name}
          />
        </td>
      </>
    );
  }
}

export default NameProperty;
