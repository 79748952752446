import {
  COMPONENT_LISTVIEW
} from "../constants/typeConstants";
import common from "./common";
import horizentalFactory from "./horizontalFactory"
/**
 * factory to produce listview
 * @param {*} name
 * @param {*} layout of the listview
 */
export default function (name = "List_View", layout = {}) {
  let obj = {
    name: name,
    type: COMPONENT_LISTVIEW,
    children: []
  };
  obj = Object.assign(obj, common);
  delete obj.properties;
  obj.properties = {
    properties: {}
  }
  const defaultStyles = {
    width: "match_parent",
    height: "match_parent"
  }
  obj.properties.styles = defaultStyles;
  return obj;
}