/** @format */

import { useSelector } from "react-redux";

export function basicProps() {
  const {
    disableSelection,
    idToBeBinded,
    idsBinded,
    components,
    selectedPropertiesScreenID,
    heightLightComponent,
    disableDrag,
    showExpectedPositionFor,
    showPaddings,
    showMargins,
    showCssEditor,
    cssCode,
    dragInProcess,
    transitionMode,
  } = useSelector((state) => state.designer);
  return {
    disableSelection,
    idToBeBinded,
    idsBinded,
    components,
    heightLightComponent,
    selectedPropertiesScreenID,
    disableDrag,
    showExpectedPositionFor,
    showPaddings,
    showMargins,
    showCssEditor,
    cssCode,
    dragInProcess,
    transitionMode,
  };
}
