import { ADD_PROPERTIES_OBJECT } from "../../constants/action-types";

/**
 * Add a missing properties object. It will be removed later on as network properties are placed in root
 * @param {*} index of the component
 */
export function addPropertiesObjectToComponent(id) {
  window.store.dispatch({
    type: ADD_PROPERTIES_OBJECT,
    payLoad: {
      id: id
    }
  });
}

export function addPropertiesObjectToComponentReducer(state, action) {
                                                                       const id =
                                                                         action
                                                                           .payLoad
                                                                           .id;
                                                                       ////(id + "Adding properties");
                                                                       // return state;
                                                                       return {
                                                                         ...state,
                                                                         components: state.components.map(
                                                                           (
                                                                             item,
                                                                             i
                                                                           ) => {
                                                                             if (
                                                                               i !=
                                                                               id
                                                                             )
                                                                               return item;
                                                                             else
                                                                               return {
                                                                                 ...item,
                                                                                 properties: {
                                                                                   styles: {},
                                                                                   network: {},
                                                                                 },
                                                                               };
                                                                           }
                                                                         ),
                                                                       };
                                                                     }
