import {
    START_BINDING_PROCESS
} from "../../constants/action-types";
import produce from "immer"
import {
    findComponentByType
} from "../../constants/component_list";
import {
    toast
} from "react-toastify";

export function startStopBindings(value) {
    window.store.dispatch({
        type: START_BINDING_PROCESS,
        payLoad: {
            value
        }
    })
}
export function startStopBindingsReducer(state, action) {
    const value = action.payLoad.value;
    const type = state.components[state.selectedPropertiesScreenID].type;
    if (findComponentByType(type).isLayout) {
        toast.warn("Choose Another Component Curretly Layout is Selected");
        return state;

    }
    return produce(state, draftState => {
        draftState.disableSelection = value
        let previos = draftState.components[draftState.selectedPropertiesScreenID].fieldsForDatabase;
        if (value) {
            draftState.disableDrag = true;
            draftState.idToBeBinded = draftState.selectedPropertiesScreenID;
            draftState.idsBinded = previos ? previos.split(",") : [];
        } else {
            draftState.disableDrag = false;
            draftState.idsBinded = []
            draftState.idToBeBinded = null;
        }

    })
}