/** @format */

import React, { Component } from "react";
import ActivityDesigner from "../ComponentDesigner/ActivityDesigner";
import { connect } from "react-redux";
import { changePropertiesComponent } from "../../../store/actions/designerActions";
import { IconButton } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import Fab from "@material-ui/core/Fab";
import { addScreen } from "../../../store/actions/action-functions/addScreen";

class StoryBoard extends Component {
  state = {
    showMapper: false,
  };
  render() {
    return (
      <React.Fragment>
        <div
          style={{
            overflow: "auto",
            position: "absolute",
            bottom: "0",
            zIndex: "1000",
            maxWidth: "700px",
            display: "flex",
            flexWrap: "nowrap",
            zIndex: "10000000",
          }}
        >
          {this.props.designer.components[2].children.map((screen, key) => (
            <React.Fragment key={screen}>
              {this.managingChangeInProperties(screen)}
            </React.Fragment>
          ))}
        </div>
        {
          <div
            style={{
              position: "absolute",
              bottom: "0",
              right: "20%",
              zIndex: "1200",
            }}
          >
            <Fab color="primary" aria-label="add" onClick={() => addScreen()}>
              <AddIcon />
            </Fab>
          </div>
        }
      </React.Fragment>
    );
  }

  managingChangeInProperties = (id) => {
    let myDefaultWidth = 600 * 0.39;
    let myDefaultHeight = 960 * 0.56;
    const mobileResolutionWidth = myDefaultWidth / 1200 - 0.0001;
    const mobileResolutionHeight = myDefaultHeight / 1820 - 0.097;
    return (
      <div
        className="d-inline-block"
        onClick={(e) => {
          changePropertiesComponent(id);
        }}
        style={{ cursor: "pointer", background: "#d9d9d9" }}
      >
        <div
          className="app-designer-add-no-scroll"
          style={{
            position: "relative",
            display: "inline-block",
            marginLeft: "20px",
            height: "200px",
            transitionDuration: "0.5s",
            width: "116px",
            overflow: "hidden",
            backgroundColor: "white",
            marginTop: "10px",
            borderColor: this.props.designer.color,
            pointerEvents: "none",
          }}
        >
          <div
            style={{
              width: "600px",
              height: "999px",
              margin: "0px",
              padding: "0px",
              transform:
                "scale(" +
                mobileResolutionWidth +
                "," +
                mobileResolutionHeight +
                ")",
              transformOrigin: "top left",
            }}
          >
            <ActivityDesigner
              disableDrag={true}
              storyScreen={id}
              designer={this.props.designer}
            ></ActivityDesigner>
          </div>
        </div>
      </div>
    );
  };
}

const mapStateToProps = (state) => state;

export default connect(mapStateToProps)(StoryBoard);
