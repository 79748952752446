/** @format */

import React, { Component } from "react";
import { FormControlLabel, Switch } from "@material-ui/core";
import { makeNavigation } from "../../../store/actions/action-functions/makeActivityNavigatable";
class MakeNavigationActivity extends Component {
  state = {};
  render() {
    const { component } = this.props;
    const checked = component.isNavigatable ? component.isNavigatable : false;
    return (
      <FormControlLabel
        value={component.label}
        control={
          <Switch
            color="primary"
            checked={checked}
            onChange={(e) => this.handleChecking(e)}
          />
        }
        label={"Make Navigation Activity"}
        labelPlacement="start"
      />
    );
  }
  handleChecking(e) {
    const value = e.target.checked;
    makeNavigation(this.props.component.id, value);
  }
}

export default MakeNavigationActivity;
