/** @format */

import React from "react";
import AllProperties from "./../utils/allPropertiesMethods";
import "./properties.css";
import MaterialSelect from "../utils/bootstrapSelect";
import MaterialSlider from "./MaterialSlider";
import { styleChanged } from "./styleActions";
// import ButtonDesigns from "../ComponentDesigner/ComponentViewer/ButtonStyles/buttonDesigns";
import ColorProperties from "./ColorProperties";
import "./../SideBar/Designer.css";
import DropDown from "../ComponentDesigner/ComponentViewer/GenericPropertiesGenerator/DropDrown";
class FontProperties extends AllProperties {
  state = {
    collapsed: false,
    boldEnabled: false,
    italicEnabled: false,
    underlineEnabled: false,
  };
  giveMeSpanIcon(name, title, methodCall, icon) {
    const { component } = this.props;
    return (
      <span>
        <i
          name={name}
          title={title}
          component={component}
          style={{
            fontSize: "18px",
            cursor: "pointer",
            padding: "5px",
            background: "#ECECEC",
            textAlign: "center",
            color: this.state.underlineEnabled ? "green" : "black",
          }}
          onClick={() =>
            window.store.dispatch(styleChanged(methodCall(), name, component))
          }
          className={icon + " ml-5"}
        ></i>
      </span>
    );
  }
  render() {
    const { component } = this.props;

    // if component.properties.styles["fontWeight"] === "bold"
    // ? "green"
    // : "black",
    return (
      <>
        {/* // <DropDown label={"Font Props"}>
      //   <div */}
        {/* //     style={{ */}
        {/* //       width: "100%",
      //       padding: "2px",
      //       border: "2px solid lightgrey"
      //     }}
      //   > */}
        {/* <MaterialSelect
            label="Font Family"
            options={[
              "Courier New",
              "Courier",
              "monospace",
              "Franklin Gothic Medium",
              "Arial Narrow",
              "Arial",
              "sans-serif"
            ]}
            name={"fontFamily"}
            component={component}
          ></MaterialSelect> */}
        {/* <div className="row zero_padding mt-2">
            <div className="col-md-4 zero_padding">Font Size</div>
            <div className="col-md-8 zero_padding">
              <MaterialSlider
                name={"fontSize"}
                label="Font Size"
                component={component}
              ></MaterialSlider>
            </div>
          </div> */}
        {/* <hr style={{ background: "green", width: "100%" }} /> */}
        <td></td>
        <td>
          <div className="text-center">
            <span
              style={{
                background: "#ECECEC",
                textAlign: "center",
                display: "inline-block",
                marginLeft: "8px",
                width: "30px",
                height: "30px",
              }}
            >
              <i
                name="fontWeight"
                title="Font Weight"
                component={component}
                style={{
                  fontSize: "18px",
                  cursor: "pointer",
                  padding: "5px",
                  background: "#ECECEC",
                  textAlign: "center",
                  // color:
                  //   component.properties.styles["fontWeight"] === "bold"
                  //     ? "green"
                  //     : "black",
                }}
                onClick={() =>
                  window.store.dispatch(
                    styleChanged(this.handleBoldFont(), "fontWeight", component)
                  )
                }
                className="fas fa-bold"
              ></i>
            </span>
            <span
              style={{
                background: "#ECECEC",
                textAlign: "center",
                display: "inline-block",
                marginLeft: "8px",
                width: "30px",
                height: "30px",
              }}
            >
              <i
                name="fontStyle"
                title="Font Style"
                component={component}
                style={{
                  fontSize: "18px",
                  cursor: "pointer",
                  padding: "5px",
                  background: "#ECECEC",
                  textAlign: "center",
                  // color:
                  //   component.properties.styles["fontStyle"] === "italic"
                  //     ? "green"
                  //     : "black",
                }}
                onClick={() =>
                  window.store.dispatch(
                    styleChanged(
                      this.handleitalicEnabled(),
                      "fontStyle",
                      component
                    )
                  )
                }
                className="fas fa-italic ml-2"
              ></i>
            </span>
            <span
              style={{
                background: "#ECECEC",
                textAlign: "center",
                display: "inline-block",
                marginLeft: "8px",
                width: "30px",
                height: "30px",
              }}
            >
              <i
                name="textDecoration"
                title="Text UnderLine"
                component={component}
                style={{
                  fontSize: "18px",
                  cursor: "pointer",
                  padding: "5px",
                  background: "#ECECEC",
                  textAlign: "center",
                  // color: component.properties.styles["textDecoration"]
                  //   ? "green"
                  //   : "black",
                }}
                onClick={() =>
                  window.store.dispatch(
                    styleChanged(
                      this.handleunderlineEnabled(),
                      "textDecoration",
                      component
                    )
                  )
                }
                className="fas fa-underline ml-2"
              ></i>
            </span>
          </div>
        </td>

        {/* <ColorProperties name="color" component={component} />
            <div style={{ display,: "inline-block"
          marginLeft:"8px" }} className="ml-5">
              <ColorProperties name="backgroundColor" component={component} />
            </div>
          </div>
        </div> */}
        {/* </DropDown> */}
      </>
    );
  }
  handleitalicEnabled = () => {
    const { italicEnabled } = this.state;
    this.setState({ italicEnabled: !this.state.italicEnabled });
    if (italicEnabled) return "normal";
    else return "italic";
  };
  handleBoldFont = () => {
    const { boldEnabled } = this.state;
    this.setState({ boldEnabled: !this.state.boldEnabled });
    if (boldEnabled) return "normal";
    else return "bold";
  };
  handleunderlineEnabled = () => {
    const { underlineEnabled } = this.state;
    this.setState({ underlineEnabled: !this.state.underlineEnabled });
    if (underlineEnabled) return "none";
    return "underline";
  };
  handleCollpase = () => {
    this.setState({ collapsed: !this.state.collapsed });
  };
}

export default FontProperties;
