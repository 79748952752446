import React from "react";
import checking from "./measuring";
import "./../Properties/properties.css";

const Input = ({ name, label, component, error, ...rest }) => {
  return (
    <input
      name={name}
      style={{ height: "15px" }}
      className="form-control form-control-sm"
      value={
        component &&
        (name === "name" ? component.name : checking(name, component))
      }
      {...rest}
    />
  );
};

export default Input;
