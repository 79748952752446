import React, { useState } from "react";
import DeleteForeverOutlined from "@material-ui/icons/Delete";
import { IconButton } from "@material-ui/core";
import AssetUploadActions from "./AssetUploadActions";
import AssetFileList from "./AssetFileList";
const AssetFileListItem = ({ file, onFileDelete, onChange, appId, files }) => {
  const [open, setOpen] = useState(true);
  const [showActions, setShowActions] = useState(false);

  let children = [];
  if (file.isDir) {
    children = files.filter(f => f.parent_id == file._id);
  }
  // //(files);
  // //(children);
  return (
    <div>
      <div
        onMouseOver={e => setShowActions(true)}
        onMouseLeave={e => setShowActions(false)}
      >
        <FileFolderIcon isDir={file.isDir} /> {file.name} : {file._id}
        {showActions && (
          <span>
            <IconButton
              color="secondary"
              aria-label="delete folder"
              component="span"
              onClick={e => onFileDelete(file._id)}
              size="small"
              title={
                file.isDir
                  ? "Delete Folder: " + file.name
                  : "Delete File: " + file.name
              }
            >
              <DeleteForeverOutlined fontSize="small" />
            </IconButton>
            {file.isDir && (
              <AssetUploadActions
                onChange={onChange}
                appId={appId}
                parent_id={file._id}
                file={file}
              />
            )}
          </span>
        )}
      </div>
      {children.length > 0 && (
        <AssetFileList
          onFileDelete={onFileDelete}
          files={files}
          onChange={onChange}
          appId={appId}
          parent_id={file._id}
        />
      )}
    </div>
  );
};

const FileFolderIcon = ({ isDir }) => {
  return isDir ? (
    <i className="far fa-folder"></i>
  ) : (
    <i className="far fa-file"></i>
  );
};
export default AssetFileListItem;
