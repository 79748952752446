import factories from "../factories";
import {
    convertJSONToComponents
} from "./utils";
const newApp = factories.baseFactory(
    "Base",
    factories.initFactory(
        "App", [
            factories.activityFactory(
                "Main",
                factories.verticalFactory("base_layout", [
                    // factories.textViewFactory("lblTitle", "Fifa"),
                    // factories.buttonFactory("btnTitle", "Show All Matches")
                ])
            )
        ],
        factories.horizontalFactory("toolbar_Layout", [

        ]),
        factories.verticalFactory("Navigation_Layout", [
            factories.textViewFactory("lblNavigation", "Navigation")

        ]),
        factories.horizontalFactory("DialogeBox", [
            factories.horizontalFactory("DialogeLayout", []),

        ])
    )
);
let newAppComponents = convertJSONToComponents(newApp);
export default newAppComponents;