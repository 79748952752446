const nodeModels = {
  START: "start",
  FINISH: "finish",
  PRINT: "print",
  PLUS: "plus",
  MINUS: "minus",
  MUL: "mul",
  DIV: "div",
  LESSTHAN: "lessthan",
  GREATERTHAN: "greaterthan",
  OR: "or",
  AND: "and",
  FOR: "for",
  FOREACH: "foreach",
  WHILE: "while",
  SWITCH: "switch",
  BRANCH: "branch",
  COMPONENT: "component",
  UTILITY_FUNCTION: "utility-functions",
  BLL_FUNCTION: "bll-functions",
  VARIABLE_GETTER: "variable-getter",
  VARIABLE_SETTER: "variable-setter",
  VARIABLE_INCREMENT: "variable-increment",
  VARIABLE_DECREMENT: "variable-decrement",
  INPUT: "input",
  OUTPUT: "output"
};

export default nodeModels;
