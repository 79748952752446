import React from "react";
import { Icon, IconButton } from "@material-ui/core";

import { useDispatch } from "react-redux";
import { toggleNavBar } from "./../../store/actions/navigation.actions";

function NavBarMobileToggleButton(props) {
  const dispatch = useDispatch();

  return (
    <IconButton
      className={props.className}
      onClick={(ev) => dispatch(toggleNavBar())}
      color="inherit"
      disableRipple
    >
      {props.children}
    </IconButton>
  );
}

NavBarMobileToggleButton.defaultProps = {
  children: <Icon>menu</Icon>,
};

export default NavBarMobileToggleButton;
