/** @format */

import React, { useState, useEffect } from "react";
import { DropTarget, DragSource } from "react-dnd";
import ComponentViewerSelector from "./ComponentViewerSelector";
import flow from "lodash/flow";
import LayoutProperties from "./LayoutProperties/LayoutProperties";
import MaterialPopOver from "./Popover/MaterialPopOver";
import { getDimensionValue } from "../../Properties/utils/changeToHtmlUnits";
import { getLayoutStyleObject } from "../../Properties/utils/layoutStyleObject";
import { toast } from "react-toastify";
import { basicProps } from "./shared/BasicProps";
import {
  dropSpecs,
  dropCollect,
  dragSpec,
  dragCollect,
} from "./utils/dndForHorizentalLayouts";
import { changePropertiesComponent } from "../../../../store/actions/designerActions";
import "./selectedComponentCustomBorders.css";
import BorderLayout from "./LayoutBorder";
import Positioning from "../../Properties/positioning";
import useTempComponent from "./utils/TempElements";

const ComponentViewerHorizontalLayout = ({
  id,
  connectDropTarget,
  connectDragSource,
  hover,
}) => {
  const [state, setState] = useState({
    anchorEl: null,
  });

  const basicObject = basicProps();
  const component = basicObject.components[id];
  const { width: w, height: h } = component.properties.styles;
  const width = getDimensionValue(w);
  const height = getDimensionValue(h);
  //("Basic Object", basicObject);
  const showBorders = id === basicObject.selectedPropertiesScreenID || hover || basicObject.dragInProcess;

  const componentStyles = component.properties.styles;
  const toggle = useTempComponent(id);
  //code below needs refactoring refactoring
  let arrangingStyles = getLayoutStyleObject(
    id,
    basicObject.components,
    hover,
    basicObject.selectedPropertiesScreenID
  );
  // delete arrangingStyles.width;
  return (

    <div
      ref={(instance) => {
        connectDropTarget(instance);
        connectDragSource(instance);
      }}
      id={"component-" + id}
      className="horizontal_layout"
      style={{
        ...arrangingStyles,
        ...{
          display: "flex",
          flexFlow: "row nowrap",
        }
      }}
      onClick={(e) => {
        e.stopPropagation();
        if (basicObject.disableSelection) {
          toast.warn("Layouts Cannot Be Data Binded");
        }
        if (!basicObject.disableDrag && !basicObject.disableSelection) {
          changePropertiesComponent(id);
        }
      }}
    >

      {showBorders && <BorderLayout hover={hover} />}
      {component.children &&
        component.children.map((child, index) => (
          <ComponentViewerSelector
            id={child}
            key={index}
            components={basicObject.components}
            component={basicObject.components[child]}
          />
        ))}
    </div>
  );
};

export default flow(
  DropTarget("modules", dropSpecs, (connect, monitor) =>
    dropCollect(connect, monitor)
  ),
  DragSource("modules", dragSpec, (connect, monitor) =>
    dragCollect(connect, monitor)
  )
)(ComponentViewerHorizontalLayout);
