import { COMPONENT_BASE } from "../constants/typeConstants";
import common from "./common";
import initFactory from "./initFactory";

/**
 * factory Function to create a base node
 * @param {String} name
 * @param {Object} initObject default is init Factory
 */
export default function(name = "Base", initObject = initFactory()) {
                                                                     let obj = {
                                                                       name: name,
                                                                       type: COMPONENT_BASE,
                                                                       children: [
                                                                         initObject,
                                                                       ],
                                                                       functions: [],
                                                                     };

                                                                     // //("Loggging Common");
                                                                     // //(common);
                                                                     obj = Object.assign(
                                                                       obj,
                                                                       common
                                                                     );

                                                                     return obj;
                                                                   }
