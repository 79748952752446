import {
  COMPONENT_GRIDVIEW
} from "../constants/typeConstants";
import common from "./common";
/**
 * factory function to produce GridView Component Object
 * @param {String} name
 * @param {Array} children array
 */
export default function (name = "GridView", children = []) {
  let obj = {
    name: name,
    type: COMPONENT_GRIDVIEW,
    children: children
  };
  obj = Object.assign({}, obj, common);
  delete obj.properties;
  obj.properties = {
    properties: {}
  }
  const defaultStyles = {
    width: "match_parent",
    height: "match_parent",

  }
  obj.properties.styles = defaultStyles;
  return obj;
}