import {
    CHANGE_LABEL
} from "./../../constants/action-types";
import produce from "immer";

export function changeLabel(index, value) {
    window.store.dispatch({
        type: CHANGE_LABEL,
        payLoad: {
            index,
            value
        }

    });
}
export function changeLabelReducer(state, action) {
    const {
        index,
        value
    } = action.payLoad
    return produce(state, draftState => {
        draftState.components[index].label = value;
    })
}