import React, { Component } from "react";
import DefaultProperties from "./DefaultProperties";
import SingleProperty from "./../SingleProperty";
class LabelProperties extends Component {
  state = {};
  render() {
    const { components, id } = this.props;
    // const component = components[id];
    return (
      <React.Fragment>
        <DefaultProperties id={id} components={components}>
          {/* <SingleProperty component={component} property="label" title="Text" /> */}
        </DefaultProperties>{" "}
      </React.Fragment>
    );
  }
}

export default LabelProperties;
