import React, { Component } from "react";
import AllProperties from "../utils/allPropertiesMethods";
import { Input } from "@material-ui/core";
import { changeStyle } from "../../../store/actions/designerActions";
class ImageProperties extends AllProperties {
  state = {};
  render() {
    const { component, generate } = this.props;

    return (
      <>
        <Input
          placeholder="Img Name"
          // value={component.properties.styles.src}
          onChange={e =>
            changeStyle(
              component.id,
              "src",
              window.location.origin + "/" + e.target.value
            )
          }
        ></Input>
        <Input
          value={component.properties.styles.src}
          placeholder="Img Url"
          onChange={e => changeStyle(component.id, "src", e.target.value)}
        ></Input>
      </>
    );
  }
}

export default ImageProperties;
