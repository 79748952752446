/** @format */

import React, { useRef, useState, useEffect } from "react";
import ButtonProperties from "./ButtonProperties";
import {
  dropSpecs,
  dropCollect,
  dragSpecs,
  dragCollect,
} from "./utils/dndMethods";
import { DragSource, DropTarget } from "react-dnd";

import flow from "lodash/flow";
import { highLightKey } from "../../../../store/actions/action-functions/addBindedId";
import { getDimensionValue } from "../../Properties/utils/changeToHtmlUnits";
import {
  manageBindedId,
  removeBindedId,
} from "../../../../store/actions/action-functions/manageBindedIds";
import { changePropertiesComponent } from "../../../../store/actions/designerActions";
import { basicProps } from "./shared/BasicProps";
import { useDraggableComponent } from "./utils/dragComponent";
import { useDropComponent } from "./utils/dropComponent";
import useTempComponent from "./utils/TempElements";
import { toast } from "react-toastify";
import "./baseStyleSheet.css";
import BorderLayout from "./LayoutBorder";
import { DRAG_IN_PROGRESS } from "../../../../store/constants/action-types";
import MaterialPopOver from "./Popover/MaterialPopOver";

const ComponentViewerButton = ({ id }) => {
  const ref = useRef();
  const [state, setState] = useState({
    anchorEl: null,
    changed: false,
  });
  const basicObject = basicProps();
  const dragBehaviour = useDraggableComponent(id);
  const dropBehaviour = useDropComponent(id, ref);
  const component = basicObject.components[id];
  const { hovered } = dropBehaviour;
  const { disableSelection, idToBeBinded, disableDrag } = basicObject;
  const componentStyles = component.properties.styles;
  const width = getDimensionValue(componentStyles.width);
  const height = getDimensionValue(componentStyles.height);
  const className = component.properties.customClassName;
  const alreadyBinded = basicObject.idsBinded.find((x) => x == component.id);
  const showBorders = id === basicObject.selectedPropertiesScreenID;

  const color = componentStyles.color;
  let arrangingStyles = {
    ...componentStyles,
    width, height,
    color,
    position: "relative",
  };
  // //();
  //("arranging styles", arrangingStyles)
  const html_id = "component-" + id;

  const handleClose = (e) => {
    setState({ anchorEl: null });
  };
  const hanldeDoubleClick = (e) => {
    e.stopPropagation();
    setState({ anchorEl: e.currentTarget });
  };

  //   useEffect(() => {
  //     toggle(hovered);
  //     return () => {};
  //   }, [hovered, id, toggle]);

  useEffect(() => {
    //this checks if a component exceeding its limits
    if (ref.current.offsetWidth > ref.current.offsetParent.offsetWidth) {
      // toast.error("Error");
    }
  });

  const handleActions = event => {
    event.stopPropagation();
    //("Loggin Actions", basicObject.transitionMode);
    if (basicObject.transitionMode) {
      const target = basicObject.components[2].children.find((screen, key) => basicObject.components[screen].name == component.transitionTarget);
      changePropertiesComponent(target);
    } else {
      if (
        disableSelection &&
        idToBeBinded !== component.id &&
        !alreadyBinded
      ) {
        manageBindedId(component.id);
      }
      if (
        disableSelection &&
        idToBeBinded !== component.id &&
        alreadyBinded
      ) {
        removeBindedId(component.id);
      }

      if (!disableDrag && !disableSelection)
        changePropertiesComponent(id);
      if (disableDrag)
        highLightKey(splittingAndMakingObject(component.node_id));
    }
  }

  useEffect(() => {
    //this checks if a component exceeding its limits
    if (ref.current.offsetWidth > ref.current.offsetParent.offsetWidth || ref.current.offsetHeight > ref.current.offsetParent.offsetHeight) {
      // toast.error(`${component.label} Button Error Exceeding Limits`);
    }
  }, [arrangingStyles.width, arrangingStyles.height]);

  dropBehaviour.drop(dragBehaviour.drag(ref));

  return (
    <>
      <button
        id={html_id}
        ref={ref}
        className={className + " " + html_id}
        style={{
          ...arrangingStyles,
        }}
        onClick={handleActions}
        onDoubleClick={hanldeDoubleClick}
      >
        {showBorders && <BorderLayout />}
          {typeof component.label !== "undefined" ? component.label : "Button"}
      </button>

      {!basicObject.transitionMode && (
        <MaterialPopOver
          handleClose={handleClose}
          anchorEl={state.anchorEl}
          component={component}
        >
          <ButtonProperties component={component} />
        </MaterialPopOver>
      )}
    </>
  );
  function splittingAndMakingObject(node) {
    if (node) {
      const split = node.split(":");
      const key = split[0];
      const value = split[1];
      return {
        key,
        value,
      };
    }
  }
};

export default flow(
  DropTarget("modules", dropSpecs, (connect, monitor) =>
    dropCollect(connect, monitor)
  ),
  DragSource("modules", dragSpecs, (connect, monitor) =>
    dragCollect(connect, monitor)
  )
)(ComponentViewerButton);
