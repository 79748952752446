
import * as actions from '../constants/schema-actions';
import SchemaHandler from './handlers/SchemaHandler';
import { entities } from './../data/schemas';
const initialState = {
    entities: entities,
    selectedEntityIndex: 0
}

export const schemaDesignerReducer = (state = initialState, action) => {
    switch (action.type) {
        case (actions.ADD_ENTITY):
            return SchemaHandler.addTable(state, action.payLoad.name);
        case (actions.REMOVE_ENTITY):
            return SchemaHandler.removeTable(state, action.payLoad);
        case (actions.CHANGE_SELECTED_ENTITY):
            return { ...state, selectedEntityIndex: action.payLoad }
        default:
            return state;
    }
}



