import React from "react";
import {
  Button,
  Card,
  CardContent,
  Divider,
  TextField,
  Typography,
} from "@material-ui/core";
import { darken } from "@material-ui/core/styles/colorManipulator";
import { makeStyles } from "@material-ui/styles";

import clsx from "clsx";
import AlphaAnimate from "../shared-components/AlphaAnimate";
import AlphaCountDown from "../shared-components/AlphaCountDown";
import Logo from "./layout/Logo";

const useStyles = makeStyles((theme) => ({
  root: {
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",

    textAlign: "center",
    padding: "3.2rem",

    display: "flex",
    flexShrink: 0,
    flex: "1 1 auto",
    background:
      "radial-gradient(" +
      darken(theme.palette.primary.dark, 0.5) +
      " 0%, " +
      theme.palette.primary.dark +
      " 80%)",
    color: theme.palette.primary.contrastText,
  },
  rootInner: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
  },
  card: {
    width: "100%",
    maxWidth: "38.4rem",
  },
  cardContent: {
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    padding: "3.2rem",
    display: "flex",
    flexDirection: "column",
  },
}));

function ComingSoon() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.rootInner}>
        <AlphaAnimate animation="transition.expandIn">
          <Card className={classes.card}>
            <CardContent className={classes.cardContent}>
              <div style={{ margin: "3.2rem" }}>
                <Logo />
              </div>

              <Typography
                variant="subtitle1"
                style={{ marginBottom: "1.6rem" }}
              >
                Hey! Thank you for checking out our app.
              </Typography>

              <Typography color="textSecondary" style={{ maxWidth: "28.8rem" }}>
                It’s not quite ready yet, but we are working hard and it will be
                ready in approximately:
              </Typography>

              <AlphaCountDown endDate="2020-05-24" className="my-48" />

              <Divider style={{ width: "4.8rem" }} />

              {/* <Typography className="font-bold my-32 w-full">
                If you would like to be notified when the app is ready, you can
                subscribe to our e-mail list.
              </Typography>

              <div className="flex flex-col justify-center w-full">
                <TextField
                  className="mb-16"
                  label="Email"
                  autoFocus
                  type="email"
                  name="email"
                  variant="outlined"
                  required
                  fullWidth
                />

                <Button
                  variant="contained"
                  color="primary"
                  className="w-224 mx-auto my-16"
                  aria-label="Subscribe"
                  type="submit"
                >
                  SUBSCRIBE
                </Button>
              </div>
             */}
            </CardContent>
          </Card>
        </AlphaAnimate>
      </div>
    </div>
  );
}

export default ComingSoon;
