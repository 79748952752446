/** @format */

import React, { useState, useRef, useEffect } from "react";
import { DropTarget, DragSource } from "react-dnd";
import flow from "lodash/flow";
import {
  dropSpecs,
  dropCollect,
  dragSpecs,
  dragCollect,
} from "./utils/dndMethods";
import MaterialPopOver from "./Popover/MaterialPopOver";
import ButtonProperties from "./ButtonProperties";
import splittingAndMakingObject from "./utils/splitingKeys";
import { highLightKey } from "../../../../store/actions/action-functions/addBindedId";
import { FormControlLabel, Switch, withStyles } from "@material-ui/core";
import { getDimensionValue } from "../../Properties/utils/changeToHtmlUnits";
import { changePropertiesComponent } from "../../../../store/actions/designerActions";
import {
  manageBindedId,
  removeBindedId,
} from "../../../../store/actions/action-functions/manageBindedIds";
import { basicProps } from "./shared/BasicProps";
import useTempComponent from "./utils/TempElements";
import { useDraggableComponent } from "./utils/dragComponent";
import { useDropComponent } from "./utils/dropComponent";

const PurpleSwitch = withStyles({
  switchBase: (props) => ({
    color: props.color,
    "&$checked": {
      color: props.color ? props.color : "red",
    },
    "&$checked + $track": {
      backgroundColor: props.color,
    },
  }),
  checked: {},
  track: {},
})(Switch);

const ComponentViewerCheckBox = ({
  id,
  hover,
  connectDragSource,
  connectDropTarget,
}) => {
  const basicObject = basicProps();
  const [state, setState] = useState({
    anchorEl: null,
  });
  const ref = useRef();
  const component = basicObject.components[id];
  const alreadyBinded = basicObject.idsBinded.find((x) => x == component.id);
  const componentStyles = component.properties.styles;
  const width = getDimensionValue(componentStyles.width);
  const height = getDimensionValue(componentStyles.height);
  const color = componentStyles.color;
  const backgroundColor = componentStyles.backgroundColor;
  const dragBehaviour = useDraggableComponent(id);
  const dropBehaviour = useDropComponent(id, ref);
  const { hovered } = dropBehaviour.hovered;
  let styles = { opacity: hovered ? 1 : 0.3 };
  if (component.properties) {
    if (component.properties.styles)
      styles = { ...component.properties.styles };
  }
  const border =
    (basicObject.heightLightComponent === id && basicObject.disableDrag) ||
    alreadyBinded
      ? "2px solid black"
      : styles.border;
  const arrangingStyles = { ...componentStyles, width, height, border };

  const handleClose = (e) => {
    setState({ anchorEl: null });
  };

  const hanldeDoubleClick = (e) => {
    e.stopPropagation();
    setState({ anchorEl: e.currentTarget });
  };
  const className = component.properties.customClassName;

  const toggle = useTempComponent(id);

  //   useEffect(() => {
  //     toggle(hovered);
  //     return () => {};
  //   }, [hovered, id, toggle]);

  dropBehaviour.drop(dragBehaviour.drag(ref));

  return (
    <>
      <input
        id={"component-" + id}
        ref={ref}
        className={className}
        type={"checkbox"}
        style={{ ...arrangingStyles }}
        onDoubleClick={(e) => {
          e.stopPropagation();
          setState({ anchorEl: e.currentTarget });
        }}
        onClick={(e) => {
          e.stopPropagation();
          if (
            basicObject.disableSelection &&
            component.id !== basicObject.idToBeBinded &&
            !alreadyBinded
          ) {
            manageBindedId(component.id);
          }
          if (
            basicObject.disableSelection &&
            component.id !== basicObject.idToBeBinded &&
            alreadyBinded
          ) {
            removeBindedId(component.id);
          }
          if (!basicObject.disableDrag && !basicObject.disableSelection)
            changePropertiesComponent(id);
          if (basicObject.disableDrag)
            highLightKey(splittingAndMakingObject(component.node_id));
        }}
        value={component.properties.styles.value}
        placeholder={component.properties.styles["placeholder"]}
      />
      {!basicObject.disableDrag && (
        <MaterialPopOver
          handleClose={handleClose}
          anchorEl={state.anchorEl}
          component={component}
        >
          <ButtonProperties component={component} />
        </MaterialPopOver>
      )}
    </>
  );
};

export default flow(
  DropTarget("modules", dropSpecs, (connect, monitor) =>
    dropCollect(connect, monitor)
  ),
  DragSource("modules", dragSpecs, (connect, monitor) =>
    dragCollect(connect, monitor)
  )
)(ComponentViewerCheckBox);
