import { STORY_MODE } from "../../constants/action-types";
import produce from "immer";

export function storyMode(value) {
    window.store.dispatch({
        type: STORY_MODE,
        payLoad: {
            value
        }
    });
}

export function storyModeReducer(state, action) {
    return produce(state, draftState => {
        draftState.storyMode = action.payLoad.value;
    });
}