/** @format */

import {
    API_REQUEST_APPS_PAGE,
    API_REQUEST_APP_BLL,
    API_REQUEST_APP_BLL_RECEIVED,
    API_REQUEST_APP,
    API_REQUEST_APP_RECEIVED,
    API_DELETE_APP,
    API_APP_CREATED,
} from "../../constants/api-action-types";
import axiosInstance from "./axios";
import { API_REQUEST_APPS_PAGE_RECEIVED } from "./../../constants/api-action-types";
import baseFactory from "../../factories/baseFactory";
import newAppComponents from "../../data/newAppComponents";
import { toast } from "react-toastify";

export default class APIHandler {
    static addFunctionToApp(id, name, type = "function") {
        return axiosInstance
            .post("bll/" + id, { name })
            .then((response) => {
                window.store.dispatch({
                    type: "API_NEW_FUNCTION_ADDED",
                    payLoad: { id: id, func: response.data },
                });
            })
            .catch((error) => console.log("error in saving new bll function"));
    }
    static deleteFunctionFromApp(id, funcId) {
        console.log("Dispatching");
        return axiosInstance
            .delete("bll/" + id + "/" + funcId)
            .then((response) => {
                window.store.dispatch({
                    type: "API_BLL_FUNCTION_DELETED",
                    payLoad: { id, funcId },
                });
                toast("Deleted");
            })
            .catch((error) => {
                console.log(error);
                toast.error("Error Deleting Func");
            });
    }
    static requestApp(id) {
        window.store.dispatch({ type: API_REQUEST_APP, payLoad: { id: id } });
        return axiosInstance
            .get("apps/" + id)
            .then((response) => {
                window.store.dispatch({
                    type: API_REQUEST_APP_RECEIVED,
                    payLoad: { app: response.data },
                });
            })
            .catch((err) => console.log("Error in Api fetching" + err));
    }
    static createApp(name) {
        if (!name) return;

        const dataToSubmit = {
            name,
            appJSON: baseFactory(name),
            components: newAppComponents,
        };
        axiosInstance
            .post("apps", dataToSubmit, {
                headers: {
                    "x-auth-token": localStorage.getItem("token"),
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
            })
            .then((response) => {
                window.store.dispatch({
                    type: API_APP_CREATED,
                    payLoad: { app: response.data },
                });
            })
            .catch((error) => console.log("Error in posting new app " + error));
    }
    static deleteApp(id) {
        axiosInstance
            .delete("apps/" + id)
            .then((response) => {
                window.store.dispatch({ type: API_DELETE_APP, payLoad: { id: id } });
            })
            .catch((err) => console.log("Ërror Deleting " + id));
    }
    static requestBLL(id) {
        window.store.dispatch({ type: API_REQUEST_APP_BLL, payLoad: { id: id } });
        return axiosInstance
            .get("bll/" + id)
            .then((response) => {
                window.store.dispatch({
                    type: API_REQUEST_APP_BLL_RECEIVED,
                    payLoad: { id: id, data: response.data },
                });
            })
            .catch((err) => {
                console.log("Error Occured in Getting BLL:" + err);
            });
    }

    static requestAppsPage(page = 1) {
        window.store.dispatch({ type: API_REQUEST_APPS_PAGE, payLoad: { page } });
        return axiosInstance
            .get(
                "apps?current_page=" +
                page +
                "&per_page=" +
                process.env.REACT_APP_APPS_PER_PAGE, {
                    headers: {
                        "x-auth-token": localStorage.getItem("token"),
                        Accept: "application/json",
                        "Content-Type": "application/json",
                    },
                }
            )
            .then((response) => {
                window.store.dispatch({
                    type: API_REQUEST_APPS_PAGE_RECEIVED,
                    payLoad: {
                        page: page,
                        data: response.data,
                    },
                });
            })
            .catch((err) => {
                console.log("Error Occured in Getting apps:" + err);
            });
    }
}