import {
    COMPONENT_FREELAYOUT
} from "../constants/typeConstants";
import common from "./common";

/**
 * factory function to produce Linear horizontal Component Object
 * @param {String} name
 * @param {Array} children array
 */
export default function (name = "Free_Layout", children = []) {
    let obj = {
        name: name,
        type: COMPONENT_FREELAYOUT,
        children: children
    };
    return Object.assign(obj, common);
}