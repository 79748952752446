/** @format */

import React, { Component } from "react";
import DesignerContextMenuTrigger from "./ContextMenu/DesignerContextMenuTrigger";
import { COMPONENT_ACTIVITY } from "../../../store/constants/typeConstants";
import { changeCurrentSelectedActivity } from "../../../store/actions/designerActions";
import SingleNavigationItem from "./SingleNavigationItem";
class ComponentNavigation extends Component {
  state = { open: true };

  toggleNavigation = () => {
    this.setState({ open: !this.state.open });
    //if component is activity then change current selected activity
    const { id, components } = this.props;
    const component = components[id];
    if (component.type === COMPONENT_ACTIVITY) {
      changeCurrentSelectedActivity(id);
    }
  };

  render() {
    const {
      id,
      components,
      selectedPropertiesScreenID,
      disableDrag,
    } = this.props;

    const component = components[id];
    const hasChildren = component.children && true;
    const childLength = component.children.length > 0 && true;
    const color = selectedPropertiesScreenID === component.id ? "green" : "";
    return (
      <DesignerContextMenuTrigger id={id} components={components}>
        <SingleNavigationItem
          id={id}
          color={color}
          disableDrag={disableDrag}
          selectedPropertiesScreenID={selectedPropertiesScreenID}
          components={components}
          component={components[id]}
        >
          {hasChildren &&
            childLength &&
            component.children.map((child) => (
              <ComponentNavigation
                id={child}
                disableDrag={disableDrag}
                component={components[child]}
                components={components}
                key={child}
                selectedPropertiesScreenID={selectedPropertiesScreenID}
              />
            ))}
        </SingleNavigationItem>
      </DesignerContextMenuTrigger>
    );
  }
}

export default ComponentNavigation;
