import React from "react";
import { ThemeProvider } from "@material-ui/styles";

import theme from "./theme.js";
import { createMuiTheme } from "@material-ui/core";
const alphaTheme = createMuiTheme(theme);
const AlphaTheme = (props) => {
  return <ThemeProvider theme={alphaTheme}>{props.children}</ThemeProvider>;
};

export default AlphaTheme;
