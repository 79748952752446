import * as actions from "../actions/projectsAPIActions";
import { produce } from "immer";

const initialState = {
  pages: {},
  currentPage: 1,
  projects: {},
  total: 0,
};

export const projectsReducer = function(state = initialState, action) {
  switch (action.type) {
    case actions.PROJECTS_LOAD_PAGE_START:
      return produce(state, (draftState) => {
        draftState.pages[action.payLoad] = { loading: true };
      });
    case actions.PROJECTS_PAGE_LOADED:
      return produce(state, (draftState) => {
        draftState.pages[action.payLoad.page] = {
          loading: false,
          records: action.payLoad.data.records,
        };
        draftState.total = action.payLoad.data.total;
      });
    case actions.PROJECTS_INVALIDATE_PAGES:
      return produce(state, (draftState) => {
        draftState.pages = {};
      });
    case actions.PROJECTS_CREATED:
      return produce(state, (draftState) => {
        draftState.pages[draftState.currentPage].records.splice(
          0,
          0,
          action.payLoad
        );
      });
    case actions.PROJECTS_UPDATED:
      return produce(state, (draftState) => {
        let data = action.payLoad;
        let index = draftState.pages[draftState.currentPage].records.findIndex(
          (r) => r._id == data._id
        );
        draftState.pages[draftState.currentPage].records[index] = data;
      });
    default: {
      return state;
    }
  }
};
