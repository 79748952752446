import projectsService from "../../services/projectsService";
import {
  showBackdrop,
  hideBackdrop,
} from "./../../../AlphaDndApp/store/actions/backdrop.actions";

export const PROJECTS_LOAD_PAGE = "PROJECTS_LOAD_PAGE";
export const PROJECTS_LOAD_PAGE_START = "PROJECTS_LOAD_PAGE_START";
export const PROJECTS_PAGE_LOADED = "PROJECTS_PAGE_LOADED";
export const PROJECTS_DELETED = "PROJECTS_DELETED";
export const PROJECTS_CREATED = "PROJECTS_CREATED";
export const PROJECTS_UPDATED = "PROJECTS_UPDATED";
export const PROJECTS_INVALIDATE_PAGES = "PROJECTS_INVALIDATE_PAGES";
//this action s redux thunk action
export function loadPRojectsPage(page) {
  return (dispatch, getState) => {
    dispatch(projectsLoadPageStart(page));
    projectsService
      .getPage(page)
      .then((data) => {
        dispatch(projectsPageLoaded(page, data));
      })
      .catch((err) => {
        //(err);
      });
  };
}
function projectCreated(data) {
  return {
    type: PROJECTS_CREATED,
    payLoad: data,
  };
}
function projectDeleted(id) {
  return {
    type: PROJECTS_DELETED,
    payLoad: id,
  };
}
function invalidatePages() {
  return {
    type: PROJECTS_INVALIDATE_PAGES,
  };
}

export function createProject(newProject) {
  return (dispatch) => {
    return projectsService
      .create(newProject)
      .then((data) => {
        dispatch(projectCreated(data));
        return Promise.resolve(data);
      })
      .catch((error) => {
        //(error);
      });
  };
}
function projectUpdated(data) {
  return {
    type: PROJECTS_UPDATED,
    payLoad: data,
  };
}
export function updateProject(newProjectData) {
  return (dispatch) => {
    return projectsService
      .update(newProjectData._id, newProjectData)
      .then((data) => {
        dispatch(projectUpdated(data));
        return Promise.resolve(data);
      })
      .catch((error) => {
        //(error);
      });
  };
}
export function getSingleProject(_id) {
  return (dispatch) => {
    dispatch(showBackdrop());
    return projectsService
      .getSingleProject(_id)
      .then((data) => {
        return Promise.resolve(data);
      })
      .finally(() => {
        dispatch(hideBackdrop());
      });
  };
}
export function deleteProject(_id) {
  return (dispatch, getState) => {
    dispatch(showBackdrop());
    return projectsService
      .deleteProject(_id)
      .then((data) => {
        dispatch(invalidatePages());
      })
      .catch((err) => {
        //(err);
      })
      .finally(() => {
        dispatch(hideBackdrop());
      });
  };
}
function projectsLoadPageStart(page) {
  return {
    type: PROJECTS_LOAD_PAGE_START,
    payLoad: page,
  };
}

function projectsPageLoaded(page, data) {
  return {
    type: PROJECTS_PAGE_LOADED,
    payLoad: { page, data },
  };
}
