export default function splittingAndMakingObject(node) {
    if (node) {
        const split = node.split(":");
        const key = split[0];
        const value = split[1];
        return {
            key,
            value
        };
    }
    return {}
}