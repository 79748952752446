import React from "react";
import Dialog from "@material-ui/core/Dialog";
import Slide from "@material-ui/core/Slide";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import { makeStyles } from "@material-ui/styles";
import Button from "@material-ui/core/Button";
import messageService from "../../services/MessageService";
import Fab from "@material-ui/core/Fab";
import DeleteIcon from "@material-ui/icons/Delete";
import { PropTypes } from "prop-types";
const useStyles = makeStyles((theme) => ({
  root: {
    padding: 24,
  },
  table: {
    minWidth: 650,
  },
  titleCell: {
    width: "70%",
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  appBar: {
    position: "relative",
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  formControl: {
    margin: 0,
    fullWidth: true,

    display: "flex",
    wrap: "nowrap",
  },
  fab: {
    position: "absolute",
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
}));
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const AlphaDialog = (props) => {
  const classes = useStyles();
  const { open, setOpen, onSave, onSaveAndClose, title } = props;

  const handleSave = () => {
    onSave();
  };
  const handleSaveAndClose = () => {
    onSaveAndClose();
  };

  return (
    <Dialog
      fullScreen
      open={open}
      onClose={(e) => {
        setOpen(false);
      }}
      TransitionComponent={Transition}
    >
      <AppBar className={classes.appBar}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={(e) => {
              setOpen(false);
            }}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Typography variant="h6" className={classes.title}>
            {title}
          </Typography>
          <ButtonGroup
            color="primary"
            aria-label="outlined primary button group"
          >
            <Button
              color="inherit"
              onClick={(e) => {
                onSave();
              }}
            >
              save
            </Button>
            <Button
              color="inherit"
              onClick={(e) => {
                onSaveAndClose();
              }}
            >
              save & Close
            </Button>
          </ButtonGroup>
        </Toolbar>
      </AppBar>
      <div className={classes.root}>{props.children}</div>
      {props.delete && (
        <Fab aria-label={"delete"} className={classes.fab} color={"primary"}>
          <DeleteIcon onClick={props.handleDelete} color="secondary" />
        </Fab>
      )}
    </Dialog>
  );
};
AlphaDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  onSave: PropTypes.func,
  onSaveAndClose: PropTypes.func,
  title: PropTypes.string,
};
export default AlphaDialog;
