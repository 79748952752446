/** @format */

import React, { useState } from "react";
import { makeStyles, FormControlLabel, Switch } from "@material-ui/core";
import { separator } from "./pxSeparator";
import { useSelector } from "react-redux";
import {
  STYLE_CHANGE,
  ENABLE_TRANSITION_ACTIVITY,
  SET_TRANSITION_TARGET,
} from "../../../store/constants/action-types";
import { basicProps } from "../ComponentDesigner/ComponentViewer/shared/BasicProps";
import transitions from "@material-ui/core/styles/transitions";
import MaterialSelect from "../utils/bootstrapSelect";

const useStyles = makeStyles((theme) => ({
  root: {
    background: "#ECECEC",
    display: "inline-block",
    padding: "1px",
    marginLeft: "7px",
  },
  icons: {
    cursor: "pointer",
    fontSize: "11px",
    marginLeft: "6px",
  },
  input: {
    background: "#ECECEC",
    border: 0,
    outline: "none",
    width: "45px",
    padding: "1px",
    height: "16px",
    fontSize: "12px",
    fontWeight: "bold",
    marginLeft: "5px",
    "&$input[type=number]::-webkit-inner-spin-button": {
      display: "none",
    },
  },
  selectSpan: {
    display: "inline-block",
    marginLeft: "4px",
  },
  select: {
    cursor: "not-allowed",
    background: "#ECECEC",
    fontSize: "14.7px",
  },
  noBorder: {
    border: 0,
    height: "20px",
    paddingTop: "4px",
    textAlign: "center",
  },
  title: {
    width: "60px",
  },
}));
const TransitionProp = () => {
  const classes = useStyles();
  let { selectedPropertiesScreenID, components } = basicProps();
  let component = components[selectedPropertiesScreenID];
  let isTransition = component.isActivityTransition;
  let selected = component.transitionTarget;
  function activitiesNames() {
    return components[2].children.map((screen, key) => components[screen].name);
  }
  const handleSelect = (e) => {
    window.store.dispatch({
      type: SET_TRANSITION_TARGET,
      payLoad: {
        name: e.target.value,
      },
    });
  };
  const handleChecking = (e) => {
    let value = e.target.checked;
    window.store.dispatch({
      type: ENABLE_TRANSITION_ACTIVITY,
      payLoad: {
        status: value,
        id: selectedPropertiesScreenID,
      },
    });
  };

  return (
    <>
      <tr>
        <td className={`${classes.noBorder} ${classes.title}`}>Transition</td>
        <td className={classes.noBorder}>
          <div style={{ textAlign: "center" }}>
            <FormControlLabel
              value={isTransition}
              control={
                <Switch
                  color="primary"
                  checked={isTransition}
                  onChange={(e) => handleChecking(e)}
                />
              }
            ></FormControlLabel>
          </div>
        </td>
      </tr>
      {isTransition ? (
        <tr>
          <td className={`${classes.noBorder} ${classes.title}`}>Target</td>
          <td className={`${classes.noBorder} ${classes.title}`}>
            <select
              onChange={handleSelect}
              value={selected || ""}
              style={{
                fontSize: "14.7px",
                width: "120px",
                background: "#ECECEC",
              }}
            >
              <option value="" disabled></option>
              {activitiesNames().map((name, i) => (
                <option key={i}>{name}</option>
              ))}
            </select>
          </td>
        </tr>
      ) : (
        <tr>
          <td className={`${classes.noBorder} ${classes.title}`} colSpan={2}>
            <button className="btn  save w-100 ml-5">Add Logic</button>
          </td>
        </tr>
      )}
    </>
  );
};

export default TransitionProp;
