import React, { Component } from "react";
import AllProperties from "../utils/allPropertiesMethods";
import { Input } from "@material-ui/core";
import { styleChanged } from "./styleActions";

class InputFieldProperties extends AllProperties {
  state = {};
  render() {
    const { component } = this.props;
    return (
      <Input
        placeholder="Placeholder"
        value={component.properties.styles.placeholder}
        onChange={e =>
          window.store.dispatch(
            styleChanged(e.target.value, "placeholder", component)
          )
        }
      />
    );
  }
}

export default InputFieldProperties;
