import React, { useState } from "react";
import ProjectForm from "./ProjectForm";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import AlphaLoading from "../AlphaDndApp/shared-components/AlphaLoading";
import { loadPRojectsPage } from "./store/actions/projectsAPIActions";
import { makeStyles } from "@material-ui/core/styles";
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";
import ProjectSummary from "./ProjectSummary";
import { Grid } from "@material-ui/core";
const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    paddingTop: theme.spacing(3),
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },

  fab: {
    position: "fixed",
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
  projects: {
    padding: theme.spacing(2),
  },
}));
const Projects = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { pages, currentPage } = useSelector((state) => state.projects);
  const [projectId, setProjectId] = useState(null);
  useEffect(
    () => {
      if (!pages[currentPage]) dispatch(loadPRojectsPage(currentPage));
    },
    [currentPage, pages]
  );
  const [openForm, setOpenForm] = React.useState(false);
  const openNewForm = () => {
    setProjectId(null);
    setOpenForm(true);
  };
  if (!pages[currentPage]) {
    return null;
  }
  if (pages[currentPage].loading) return <AlphaLoading />;
  let records = pages[currentPage].records;
  const handleEdit = (_id) => {
    //(_id + " Edit");
    setProjectId(_id);
    setOpenForm(true);
  };
  // //(openForm);
  return (
    <div className={classes.root}>
      <Fab color="primary" aria-label="add new project" className={classes.fab}>
        <AddIcon onClick={openNewForm} />
      </Fab>
      <ProjectForm
        open={openForm}
        setOpen={setOpenForm}
        projectId={projectId}
      />
      {records && records.length == 0 ? (
        <p>No Records To Show</p>
      ) : (
        <Grid className={classes.projects} container spacing={2}>
            {records && records.map((project, index) => (
            <Grid key={index} item sm={12} md={6}>
              <ProjectSummary project={project} onEdit={handleEdit} />
            </Grid>
          ))}
        </Grid>
      )}
    </div>
  );
};

export default Projects;
