import { combineReducers } from "redux";

import { designerReducer as designer } from "./designerReducer";
import { schemaDesignerReducer as schema } from "./schemaDesignerReducer";
import { themeReducer as theme } from "./themeReducer";
import { apiReducer as api } from "./apiReducer";

import { bllGUIReducer as bll } from "./bllGUIReducer";

import { alphaReducer as alpha } from "./../../AlphaDndApp/store/reducers/alphaReducer";
import { projectsReducer as projects } from "../../Projects/store/reducers/projectsReducer";
import { authReducer as auth } from "./../../auth/store/reducers/authReducer";
const allReducers = combineReducers({
  bll,
  schema,
  designer,
  theme,
  api,
  alpha,
  projects,
  auth,
});

export default allReducers;
