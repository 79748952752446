import {
    addComponent
} from "../designerActions";
import {
    COMPONENT_ACTIVITY,
    COMPONENT_LINEARLAYOUT_HORIZONTAL
} from "./../../constants/typeConstants";

/**
 * Adds a Screen To the Designer
 */
export function addScreen() {
    addComponent(2, COMPONENT_ACTIVITY);
}

export function addDialog() {
    addComponent(window.store.getState().designer.components[2].dialog, COMPONENT_LINEARLAYOUT_HORIZONTAL)
}