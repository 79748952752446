/** @format */

import React, { Component } from "react";
// import TopNavigation from "./TopNavigation";
import Main from "../../../Main/Main";
import NavBar from "../../../views/pages/AlphaDnd/NavBar";
class DefaultLayout extends Component {
  render() {
    return (
      <div style={{ overflow: "hidden" }}>
        {/* <NavBar /> */}
        <Main />
        <hr />
      </div>
    );
  }
}
export default DefaultLayout;
